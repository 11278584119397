import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import AppWrapper from 'components/AppWrapper';
import TGSignupHeader from 'components/SignupHeader';
import SignupForm from 'containers/SignupForm';

import { SIGNUP_TABS } from 'appconstants';

import './styles.css';

class SignupPage extends Component {
  constructor(props) {
    super(props);
    const { state } = props.location;
    this.state = {
      redirectPath: state && state.redirectPath,
      redirectState: state && state.redirectState,
    };
  }

  handleChangeTab = (newTab) => {
    browserHistory.push(`/${newTab}`);
  };

  handleRedirect = () => {
    const { redirectPath, redirectState } = this.state;
    browserHistory.push({
      pathname: redirectPath,
      state: { redirectState },
    });
  };

  render() {
    const {
      location,
      params: { token },
      route,
      user,
      loading,
    } = this.props;
    const { redirectPath } = this.state;

    const selectedTab =
      route.path === `/${SIGNUP_TABS.signin}` ? SIGNUP_TABS.signin : SIGNUP_TABS.signup;

    return (
      <AppWrapper>
        <div className="page-signup">
          <div className="body-container signup-container">
            <TGSignupHeader
              className="signup-header"
              user={user}
              loading={loading}
              goToSignIn={() => this.handleChangeTab(SIGNUP_TABS.signin)}
            />
            <SignupForm
              location={location}
              token={token}
              defaultTab={selectedTab}
              onChangeTab={this.handleChangeTab}
              onSubmitSuccess={redirectPath && this.handleRedirect}
            />
          </div>
        </div>
      </AppWrapper>
    );
  }
}

SignupPage.propTypes = {
  location: PropTypes.object,
  user: PropTypes.object,
  loading: PropTypes.bool,
  params: PropTypes.object,
  route: PropTypes.object,
};

export default connect(({ auth }) => ({
  auth,
  user: auth.user,
  loading: auth.loading,
}))(SignupPage);
