import React from 'react';
import styled from 'styled-components';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';

const PublishRow = ({ title, description, hasError, index, goToStep }) => (
  <RowItem onClick={() => goToStep(index)}>
    {hasError ? <ErrorIcon /> : <SuccessIcon />}
    <InformationContainer>
      <RowTitle>{title}</RowTitle>
      <RowDescription>{description}</RowDescription>
    </InformationContainer>
  </RowItem>
);

const RowItem = styled.div`
  padding: 24px 0;
  display: flex;
  gap: 18px;
  border-bottom: 1px solid #d7d7d7;
  margin: 0 24px;
  cursor: pointer;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 90%;
`;

const SuccessIcon = styled(CheckCircle)`
  height: 29px;
  width: 29px;
  color: #69d27c;
`;

const ErrorIcon = styled(Cancel)`
  height: 29px;
  width: 29px;
  color: #ff6767;
`;

const RowTitle = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
`;

const RowDescription = styled.div`
  width: 100%;
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default PublishRow;
