import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Snackbar } from 'material-ui';
import { Chart } from 'react-google-charts'; // @NOTE: Error loading: https://github.com/rakannimer/react-google-charts/issues/195

import * as actionsExperiences from 'actions/experiences';
import * as actionsAuth from 'actions/auth';
import * as actionsTags from 'actions/tags';
import { selectLoadingExperiences, selectCurrentExperience } from 'selectors/experiences';

import ImageNotFound from 'images/no-image-box.png';
import ExperienceManagerPage from 'containers/ExperienceManagerPage';
import Discounts from 'containers/Discounts';

import GuestLists from 'components/GuestLists';
import ReliveForm from 'components/ReliveForm';
import { getResizedImageURL } from 'utils/helpers';
import { dateIsBeforeNow } from 'utils/dates';
import { IMAGE_SIZES } from 'appconstants';
import moment from 'moment';
import './styles.css';
import { dateFormat } from 'utils/dates';

const chartOptions = {
  enableInteractivity: false,
  legend: { position: 'none' },
  pieSliceText: 'none',
  pieSliceBorderColor: 'transparent',
  colors: ['#69D27C', '#F3F3F3'],
};

const styles = {
  modalSpinner: {
    display: 'block',
    zIndex: '1001',
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    filter: 'alpha(opacity=00)',
    background:
      'linear-gradient(24deg, rgba(125, 152, 241, 0.9) 13%, rgba(229, 120, 162, 0.9) 100%)',
  },
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
  upgradeButton: {
    style: {
      backgroundColor: '#F57AA8',
      borderRadius: 20,
      height: 29,
      lineHeight: 0,
      marginTop: 3,
      marginRight: 30,
      padding: '0px 14px 1px 14px',
      minWidth: 81,
      width: 'auto',
    },
    memberStyle: {
      backgroundColor: '#7e9bf4',
      borderRadius: 20,
      height: 29,
      lineHeight: 0,
      marginTop: '18px',
      marginRight: 0,
      minWidth: 81,
      width: 'auto',
      paddingBottom: '2px',
    },
    label: {
      textTransform: 'none',
      color: '#FFFFFF',
      padding: '0px 0px 0px 1px',
      verticalAlign: 'none',
    },
  },
  userIcon: {
    borderRadius: '50%',
    backgroundColor: '#000000',
    marginRight: '10px',
  },
};

const TABS = {
  TICKETS: 'tickets',
  DISCOUNTS: 'discounts',
  RELIVE: 'relive',
  EDIT: 'edit',
};

class ExperienceDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'tickets',
    };
  }

  UNSAFE_componentWillMount() {
    const tab = this.props.location.query['initial-tab'] || 'tickets';
    this.loadData(() => this.changeTab(tab));
  }

  getExperienceDate = (experience) => {
    const from = dateFormat(
      {
        month: 'numeric',
        day: 'numeric',
        // year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      },
      experience.startTime
    );
    const startTime = moment(experience.startTime);
    const startTimeDay = startTime.get('D');
    const startTimeMonth = startTime.get('M');
    const endTime = moment(experience.endTime);
    const endTimeDay = endTime.get('D');
    const endTimeMonth = endTime.get('M');

    const to =
      startTimeDay !== endTimeDay || startTimeMonth !== endTimeMonth
        ? dateFormat(
            {
              month: 'numeric',
              day: 'numeric',
              // year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            },
            experience.endTime
          )
        : dateFormat({ hour: 'numeric', minute: 'numeric', hour12: true }, experience.endTime);
    return `${from} to ${to}`;
  };

  loadData = (callback = null) => {
    const { loadExperience, user, fetchAllTags } = this.props;
    const { channel, alias } = this.props.params;
    this.setState({ user });
    const promises = [loadExperience({ channel, alias }), fetchAllTags()];
    Promise.all(promises).then(() => {
      if (callback) {
        callback();
      }
    });
  };

  closeErrorMessage = () => {
    this.setState({
      errorMessage: null,
    });
  };

  changeTab = (tab) => {
    this.setState({ currentTab: tab });
  };

  menuItemClass = (tab) => {
    const isSelected = tab === this.state.currentTab;
    return isSelected ? 'menu-item menu-item-selected' : 'menu-item';
  };
  guestlistProcessor = (exp) => {
    if (!exp || typeof exp.guestlist === 'undefined') {
      return 0;
    } else {
      return exp.guestlist.length;
    }
  };
  renderExperienceInfo = () => {
    const { experience, loading } = this.props;
    if (loading) {
      return <div className="experience-info" />;
    }
    const img = experience.images.length
      ? getResizedImageURL(experience.images[0].url, IMAGE_SIZES.THUMBNAIL)
      : ImageNotFound;
    const attendingPercentage =
      (this.guestlistProcessor(experience) / experience.totalTickets) * 100;
    const notAttendingPercentage = 100 - attendingPercentage;
    return (
      <div className="experience-info">
        <div className="experience-info-inner">
          <div className="experience-img">
            <img width="88px" height="58px" alt="experience" src={img} />
          </div>
          <div className="experience-name-wrapper">
            <div className="experience-name">{experience.name}</div>
            <div className="experience-date">{this.getExperienceDate(experience)}</div>
          </div>
          <div className="float-right">
            <div className="experience-guests-wrapper">
              <div className="pie-chart">
                <Chart
                  chartType="PieChart"
                  data={[
                    ['Guest', 'Percentage'],
                    ['attending', attendingPercentage],
                    ['not-attending', notAttendingPercentage],
                  ]}
                  options={chartOptions}
                  width="50px"
                  height="50px"
                />
              </div>
              <div className="info">
                <div className="info-values">
                  <span className="attending-value">{this.guestlistProcessor(experience)}</span>
                  <span className="total-value">/{experience?.totalTickets}</span>
                </div>
                <div className="info-label">Guests attending</div>
              </div>
            </div>
            <div className="info net-sales">
              <div className="info-values">${experience?.netSales?.toFixed(2)}</div>
              <div className="info-label">Net Sales</div>
            </div>
            <div className="info">
              <div className="info-values">${experience?.netSales?.toFixed(2)}</div>
              <div className="info-label">Current Payout</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderExperienceMenu = (experience) => {
    if (this.props.loading) {
      return <div className="experience-menu" />;
    }

    const isPast = dateIsBeforeNow(experience.endTime);
    return (
      <div className="experience-menu">
        <div className="experience-menu-inner">
          <div className="menu-items">
            {experience.status !== 'draft' && (
              <button
                className={this.menuItemClass(TABS.TICKETS)}
                onClick={() => this.changeTab(TABS.TICKETS)}
              >
                Tickets
              </button>
            )}
            <button
              className={this.menuItemClass(TABS.DISCOUNTS)}
              onClick={() => this.changeTab(TABS.DISCOUNTS)}
            >
              Discounts
            </button>
            {isPast && (
              <button
                className={this.menuItemClass(TABS.RELIVE)}
                onClick={() => this.changeTab(TABS.RELIVE)}
              >
                re-live
              </button>
            )}
            <button
              className={this.menuItemClass(TABS.EDIT)}
              onClick={() => this.changeTab(TABS.EDIT)}
            >
              Edit
            </button>
          </div>
          <div className="menu-actions" />
        </div>
      </div>
    );
  };

  renderDesktop(experience) {
    return (
      <div className="dashboard">
        {this.renderExperienceInfo()}
        {this.renderExperienceMenu(experience)}
        <div className="dashboard-content">{this.renderTab(experience)}</div>
      </div>
    );
  }

  renderTab = (experience) => {
    switch (this.state.currentTab) {
      case TABS.TICKETS:
        return <GuestLists experience={experience} />;
      case TABS.DISCOUNTS:
        return <Discounts experience={experience} loadData={this.loadData} />;
      case TABS.EDIT:
        return <ExperienceManagerPage params={{ id: experience.id }} />;
      case TABS.RELIVE:
        return <ReliveForm />;
      default:
        return '';
    }
  };

  renderSnackbar() {
    return (
      <Snackbar
        open={!!this.state.errorMessage}
        message={this.state.errorMessage || ''}
        autoHideDuration={4000}
        onRequestClose={this.closeErrorMessage}
        bodyStyle={styles.snackbar}
      />
    );
  }

  render() {
    const { experience } = this.props;

    return (
      <div className="dashboard-container">
        {experience && this.renderDesktop(experience)}
        {this.renderSnackbar()}
      </div>
    );
  }
}

ExperienceDashboard.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  experience: PropTypes.object,
  loadExperience: PropTypes.func.isRequired,
  fetchAllTags: PropTypes.func,
  user: PropTypes.object,
  loading: PropTypes.bool,
};

ExperienceDashboard = connect(
  (state) => {
    return {
      experience: selectCurrentExperience(state),
      loading: selectLoadingExperiences(state),
    };
  },
  {
    ...actionsTags,
    ...actionsExperiences,
    ...actionsAuth,
    ...actionsTags,
  }
)(ExperienceDashboard);

export default ExperienceDashboard;
