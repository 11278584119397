const GET_LOGGED_USER_INFO = 'GET_LOGGED_USER_INFO';
const initialState = { user: {} };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_LOGGED_USER_INFO:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
}
