export const SAVE_ARTIST_REQUEST = 'SAVE_ARTIST_REQUEST';
export const SAVE_ARTIST_SUCCESS = 'SAVE_ARTIST_SUCCESS';
export const SAVE_ARTIST_FAILURE = 'SAVE_ARTIST_FAILURE';
export const UPDATE_ARTIST_REQUEST = 'UPDATE_ARTIST_REQUEST';
export const UPDATE_ARTIST_SUCCESS = 'UPDATE_ARTIST_SUCCESS';
export const UPDATE_ARTIST_FAILURE = 'UPDATE_ARTIST_FAILURE';
export const LOAD_ARTIST_REQUEST = 'LOAD_ARTIST_REQUEST';
export const LOAD_ARTIST_SUCCESS = 'LOAD_ARTIST_SUCCESS';
export const LOAD_ARTIST_FAILURE = 'LOAD_ARTIST_FAILURE';
export const INVALIDATE_TOKEN = 'INVALIDATE_TOKEN';
export const LOADING_USER_START = 'LOADING_USER_START';
export const LOADING_USER_FINISH = 'LOADING_USER_FINISH';
export const SUBMIT_LOGIN_REQUEST = 'SUBMIT_LOGIN_REQUEST';
export const SUBMIT_LOGIN_SUCCESS = 'SUBMIT_LOGIN_SUCCESS';
export const SUBMIT_LOGIN_FAILURE = 'SUBMIT_LOGIN_FAILURE';
export const SUBMIT_FORGOT_PASSWORD_REQUEST = 'SUBMIT_FORGOT_PASSWORD_REQUEST';
export const SUBMIT_FORGOT_PASSWORD_SUCCESS = 'SUBMIT_FORGOT_PASSWORD_SUCCESS';
export const SUBMIT_FORGOT_PASSWORD_FAILURE = 'SUBMIT_FORGOT_PASSWORD_FAILURE';
export const SUBMIT_RESET_PASSWORD_REQUEST = 'SUBMIT_RESET_PASSWORD_REQUEST';
export const SUBMIT_RESET_PASSWORD_SUCCESS = 'SUBMIT_RESET_PASSWORD_SUCCESS';
export const SUBMIT_RESET_PASSWORD_FAILURE = 'SUBMIT_RESET_PASSWORD_FAILURE';
export const SUBMIT_FACEBOOKLOGIN_REQUEST = 'SUBMIT_FACEBOOKLOGIN_REQUEST';
export const SUBMIT_FACEBOOKLOGIN_SUCCESS = 'SUBMIT_FACEBOOKLOGIN_SUCCESS';
export const SUBMIT_FACEBOOKLOGIN_FAILURE = 'SUBMIT_FACEBOOKLOGIN_FAILURE';
export const SUBMIT_CREATEUSER_REQUEST = 'SUBMIT_CREATEUSER_REQUEST';
export const SUBMIT_CREATEUSER_SUCCESS = 'SUBMIT_CREATEUSER_SUCCESS';
export const SUBMIT_CREATEUSER_FAILURE = 'SUBMIT_CREATEUSER_FAILURE';
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const RESET_ERROR = 'RESET_ERROR';
export const FETCH_EXPERIENCES_REQUEST = 'FETCH_EXPERIENCES_REQUEST';
export const FETCH_EXPERIENCES_SUCCESS = 'FETCH_EXPERIENCES_SUCCESS';
export const FETCH_EXPERIENCES_FAILURE = 'FETCH_EXPERIENCES_FAILURE';
export const LOAD_EXPERIENCE_REQUEST = 'LOAD_EXPERIENCE_REQUEST';
export const LOAD_EXPERIENCE_SUCCESS = 'LOAD_EXPERIENCE_SUCCESS';
export const LOAD_EXPERIENCE_FAILURE = 'LOAD_EXPERIENCE_FAILURE';
export const LOAD_EXPERIENCE_FINISH = 'LOAD_EXPERIENCE_FINISH';
export const LOAD_CMS_EXPERIENCE_REQUEST = 'LOAD_CMS_EXPERIENCE_REQUEST';
export const LOAD_CMS_EXPERIENCE_SUCCESS = 'LOAD_CMS_EXPERIENCE_SUCCESS';
export const LOAD_CMS_EXPERIENCE_FAILURE = 'LOAD_CMS_EXPERIENCE_FAILURE';
export const LOAD_CMS_EXPERIENCE_FINISH = 'LOAD_CMS_EXPERIENCE_FINISH';
export const SAVE_EXPERIENCE_REQUEST = 'SAVE_EXPERIENCE_REQUEST';
export const SAVE_EXPERIENCE_SUCCESS = 'SAVE_EXPERIENCE_SUCCESS';
export const SAVE_EXPERIENCE_FAILURE = 'SAVE_EXPERIENCE_FAILURE';
export const PUBLISH_EXPERIENCE_REQUEST = 'PUBLISH_EXPERIENCE_REQUEST';
export const PUBLISH_EXPERIENCE_SUCCESS = 'PUBLISH_EXPERIENCE_SUCCESS';
export const PUBLISH_EXPERIENCE_FAILURE = 'PUBLISH_EXPERIENCE_FAILURE';
export const UNPUBLISH_EXPERIENCE_REQUEST = 'UNPUBLISH_EXPERIENCE_REQUEST';
export const UNPUBLISH_EXPERIENCE_SUCCESS = 'UNPUBLISH_EXPERIENCE_SUCCESS';
export const UNPUBLISH_EXPERIENCE_FAILURE = 'UNPUBLISH_EXPERIENCE_FAILURE';
export const RSVP_EXPERIENCE_REQUEST = 'RSVP_EXPERIENCE_REQUEST';
export const RSVP_EXPERIENCE_SUCCESS = 'RSVP_EXPERIENCE_SUCCESS';
export const RSVP_EXPERIENCE_FAILURE = 'RSVP_EXPERIENCE_FAILURE';
export const SAVE_FLOW_REQUEST = 'SAVE_FLOW_REQUEST';
export const SAVE_FLOW_SUCCESS = 'SAVE_FLOW_SUCCESS';
export const SAVE_FLOW_FAILURE = 'SAVE_FLOW_FAILURE';
export const UPDATE_FLOW_REQUEST = 'UPDATE_FLOW_REQUEST';
export const UPDATE_FLOW_SUCCESS = 'UPDATE_FLOW_SUCCESS';
export const UPDATE_FLOW_FAILURE = 'UPDATE_FLOW_FAILURE';
export const LOAD_FLOW_REQUEST = 'LOAD_FLOW_REQUEST';
export const LOAD_FLOW_SUCCESS = 'LOAD_FLOW_SUCCESS';
export const LOAD_FLOW_FAILURE = 'LOAD_FLOW_FAILURE';
export const SAVE_GUIDELINE_REQUEST = 'SAVE_GUIDELINE_REQUEST';
export const SAVE_GUIDELINE_SUCCESS = 'SAVE_GUIDELINE_SUCCESS';
export const SAVE_GUIDELINE_FAILURE = 'SAVE_GUIDELINE_FAILURE';
export const UPDATE_GUIDELINE_REQUEST = 'UPDATE_GUIDELINE_REQUEST';
export const UPDATE_GUIDELINE_SUCCESS = 'UPDATE_GUIDELINE_SUCCESS';
export const UPDATE_GUIDELINE_FAILURE = 'UPDATE_GUIDELINE_FAILURE';
export const LOAD_GUIDELINE_REQUEST = 'LOAD_GUIDELINE_REQUEST';
export const LOAD_GUIDELINE_SUCCESS = 'LOAD_GUIDELINE_SUCCESS';
export const LOAD_GUIDELINE_FAILURE = 'LOAD_GUIDELINE_FAILURE';
export const SAVE_IMAGE_REQUEST = 'SAVE_IMAGE_REQUEST';
export const SAVE_IMAGE_SUCCESS = 'SAVE_IMAGE_SUCCESS';
export const SAVE_IMAGE_FAILURE = 'SAVE_IMAGE_FAILURE';
export const LOAD_IMAGE_REQUEST = 'LOAD_IMAGE_REQUEST';
export const LOAD_IMAGE_SUCCESS = 'LOAD_IMAGE_SUCCESS';
export const LOAD_IMAGE_FAILURE = 'LOAD_IMAGE_FAILURE';
export const SAVE_LOCATION_REQUEST = 'SAVE_LOCATION_REQUEST';
export const SAVE_LOCATION_SUCCESS = 'SAVE_LOCATION_SUCCESS';
export const SAVE_LOCATION_FAILURE = 'SAVE_LOCATION_FAILURE';
export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';
export const LOAD_LOCATION_REQUEST = 'LOAD_LOCATION_REQUEST';
export const LOAD_LOCATION_SUCCESS = 'LOAD_LOCATION_SUCCESS';
export const LOAD_LOCATION_FAILURE = 'LOAD_LOCATION_FAILURE';
export const SAVE_TAG_REQUEST = 'SAVE_TAG_REQUEST';
export const SAVE_TAG_SUCCESS = 'SAVE_TAG_SUCCESS';
export const SAVE_TAG_FAILURE = 'SAVE_TAG_FAILURE';
export const LOAD_TAG_REQUEST = 'LOAD_TAG_REQUEST';
export const LOAD_TAG_SUCCESS = 'LOAD_TAG_SUCCESS';
export const LOAD_TAG_FAILURE = 'LOAD_TAG_FAILURE';
export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';
export const FETCH_TAGS_FINISH = 'FETCH_TAGS_FINISH';
export const OPENED_EXPERIENCE_REQUEST = 'OPENED_EXPERIENCE_REQUEST';
export const OPENED_EXPERIENCE_SUCCESS = 'OPENED_EXPERIENCE_SUCCESS';
export const OPENED_EXPERIENCE_FAILURE = 'OPENED_EXPERIENCE_FAILURE';
export const SAVE_TICKET_REQUEST = 'SAVE_TICKET_REQUEST';
export const SAVE_TICKET_SUCCESS = 'SAVE_TICKET_SUCCESS';
export const SAVE_TICKET_FAILURE = 'SAVE_TICKET_FAILURE';
export const LOAD_TICKET_REQUEST = 'LOAD_TICKET_REQUEST';
export const LOAD_TICKET_SUCCESS = 'LOAD_TICKET_SUCCESS';
export const LOAD_TICKET_FAILURE = 'LOAD_TICKET_FAILURE';
export const FETCH_TICKETS_REQUEST = 'FETCH_TICKETS_REQUEST';
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export const FETCH_TICKETS_FAILURE = 'FETCH_TICKETS_FAILURE';
export const BOX_SHADOW = {
  style: {
    boxShadow:
      '0 5px 5px -3px rgba(0,0,0,0), 0 8px 10px 1px rgba(0,0,0,0.03), 0 3px 14px 2px rgba(0,0,0,0.05)',
  },
};
export const DROP_MENU_SHADOW_STYLE = {
  style: {
    ...BOX_SHADOW.style,
    marginTop: 55,
  },
};
