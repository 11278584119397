import getMuiTheme from 'material-ui/styles/getMuiTheme';

import './configureTheme.css';

const configureTheme = () =>
  getMuiTheme({
    fontFamily: '"futuraStd medium",sans-serif',
    palette: {
      primary1Color: '#246ab2',
      accent1Color: '#f14c54',
    },
    tabs: {
      backgroundColor: 'none',
    },
    subheader: {
      color: '#246ab2',
    },
    drawer: {
      width: 300,
    },
    datePicker: {
      selectColor: 'rgba(155, 99, 248, 1)',
      headerColor: 'rgba(60, 39, 132, 1)',
    },
    overlay: {
      backgroundColor: 'rgba(60, 39, 132, 0.54)',
    },
  });

export default configureTheme;
