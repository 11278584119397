import React from 'react';
import cn from 'classnames';

import { string, oneOfType, element, node, func, oneOf, bool } from 'prop-types';

import { BUTTON_STYLES } from 'appconstants';
import './styles.css';

const Button = ({ children, type, className, onClick, disabled, buttonStyle }) => (
  <button
    type={type}
    className={cn('button-all', `button-${buttonStyle}`, className)}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: oneOfType([element, node]).isRequired,
  onClick: func,
  type: oneOf(['button', 'link', 'submit']),
  buttonStyle: oneOf(Object.keys(BUTTON_STYLES).map((k) => BUTTON_STYLES[k])),
  className: string,
  disabled: bool,
};

Button.defaultProps = {
  type: 'button',
  className: '',
  onClick: () => {},
  buttonStyle: BUTTON_STYLES.GREEN,
};

export default Button;
