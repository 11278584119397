import React from 'react';

function ListIcon({ active }) {
  return active ? (
    <svg
      width="30px"
      height="23px"
      viewBox="0 0 30 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 3</title>
      <g
        id="Pages"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="festival-schedule"
          transform="translate(-1376.000000, -901.000000)"
          stroke="#9B63F8"
          strokeWidth="3"
        >
          <g id="Group-3" transform="translate(1378.000000, 902.000000)">
            <line x1="0.382352941" y1="0.5" x2="25.6176471" y2="0.5" id="Line-5"></line>
            <line x1="0.382352941" y1="10.5" x2="25.6176471" y2="10.5" id="Line-5-Copy"></line>
            <line
              x1="0.382352941"
              y1="20.5"
              x2="25.6176471"
              y2="20.5"
              id="Line-5-Copy-2"
              strokeLinejoin="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      width="30px"
      height="23px"
      viewBox="0 0 30 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 3</title>
      <g
        id="Pages"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="festival-profile-page"
          transform="translate(-1376.000000, -901.000000)"
          stroke="#D5D5D5"
          strokeWidth="3"
        >
          <g id="Group-3" transform="translate(1378.000000, 902.000000)">
            <line x1="0.382352941" y1="0.5" x2="25.6176471" y2="0.5" id="Line-5"></line>
            <line x1="0.382352941" y1="10.5" x2="25.6176471" y2="10.5" id="Line-5-Copy"></line>
            <line
              x1="0.382352941"
              y1="20.5"
              x2="25.6176471"
              y2="20.5"
              id="Line-5-Copy-2"
              strokeLinejoin="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ListIcon;
