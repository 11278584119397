import React from 'react';
import { Card, CircularProgress } from 'material-ui';
import { object } from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';

const MemberList = ({ loading, team, styles }) => {
  return (
    <Card style={styles.cardTable}>
      <h3 style={styles.h3}>Team</h3>
      {loading ? (
        <CircularProgress />
      ) : (
        <Table selectable={false} multiSelectable={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false} enableSelectAll={false}>
            <TableRow>
              <HeaderColumn>Name</HeaderColumn>
              <HeaderColumn>Email</HeaderColumn>
              <HeaderColumn>Status</HeaderColumn>
              {/* <HeaderColumn>Invited by</HeaderColumn> */}
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            {team.map(({ id, user, role }) => (
              // invitedBy,
              <TableRow key={id}>
                <DataColumn largeText>{user.name}</DataColumn>
                <DataColumn>{user.email}</DataColumn>
                <StatusColumn pending={!user.active}>{user.active ? role : 'Pending'}</StatusColumn>
                {/* Not necessary right now but ready to be added */}
                {/* <DataColumn>{invitedBy.name}</DataColumn> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Card>
  );
};

const HeaderColumn = styled(TableCell)`
  && {
    color: #d5d5d5;
    font-family: CircularStd Book !important;
    font-size: 12px;
    font-weight: 400;
    padding: 0 !important;
    margin: 0;
  }
`;

const DataColumn = styled(TableCell)`
  && {
    font-family: CircularStd Book;
    color: #4d4d4d;
    font-size: ${({ largeText }) => (largeText ? 18 : 14)}px;
    font-weight: 400;
    padding-left: 0;
  }
`;

const StatusColumn = styled(DataColumn)`
  && {
    text-transform: capitalize;
    ${({ pending }) => !pending && 'color: #9B63F8;'}
  }
`;

MemberList.propTypes = {
  styles: object,
};

export default MemberList;
