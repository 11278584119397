import React from 'react';

function TileIcon({ active }) {
  return active ? (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 23 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 2</title>
      <g id="Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="festival-profile-page"
          transform="translate(-1339.000000, -901.000000)"
          fill="#9B63F8"
        >
          <g id="Group-2" transform="translate(1339.000000, 901.000000)">
            <rect id="Rectangle" x="0" y="0" width="8.84615385" height="8.84615385"></rect>
            <rect
              id="Rectangle-Copy-2"
              x="0"
              y="14.1538462"
              width="8.84615385"
              height="8.84615385"
            ></rect>
            <rect
              id="Rectangle-Copy"
              x="14.1538462"
              y="0"
              width="8.84615385"
              height="8.84615385"
            ></rect>
            <rect id="Rectangle-Copy-3" x="14" y="14" width="8.84615385" height="8.84615385"></rect>
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 23 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 2</title>
      <g id="Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="festival-schedule" transform="translate(-1339.000000, -901.000000)" fill="#D8D8D8">
          <g id="Group-2" transform="translate(1339.000000, 901.000000)">
            <rect id="Rectangle" x="0" y="0" width="8.84615385" height="8.84615385"></rect>
            <rect
              id="Rectangle-Copy-2"
              x="0"
              y="14.1538462"
              width="8.84615385"
              height="8.84615385"
            ></rect>
            <rect
              id="Rectangle-Copy"
              x="14.1538462"
              y="0"
              width="8.84615385"
              height="8.84615385"
            ></rect>
            <rect id="Rectangle-Copy-3" x="14" y="14" width="8.84615385" height="8.84615385"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TileIcon;
