import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { browserHistory, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ScheduleIcon from '../../images/list-icon.jsx';
import TiledIcon from '../../images/tile-icon.jsx';
import { loadExperiences, loadReliveExperiences } from 'actions/experiences';
import MainPageMob from 'containers/MainPageMob';
import AdminFooter from 'components/AdminFooter';
import ChannelDescription from 'components/ChannelDescription';
import ListExperiences from 'components/ListExperiences';
import ThumbExperiences from 'components/ThumbExperiences';
import { EXPERIENCE_CARD } from 'appconstants/experiences';
import { selectCurrentChannel, selectChannels } from 'selectors/channels';
import {
  selectCanLoadMore,
  selectExperiencesUsers,
  selectFeaturedExperiences,
  selectLoadingExperiences,
  selectNextPage,
  selectReliveExperiences,
  selectUpcomingExperiences,
} from 'selectors/experiences';
import { selectUpcomingFestivals } from 'selectors/festivals';
import { setCurrentChannel, fetchChannels } from 'actions/channels';
import './styles.css';
import IconButton from 'material-ui/IconButton';
import ScheduledExperience from 'components/ScheduledExperience/ScheduledExperience';
import PresenceSlider from 'components/PresenceSlider/PresenceSlider.jsx';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

// const styles = {
//   rsvpButton: {
//     style: {
//       marginLeft: 6,
//       height: '52px',
//       borderRadius: '8px',
//     },
//     button: {
//       backgroundImage: '#9764f0',
//       backgroundColor: '#f07da8',
//       cursor: 'pointer',
//       minWidth: '186px',
//     },
//     label: {
//       textTransform: 'initial',
//       fontSize: '20px',
//       color: '#fff',
//       fontFamily: 'CircularStd Bold',
//       lineHeight: '52px',
//     },
//   },
//   learnMoreButton: {
//     style: {
//       height: '52px',
//       borderRadius: '8px',
//     },
//     button: {
//       backgroundColor: '#FFFFFF',
//       cursor: 'pointer',
//       width: '150px',
//     },
//     label: {
//       textTransform: 'none',
//       fontSize: '20px',
//       color: '#fff',
//       fontFamily: 'CircularStd Book',
//       lineHeight: '52px',
//     },
//   },
//   expImg: {
//     height: '100%',
//     position: 'absolute',
//     objectFit: 'cover',
//     objectPosition: 'center',
//   },
// };

class MainPageChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      view: props.location.hash === '#schedule' ? 'schedule' : 'tiled',
      slideChangeTo: -1,
      category: '',
    };
    this.changeSlide = this.changeSlide.bind(this);
    this.organizeExperiences = this.organizeExperiences.bind(this);
    this.renderTiledView = this.renderTiledView.bind(this);
    this.renderListview = this.renderScheduleView.bind(this);
    this.setCategory = this.setCategory.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadData(this.props.params.channel, true);
    this.setState({
      infinitePlaya: /infiniteplaya/.test(this.props.location.pathname),
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize, false);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.params.channel !== newProps.params.channel) {
      this.loadData(newProps.params.channel, true);
    }
  }

  UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.onResize, false);
  }

  onResize = () => {
    this.setState({ ...this.state, width: window.innerWidth });
  };

  updateBackgroundColor() {
    const { currentChannel } = this.props;
    const backgroundColor = currentChannel && `background: ${currentChannel.color || '#000'};`;
    const divDgr = document.getElementById('degradeDiv');

    if (divDgr) {
      divDgr.setAttribute('style', `height: 100%; ${backgroundColor}`);
    }
  }

  changeSlide(slide) {
    this.updateBackgroundColor();
    this.refs.slider.slickGoTo(slide); // eslint-disable-line
  }

  async loadData(channel, reset = true) {
    const {
      state: { category },
      props: {
        loadExperiences,
        loadReliveExperiences,
        fetchChannels,
        setCurrentChannel,
        channels,
        nextPage,
      },
    } = this;

    if (!channels.length) {
      await fetchChannels();
    }

    setCurrentChannel(channel);

    loadExperiences(channel, nextPage, reset, category);

    if (reset) {
      loadReliveExperiences(channel, nextPage, reset, category);
    }
  }

  organizeExperiences() {
    const { upcomingExperiences } = this.props;
    const maxExperiences = Math.floor(
      this.state.width / (EXPERIENCE_CARD.WIDTH + EXPERIENCE_CARD.PADDING)
    );
    let removedFestivalExperiences = upcomingExperiences.filter((el) => !el.festival);
    const upcomingFirstRow = removedFestivalExperiences.slice(0, maxExperiences);
    const upcomingTail = removedFestivalExperiences.slice(maxExperiences);

    return { upcomingFirstRow, upcomingTail };
  }

  organizeFestivals() {
    const { upcomingFestivals } = this.props;
    const maxFestivals = Math.floor(
      this.state.width / (EXPERIENCE_CARD.WIDTH + EXPERIENCE_CARD.PADDING)
    );
    const upcomingFirstRowFestivals = upcomingFestivals.slice(0, maxFestivals);
    const upcomingTailFestivals = upcomingFestivals.slice(maxFestivals);
    return { upcomingFirstRowFestivals, upcomingTailFestivals };
  }

  renderSpinner() {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <MainCircularProgress size={250} thickness={2} />
      </div>
    );
  }

  setCategory(category) {
    this.setState(
      {
        category,
      },
      () => {
        this.loadData(this.props.params.channel, true);
      }
    );
  }

  renderTiledView() {
    const {
      // loading,
      reliveExperiences,
      // loadingUpcoming,
      loadingRelive,
    } = this.props;
    const { upcomingFirstRowFestivals, upcomingTailFestivals } = this.organizeFestivals();
    const { upcomingFirstRow, upcomingTail } = this.organizeExperiences();

    if (loadingRelive) {
      return null;
    }
    return (
      <div>
        <ListExperiences
          experiences={upcomingFirstRowFestivals}
          alignment="center"
          title={this.state.infinitePlaya ? 'Featured Camps' : 'Upcoming Festivals'}
          className="main-page-channel-list-experiences"
        />
        {!!upcomingTailFestivals.length && (
          <ListExperiences
            experiences={upcomingTailFestivals}
            className="main-page-channel-list-experiences-tail"
          />
        )}
        <ListExperiences
          experiences={upcomingFirstRow}
          alignment="center"
          title="Upcoming Experiences"
          className="main-page-channel-list-experiences"
          style={{
            paddingTop: upcomingFirstRowFestivals ? '32px' : '64px',
          }}
        />
        {!!upcomingTail.length && (
          <ListExperiences
            experiences={upcomingTail}
            className="main-page-channel-list-experiences-tail"
          />
        )}
        <ListExperiences
          className="main-page-channel-list-experiences-past"
          alignment="center"
          experiences={reliveExperiences}
          title="Re-live"
        />
      </div>
    );
  }

  renderScheduleView() {
    const { upcomingFirstRowFestivals, upcomingTailFestivals } = this.organizeFestivals();
    const { upcomingFirstRow, upcomingTail } = this.organizeExperiences();
    const orderedFestivals = [...upcomingFirstRowFestivals, ...upcomingTailFestivals].sort(
      (a, b) => new Date(a.startTime) - new Date(b.startTime)
    );
    const orderedExeriences = [...upcomingFirstRow, ...upcomingTail].sort(
      (a, b) => new Date(a.startTime) - new Date(b.startTime)
    );

    return (
      <>
        <ScheduledExperience experiences={orderedFestivals} festivals={true} />
        <ScheduledExperience experiences={orderedExeriences} />
      </>
    );
  }

  render() {
    const {
      // experiences,
      loading,
      currentChannel,
      featured,
      location,
      loadingFeatured,
      experiencesUsers,
      params,
      canLoadMore,
    } = this.props;

    const settings = {
      className: 'center',
      arrows: false,
      centerMode: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      draggable: false,
    };

    const { category } = this.state;

    this.updateBackgroundColor();

    return (
      <div className="main-page-container">
        {!loading && currentChannel && (
          <Helmet>
            <meta property="og:title" content={currentChannel.name} />
            <meta property="og:description" name="description" content={currentChannel.about} />
            <meta property="og:image" name="og:image" content={currentChannel.images[0]} />
            <meta
              property="og:image:secure_url"
              name="og:image:secure_url"
              content={currentChannel.images[0]}
            />
            <meta
              property="og:url"
              content={`${process.env.REACT_APP_APP_URL}/in/${currentChannel.urlSlug}`}
            />
            <title>{currentChannel.name}</title>
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:image" content={currentChannel.images[0]} />
            <link rel="icon" href={currentChannel.images[0]} />
          </Helmet>
        )}
        <MediaQuery query="(min-device-width: 1224px)">
          <PageWrapper style={{ height: '100%' }}>
            <div className="section-slideshow page-container-flex">
              {loadingFeatured && this.renderSpinner()}
              {!loadingFeatured && !!featured.length && (
                <PresenceSlider
                  backgroundColor={
                    currentChannel ? `background: ${currentChannel.color || '#000'};` : ''
                  }
                  settings={settings}
                  featured={featured}
                  channel={currentChannel}
                  experiencesUsers={experiencesUsers}
                  slideChangeTo={this.state.slideChangeTo}
                />
              )}
              {!loadingFeatured && !featured.length && (
                <div
                  style={{
                    textAlign: 'center',
                    margin: '40px 20px 20px',
                    color: '#fff',
                  }}
                >
                  {currentChannel && <h2>Welcome to {currentChannel.name}</h2>}
                  <p>
                    We currently have no upcoming experiences but perhaps browse our re-lives below.
                  </p>
                </div>
              )}
            </div>
            {!loadingFeatured && featured?.length > 1 && (
              <ThumbExperiences
                featured={featured}
                onClickThumb={(slide) => this.setState({ ...this.state, slideChangeTo: slide })}
                hasShadow
              />
            )}
            <div className="top-bar">
              <IconButton
                onClick={() => {
                  this.setState({ ...this.state, view: 'schedule' });
                  browserHistory.push(`${location.pathname}#schedule`);
                }}
              >
                <div className="schedule-icon">
                  <ScheduleIcon active={this.state.view === 'schedule'} />
                </div>
              </IconButton>
              <IconButton
                onClick={() => {
                  this.setState({ ...this.state, view: 'tiled' });
                  browserHistory.push(location.pathname);
                }}
              >
                <div className="tiled-icon">
                  <TiledIcon active={this.state.view === 'tiled'} />
                </div>
              </IconButton>
              <Categories>
                <Category
                  onClick={() => this.setCategory('')}
                  className={!category ? 'active' : ''}
                >
                  Browse All
                </Category>
                {currentChannel?.categories?.map(({ id, description }, index) => (
                  <Category
                    className={category === id ? 'active' : ''}
                    onClick={() => this.setCategory(id)}
                    key={index}
                  >
                    {description}
                  </Category>
                ))}
              </Categories>
            </div>
            {this.state.view === 'tiled' ? this.renderTiledView() : this.renderScheduleView()}
            {canLoadMore && (
              <div className="load-more-container">
                <LoadMoreButton
                  className="load-more-button"
                  disabled={loading}
                  onClick={() => this.loadData(params.channel, false)}
                >
                  {loading ? <CircularProgress size={25} thickness={2} /> : 'Load more experiences'}
                </LoadMoreButton>
              </div>
            )}
            {!loading && currentChannel && <ChannelDescription channel={currentChannel} />}
            <AdminFooter />
          </PageWrapper>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <MainPageMob
            view={this.state.view}
            setView={(view) => this.setState({ ...this.state, view })}
            location={location}
          />
        </MediaQuery>
      </div>
    );
  }
}

const MainCircularProgress = styled(CircularProgress)`
  & .MuiCircularProgress-circle {
    color: #fff;
  }
`;

const Categories = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex: 0 0 90%;
  position: relative;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-height: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Category = styled.div`
  height: 81px;
  display: flex;
  font-family: CircularStd Book;
  font-size: 18px;
  color: #626262
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  &.active,
  &:hover {
    border-bottom: 1px solid #9b63f8;
    color: #9b63f8;
  }
`;

const LoadMoreButton = styled.button`
  cursor: pointer;
  height: 54px;
  width: 230px;
  border-radius: 8px;
  border: 2px solid #9b63f8;
  background-color: #ffffff;
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  outline: none;
  transition: background-color 0.25s;

  & .MuiCircularProgress-circle {
    color: #9b63f8;
  }

  &:hover {
    background-color: #9b63f8;
    border: 2px solid #9b63f8;
    color: #ffffff;
  }

  &:hover .MuiCircularProgress-circle {
    color: #fff;
  }
`;

MainPageChannel.propTypes = {
  loadExperiences: PropTypes.func,
  loading: PropTypes.bool,
  params: {
    channel: PropTypes.string,
  },
  channels: PropTypes.array,
  experiences: PropTypes.array,
  currentChannel: PropTypes.object,
  featured: PropTypes.array,
  reliveExperiences: PropTypes.array,
  upcomingExperiences: PropTypes.array,
  setCurrentChannel: PropTypes.func,
  view: PropTypes.string,
  nextPage: PropTypes.string,
  canLoadMore: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  channels: selectChannels(state),
  currentChannel: selectCurrentChannel(state),
  experiences: selectUpcomingExperiences(state),
  loadingFeatured: state.experiences.loadingFeaturedExperiences,
  loadingUpcoming: state.experiences.loadingUpcomingExperiences,
  loadingRelive: state.experiences.loadingReliveExperiences,
  experiencesUsers: selectExperiencesUsers(state),
  featured: selectFeaturedExperiences(state),
  loading: selectLoadingExperiences(state),
  reliveExperiences: selectReliveExperiences(state),
  upcomingExperiences: selectUpcomingExperiences(state),
  upcomingFestivals: selectUpcomingFestivals(state),
  nextPage: selectNextPage(state),
  canLoadMore: selectCanLoadMore(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadExperiences,
      loadReliveExperiences,
      fetchChannels,
      setCurrentChannel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPageChannel));
