import React from 'react';
import PropTypes from 'prop-types';

/*const physicalItems = [
  'Description',
  'Dates',
  'Tickets',
  'Location',
  'Artists',
  'Food & Drinks',
  'Guidelines',
  'Photos',
  'Agreements',
  'Publish',
];*/

// const virtualItems = [
//   'Description',
//   'Dates',
//   'Tickets',
//   'Virtual-Venue',
//   'Featuring',
//   'Guidelines',
//   'Media',
//   'Publish',
// ];

const Navigation = ({ currentPage, goToPage, items }) => {
  return (
    <div className="experience-navigation">
      {items.map((item, index) => (
        <div
          className={index + 1 === currentPage ? 'active' : ''}
          key={item}
          onClick={() => {
            goToPage(index + 1);
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

Navigation.propTypes = {
  currentPage: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
};

export default Navigation;
