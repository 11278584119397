import React, { useCallback, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DiscountModal from 'components/FestivalDashboard/DiscountModal';
import DiscountItem from 'components/FestivalDashboard/DiscountItem';

import * as actionsDiscounts from 'actions/discounts';

import { useFestivalCMS } from 'hooks/useFestivalInformation';

import api from 'api';
import Loader from './Loader';

const Discounts = ({ alias, channel, saveDiscount, festivalId }) => {
  const [activeDiscount, setActiveDiscount] = useState(null);
  const { isLoading, data, refetch } = useFestivalCMS(alias, channel, 'discounts');
  const [discountError, setDiscountError] = useState(null);
  const [savingDiscount, setSavingDiscount] = useState(false);
  const discounts = useMemo(
    () =>
      Array.isArray(data)
        ? data.map((discount) => ({
            ...discount,
            [discount.isPercentage ? 'amountPercentage' : 'amountAbsolute']: discount.amount,
          }))
        : [],
    [data]
  );

  const submitDiscount = useCallback(async () => {
    if (!activeDiscount) {
      return;
    }

    setDiscountError(null);
    setSavingDiscount(true);

    const { code, amountAbsolute, amountPercentage, total, _id: id } = activeDiscount;

    if (
      !code ||
      !(amountAbsolute || amountPercentage) ||
      (amountAbsolute > 0 && amountPercentage > 0) ||
      amountAbsolute < 0 ||
      amountPercentage < 0 ||
      amountPercentage > 100
    ) {
      setSavingDiscount(false);
      setDiscountError('Cannot submit discount');

      return;
    }

    const dataToSend = {
      id,
      code,
      amount: amountAbsolute || amountPercentage,
      isPercentage: !!amountPercentage,
      total,
    };

    try {
      const { id } = await saveDiscount(dataToSend);
      await api.festivals.updateFestivalInformation(
        festivalId,
        'discounts',
        { discounts: id },
        false
      );

      setActiveDiscount(null);
      refetch();
    } catch (error) {
      setDiscountError(error.message);
    } finally {
      setSavingDiscount(false);
    }
  }, [activeDiscount, setDiscountError, festivalId, refetch, saveDiscount]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="discounts-title-container">
        <span className="discounts-title">Discounts</span>
        <button className="discounts-new-btn" onClick={() => setActiveDiscount({})}>
          New
        </button>
      </div>

      {activeDiscount && (
        <DiscountModal
          isOpen={true}
          error={discountError}
          onClose={() => setActiveDiscount(null)}
          discount={activeDiscount}
          onSave={submitDiscount}
          disabled={savingDiscount}
          onChange={(data) => setActiveDiscount({ ...activeDiscount, ...data })}
        />
      )}

      {discounts.map((discount) => (
        <DiscountItem
          discount={discount}
          key={`${discount.code}-${discount.createdOn}`}
          onEdit={setActiveDiscount}
        />
      ))}
      {discounts.length === 0 && (
        <div className="content">
          <span className="discounts--empty-state">
            No discounts added
            <button className="discounts-new-btn" onClick={() => setActiveDiscount({})}>
              Add discount
            </button>
          </span>
        </div>
      )}
    </div>
  );
};

// const ProgressContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
// `;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...actionsDiscounts,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Discounts);
