import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

const settingsDefault = {
  infinite: false,
  dots: false,
  arrows: true,
};

export default class TGSlider extends Component {
  static propTypes = {
    renderItem: PropTypes.func.isRequired,
    items: PropTypes.array,
    settings: PropTypes.object,
  };

  render() {
    const { items, settings, renderItem } = this.props;

    if (items && items.length) {
      const props = {
        ...settingsDefault,
        ...settings,
      };

      return (
        <Slider {...props} slidesToShow={items.length}>
          {items.map((item, index) => {
            return renderItem(item, index);
          })}
        </Slider>
      );
    }

    return false;
  }
}
