const professions = [
  {
    id: 'producer',
    description: 'Producer',
  },
  {
    id: 'art-director',
    description: 'Art Director',
  },
  {
    id: 'visual-artist',
    description: 'Visual Artist',
  },
  {
    id: 'chef',
    description: 'Chef',
  },
  {
    id: 'musician',
    description: 'Musician',
  },
  {
    id: 'dancer',
    description: 'Dancer',
  },
  {
    id: 'healing-artist',
    description: 'Healing Artist',
  },
  {
    id: 'experiential-designer',
    description: 'Experiential Designer',
  },
];

export default professions;
