export const EXPERIENCES_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
};

export const EXPERIENCE_FILTER_TYPES = {
  UPCOMING: 'upcoming',
  DRAFT: 'draft',
  PAST: 'past',
};

export const EXPERIENCE_CARD = {
  WIDTH: 254,
  PADDING: 30,
};
