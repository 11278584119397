import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import useFestivalInformation from 'hooks/useFestivalInformation';
import { rsvpExperience } from 'actions/experiences';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CheckoutScheduleItem from './CheckoutScheduleItem';
import { useFestivalExperiences } from 'hooks/useFestivalInformation';
import { selectRSVPedExp } from 'selectors/userExperiences';
// import { browserHistory } from 'react-router';

const CheckoutSchedule = ({ params, nextStep, rsvp, selectDays, rsvped, resetCheckout }) => {
  const { data: festival } = useFestivalInformation(`${params.channel}/${params.id}`);
  const { data, fetchNextPage } = useFestivalExperiences(`${params.channel}/${params.id}`);
  // SCHEDULED LIST NEVER SET
  const [scheduledList] = useState([]);
  const [scheduledItems, setScheduledItems] = useState(rsvped.map(({ _id, id }) => id || _id));
  const totalCount = festival?.experiences?.totalCount || 0;
  const addToScheduledItems = useCallback(
    (id) => {
      setScheduledItems([...scheduledItems, id]);
    },
    [scheduledItems, setScheduledItems]
  );

  const handleClick = (experience) => {
    const isScheduled = scheduledItems.findIndex((id) => id === experience._id) !== -1;
    const isUnlocked = experience.festivalUnlocked;
    if (!isScheduled && isUnlocked) {
      if (!scheduledList.find((item) => item === experience.alias)) {
        rsvp({
          alias: experience.alias,
          channel: experience.channel.urlSlug,
          tickets: [],
        }).then(() => {
          addToScheduledItems(experience._id);
        });
      }
    } else if (!isUnlocked) {
      resetCheckout({ channel: experience.channel.urlSlug, alias: experience.alias });
    }
  };

  const getLabel = useCallback(
    (experience) => () => {
      const isScheduled = scheduledItems.findIndex((id) => id === experience._id) !== -1;
      const isUnlocked = experience.festivalUnlocked;

      if (isScheduled) {
        return "I'm going";
      }

      if (isUnlocked) {
        return '+ Add to schedule';
      }

      return '+ Upgrade pass';
    },
    [scheduledItems]
  );

  const scheduledItemsNumber = useMemo(() => {
    if (data?.pages) {
      return data.pages.reduce((prev, curr) => {
        return (
          prev +
          curr.experiences.filter(({ _id }) => scheduledItems.findIndex((id) => id === _id) !== -1)
            .length
        );
      }, 0);
    }

    return 0;
  }, [data, scheduledItems]);

  const loadMore = useMemo(() => {
    let count = 0;
    data?.pages.forEach((page) => (count += page.experiences.length));
    return count < totalCount;
  }, [data, totalCount]);
  return festival ? (
    <Container>
      <Title
        $backgroundColor={festival.pageDesign.backgroundColor}
        $color={festival.pageDesign.descriptionColor}
      >
        {festival.name}
      </Title>
      <ListContainer>
        <ScheduledExperiences>
          {data?.pages.map((group) =>
            group.experiences.map((experience, index) => {
              const isUnlocked = experience.festivalUnlocked;

              return (
                <CheckoutScheduleItem
                  key={experience._id}
                  experience={experience}
                  lastStartTime={
                    index !== 0 ? festival?.experiences?.experiences[index - 1].startTime : ''
                  }
                  isLastItem={festival?.experiences?.experiences.length - 1 === index}
                  onClick={() => handleClick(experience)}
                  selectDays={selectDays}
                  getLabel={getLabel(experience)}
                  $color={!isUnlocked ? '#EB9000' : '#9b63f8'}
                />
              );
            })
          )}
        </ScheduledExperiences>
        <ButtonsContainer loadMore={loadMore}>
          <Scheduled>{`You’ve scheduled ${scheduledItemsNumber} experiences`}</Scheduled>
          {loadMore && (
            <LoadMore
              $backgroundColor={festival.pageDesign.actionButtonColor}
              $color={festival.pageDesign.fontColor}
              onClick={() => fetchNextPage()}
            >
              Load more
            </LoadMore>
          )}
          <Donebutton $color={festival.pageDesign.actionButtonColor} onClick={() => nextStep()}>
            I'm done
          </Donebutton>
        </ButtonsContainer>
      </ListContainer>
    </Container>
  ) : null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  height: 136px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  font-family: 'CircularStd Book';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Donebutton = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  color: ${({ $color }) => $color};
  font-family: 'CircularStd Book';
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
  margin-top: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  padding: 40px 0 20px;
  width: 100%;
  ${({ loadMore }) =>
    loadMore
      ? `
      height: 300px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(238,238,238,0) 0%, #F5F5F5 100%);
  `
      : `
      background-color: #f3f3f4;`}
`;

const Scheduled = styled.div`
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;

const LoadMore = styled.button`
  height: 53px;
  width: 175px;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
`;

const ScheduledExperiences = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #f3f3f4;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  justify-content: center;
`;

const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state) => ({
  rsvped: selectRSVPedExp(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ rsvp: rsvpExperience }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSchedule);
