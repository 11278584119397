import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  height: 64px;
  margin-bottom: 34px;
  padding: 0 12px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  width: 207px;
`;

export const Icon = styled.img`
  height: 21px;
  width: 21px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-right: 9px;
`;

const Input = styled.input`
  background: none;
  border: none;
  color: #838383;
  fontfamily: CircularStd Book, sans-serif;
  fontsize: 18px;
  height: 25px;
  lineheight: 25px;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export const TimeInput = styled(Input)`
  width: 50px;
`;

export const AmPmInput = styled(Input)`
  text-align: right;
  width: 40px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export const TimeZone = styled.div`
  color: #d5d5d5;
  fontfamily: CircularStd Book, sans-serif;
  fontsize: 18px;
  lineheight: 25px;
  marginleft: 9px;
`;
