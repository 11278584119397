import moment from 'moment';
import { TIME_FORMATS } from 'appconstants/time2';
import { DATE_RANGE_FORMATS, TIME_FORMATS as TIME_FORMATS_OLD } from 'appconstants/time';

// eslint-disable-next-line import/prefer-default-export
export const formatDateLapse = (startDate, endDate) =>
  `${dateFormat(TIME_FORMATS.MONTH_AND_DATETIME, startDate)} to ${dateFormat(
    TIME_FORMATS.MONTH_AND_DATETIME,
    endDate
  )}`;

export const dateFormat = (format = {}, date) => {
  const formattedDate = date ? Intl.DateTimeFormat('en', format).format(new Date(date)) : '';
  return formattedDate;
};

export const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const datesNotOnSameDay = (first, second) =>
  first.getFullYear() !== second.getFullYear() ||
  first.getMonth() !== second.getMonth() ||
  first.getDate() !== second.getDate();

const getEventMonthRange = ({ startTime, endTime, useMonthRange }) => {
  if (useMonthRange) {
    return `${dateFormat(TIME_FORMATS.EVENT_MONTH, startTime)} - ${dateFormat(
      TIME_FORMATS.EVENT_MONTH,
      endTime
    )}`;
  }
  return `${dateFormat(TIME_FORMATS.EVENT_MONTH, startTime)}`;
};

const getEventDayRange = ({ startTime, endTime, useDateRange }) => {
  if (useDateRange) {
    return `${dateFormat(TIME_FORMATS.EVENT_DAY, startTime)} - ${dateFormat(
      TIME_FORMATS.EVENT_DAY,
      endTime
    )}`;
  }
  return dateFormat(TIME_FORMATS.EVENT_DAY, startTime);
};

const getFullDateRange = ({ startTime, endTime, useDateRange, useMonthRange }) => {
  const momentStart = moment(startTime);
  // const momentEnd = moment(endTime);
  if (!useDateRange) {
    return dateFormat(TIME_FORMATS.DATETIME, startTime);
  }
  const rangeTemplate = momentStart.format(TIME_FORMATS_OLD.DATETIME_RANGE_TEMPLATE);
  let endOfRangeDay;
  if (useMonthRange) {
    endOfRangeDay = dateFormat(TIME_FORMATS.MONTH_AND_DATE_WITH_ABBREV, endTime);
  } else {
    endOfRangeDay = dateFormat(TIME_FORMATS.EVENT_DAY_WITH_ABBREV, endTime);
  }
  return `${rangeTemplate.replace(
    TIME_FORMATS_OLD.DATETIME_RANGE_TEMPLATE_REPLACE_STRING,
    endOfRangeDay
  )} | ${dateFormat(TIME_FORMATS.EVENT_TIME, startTime)}`;
};

export const getFormattedDateRange = ({ startTime, endTime, format }) => {
  const momentStart = moment(startTime);
  const momentEnd = moment(endTime);
  const useDateRange = momentEnd.diff(momentStart, 'days') > 0;
  const useMonthRange = momentEnd.month() !== momentStart.month();

  switch (format) {
    // eg. `22` or `22 - 24`
    case DATE_RANGE_FORMATS.DATE:
      return getEventDayRange({ startTime, endTime, useDateRange });
    // eg. `DEC` or `DEC - JAN`
    case DATE_RANGE_FORMATS.MONTH:
      return getEventMonthRange({ startTime, endTime, useMonthRange });
    // e.g `Tue, Dec 8th - 10th | 7:30pm.` or `Tue, Dec 28th - Jan 2nd | 7:30pm.`
    case DATE_RANGE_FORMATS.FULL_DATE:
      return getFullDateRange({ startTime, endTime, useDateRange, useMonthRange });
    default:
      return '';
  }
};

export const getFullDate = (date) => moment(date).format(TIME_FORMATS_OLD.FULL_NAMED_DAY_AND_MONTH);

export function getDateRange(start, end) {
  const startTime = new Date(start);
  const endTime = new Date(end);

  if (startTime.getMonth() === endTime.getMonth()) {
    return startTime.getDate() === endTime.getDate()
      ? dateFormat(TIME_FORMATS.FESTIVAL_EXPERIENCE_DATE_FULL_MONTH, startTime)
      : `${dateFormat(TIME_FORMATS.FESTIVAL_EXPERIENCE_DATE_FULL_MONTH, startTime)} - ${dateFormat(
          TIME_FORMATS.EVENT_DAY_WITH_ABBREV,
          endTime
        )}`;
  }

  return `${dateFormat(TIME_FORMATS.FESTIVAL_EXPERIENCE_DATE_FULL_MONTH, startTime)} - ${dateFormat(
    TIME_FORMATS.FESTIVAL_EXPERIENCE_DATE_FULL_MONTH,
    endTime
  )}`;
}

export function dateIsAfterNow(dateTime) {
  return moment(dateTime).isAfter(Date.now());
}

export function dateIsBeforeNow(dateTime) {
  return moment(dateTime).isBefore(Date.now());
}
