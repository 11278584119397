import { get } from 'lodash';

export const selectFestivals = (state) => {
  return [...get(state, 'festivals.festivals')] || [];
};

export const selectCurrentFestival = (state) => state.festivals.festivalInfo;
export const selectLoadingFestivals = (state) => state.festivals.loading;

export const selectUpcomingFestivals = selectFestivals;
