import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionsExperiences from 'actions/experiences';
import { selectRSVPedExp } from 'selectors/userExperiences';
import { selectLoadingExperiences } from 'selectors/experiences';
import { MOBILE_MAX_WIDTH, USER_TYPES } from 'appconstants';
import { selectUser } from 'selectors/user';
import Tickets from 'components/Tickets';
import MediaQuery from 'react-responsive';
import AdminHeaderMob from 'components/AdminHeaderMob';

import './styles.css';
import styled from 'styled-components';
import DefaultHelmet from 'components/DefaultHelmet';

const TicketsPage = ({ loadExperiencesByUser, user }) => {
  useEffect(() => {
    if (user.id !== USER_TYPES.GUEST) {
      loadExperiencesByUser();
    }
  }, [loadExperiencesByUser, user]);

  return (
    <StyledSection>
      <DefaultHelmet />
      <MediaQuery query={MOBILE_MAX_WIDTH}>
        <AdminHeaderMob />
      </MediaQuery>
      <Tickets />
    </StyledSection>
  );
};

const StyledSection = styled.section`
  display: flex;
  flex-grow: 1;
`;

TicketsPage.propTypes = {
  loadExperiencesByUser: PropTypes.func,
  rsvpedExperiences: PropTypes.array,
  loading: PropTypes.bool,
  user: PropTypes.object,
};

export default connect(
  (state) => ({
    rsvpedExperiences: selectRSVPedExp(state),
    loading: selectLoadingExperiences(state),
    user: selectUser(state),
  }),
  {
    ...actionsExperiences,
  }
)(TicketsPage);
