const SAVE_DISCOUNT_REQUEST = 'SAVE_DISCOUNT_REQUEST';
const SAVE_DISCOUNT_SUCCESS = 'SAVE_DISCOUNT_SUCCESS';
const SAVE_DISCOUNT_FAILURE = 'SAVE_DISCOUNT_FAILURE';
const LOAD_DISCOUNT_REQUEST = 'LOAD_DISCOUNT_REQUEST';
const LOAD_DISCOUNT_SUCCESS = 'LOAD_DISCOUNT_SUCCESS';
const LOAD_DISCOUNT_FAILURE = 'LOAD_DISCOUNT_FAILURE';
const FETCH_DISCOUNTS_REQUEST = 'FETCH_DISCOUNTS_REQUEST';
const FETCH_DISCOUNTS_SUCCESS = 'FETCH_DISCOUNTS_SUCCESS';
const FETCH_DISCOUNTS_FAILURE = 'FETCH_DISCOUNTS_FAILURE';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        tag: action,
      };
    case SAVE_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LOAD_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        tag: action,
      };
    case LOAD_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_DISCOUNTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DISCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action.response,
      };
    case FETCH_DISCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
