import React from 'react';
import { string, object, shape, bool } from 'prop-types';

const FormFieldShowingError = ({ input, meta: { touched, error }, className, ...other }) => {
  return (
    <input
      {...input}
      {...other}
      className={`${className || ''} ${touched && error && 'input-error'}`}
    />
  );
};

FormFieldShowingError.propTypes = {
  input: object,
  className: string,
  meta: shape({ error: string, touched: bool }),
};

export default FormFieldShowingError;
