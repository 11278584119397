import React, { useCallback, useMemo } from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { CircularProgress } from 'material-ui';
import TGExperienceTabItem from 'components/ExperienceTabItem';
import DefaultHelmet from 'components/DefaultHelmet';
import { connect } from 'react-redux';
import {
  selectPastExperiences,
  selectUpcomingExperiencesCMS,
  selectDraftExperiences,
} from 'selectors/experiences';
import { EXPERIENCE_FILTER_TYPES } from 'appconstants/experiences';
// import _delete from 'material-ui/svg-icons/action/delete';

const sortByTime = (a, b) => {
  if (new Date(a.startTime) < new Date(b.startTime)) {
    return -1;
  } else if (new Date(a.startTime) > new Date(b.startTime)) {
    return 1;
  }
  return 0;
};

const sortByFilter = (selectedFilter) => (expA, expB) => {
  let result = sortByTime(expA, expB);
  if (selectedFilter !== EXPERIENCE_FILTER_TYPES.UPCOMING) {
    result *= -1;
  }
  return result;
};

const TGExperiencesList = ({
  selectedFilter,
  onSelectExperience,
  onCreateExperience,
  loading: showSpinner,
  pastExperiences,
  upcomingExperiences,
  draftExperiences,
  ...props
}) => {
  const getFilteredExperiences = useMemo(() => {
    if (selectedFilter === EXPERIENCE_FILTER_TYPES.PAST) {
      return pastExperiences;
    }
    if (selectedFilter === EXPERIENCE_FILTER_TYPES.UPCOMING) {
      return upcomingExperiences;
    }
    if (selectedFilter === EXPERIENCE_FILTER_TYPES.DRAFT) {
      return draftExperiences;
    }
    return [];
  }, [selectedFilter, pastExperiences, upcomingExperiences, draftExperiences]);

  const renderExperienceTabItem = useCallback(
    (item) => (
      <TGExperienceTabItem
        key={item.id}
        experience={item}
        selectedFilter={selectedFilter}
        onSelect={() => onSelectExperience(item)}
      />
    ),
    [onSelectExperience, selectedFilter]
  );

  const renderSpinner = useCallback(
    () => (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <CircularProgress size={90} />
      </div>
    ),
    []
  );

  const renderNoExperiences = useCallback(
    () => (
      <div className="no-experiences">
        You have no {selectedFilter} experiences.
        <div className="button-container">
          <button onClick={onCreateExperience}>Create experience</button>
        </div>
      </div>
    ),
    [selectedFilter, onCreateExperience]
  );

  const filteredAndSortedExperiences = useMemo(
    () => getFilteredExperiences.sort(sortByFilter(selectedFilter)),
    [selectedFilter, getFilteredExperiences]
  );

  const showNoExperiences = useMemo(
    () => !showSpinner && filteredAndSortedExperiences.length === 0,
    [showSpinner, filteredAndSortedExperiences]
  );
  const showExperiences = useMemo(() => !showSpinner && filteredAndSortedExperiences.length > 0, [
    showSpinner,
    filteredAndSortedExperiences,
  ]);

  return (
    <div className="experience-list">
      <DefaultHelmet />
      {showSpinner && renderSpinner()}
      {showExperiences &&
        map(filteredAndSortedExperiences, (item) => {
          return renderExperienceTabItem(item);
        })}
      {showNoExperiences && renderNoExperiences()}
    </div>
  );
};

TGExperiencesList.propTypes = {
  pastExperiences: PropTypes.array,
  upcomingExperiences: PropTypes.array,
  draftExperiences: PropTypes.array,
  loading: PropTypes.bool,
  onCreateExperience: PropTypes.func,
  onSelectExperience: PropTypes.func,
  selectedFilter: PropTypes.string,
};

export default connect((state) => ({
  pastExperiences: selectPastExperiences(state, true),
  upcomingExperiences: selectUpcomingExperiencesCMS(state, true),
  draftExperiences: selectDraftExperiences(state, true),
}))(TGExperiencesList);
