import YoutubeLogoIcon1X from 'images/youtube-logo.png';
import YoutubeLogoIcon2X from 'images/youtube-logo@2x.png';
import YoutubeLogoIcon3X from 'images/youtube-logo@3x.png';
import VimeoLogoIcon1X from 'images/vimeo-logo.png';
import VimeoLogoIcon2X from 'images/vimeo-logo@2x.png';
import VimeoLogoIcon3X from 'images/vimeo-logo@3x.png';
import TwitchLogoIcon1X from 'images/twitch-logo.png';
import TwitchLogoIcon2X from 'images/twitch-logo@2x.png';
import TwitchLogoIcon3X from 'images/twitch-logo@3x.png';
import ZoomLogoIcon1X from 'images/zoom-logo.png';
import ZoomLogoIcon2X from 'images/zoom-logo@2x.png';
import ZoomLogoIcon3X from 'images/zoom-logo@3x.png';
import FacebookLogoIcon1X from 'images/facebook-logo.png';
import FacebookLogoIcon2X from 'images/facebook-logo@2x.png';
import FacebookLogoIcon3X from 'images/facebook-logo@3x.png';
import TopiaLogoIcon1X from 'images/topia-logo.png';
import TopiaLogoIcon2X from 'images/topia-logo@2x.png';
import TopiaLogoIcon3X from 'images/topia-logo@3x.png';
import InstagramLogoIcon1X from 'images/instagram-logo.png';
import InstagramLogoIcon2X from 'images/instagram-logo@2x.png';
import InstagramLogoIcon3X from 'images/instagram-logo@3x.png';
import OtherLogoIcon1X from 'images/other-logo.png';
import FestivalIcon from 'images/festival-logo.png';
import styled from 'styled-components';

export function getLogo(platform) {
  const other = (
    <OtherLogo
      src={OtherLogoIcon1X}
      srcSet={`${OtherLogoIcon1X} 1x, ${OtherLogoIcon1X} 2x`}
      alt="logo"
    />
  );

  if (!platform) {
    return other;
  }

  switch (platform.toLowerCase()) {
    case 'youtube':
      return (
        <YoutubeLogo
          src={YoutubeLogoIcon1X}
          srcSet={`${YoutubeLogoIcon1X} 1x, ${YoutubeLogoIcon2X} 2x, ${YoutubeLogoIcon3X} 3x`}
          alt="logo"
        />
      );
    case 'vimeo':
      return (
        <VimeoLogo
          src={VimeoLogoIcon1X}
          srcSet={`${VimeoLogoIcon1X} 1x, ${VimeoLogoIcon2X} 2x, ${VimeoLogoIcon3X} 3x`}
          alt="logo"
        />
      );
    case 'twitch':
      return (
        <TwitchLogo
          src={TwitchLogoIcon1X}
          srcSet={`${TwitchLogoIcon1X} 1x, ${TwitchLogoIcon2X} 2x, ${TwitchLogoIcon3X} 3x`}
          alt="logo"
        />
      );
    case 'facebook':
    case 'facebook live':
      return (
        <FacebookLogo
          src={FacebookLogoIcon1X}
          srcSet={`${FacebookLogoIcon1X} 1x, ${FacebookLogoIcon2X} 2x, ${FacebookLogoIcon3X} 3x`}
          alt="logo"
        />
      );
    case 'zoom':
      return (
        <ZoomLogo
          src={ZoomLogoIcon1X}
          srcSet={`${ZoomLogoIcon1X} 1x, ${ZoomLogoIcon2X} 2x, ${ZoomLogoIcon3X} 3x`}
          alt="logo"
        />
      );
    case 'topia':
      return (
        <TopiaLogo
          src={TopiaLogoIcon1X}
          srcSet={`${TopiaLogoIcon1X} 1x, ${TopiaLogoIcon2X} 2x, ${TopiaLogoIcon3X} 3x`}
          alt="logo"
        />
      );
    case 'instagram':
      return (
        <InstagramLogo
          src={InstagramLogoIcon1X}
          srcSet={`${InstagramLogoIcon1X} 1x, ${InstagramLogoIcon2X} 2x, ${InstagramLogoIcon3X} 3x`}
          alt="logo"
        />
      );
    case 'festival':
      return (
        <FestivalLogo
          src={FestivalIcon}
          srcSet={`${FestivalIcon} 1x, ${FestivalIcon} 2x`}
          alt="logo"
        />
      );
    default:
      return other;
  }
}

const YoutubeLogo = styled.img`
  height: 10px;
  width: 13px;
`;

const VimeoLogo = styled.img`
  height: 9px;
  width: 10px;
`;

const TopiaLogo = styled.img`
  height: 19px;
  width: 8px;
`;

const ZoomLogo = styled.img`
  height: 6px;
  width: 10px;
`;

const FacebookLogo = styled.img`
  height: 10px;
  width: 10px;
`;

const TwitchLogo = styled.img`
  height: 11px;
  width: 10px;
`;

const InstagramLogo = styled.img`
  height: 13px;
  width: 13px;
`;

const FestivalLogo = styled.img`
  height: 11px;
  width: 6px;
`;

const OtherLogo = styled.img`
  height: 16px;
  width: 4px;
`;
