import { CALL_API } from 'redux_store/middleware/api';
import {
  CREATE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  FETCH_CHANNEL,
  GET_CHANNEL_TEAM,
  GET_REV_SHARE,
  GET_SUBCATEGORIES,
  POST_REV_SHARE,
  SELECT_CURRENT_CHANNEL,
} from 'appconstants';

function updateChannel({ payload, id }) {
  const method = 'put';
  const endpoint = `/channels/${id}`;
  if (payload.channel) delete payload.channel;
  return {
    [CALL_API]: {
      types: [
        FETCH_CHANNEL.SAVE_CHANNEL_REQUEST,
        FETCH_CHANNEL.SAVE_CHANNEL_SUCCESS,
        FETCH_CHANNEL.SAVE_CHANNEL_FAILURE,
      ],
      method,
      endpoint,
      body: payload,
    },
  };
}

function getChannels() {
  const method = 'get';
  const endpoint = `/channels/`;
  return {
    [CALL_API]: {
      types: [
        FETCH_CHANNEL.FETCH_CHANNELS_REQUEST,
        FETCH_CHANNEL.FETCH_CHANNELS_SUCCESS,
        FETCH_CHANNEL.FETCH_CHANNELS_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

export function fetchChannels() {
  return (dispatch) => {
    return dispatch(getChannels());
  };
}

export function saveChannelAction({ payload, id }) {
  return async (dispatch) => {
    return dispatch(updateChannel({ payload, id }));
  };
}

export function setCurrentChannel(urlSlug) {
  return (dispatch) =>
    dispatch({
      type: SELECT_CURRENT_CHANNEL,
      urlSlug,
    });
}

export function createSubcategory(channel, subcategory) {
  const method = 'post';
  const endpoint = `/channels/${channel}/subcategories`;

  const action = {
    [CALL_API]: {
      types: [
        CREATE_SUBCATEGORY.CREATE_SUBCATEGORY_REQUEST,
        CREATE_SUBCATEGORY.CREATE_SUBCATEGORY_SUCCESS,
        CREATE_SUBCATEGORY.CREATE_SUBCATEGORY_FAILURE,
      ],
      method,
      endpoint,
      body: { subcategory },
    },
  };

  return async (dispatch) => {
    return dispatch(action);
  };
}

export function deleteSubcategory(channel, subcategoryId) {
  const method = 'delete';
  const endpoint = `/channels/${channel}/subcategories/${subcategoryId}`;

  const action = {
    [CALL_API]: {
      types: [
        DELETE_SUBCATEGORY.DELETE_SUBCATEGORY_REQUEST,
        DELETE_SUBCATEGORY.DELETE_SUBCATEGORY_SUCCESS,
        DELETE_SUBCATEGORY.DELETE_SUBCATEGORY_FAILURE,
      ],
      method,
      endpoint,
    },
  };

  return async (dispatch) => {
    return dispatch(action);
  };
}

export function getSubcategories(channel) {
  const method = 'get';
  const endpoint = `/channels/${channel}/subcategories`;

  const action = {
    [CALL_API]: {
      types: [
        GET_SUBCATEGORIES.GET_SUBCATEGORIES_REQUEST,
        GET_SUBCATEGORIES.GET_SUBCATEGORIES_SUCCESS,
        GET_SUBCATEGORIES.GET_SUBCATEGORIES_FAILURE,
      ],
      method,
      endpoint,
    },
  };

  return async (dispatch) => dispatch(action);
}

export function getRevShare(channel) {
  const method = 'get';
  const endpoint = `/channels/${channel}/rev-share`;

  const action = {
    [CALL_API]: {
      types: [
        GET_REV_SHARE.GET_REV_SHARE_REQUEST,
        GET_REV_SHARE.GET_REV_SHARE_SUCCESS,
        GET_REV_SHARE.GET_REV_SHARE_FAILURE,
      ],
      method,
      endpoint,
    },
  };

  return async (dispatch) => dispatch(action);
}

export function postRevShare(channel, channelRevShareCut) {
  const method = 'post';
  const endpoint = `/channels/${channel}/rev-share`;

  const action = {
    [CALL_API]: {
      types: [
        POST_REV_SHARE.POST_REV_SHARE_REQUEST,
        POST_REV_SHARE.POST_REV_SHARE_SUCCESS,
        POST_REV_SHARE.POST_REV_SHARE_FAILURE,
      ],
      method,
      endpoint,
      body: {
        channelRevShareCut,
      },
    },
  };

  return async (dispatch) => dispatch(action);
}

export function getChannelTeam(channel) {
  const endpoint = `/channels/${channel}/team`;
  const method = 'GET';

  const action = {
    [CALL_API]: {
      endpoint,
      method,
      types: [
        GET_CHANNEL_TEAM.GET_CHANNEL_TEAM_REQUEST,
        GET_CHANNEL_TEAM.GET_CHANNEL_TEAM_SUCCESS,
        GET_CHANNEL_TEAM.GET_CHANNEL_TEAM_FAILURE,
      ],
    },
  };

  return (dispatch) => dispatch(action);
}
