import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { Card } from 'material-ui/Card';
import Check from 'material-ui/svg-icons/action/check-circle';
import Snackbar from 'material-ui/Snackbar';
import { USER_TYPES } from 'appconstants';
import { createValidator, required, email } from 'utils/validation';
import * as actionsExperiences from 'actions/experiences';
import * as actionsAuth from 'actions/auth';
import * as actionsChannels from 'actions/channels';
import FormFieldShowingError from 'components/common/FormFieldShowingError';
import SelectFieldShowingError from 'components/common/SelectFieldShowingError';
import { selectUser } from 'selectors/user';
import MemberList from './MemberList';

const colors = {
  green: '#69D27C',
  red: 'rgba(255, 0, 13, 0.88)',
  yellow: '#FEBD21',
};

const styles = {
  snackbar: {
    backgroundColor: colors.red,
  },
  card: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
  },
  cardTable: {
    width: '100%',
    maxWidth: '900px',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '30px',
    marginTop: '10px',
    minHeight: '300px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
  },
  label: {
    color: '#999999',
    fontFamily: 'CircularStd Book',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '20px',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left',
  },
  labelViolet: {
    color: '#9B63F8',
    fontFamily: 'CircularStd Book',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '20px',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left',
  },
  labelGreen: {
    color: colors.green,
    fontFamily: 'CircularStd Book',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '20px',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left',
  },
  h3: {
    fontFamily: 'CircularStd Book',
    fontSize: '20px',
    marginTop: '10px',
    textAlign: 'left',
    marginBottom: '10px',
  },
};

const validate = createValidator({
  name: [required('name')],
  email: [required('email'), email],
  inviteType: [required('role')],
  channelId: [required('channel')],
});

class TeamSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrors: true,
      channelSelected: '',
      channels: [],
      message: '',
    };
    this.handleSubmit = this.props.handleSubmit;
    this.submit = this.submit.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.handlerChangeChannel = this.handlerChangeChannel.bind(this);
  }

  componentDidUpdate({ currentChannel: prevChannel }) {
    const {
      props: { change, currentChannel, getChannelTeam },
    } = this;

    if (currentChannel && currentChannel !== prevChannel) {
      change('channelId', currentChannel.id);
      getChannelTeam(currentChannel.id);
    }
  }

  componentDidMount() {
    const {
      props: { change, currentChannel, getChannelTeam },
    } = this;

    if (currentChannel?.id) {
      change('channelId', currentChannel.id);
      getChannelTeam(currentChannel.id);
    }
  }

  async submit(values) {
    if (values.inviteType === USER_TYPES.CREATOR && values.channelId === 'new') {
      throw new SubmissionError({
        channelId: 'Channel id is required for creators!',
        _error: 'Submission failed!',
      });
    }
    try {
      await this.props.sendCreatorInvite(values);
      this.setState({ openSnackbar: true, message: 'Invite sent correctly!' });
    } catch (e) {
      this.setState({ openSnackbar: true, message: 'Error sending invite! Try again later' });
    }
  }

  closeSnackbar() {
    this.setState({ openSnackbar: false });
  }

  handlerChangeChannel(e) {
    const channelSelected = e.target.value;
    this.setState({ channelSelected });
  }

  render() {
    const { handleSubmit, user, currentChannel, loadingChannelTeam, team } = this.props;
    const channels = currentChannel ? [currentChannel] : [];

    return (
      <div className="container-settings">
        <form className="settings-form" onSubmit={handleSubmit((values) => this.submit(values))}>
          <Card style={styles.card}>
            <div className="container-invite">
              <h3 style={styles.h3}>Invite Creators</h3>
              <p style={styles.label}>
                {' '}
                Invite creators from yours community to submit or curate experiences.{' '}
              </p>
            </div>
            <div className="container-invite flex">
              <div className="containerInput min">
                <Field
                  name="name"
                  className="simple-input"
                  component={FormFieldShowingError}
                  placeholder="Name"
                  type="text"
                />
              </div>
              <div className="containerInput min">
                <Field
                  name="email"
                  className="simple-input"
                  component={FormFieldShowingError}
                  placeholder="Email"
                  type="text"
                />
              </div>
              {user.isAdmin && (
                <div className="containerInput sel">
                  <Field
                    name="channelId"
                    className="simple-input"
                    component={SelectFieldShowingError}
                  >
                    <option id="new" value="new">
                      Create channel
                    </option>
                    {channels.map((channel) => {
                      return (
                        <option key={channel.id} id={channel.id} value={channel.id}>
                          {channel.name ||
                            `Channel pending setup (owned by ${channel.createdFor.name})`}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              )}

              <div className="containerInput sel">
                <Field
                  name="inviteType"
                  className="simple-input"
                  component={SelectFieldShowingError}
                >
                  <option id="default" value="">
                    Role
                  </option>
                  <option id="creator" value="creator">
                    {USER_TYPES.CREATOR}
                  </option>
                  <option id="producer" value="producer">
                    {USER_TYPES.PRODUCER}
                  </option>
                </Field>
              </div>
            </div>
            <button
              className="continueProfile-button button-settings"
              onClick={this.handleSubmit((values) => this.submit(values))}
            >
              <Check color="#FFFFFF" className="check" />
              <div className="container-save"> Invite </div>
            </button>
          </Card>
        </form>
        <MemberList loading={loadingChannelTeam} team={team} styles={styles} />
        {/* Commenting this out until it's implemented on backend
         */}
        <Snackbar
          open={!!this.state.openSnackbar}
          message={this.state.message}
          autoHideDuration={4000}
          onRequestClose={this.closeSnackbar}
        />
      </div>
    );
  }
}

TeamSettings.propTypes = {
  sendCreatorInvite: PropTypes.func,
  handleSubmit: PropTypes.func,
  user: PropTypes.object,
};

TeamSettings = reduxForm({
  form: 'CreatorLink',
  validate,
})(TeamSettings);

export default connect(
  (state) => ({
    user: selectUser(state),
    team: state.channels.channelTeam,
    loadingChannelTeam: state.channels.loadingChannelTeam,
    currentChannel: state.channels.currentChannel,
  }),
  {
    ...actionsAuth,
    ...actionsChannels,
    ...actionsExperiences,
  }
)(TeamSettings);
