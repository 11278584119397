import React, { Component } from 'react';
import { object, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link, withRouter } from 'react-router';
import { CircularProgress } from 'material-ui';
import ImageNotFound from 'images/no-image-box.png';
import iconLocation from 'images/icon-location.png';
import iconDate from 'images/icon-date.png';
import { saveUserRelive } from 'actions/users';
import { selectRSVPedToExperience } from 'selectors/userExperiences';
import { selectLoadingExperiences } from 'selectors/experiences';
import { getRSVPLabelAndStatus, replaceRelivePage, getResizedImageURL } from 'utils/helpers';
import { dateIsBeforeNow } from 'utils/dates';
import { EXPERIENCES_TYPES, IMAGE_SIZES, PLAYERS } from 'appconstants';
import { TIME_FORMATS } from 'appconstants/time2';
import { bindActionCreators } from 'redux';
import * as actionsExperiences from 'actions/experiences';

import routes from 'appconstants/routes';
import styled from 'styled-components';
import { dateFormat } from 'utils/dates';

const styles = {
  header: {
    style: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '92%',
      padding: '52px 4% 0 4%',
      zIndex: 9,
    },
  },
  expProfile: {
    subtitle: {
      lineHeight: '31px',
      color: '#727272',
      marginBottom: '6',
      display: 'inline-block',
    },
  },

  modalGL: {
    body: {
      maxHeight: '1400px',
      minHeight: '1400px',
      padding: '0',
      marginTop: '-20px',
    },
    content: {
      height: '100%',
      width: '100%',
      maxWidth: 'none',
      zIndex: '9999',
      transform: 'translate(0, 0)',
    },
  },

  headerGL: {
    style: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '92%',
      padding: '52px 4% 0 4%',
      zIndex: 9,
      marginTop: '-10px',
    },
  },
};

class ExperienceProfilePageMob extends Component {
  constructor() {
    super();
    this.renderItem = this.renderItem.bind(this);
    this.state = {
      experienceLocation: {},
      modalGLVisible: false,
      expArtist: '',
      loading: false,
    };
    this.showModalGL = this.showModalGL.bind(this);
    this.closeModalGL = this.closeModalGL.bind(this);
  }

  showModalGL() {
    this.setState({ modalGLVisible: true });
  }

  closeModalGL() {
    this.setState({ modalGLVisible: false });
  }

  handleRelive = async (experience) => {
    const {
      reliveUrl,
      relivePlayer,
      alias,
      channel: { urlSlug },
    } = experience;
    this.setState({ loading: true });
    if (relivePlayer === PLAYERS.YOUTUBE || relivePlayer === PLAYERS.VIMEO) {
      browserHistory.push(replaceRelivePage({ alias, channel: urlSlug }));
    } else {
      await this.props.saveUserRelive({ alias, channel: urlSlug });
      window.location.assign(reliveUrl);
    }
  };

  handleClick = (experience) => {
    if (experience.reliveUrl) {
      this.handleRelive(experience);
    }
  };

  onRSVP = () => {
    const {
      experience: {
        festivalUnlocked,
        festival,
        channel: { urlSlug: channel },
        alias,
      },
      rsvpExperience,
      router,
    } = this.props;

    if (festival && festivalUnlocked) {
      rsvpExperience({
        alias,
        channel,
        tickets: [],
      }).then(() => {
        router.push(routes.tickets);
      });
    } else if (festival) {
      router.push({
        pathname: `/in/${festival.channel.urlSlug}/f/${festival.alias}/checkout`,
        state: {
          referral: {
            channel,
            alias,
          },
        },
      });
    } else {
      router.push(`/tickets/${channel}/${alias}`);
    }
  };

  renderItem(experience) {
    const { rsvp } = this.props;
    const { loading } = this.state;
    const {
      tickets,
      channel: { name: channelName },
      // alias,
      startTime,
      endTime,
      location,
      description,
      name,
      reliveUrl,
      festival,
      // type,
    } = experience;

    const startExperience = dateFormat(TIME_FORMATS.MONTH_AND_DATETIME, startTime);
    const endExperience = dateFormat(
      {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
      },
      endTime
    );
    const { rsvpDisable, rsvpLabel } = getRSVPLabelAndStatus(experience, tickets, rsvp);
    const isPast = dateIsBeforeNow(experience.endTime);
    const isRelive = !!reliveUrl;

    let locationText = (location && (location.platform || location.name)) || '';

    if (rsvp && experience.type === EXPERIENCES_TYPES.PHYSICAL) {
      const { address, city, state } = location;

      locationText = `${address}, ${city}, ${state}`;
    }

    return (
      <section className="exp-profile" style={{ backgroundColor: '#fff' }}>
        <div className="exp-profile-header">{experience && this.renderImage(experience)}</div>
        {festival && (
          <div className="exp-content-wrap">
            <div className="exp-profile-content">
              <FestivalDetails>
                <div className="ticket-detail ticket-detail--no-margin">
                  This experience is part of
                </div>
                <FestivalDetailsLink to={`/in/${festival.channel.urlSlug}/f/${festival.alias}`}>
                  {festival.name}
                </FestivalDetailsLink>
              </FestivalDetails>
            </div>
          </div>
        )}
        <div className="exp-content-wrap">
          <div className="exp-profile-content">
            <div className="exp-title">{name}</div>
            {channelName && <div className="exp-artist-name"> by {channelName}</div>}
            <div className="exp-description-mob">
              <p>{description}</p>
            </div>
          </div>
          <div className="profile-ticket-details clearfix">
            <div className="profile-ticket-block clearfix">
              <div className="ticket-detail-label">
                <img width="14px" src={iconLocation} alt="location" /> LOCATION
              </div>
              <div>
                <div className="ticket-detail">{locationText}</div>
              </div>
            </div>

            <div className="profile-ticket-block clearfix ">
              <div className="ticket-detail-label">
                <img width="14px" src={iconDate} alt="Date" /> DATE
              </div>
              <div className="ticket-detail">{`${startExperience} to ${endExperience}`}</div>
            </div>

            {/* TEMPORARILY DISABLED */}
            {/* <Link
              onClick={this.showModalGL}
              className="profile-ticket-block clearfix"
            >
              <div className="ticket-detail-label">
                <img width="14px" src={iconGuideline} alt="Guidelines" />{" "}
                GUIDELINES
              </div>
              <div className="guide-line-label"> Read our guidelines </div>
            </Link> */}
          </div>
          <div className="ticketFooter">
            <div className="btn-ticket-experience">
              {!rsvpDisable && !isPast && (
                <Link onClick={this.onRSVP} className="btn-text-mobile">
                  RSVP: {rsvpLabel}
                </Link>
              )}
              {(rsvpDisable || isPast) && (
                <button
                  className="btn-text-mobile"
                  disabled={loading}
                  onClick={() => this.handleClick(experience)}
                >
                  {isRelive ? 'Re-live' : rsvpLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderImage({ images, description }) {
    const srcSet =
      images && images.length
        ? [
            `${getResizedImageURL(images[0].url, IMAGE_SIZES.LARGE)}`,
            `${getResizedImageURL(images[0].url, IMAGE_SIZES.X_LARGE)} 2x`,
            `${getResizedImageURL(images[0].url, IMAGE_SIZES.XX_LARGE)} 3x`,
          ].join(', ')
        : [];

    const image =
      images && images.length ? (
        <img
          src={getResizedImageURL(images[0].url, IMAGE_SIZES.LARGE)}
          alt={description}
          srcSet={srcSet}
          className="image-experience-profile-mobile"
        />
      ) : (
        <img alt="" src={ImageNotFound} className="image-experience-profile-mobile" />
      );

    return <div className="exp-image-mob">{image}</div>;
  }

  renderSpinner() {
    return (
      <div className="loader-mobile-container">
        <CircularProgress size={150} thickness={10} color={'#976efa'} />
      </div>
    );
  }

  render() {
    const { loading, experience, router } = this.props;

    return (
      <section className="page-container">
        {(loading || !experience) && this.renderSpinner()}
        <header style={styles.header.style}>
          <Link onClick={router.goBack} className="btn-back-mob">
            {''}
          </Link>
          <Link className="btn-share-mob">{''}</Link>
        </header>
        {!loading && experience && this.renderItem(experience)}
      </section>
    );
  }
}

const FestivalDetails = styled.div`
  margin-bottom: 10px;
`;

const FestivalDetailsLink = styled(Link)`
  color: #a55bff;
  font-size: 22px;
  padding-left: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

ExperienceProfilePageMob.propTypes = {
  loading: bool,
  experience: object,
  rsvp: bool,
  saveUserRelive: func.isRequired,
  rsvpExperience: func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: selectLoadingExperiences(state),
  rsvp: selectRSVPedToExperience(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveUserRelive,
      ...actionsExperiences,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExperienceProfilePageMob));
