import React from 'react';
import { bool, oneOfType, string, func } from 'prop-types';
import Snackbar from 'material-ui/Snackbar';
import NounAlert from 'images/noun-alert.png';
import SuccessAlert from 'images/success-alert.png';
import CloseIcon from 'images/close.png';

import { SNACKBAR_TYPES } from 'appconstants';
import './styles.css';

const SnackBar = ({ open, message, title, type, onRequestClose, ...props }) => {
  const snackbarBody = () => {
    const isSuccess = type === SNACKBAR_TYPES.SUCCESS;
    return (
      <div className="snackbar-container">
        <div className="snackbar-header">
          <button className="snackbar-close-icon-wrapper" onClick={onRequestClose}>
            <img className="snackbar-close-icon" src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="snackbar-body">
          <div className="snackbar-image-wrapper">
            <img src={isSuccess ? SuccessAlert : NounAlert} alt="" />
          </div>
          <div>
            <p className="snackbar-title">{title}</p>
            <p className="snackbar-description">{message}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Snackbar
      open={open}
      className="snackbar-root"
      message={snackbarBody()}
      {...props}
      bodyStyle={{ backgroundColor: 'none', height: '99px' }}
      onRequestClose={onRequestClose}
    />
  );
};

SnackBar.propTypes = {
  open: bool,
  message: string,
  title: string,
  type: oneOfType([SNACKBAR_TYPES.SUCCESS, SNACKBAR_TYPES.ERROR]),
  onRequestClose: func,
};

export default SnackBar;
