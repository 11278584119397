import React from 'react';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    margin: 20,
    flex: 1,
  },
};

const TermsPage = () => {
  return (
    <div style={styles.root}>
      <h1>Terms of Service</h1>
      <p>
        Carefully read the terms and conditions set forth in this Terms of Use
        (&quot;AGREEMENT&quot;) before downloading, installing or using this application
        (&quot;APPLICATION&quot;) to your wireless communications device. To the extent you
        download, install and/or use this APPLICATION, you agree to be bound by all of the terms and
        conditions set forth in this AGREEMENT.
      </p>
      <p>
        <strong>Agreement.</strong> This AGREEMENT is between you and &quot;REACH PRESENCE LLC&quot;
        (&quot;DEVELOPER&quot;). Your wireless service provider is not a party to this AGREEMENT.
      </p>
      <p>
        <strong>Limited License.</strong> The DEVELOPER grants to you a worldwide, non-exclusive,
        limited license or equivalent right to download, install and/or use the object code version
        of the APPLICATION (referred to below as the &quot;Licensed Application&quot;) on your
        wireless communications device. All rights not expressly granted in this AGREEMENT are
        reserved by the DEVELOPER. This license granted to you for the Licensed Application by
        Developer is limited to a non-transferable license to use the Licensed Application on any
        mobile device that you own or control in accordance with this license and any usage rules
        posted by Developer in connection with the Licensed Application. This license does not allow
        you to use the Licensed Application on any mobile device that you do not own or control, and
        you may not distribute or make the Licensed Application available over a network where it
        could be used by multiple devices at the same time. You may not rent, lease, lend, sell,
        redistribute or sublicense the Licensed Application. You may not copy (except as expressly
        permitted by this license and any additional or subsequent usage rules posted by Developer
        in connection with the Licensed Application), decompile, reverse engineer, disassemble,
        attempt to derive the source code of, modify, or create derivative works of the Licensed
        Application, any updates, or any part thereof (except as and only to the extent any
        foregoing restriction is prohibited by applicable law or to the extent as may be permitted
        by the licensing terms governing use of any open sourced components included with the
        Licensed Application). Any attempt to do so is a violation of the rights of the Developer
        and its licensors. If you breach this restriction, you may be subject to prosecution and
        damages. The terms of the license will govern any upgrades provided by Developer that
        replace and/or supplement the original Product, unless such upgrade is accompanied by a
        separate license in which case the terms of that license will govern
      </p>
      <p>
        <strong>Other Software.</strong> Notwithstanding any other provision of this AGREEMENT, this
        AGREEMENT shall not be deemed to apply to any software that is licensed to you by third
        parties and that is included on your wireless communications device (&quot;OTHER
        SOFTWARE&quot;). Any such OTHER SOFTWARE is subject to the terms and conditions of the
        AGREEMENT, if any, between you and the developer or licensor of the OTHER SOFTWARE.
      </p>
      <p>
        <strong>Restrictions.</strong> No license is granted to you by this AGREEMENT in the human
        readable code, known as the source code, of the APPLICATION, and no rights are granted to
        you by this AGREEMENT in any patents, copyrights, trade secrets, trademarks or any other
        rights in respect of the APPLICATION. In addition, no license is granted to you by this
        AGREEMENT to decompile, reverse engineer, disassemble or modify the APPLICATION. Further, no
        license is granted to you by this AGREEMENT to (i) remove, efface or obscure any copyright
        or other proprietary notices from the APPLICATION or (ii) exploit, copy, reproduce,
        sublicense, transfer or assign the APPLICATION.
      </p>
      <p>
        <strong>Ownership.</strong> You agree that the DEVELOPER retains all right, title and
        interest in and to the APPLICATION and all copies of the APPLICATION. This includes all
        copyrights in the APPLICATION.
      </p>
      <p>
        <strong>Wireless Network Usage.</strong> APPLICATION may send and receive data over your
        wireless service providers. network. Standard data service charges may apply. Check with
        your wireless service provider for additional information.
      </p>
      <p>
        <strong>Consent to Use of Data.</strong> To facilitate product support and other services to
        you, you agree that &quot;REACH PRESENCE LLC&quot; may collect, use, store, and transmit
        non-personally identifiable technical and related information that identifies your mobile
        device, operating system, APPLICATION software and peripheral hardware. In addition,
        &quot;REACH PRESENCE LLC&quot; may collect and store non-personally identifiable APPLICATION
        usage statistics. &quot;REACH PRESENCE LLC&quot; may use this information in the aggregate,
        in a form which does not personally identify you, to improve our products and services and
        we may share anonymous aggregate data with our third party service providers. Additionally,
        &quot;REACH PRESENCE LLC&quot; may store any personal information you enter on our service
        or provide to us in any other way. The types of personal information collected may include
        such things as your name, user name, address, email address, phone number, and birth date.
        The personal information you provide may be used for such purposes as allowing you to
        interact with other users, customizing content and advertising you see, and communicating
        with you about specials and new features.
      </p>
      <p>
        <strong>Services; Third Party Materials.</strong> The Licensed Application may enable access
        to Developers and third party services and web sites (collectively and individually,
        &quot;Services&quot;). Use of the Services may require Internet access and that you accept
        additional terms of service.
      </p>
      <p>
        You understand that by using any of the Services, you may encounter content that may be
        deemed offensive, indecent, or objectionable, which content may or may not be identified as
        having explicit language, and that the results of any search or entering of a particular URL
        may automatically and unintentionally generate links or references to objectionable
        material. Nevertheless, you agree to use the Services at Your sole risk and that the
        Developer shall not have any liability to you for content that may be found to be offensive,
        indecent, or objectionable.
      </p>
      <p>
        Certain Services may display, include or make available content, data, information,
        applications or materials from third parties (&quot;Third Party Materials&quot;) or provide
        links to certain third party web sites. By using the Services, You acknowledge and agree
        that the Developer is not responsible for examining or evaluating the content, accuracy,
        completeness, timeliness, validity, copyright compliance, legality, decency, quality or any
        other aspect of such Third Party Materials or web sites. The Developer does not warrant or
        endorse and does not assume and will not have any liability or responsibility to you or any
        other person for any third-party Services, Third Party Materials or web sites, or for any
        other materials, products, or services of third parties. Third Party Materials and links to
        other web sites are provided solely as a convenience to You. Financial information displayed
        by any Services is for general informational purposes only and is not intended to be relied
        upon as investment advice. Before executing any securities transaction based upon
        information obtained through the Services, You should consult with a financial professional.
        Location data provided by any Services is for basic navigational purposes only and is not
        intended to be relied upon in situations where precise location information is needed or
        where erroneous, inaccurate or incomplete location data may lead to death, personal injury,
        property or environmental damage. Neither the Developer, nor any of its content providers,
        guarantees the availability, accuracy, completeness, reliability, or timeliness of stock
        information or location data displayed by any Services.
      </p>
      <p>
        You agree that any Services contain proprietary content, information and material that is
        protected by applicable intellectual property and other laws, including but not limited to
        copyright, and that You will not use such proprietary content, information or materials in
        any way whatsoever except for permitted use of the Services. No portion of the Services may
        be reproduced in any form or by any means. You agree not to modify, rent, lease, loan, sell,
        distribute, or create derivative works based on the Services, in any manner, and you shall
        not exploit the Services in any unauthorized way whatsoever, including but not limited to,
        by trespass or burdening network capacity. You further agree not to use the Services in any
        manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights
        of any other party, and that the Developer is not in any way responsible for any such use by
        you, nor for any harassing, threatening, defamatory, offensive or illegal messages or
        transmissions that you may receive as a result of using any of the Services.
      </p>
      <p>
        In addition, third party Services and Third Party Materials that may be accessed from,
        displayed on or linked to from the iPhone or iPod touch are not available in all languages
        or in all countries. The Developer makes no representation that such Services and Materials
        are appropriate or available for use in any particular location. To the extent You choose to
        access such Services or Materials, You do so at Your own initiative and are responsible for
        compliance with any applicable laws, including but not limited to applicable local laws. The
        Developer, and its licensors, reserve the right to change, suspend, remove, or disable
        access to any Services at any time without notice. In no event will the Developer be liable
        for the removal of or disabling of access to any such Services. The Developer may also
        impose limits on the use of or access to certain Services, in any case and without notice or
        liability.
      </p>
      <p>
        <strong>User Generated Content; Release.</strong> I hereby grant to DEVELOPER, on behalf of
        itself, the Participating Parties (as defined below) and their licensees, agents, successors
        and assigns, the absolute and irrevocable right and permission, throughout the world, in any
        and all media and in perpetuity, to utilize any and all photographs, artwork, text, music
        and/or videos taken of or submitted and/or created by me (sometimes referred to as
        &quot;User Generated Content&quot;) using and/or in connection with the APPLICATION. These
        rights extend to artwork, video, or music made with the APPLICATION and include without
        limitation the rights:
      </p>
      <ol>
        <li>
          To use, reuse, publish and republish the same in whole or in part, individually or in
          conjunction with other photographs, film, television, videotapes, audiotapes, and digital
          media in any and all manner, media and all channels of distribution,
        </li>
        <li>
          To use my name, image, photograph, likeness, and signature in connection with such User
          Generated Content, and/or
        </li>
        <li>
          To post and share such User Generated Content on the Internet, via mobile transmission and
          in all other media as Developer, the Participating Parties and/or their affiliates or
          assigns, sees fit.
        </li>
      </ol>
      <p>
        No advertisement or other material need be submitted to me for any further approval and I
        hereby waive any right to inspect or approve the finished photographs video, film, digital
        media, or printed or electronic matter that may be used in conjunction with them now or in
        the future, whether that use is known to me or unknown, and I waive any right to any
        compensation arising from or related to the use of such material.
      </p>
      <p>
        Although DEVELOPER may from time to time monitor or review postings, transmissions, User
        Generated Content and the like used on and in connection with the APPLICATION, DEVELOPER is
        under no obligation to do so and assumes no responsibility or liability arising from any
        User Generated Content nor for any error, defamation, libel, slander, omission, falsehood,
        obscenity, pornography, profanity, danger or inaccuracy contained in any User Generated
        Content and/or information utilized or created in connection with the APPLICATION. I agree
        that I am prohibited from posting or transmitting any unlawful, threatening, libelous,
        defamatory, obscene, scandalous, inflammatory, pornographic or profane material or any
        material that could constitute or encourage conduct that would be considered a criminal
        offense, give rise to civil liability, would violate any proprietary right of any person or
        entity under copyright or otherwise, or otherwise violate any law. DEVELOPER will fully
        cooperate with any law enforcement authorities or court order requesting or directing
        DEVELOPER to disclose the identity of anyone posting any such information or materials.
        Without limiting the above, content containing pornographic material defined by
        Webster&apos;s Dictionary as &quot;explicit descriptions or displays of sexual organs or
        activities intended to stimulate erotic rather than aesthetic or emotional feelings&quot;,
        and/or content that is frequently pornographic (such as the content in ex &quot;Chat
        Roulette&quot;) will be rejected
      </p>
      <p>
        I represent, warrant and covenant (a) that no materials of any kind submitted by me
        including without limitation User Generated Content will (i) violate, plagiarize, or
        infringe upon the rights of any third party, including copyright, trademark, privacy or
        other personal or proprietary rights; or (ii) contain libelous or otherwise unlawful
        material. I hereby indemnify, defend and hold harmless DEVELOPER, Participating Parties, and
        all of their officers, directors, owners, agents, information providers, affiliates,
        licensors and licensees (collectively, the &quot;Indemnified Parties&quot;) from and against
        any and all liability and costs, including, without limitation, reasonable attorneys&apos;
        fees, incurred by the Indemnified Parties in connection with any claim arising out of any
        breach by me or any user of my account of this AGREEMENT or the foregoing representations,
        warranties and covenants. I shall cooperate as fully as reasonably required in the defense
        of any such claim. DEVELOPER reserves the right, at its own expense, to assume the exclusive
        defense and control of any matter subject to indemnification by me.
      </p>
      <p>
        I hereby agree on behalf of myself, and my heirs, next of kin, spouse, guardians, legal
        representatives, executors, administrators, successors and assigns (collectively, the
        &quot;Releasing Parties&quot;), that I and the other Releasing Parties do hereby
        unconditionally and irrevocably release and forever discharge Developer, the Participating
        Parties and their parents, subsidiaries, affiliates, licensees, successors, assigns,
        partners, and related companies, and the officers, directors, employees, contractors,
        partners, shareholders, representatives, members and agents of each of the foregoing, and
        any other person or entity associated with the APPLICATION (collectively the &quot;Released
        Parties&quot;), from and against any and all claims, demands, liens, agreements, suits,
        costs, attorney&apos;s fees, damages, judgments, orders, and liabilities of whatever kind or
        nature in law, equity or otherwise, whether known or unknown, suspected or unsuspected, and
        whether or not concealed or hidden (collectively the &quot;Released Claims&quot;), in any
        way directly or indirectly related to arising directly or indirectly out of: (i) my use of
        the APPLICATION, and/or (ii) the use of, or failure to use, the User Generated Content.
        Nothing hereinabove will constitute any obligation on the part of Developer or any
        Participating Party to make any use of any of the rights set forth above.
      </p>
      <p>
        <strong>Terms and Termination.</strong> This AGREEMENT will become effective with respect to
        the APPLICATION on the date you first download, install and/or use such APPLICATION, and it
        will remain in effect until terminated. You may terminate this Agreement at any time by
        removing this APPLICATION from your wireless communications device. This AGREEMENT also will
        terminate immediately, without notice, if you fail to comply with any term or condition of
        this AGREEMENT. Upon such termination, you agree to immediately remove this APPLICATION from
        your wireless communications device. Notwithstanding any such termination, Developer will
        retain the rights in User Generated Content which are granted in this AGREEMENT above, and
        the terms of this AGREEMENT relating to User Generated Content including the release with
        respect to such content, and the Consent to Use of Data, the Restrictions on the license
        granted herein, the Disclaimer of Warranty, Dispute Resolution provision, Miscellaneous
        provisions and Limitation of Liability set forth below, shall survive any such termination..
      </p>
      <p>
        <strong>Disclaimer of Warranty.</strong> THIS APPLICATION IS LICENSED TO YOU &quot;AS
        IS.&quot; THE DEVELOPER DISCLAIMS ANY AND ALL WARRANTIES REGARDING THIS APPLICATION, WHETHER
        EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE DEVELOPER ALSO
        DISCLAIMS ALL WARRANTIES WITH REGARD TO WHETHER THE APPLICATION WILL MEET YOUR REQUIREMENTS,
        WORK IN COMBINATION WITH ANY HARDWARE OR OTHER SOFTWARE OR OPERATE ON AN UNINTERRUPTED OR
        ERROR FREE BASIS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO
        THE ABOVE DISCLAIMERS MAY NOT APPLY OR MAY BE LIMITED.
      </p>
      <p>
        <strong>Limitation of Liability.</strong> TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT
        SHALL DEVELOPER BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, INDIRECT OR
        CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
        PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES,
        ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER
        CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF
        DEVELOPER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT
        ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL
        DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall Developer&apos;s total
        liability to you for all damages (other than as may be required by applicable law in cases
        involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing
        limitations will apply even if the above stated remedy fails of its essential purpose.
      </p>
      <p>
        You may not use or otherwise export or re-export the Licensed Application except as
        authorized by United States law and the laws of the jurisdiction in which the Licensed
        Application was obtained. In particular, but without limitation, the Licensed Application
        may not be exported or re-exported (a) into any U.S. embargoed countries or (b) to anyone on
        the U.S. Treasury Department&apos;s list of Specially Designated Nationals or the U.S.
        Department of Commerce Denied Person&apos;s List or Entity List. By using the Licensed
        Application, you represent and warrant that you are not located in any such country or on
        any such list. You also agree that you will not use these products for any purposes
        prohibited by United States law, including, without limitation, the development, design,
        manufacture or production of nuclear, missiles, or chemical or biological weapons.
      </p>
      <p>
        The Licensed Application and related documentation are &quot;Commercial Items&quot;, as that
        term is defined at 48 C.F.R. §2.101, consisting of &quot;Commercial Computer Software&quot;
        and &quot;Commercial Computer Software Documentation&quot;, as such terms are used in 48
        C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or
        48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software
        and Commercial Computer Software Documentation are being licensed to U.S. Government end
        users (a) only as Commercial Items and (b) with only those rights as are granted to all
        other end users pursuant to the terms and conditions herein. Unpublished-rights reserved
        under the copyright laws of the United States.
      </p>
      <p>
        <strong>Legal Compliance.</strong> You represent and warrant that (i) you are not located in
        a country that is subject to a U.S. Government embargo, or that has been designated by the
        U.S. Government as a &quot;terrorist supporting&quot; country; and (ii) you are not listed
        on any U.S. Government list of prohibited or restricted parties.
      </p>
      <p>
        <strong>Export.</strong> This APPLICATION is subject to the export control laws, rules and
        regulations of the United States and other jurisdictions. You agree to comply with all such
        laws, rules and regulations.
      </p>
      <p>
        <strong>Government.</strong> This APPLICATION is commercial in nature and developed solely
        at private expense. The APPLICATION is provided as &quot;Commercial Computer Software&quot;
        as defined in DFARS 252.227-7014 or as a commercial item as defined in FAR 2.101(a). The
        APPLICATION, therefore, is provided with only such rights as are provided in this AGREEMENT.
      </p>
      <p>
        <strong>Dispute Resolution.</strong> Any controversy or claim arising out of or relating to
        this AGREEMENT will be settled by independent arbitration administered by the American
        Arbitration Association under the Wireless Industry Arbitration rules as modified by this
        AGREEMENT. The arbitration shall occur before a single, neutral arbitrator and, in rendering
        a decision, the arbitrator shall apply the substantive law of the State of California. The
        decision of the arbitrator shall be enforceable in the Federal District Court for the
        district where the arbitration is held.
      </p>
      <p>
        <strong>Miscellaneous.</strong> This AGREEMENT is governed by the laws of the State of
        California without regard to its conflict of law principles. Your use of the Licensed
        Application may also be subject to other local, state, national, or international laws. The
        United Nations Convention on Contracts for the Sale of International Goods does not apply to
        this AGREEMENT. If any provision of this AGREEMENT is held illegal, invalid or
        unenforceable, in whole or in part, such provision shall be modified to the minimum extent
        necessary to make it legal, valid and enforceable, and the legality, validity and
        enforceability of all other provisions of this AGREEMENT shall not be affected thereby. This
        AGREEMENT constitutes the entire agreement between you and the DEVELOPER regarding its
        subject matter, and it supersedes any prior agreements, whether written or oral, relating to
        the subject matter of this AGREEMENT. No modification or alteration of this AGREEMENT will
        be valid except in writing signed by both parties.
      </p>
      <p>
        <strong>Privacy Policy.</strong> Developer will not share your information with any other
        party except for Developer&apos;s client(s), participating artists and/or sponsor(s)
        (including without limitation participating brands and the UN and its agencies and
        departments) (collectively and individually, &quot;Participating Parties&quot;) and
        Developer&apos;s and such Participating Parties&apos; representatives with respect to the
        promotion(s) or content concerned.
      </p>
      <p>
        <strong>Support.</strong> Questions or comments regarding this Terms of Use Agreement may be
        directed to &quot;REACH PRESENCE LLC&quot;{' '}
        <a href="mailto:info@domain.com">info@domain.com</a>.
      </p>
    </div>
  );
};

export default TermsPage;
