import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import PropTypes from 'prop-types';

const TGHoverButton = (props) => {
  const {
    id,
    labelColor,
    backgroundColor,
    backgroundColorHover,
    label,
    disabled,
    onClick,
    buttonStyle,
    labelStyle,
    style,
    className,
    icon,
    children,
  } = props;
  // cubic-bezier(0.23, 1, 0.32, 1)
  // const transition = `background-color 450ms ${MaterialUI.transitions.easeOutFunction} 0ms`;

  return (
    <div style={{ display: 'inline-block' }}>
      <style>
        {`
        #${id}:hover {
          background-color: ${backgroundColorHover} !important;
          background:${backgroundColorHover} !important;
          border-radius: 8px !important;
        }

        #${id} {
          background-color: ${backgroundColor} !important;
          transition: background-color 0.8s !important;
          border-radius: 8px !important;
        }      
        `}
      </style>
      <RaisedButton
        id={id}
        backgroundColor={backgroundColor}
        labelColor={labelColor}
        label={label}
        disabled={disabled}
        onClick={onClick}
        buttonStyle={buttonStyle}
        labelStyle={labelStyle}
        style={style}
        className={className}
        icon={icon}
      >
        {children}
      </RaisedButton>
    </div>
  );
};

TGHoverButton.propTypes = {
  id: PropTypes.string,
  labelColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorHover: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.node,
};
export default TGHoverButton;
