import React from 'react';
import { bool, object } from 'prop-types';
import cn from 'classnames';

import { DATE_RANGE_FORMATS } from 'appconstants/time';
import { getFormattedDateRange } from 'utils/dates';

import './styles.css';
import { Link } from '@material-ui/core';

const getLocationString = (location, festival) => {
  if (festival) {
    return (
      <>
        <Link className="festival-link" to={`/in/${festival.channel}/f/${festival.alias}}`}>
          {festival.name}
        </Link>
        <span> | {location.platform || location.name}</span>
      </>
    );
  }

  return location && (location.platform ? `LIVE | ${location.platform}` : location.name);
};

const ExperienceDateAndLocation = ({ festival, startTime, endTime, location, isCheckout }) => {
  const dateRangeString = getFormattedDateRange({
    startTime,
    endTime,
    format: DATE_RANGE_FORMATS.DATE,
  });
  const monthRangeString = getFormattedDateRange({
    startTime,
    endTime,
    format: DATE_RANGE_FORMATS.MONTH,
  });

  const time = Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  }).format(new Date(startTime));

  const locationString = getLocationString(location, festival);

  return (
    <div className={cn('datetime-wrapper', { 'datetime-wrapper-checkout': isCheckout })}>
      <div className={cn('date-wrapper', { 'date-wrapper-checkout': isCheckout })}>
        <span>{monthRangeString}</span>
        <div className="date-num">{dateRangeString}</div>
      </div>
      <div className="time-wrapper">
        <h4>{locationString}</h4>
        <span>{time}</span>
      </div>
    </div>
  );
};

ExperienceDateAndLocation.propTypes = {
  // startTime: PropTypes.oneOf([PropTypes.instanceOf(Date), string]),
  // endTime: PropTypes.oneOf([PropTypes.instanceOf(Date), string]),
  location: object,
  isCheckout: bool,
};

export default ExperienceDateAndLocation;
