import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import routes from 'appconstants/routes';
import StripeConnectButton from './StripeConnectButton';

const SetupStripe = ({ url, settings }) => {
  return (
    <div>
      <div className="text-block">
        <h2 className="text card-title">How payment works</h2>
        <h3 className="text card-text">
          Stripe is the best software platform for processing payment. They handle billions of
          dollars every year for forward-thinking businesses around the world. We use Stripe to make
          sure you get paid on time and keep your personal and bank details secure.
        </h3>
      </div>
      <div className="actions">
        <StripeConnectButton href={url} label="Connect with Stripe" />
        {!settings && (
          <Link to={routes.experiences} className="text skip">
            Skip this
          </Link>
        )}
      </div>
    </div>
  );
};

SetupStripe.propTypes = {
  settings: PropTypes.bool,
  url: PropTypes.string,
};

export default SetupStripe;
