import {
  FETCH_CHANNEL,
  SELECT_CURRENT_CHANNEL,
  GET_SUBCATEGORIES,
  CREATE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  GET_REV_SHARE,
  POST_REV_SHARE,
  GET_CHANNEL_TEAM,
} from 'appconstants';

const initialState = {
  loading: false,
  channels: [],
  subcategories: [],
  error: null,
  channelRevShare: 1,
  channelPayouts: [],
  loadingRevShare: false,
  channelRevShareError: null,
  savingRevShare: false,
  channelTeam: [],
  loadingChannelTeam: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_CHANNEL.SAVE_CHANNEL_REQUEST:
    case FETCH_CHANNEL.FETCH_CHANNELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CHANNEL.FETCH_CHANNELS_SUCCESS:
      return {
        ...state,
        loading: false,
        channels: action.response,
      };
    case FETCH_CHANNEL.SAVE_CHANNEL_ERROR:
    case FETCH_CHANNEL.FETCH_CHANNELS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SELECT_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: state.channels.find((c) => c.urlSlug === action.urlSlug),
      };
    case GET_SUBCATEGORIES.GET_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        subcategories: [],
        loadingSubcategories: true,
        error: null,
      };
    case GET_SUBCATEGORIES.GET_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        subcategories: action.response,
        loadingSubcategories: false,
      };
    case GET_SUBCATEGORIES.GET_SUBCATEGORIES_FAILURE:
      return {
        ...state,
        subcategories: [],
        loadingSubcategories: false,
        error: action.message,
      };
    case CREATE_SUBCATEGORY.CREATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategories: [...state.subcategories, action.response],
      };
    case DELETE_SUBCATEGORY.DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategories: state.subcategories.filter(({ id }) => id !== action.response.id),
      };
    case GET_REV_SHARE.GET_REV_SHARE_REQUEST:
      return {
        ...state,
        loadingRevShare: true,
        channelPayouts: [],
        channelRevShare: 1,
      };
    // case GET_REV_SHARE.GET_REV_SHARE_REQUEST:
    //   return {
    //     ...state,
    //     loadingRevShare: true,
    //     channelPayouts: [],
    //     channelRevShare: 1,
    //     channelRevShareError: null,
    //   };
    case GET_REV_SHARE.GET_REV_SHARE_SUCCESS:
      return {
        ...state,
        loadingRevShare: false,
        channelPayouts: action.response.payouts,
        channelRevShare: action.response.channelRevShareCut ?? 1,
        channelRevShareError: null,
      };
    case GET_REV_SHARE.GET_REV_SHARE_FAILURE:
      return {
        ...state,
        loadingRevShare: false,
        channelPayouts: [],
        channelRevShare: 1,
        channelRevShareError: action.response.message,
      };
    case POST_REV_SHARE.POST_REV_SHARE_REQUEST:
      return {
        ...state,
        savingRevShare: true,
      };
    case POST_REV_SHARE.POST_REV_SHARE_SUCCESS:
    case POST_REV_SHARE.POST_REV_SHARE_FAILURE:
      return {
        ...state,
        savingRevShare: false,
      };
    case GET_CHANNEL_TEAM.GET_CHANNEL_TEAM_REQUEST:
      return {
        ...state,
        loadingChannelTeam: true,
      };
    case GET_CHANNEL_TEAM.GET_CHANNEL_TEAM_SUCCESS:
      return {
        ...state,
        loadingChannelTeam: false,
        channelTeam: action.response,
      };
    // case GET_CHANNEL_TEAM.GET_CHANNEL_TEAM_SUCCESS:
    //   return {
    //     ...state,
    //     loadingChannelTeam: false,
    //     channelTeam: [],
    //   };
    default:
      return state;
  }
}
