import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { string, func } from 'prop-types';

import './styles.css';

const WizardSnackbar = ({ error = '', onClose }) => (
  <Snackbar
    open={!!error}
    autoHideDuration={4000}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    onClose={onClose}
    classes={{
      root: 'wizard-snackbar',
    }}
  >
    <SnackbarContent
      classes={{
        root: 'wizard-snackbar__message',
        message: 'wizard-snackbar__message',
      }}
      message={error}
    />
  </Snackbar>
);

WizardSnackbar.propTypes = {
  error: string,
  onClose: func.isRequired,
};

export default WizardSnackbar;
