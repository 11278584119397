import { CALL_API } from 'redux_store/middleware/api';

const SAVE_DISCOUNT_REQUEST = 'SAVE_DISCOUNT_REQUEST';
const SAVE_DISCOUNT_SUCCESS = 'SAVE_DISCOUNT_SUCCESS';
const SAVE_DISCOUNT_FAILURE = 'SAVE_DISCOUNT_FAILURE';

const LOAD_DISCOUNT_REQUEST = 'LOAD_DISCOUNT_REQUEST';
const LOAD_DISCOUNT_SUCCESS = 'LOAD_DISCOUNT_SUCCESS';
const LOAD_DISCOUNT_FAILURE = 'LOAD_DISCOUNT_FAILURE';

function getDiscountById(id) {
  const method = 'get';
  const endpoint = `/discounts/${id}`;
  return {
    [CALL_API]: {
      types: [LOAD_DISCOUNT_REQUEST, LOAD_DISCOUNT_SUCCESS, LOAD_DISCOUNT_FAILURE],
      method,
      endpoint,
    },
  };
}

function postDiscount(body) {
  const method = 'post';
  const endpoint = '/discounts';
  return {
    [CALL_API]: {
      types: [SAVE_DISCOUNT_REQUEST, SAVE_DISCOUNT_SUCCESS, SAVE_DISCOUNT_FAILURE],
      method,
      endpoint,
      body,
    },
  };
}

function putDiscount(body, id) {
  const method = 'put';
  const endpoint = `/discounts/${id}`;
  return {
    [CALL_API]: {
      types: [SAVE_DISCOUNT_REQUEST, SAVE_DISCOUNT_SUCCESS, SAVE_DISCOUNT_FAILURE],
      method,
      endpoint,
      body,
    },
  };
}

export function loadDiscounts(ids) {
  return (dispatch) => {
    const getActions = ids.map((id) => {
      return dispatch(getDiscountById(id));
    });
    return Promise.all(getActions);
  };
}

export function saveDiscount(data) {
  return (dispatch) => {
    const body = {
      ...data,
    };
    if (body.id) {
      return dispatch(putDiscount(body, body.id)).then((res) => {
        return res;
      });
    }
    return dispatch(postDiscount(body)).then((res) => {
      return res;
    });
  };
}
