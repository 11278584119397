import React, { useMemo } from 'react';
import ScheduledItem from 'components/ScheduledExperience/ScheduledItem';
import styled from 'styled-components';

const CheckoutScheduleItem = ({
  experience,
  lastStartTime,
  isLastItem,
  getLabel,
  onClick,
  $color,
  showTitle,
  ticketPage = false,
}) => {
  const label = useMemo(() => getLabel(), [getLabel]);

  return (
    <ScheduledItem
      experience={experience}
      lastStartTime={lastStartTime}
      isLastItem={isLastItem}
      $borderColor={$color}
      showTitle={showTitle}
      ticketPage={ticketPage}
      ctaButton={
        <CTAButton $color={$color} onClick={onClick}>
          {label}
        </CTAButton>
      }
    />
  );
};

const CTAButton = styled.button`
  height: 40px;
  width: 160px;
  border-radius: 8px;
  border: 2px solid ${({ $color }) => $color};
  background-color: transparent;
  color: ${({ $color }) => $color};
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  outline: none;
  transition: background-color 0.25s;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background-color: ${({ $color }) => $color};
    border: 2px solid ${({ $color }) => $color};
    color: #ffffff;
  }
`;

export default CheckoutScheduleItem;
