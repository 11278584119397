import client from '../api/client';

export const getFestivalInformation = async (festivalId, nextPage) => {
  try {
    return await client.get(`/festivals/${festivalId}${nextPage ? `?nextPage=${nextPage}` : ''}`);
  } catch (error) {
    throw new Error(error);
  }
};

export const getFestivalCMSData = async (alias, channel, section = 'guestlist', params = {}) => {
  try {
    return await client.get(`/cms/festivals/${channel}/${alias}/${section}`, { params });
  } catch (error) {
    throw new Error(error);
  }
};

export const rsvpFestivalTicket = async ({ channel, alias, body }) => {
  try {
    return await client.post(`/users/rsvp/festival/${channel}/${alias}/`, body);
  } catch (error) {
    throw new Error(error);
  }
};

export const updateFestivalInformation = async (festivalId, step, data, formData = false) => {
  if (formData) {
    const form = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value);
    });

    return await client.put(`/festivals/${festivalId}/${step}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return await client.put(`/festivals/${festivalId}/${step}`, data);
};

export const requestFestivalDiscount = async (alias, channel, discountCode) =>
  await client.get(`/festivals/${channel}/${alias}/discount/${discountCode}`);
