import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import NavigationArrowForward from 'material-ui/svg-icons/navigation/arrow-drop-down';
import { browserHistory } from 'react-router';
import OpenInNew from '../../images/open-in-new.png';
import FestivalLightning from '../../images/festival-lightning-white.png';
import ImageNotFound from 'images/no-image-box.png';
import * as actionsExperiences from 'actions/experiences';
import { getCSVfromResponse, getResizedImageURL } from 'utils/helpers';
import { IMAGE_SIZES } from 'appconstants';
import { EXPERIENCE_FILTER_TYPES } from 'appconstants/experiences';
import './styles.css';
import { updateFestivalInformation } from 'api/festivals';
import { TIME_FORMATS } from 'appconstants/time2';
import { dateFormat } from 'utils/dates';

const styles = {
  menuOptionStyle: {
    fontSize: '17px',
    color: 'grey',
    borderBottom: 'solid 1px #fff',
    fontFamily: 'CircularStd Book',
    width: 140,
  },
  menuDivStyle: {
    marginBottom: '-8px',
  },
};

class TGExperienceTabItem extends Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor() {
    super();
    this.state = {
      statusExp: null,
      ticketSales: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadTicketInfo();
  }

  onUnpublishExperience(experience) {
    const { unpublish } = this.props;

    if (experience.isFestival) {
      updateFestivalInformation(experience.id, 'publish', { published: false }).then(() => {
        this.setState({
          statusExp: 'draft',
        });
      });
    } else {
      unpublish(experience.id).then(() => {
        this.setState({
          statusExp: 'draft',
        });
      });
    }
  }

  onViewGuestlist({ urlSlug, alias }) {
    browserHistory.push(`/in/${urlSlug}/${alias}/guestlist`);
  }

  onDownloadGuestlist = async ({ channel: { urlSlug: channel }, alias }) => {
    const response = await this.props.downloadGuestlist({ channel, alias });
    getCSVfromResponse({
      response,
      csvName: `${channel}-${alias}-guestlist.csv`,
    });
  };

  onDashboard(experience, tab) {
    const {
      channel: { urlSlug },
      alias,
      id,
      isFestival,
      status,
    } = experience;

    const routePrefix = `/in/${urlSlug}`;
    const routeSuffix = `${alias || id}/dashboard?initial-tab=${tab}`;
    const festivalRoute =
      status === 'published' ? `${routePrefix}/f/${routeSuffix}` : `/festivals/${id}/?create=true`;

    const route = isFestival ? festivalRoute : `${routePrefix}/${routeSuffix}`;

    browserHistory.push(route);
  }

  loadTicketInfo = () => {
    const { experience } = this.props;
    const tickets = experience.tickets;

    let ticketSales = 0;
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    for (let i = 0; i < tickets.length; i++) {
      const ticket = tickets[i];
      const sold = ticket.total - ticket.quantity;
      const price = ticket.price || 0;

      ticketSales += sold * price;
    }

    this.setState({ ticketSales });
  };

  renderButtons = () => {
    const { selectedFilter, experience } = this.props;
    const { statusExp } = this.state;
    let buttonLabel = statusExp || experience.status;
    let urlRedirect = 'edit';
    const statusVar = statusExp || experience.status;

    if (buttonLabel === 'published') buttonLabel = 'edit';

    if (selectedFilter === EXPERIENCE_FILTER_TYPES.PAST) {
      buttonLabel = experience.reliveUrl ? 'Published as re-live' : 'Submit as re-live';
      urlRedirect = 'relive';
    }

    return (
      <div className="edit-dropdown">
        {selectedFilter === EXPERIENCE_FILTER_TYPES.PAST && experience.isFestival ? null : (
          <button id="status" onClick={() => this.onDashboard(experience, urlRedirect)}>
            {buttonLabel}
          </button>
        )}
        <button
          className="open-in-new-button"
          onClick={() =>
            window.open(
              `${window.location.origin}/in/${experience.channel.urlSlug}/${
                experience.isFestival ? 'f/' : ''
              }${experience.alias}`
            )
          }
        >
          <IconButton
            style={{
              display: 'flex',
              alignSelf: 'center',
              height: '40px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img alt="open-in-new" className="open-in-new-icon" src={OpenInNew} />
          </IconButton>
        </button>
        <IconMenu
          value=""
          iconButtonElement={
            <IconButton
              style={{
                display: 'flex',
                alignSelf: 'center',
                height: '40px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <NavigationArrowForward color="#B9B9B9" />
            </IconButton>
          }
          menuStyle={{ minWidth: '200px' }}
        >
          {statusVar === 'published' && (
            <MenuItem
              className="menuCss"
              style={styles.menuOptionStyle}
              innerDivStyle={styles.menuDivStyle}
              onClick={() => this.onUnpublishExperience(experience)}
              primaryText="Unpublish"
            />
          )}
          <MenuItem
            className="menuCss"
            style={styles.menuOptionStyle}
            innerDivStyle={styles.menuDivStyle}
            onClick={() => this.onViewGuestlist(experience)}
            primaryText="View Guestlist"
          />
          <MenuItem
            className="menuCss"
            style={styles.menuOptionStyle}
            innerDivStyle={styles.menuDivStyle}
            onClick={() => this.onDownloadGuestlist(experience)}
            primaryText="Download Guestlist"
          />
          <MenuItem
            className="menuCss"
            style={styles.menuOptionStyle}
            innerDivStyle={styles.menuDivStyle}
            onClick={() => this.onDashboard(experience, 'discounts')}
            primaryText="Discounts"
          />
        </IconMenu>
      </div>
    );
  };

  render() {
    const { experience } = this.props;
    const { statusExp } = this.state;

    const image =
      experience.images && experience.images[0]
        ? getResizedImageURL(experience.images[0], IMAGE_SIZES.THUMBNAIL)
        : ImageNotFound;

    let rsvpCount = 0;
    let rsvpMinPrice = 0;
    let rsvpMaxPrice = 0;

    if (experience.tickets && experience.tickets.length > 0) {
      experience.tickets.map((item) => {
        rsvpCount += item.quantity;
        if ((item.price < rsvpMinPrice || rsvpMinPrice === 0) && item.quantity > 0) {
          rsvpMinPrice = item.price;
        }
        if ((item.price > rsvpMaxPrice || rsvpMaxPrice === 0) && item.quantity > 0) {
          rsvpMaxPrice = item.price;
        }
        return rsvpCount;
      });
    }

    const statusVar = statusExp || experience.status;

    const [name, lastName] = experience.createdBy?.[0]?.name.split(' ') || [];

    let ticketSales = Number(experience.netSales);

    if (isNaN(ticketSales)) {
      ticketSales = Number(this.state.ticketSales);
    }

    if (isNaN(ticketSales)) {
      ticketSales = 0;
    }

    ticketSales = ticketSales.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    return (
      <div className="experience-item clearfix">
        <div
          className="experience-details exp-block"
          onClick={() => {
            let initialTab = 'edit';

            if (statusVar === 'published') {
              initialTab = experience.isFestival ? 'guestlist' : 'tickets';
            }

            this.onDashboard(experience, initialTab);
          }}
        >
          <div className="media-thumbnail exp-thumbnail">
            <img width="88px" height="58px" src={image} alt={experience.name} />
          </div>
          <div className="exp-content" style={{ maxWidth: '190px' }}>
            <div className="experience-name">
              <h4>{experience.name}</h4>
              {experience.isFestival && (
                <span className="festival-icon">
                  <img alt="" src={FestivalLightning} />
                </span>
              )}
            </div>
            <span>
              {experience.createdBy?.[0]
                ? `by ${name} ${lastName ? `${lastName[0]}.` : ''} ${dateFormat(
                    TIME_FORMATS.TICKET_PAGE_DATE_AND_TIME,
                    experience.startTime
                  )}`
                : dateFormat(
                    { ...TIME_FORMATS.MONTH_AND_DATETIME, timeZoneName: 'short' },
                    experience.startTime
                  )}
            </span>
          </div>
        </div>
        <div className="ticket-pricing exp-block">
          <span className="ticket-price">${ticketSales}</span>
          <small>ticket sales</small>
        </div>
        <div className="ticket-guest">
          <span className="total-guest">
            <span className="guest-num">{experience.sold}</span>/{experience.totalTickets}
          </span>
          <small>guests</small>
        </div>
        <div className="experiences-panel-button">{this.renderButtons()}</div>
      </div>
    );
  }
}

TGExperienceTabItem.propTypes = {
  experience: PropTypes.object,
  unpublish: PropTypes.func,
  downloadGuestlist: PropTypes.func,
  selectedFilter: PropTypes.string,
};

export default connect(
  (state) => ({
    experiences: state.experiences,
    loading: state.experiences.loading,
  }),
  {
    ...actionsExperiences,
  }
)(TGExperienceTabItem);
