import React, { Fragment, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { getLogo } from 'utils/logos';
import { browserHistory } from 'react-router';
import { dateFormat } from 'utils/dates';

const FestivalCardMobile = ({
  festival,
  imageUrl,
  experiencesByDates,
  festivalUrl: getFestivalUrl = () => '',
}) => {
  const experiences = useMemo(() => experiencesByDates(), [experiencesByDates]);
  const festivalUrl = useMemo(() => getFestivalUrl(), [getFestivalUrl]);
  const openFestival = useCallback(() => {
    browserHistory.push(festivalUrl);
  }, [festivalUrl]);

  return (
    <Container>
      <CardContent>
        <PictureContainer>
          <Picture src={imageUrl()} onClick={openFestival} />
          <CircleContainer>
            <Circle>{getLogo('festival')}</Circle>
          </CircleContainer>
        </PictureContainer>
        <ContentContainer>
          <InformationContainer>
            <LocationInformation>
              Festival<Slash> / </Slash>
              {dateFormat(
                {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minutes: 'numeric',
                  hour12: true,
                },
                festival.startTime
              )}
            </LocationInformation>
            <Title onClick={openFestival}>{festival.name}</Title>
            <Channel>{festival.channel.name}</Channel>
          </InformationContainer>
        </ContentContainer>
      </CardContent>
      <CardDetail>
        {experiences.length > 0 ? (
          <Fragment>
            <ListTitle>Festival schedule</ListTitle>
            {experiences.map((dateWithExperiences) => (
              <DayList>
                <DayTitle>{dateWithExperiences.title}</DayTitle>
                <DayListItems>
                  {dateWithExperiences.items.map((item) => (
                    <ListItem>
                      <ItemContent>
                        <ItemTime>{`${dateFormat(
                          { hour: 'numeric', minute: 'numeric', hour12: true },
                          item.startTime
                        )} - ${dateFormat(
                          { hour: 'numeric', minute: 'numeric', hour12: true },
                          item.startTime
                        )}`}</ItemTime>
                        <ItemName onClick={() => window.open(item.location[0].url[0])}>
                          {item.name}
                        </ItemName>
                      </ItemContent>
                    </ListItem>
                  ))}
                </DayListItems>
              </DayList>
            ))}
          </Fragment>
        ) : (
          <EmptySchedule>
            <ScheduleInformation>
              You have not scheduled any experiences.
              <StartHere onClick={() => browserHistory.push(`${festivalUrl()}#schedule`)}>
                Start here
              </StartHere>
            </ScheduleInformation>
          </EmptySchedule>
        )}
      </CardDetail>
    </Container>
  );
};

const Container = styled.div`
  width: calc(100vw - 50px);
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 4%);
  display: flex;
  padding: 16px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 12px;
`;

const CardContent = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid #ebebeb;
  height: 87px;
`;

const CircleContainer = styled.div`
  width: 25px;
  position: absolute;
  right: 0;
  transform: translateX(50%);
`;

const Circle = styled.div`
  height: 25px;
  width: 25px;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;
  background-color: #ffffff;
`;

const PictureContainer = styled.div`
  height: 88px;
  width: 163px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid #ebebeb;
  position: relative;
`;

const Picture = styled.img`
  width: 87px;
  height: 58px;
  object-fit: cover;
  border-radius: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 18px;
`;

const InformationContainer = styled.div`
  justify-self: flex-start;
  align-self: flex-start;
`;

const LocationInformation = styled.div`
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Slash = styled.span`
  color: #d5d5d5;
`;

const Title = styled.div`
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Channel = styled.div`
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CardDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 0;
`;

const ListTitle = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
`;

const DayList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DayTitle = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
`;

const DayListItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ListItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemTime = styled.div`
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 31px;
  width: 110px;
`;

const ItemName = styled.div`
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 31px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const EmptySchedule = styled.div`
  display: flex;
  gap: 20px;
`;

const ScheduleInformation = styled.div`
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
`;

const StartHere = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  background-color: transparent;
`;

export default FestivalCardMobile;
