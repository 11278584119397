import { createSelector } from 'reselect';

export const selectFoods = (state) => state.tags.food;
export const selectTags = (state) => state.tags.tag;
export const selectDrinks = (state) => state.tags.drink;
export const selectDefaultCategories = (state) => state.tags.category;
export const selectSubCategories = (state) => state.tags.category;

export const selectCustomCategoriesForChannel = (state, { channelId }) =>
  (channelId && state.tags.customByChannel[channelId]) || [];

export const selectCategoriesForExperienceInChannel = createSelector(
  selectDefaultCategories,
  selectCustomCategoriesForChannel,
  (defaultCategories, customCategories) => [...defaultCategories, ...customCategories]
);
