import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { RaisedButton, Divider } from 'material-ui';

import { submitForgotPassword } from 'actions/auth';
import { selectUserLoading, selectUser } from 'selectors/user';

import AppWrapper from 'components/AppWrapper';
import TGSignupHeader from 'components/SignupHeader';
import Snackbar from 'components/common/Snackbar';
import FormFieldShowingError from 'components/common/FormFieldShowingError';

import { createValidator, required, email } from 'utils/validation';
import { SNACKBAR_TYPES } from 'appconstants';

import './styles.css';

const styles = {
  submitButton: {
    style: {
      borderRadius: '8px',
      height: '54px',
    },
    label: {
      paddingTop: '10px',
      color: '#FFFFFF',
      fontFamily: 'CircularStd Book',
      fontSize: '13.5px',
      fontWeight: 'bold',
      letterSpacing: '1.6px',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  snackbar: {
    error: { textAlign: 'center', backgroundColor: 'rgba(255, 0, 13, 0.88)' },
    success: { textAlign: 'center', backgroundColor: '#69D27C' },
  },
};

const validate = createValidator({
  email: [required('email'), email],
});

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      snackbarType: null,
    };
  }

  goToSignIn = () => {
    browserHistory.push(`/signin`);
  };

  handleChangePassword = async (data) => {
    try {
      await this.props.submitForgotPassword(data);
      this.setState({
        snackbarType: SNACKBAR_TYPES.SUCCESS,
        success: 'The reset password email was sent successfully!',
      });
    } catch (err) {
      this.setState({
        snackbarType: SNACKBAR_TYPES.ERROR,
        error: 'It’s not you, it’s us. We will look into it.',
      });
    }
  };

  closeSnackBar = () => {
    this.setState({
      success: null,
      error: null,
    });
  };

  render() {
    const { user, loading, pristine, submitting, handleSubmit, error } = this.props;
    const { error: errorState, success, snackbarType } = this.state;

    return (
      <AppWrapper>
        <div className="page-signup">
          <div className="body-container">
            <TGSignupHeader
              className="signup-header"
              user={user}
              loading={loading}
              goToSignIn={this.goToSignIn}
            />
            <div className="forgot-password-form-container">
              <div className="forgot-password-form-wrapper">
                <div className="forgot-password-form">
                  <div className="forgot-password-form-head-container">
                    <div className="form-header">
                      <h5>Retrieve your password</h5>
                    </div>
                  </div>
                  <Divider style={{ marginTop: '4rem' }} />
                  <form onSubmit={handleSubmit(this.handleChangePassword)}>
                    <div className="containerInput container-sign-up">
                      <div className="labelContainer">
                        <div className="label-sign-up">Enter email</div>
                      </div>
                      <Field
                        name="email"
                        component={FormFieldShowingError}
                        type="email"
                        placeholder=""
                        className="simple-input"
                      />
                    </div>
                    <RaisedButton
                      className="signup-button"
                      type="submit"
                      label="Submit"
                      style={styles.submitButton.style}
                      labelStyle={styles.submitButton.label}
                      disabled={loading || pristine || submitting}
                    />
                  </form>
                </div>
                <Snackbar
                  open={!!(error || errorState || success)}
                  title={
                    snackbarType === SNACKBAR_TYPES.SUCCESS ? 'Success!' : 'Something went wrong'
                  }
                  type={snackbarType}
                  message={error || errorState || success}
                  autoHideDuration={4000}
                  onRequestClose={this.closeSnackBar}
                />
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    );
  }
}

ForgotPasswordPage.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitForgotPassword: PropTypes.func,
  error: PropTypes.bool,
};

export default connect(
  (state) => ({
    user: selectUser(state),
    loading: selectUserLoading(state),
  }),
  (dispatch) => bindActionCreators({ submitForgotPassword }, dispatch)
)(
  reduxForm({
    form: 'ForgotPasswordForm',
    validate,
  })(ForgotPasswordPage)
);
