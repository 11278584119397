import isEmpty from 'lodash/isEmpty';
import { CALL_API } from 'redux_store/middleware/api';

const SAVE_FLOW_REQUEST = 'SAVE_FLOW_REQUEST';
const SAVE_FLOW_SUCCESS = 'SAVE_FLOW_SUCCESS';
const SAVE_FLOW_FAILURE = 'SAVE_FLOW_FAILURE';

const UPDATE_FLOW_REQUEST = 'UPDATE_FLOW_REQUEST';
const UPDATE_FLOW_SUCCESS = 'UPDATE_FLOW_SUCCESS';
const UPDATE_FLOW_FAILURE = 'UPDATE_FLOW_FAILURE';

const LOAD_FLOW_REQUEST = 'LOAD_FLOW_REQUEST';
const LOAD_FLOW_SUCCESS = 'LOAD_FLOW_SUCCESS';
const LOAD_FLOW_FAILURE = 'LOAD_FLOW_FAILURE';

function getFlowById(id) {
  const method = 'get';
  const endpoint = `/flows/${id}`;
  return {
    [CALL_API]: {
      types: [LOAD_FLOW_REQUEST, LOAD_FLOW_SUCCESS, LOAD_FLOW_FAILURE],
      method,
      endpoint,
    },
  };
}

function postFlow(body) {
  const isUpdate = body.id;
  const method = isUpdate ? 'put' : 'post';
  const types = isUpdate
    ? [UPDATE_FLOW_REQUEST, UPDATE_FLOW_SUCCESS, UPDATE_FLOW_FAILURE]
    : [SAVE_FLOW_REQUEST, SAVE_FLOW_SUCCESS, SAVE_FLOW_FAILURE];

  const endpoint = isUpdate ? `/flows/${body.id}` : '/flows';
  return {
    [CALL_API]: {
      types,
      method,
      endpoint,
      body,
    },
  };
}

export function loadFlows(ids) {
  return (dispatch) => {
    const getActions = ids.map((id) => {
      return dispatch(getFlowById(id));
    });
    return Promise.all(getActions);
  };
}

export function loadFlow(id) {
  return (dispatch) => {
    return dispatch(getFlowById(id));
  };
}

export function saveFlow(data) {
  return (dispatch) => {
    const body = {
      ...data,
    };
    return dispatch(postFlow(body)).then((res) => {
      return res;
    });
  };
}

export function saveFlows(flows) {
  return (dispatch) => {
    const saveActions = flows.map((item) => {
      if (!isEmpty(item)) {
        return dispatch(saveFlow(item));
      }
      return () => {};
    });
    return Promise.all(saveActions);
  };
}
