import React, { Component } from 'react';
import { object, string, bool, oneOf } from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import IconButton from 'material-ui/IconButton';

import { selectUser } from 'selectors/user';
import { selectCurrentChannel } from 'selectors/channels';

import presenceLogo from 'images/logo-violet-presence.png';
import Navigation from 'images/icon-mobile-sidebar.png';
import backArrow from 'images/backIconViolet.png';

import UserInfoMob from 'components/UserInfoMob';
import SideBar from 'components/SideBar';
import { APP_THEMES } from 'appconstants/colors';

import './styles.css';

class AdminHeaderMob extends Component {
  constructor() {
    super();
    this.state = {
      openSlideBar: false,
    };
  }

  toggleSlideBar = () => {
    this.setState({ openSlideBar: !this.state.openSlideBar });
  };

  render() {
    const { back, namePage, user, currentChannel, theme } = this.props;
    const { openSlideBar } = this.state;

    const isBack = back;
    const isMain = !back && !currentChannel;
    const isChannelPage = !back && currentChannel;
    const isDarkTheme = theme === APP_THEMES.DARK;

    return (
      <header
        className="header-mobile"
        style={{
          backgroundColor: isDarkTheme ? APP_THEMES.DARK : APP_THEMES.WHITE,
        }}
      >
        <div>
          <div className="header-mobile-center">
            <IconButton onClick={back ? () => browserHistory.goBack() : this.toggleSlideBar}>
              <img
                src={back ? backArrow : Navigation}
                alt="button"
                width={back ? '30px' : '50px'}
              />
            </IconButton>
            {isBack && (
              <div className="title-page-mob" style={{ color: isDarkTheme && 'white' }}>
                {namePage}
              </div>
            )}
            {isMain && <img className="header-mobile-logo" src={presenceLogo} alt="PRESENCE" />}
            {isChannelPage && (
              <span className="header-mobile-channel-name">{currentChannel.name}</span>
            )}
            <UserInfoMob user={user} />
          </div>
        </div>
        <SideBar open={openSlideBar} onClose={this.toggleSlideBar} />
      </header>
    );
  }
}

AdminHeaderMob.propTypes = {
  back: bool,
  namePage: string,
  user: object,
  currentChannel: object,
  theme: oneOf(Object.keys(APP_THEMES).map((k) => APP_THEMES[k])),
};

AdminHeaderMob.defaultProps = {
  theme: APP_THEMES.WHITE,
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  currentChannel: selectCurrentChannel(state),
});

export default connect(mapStateToProps)(AdminHeaderMob);
