import React from 'react';
import { oneOf } from 'prop-types';
import { APP_THEMES } from 'appconstants/colors';

const AdminFooter = ({ theme }) => (
  <footer
    className="page-footer clearfix"
    style={{
      backgroundColor: theme,
      border: theme === APP_THEMES.DARK && 'unset',
    }}
  >
    <ul className="footer-navigation">
      <li>
        <a href="http://terms.withpresence.com">Terms of Service</a>
      </li>
      <li>
        <a href="http://privacypolicy.withpresence.com">Privacy Policy</a>
      </li>
    </ul>
    <div className="copyright">© Copyright {new Date().getFullYear()} With Presence Inc.</div>
  </footer>
);

AdminFooter.propTypes = {
  theme: oneOf(Object.keys(APP_THEMES).map((k) => APP_THEMES[k])),
};

export default AdminFooter;
