import React, { useState, useCallback, useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';

import Footer from 'components/WizardComponents/Footer';
import Snackbar from 'components/WizardComponents/Snackbar';
import DateTimeInput from 'components/WizardComponents/DateTimeInput';

import useFestivalInformation, { useUpdateFestival } from 'hooks/useFestivalInformation';

import { hasChanges } from '../Validations/utils';
// import classNames from 'classnames';

const Dates = ({ injectedParams, nextStep, params, renderSteps }) => {
  const { isLoading, data } = useFestivalInformation(injectedParams.id || params.id);
  const mutation = useUpdateFestival(params.id, 'dates');
  const [end, setEnd] = useState();
  const [error, setError] = useState('');
  const [start, setStart] = useState();
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);

  useEffect(() => {
    if (data && data._id) {
      setStart(new Date(data.startTime));
      setEnd(new Date(data.endTime));
    }
  }, [setStart, setEnd, data]);

  const setTime = (value, dateOrTime = 'date', startOrEnd = 'start') => {
    const newDate = new Date(startOrEnd === 'start' ? start : end);
    if (dateOrTime === 'date') {
      newDate.setFullYear(value.getFullYear());
      newDate.setMonth(value.getMonth());
      newDate.setDate(value.getDate());
    } else {
      newDate.setHours(value.getHours());
      newDate.setMinutes(value.getMinutes());
      newDate.setSeconds(0);
    }

    const setter = startOrEnd === 'start' ? setStart : setEnd;
    setter(newDate);

    if (dateOrTime === 'date' && startOrEnd === 'start') {
      if (newDate >= end) {
        const newEnd = new Date(end);

        newEnd.setFullYear(newDate.getFullYear());
        newEnd.setMonth(newDate.getMonth());
        newEnd.setDate(newDate.getDate() + 1);

        setEnd(newEnd);
      }

      setTimeout(() => {
        setEndDatePickerOpen(true);
      }, 500);
    }
  };

  const validate = useCallback(() => {
    if (!(data && data._id)) {
      return;
    }

    if (start >= end) {
      setError('Start date/time must be before end date/time');
      return;
    }

    setError('');

    const updateData = {
      startTime: start,
      endTime: end,
    };

    const pairs = [
      [new Date(data.startTime), start],
      [new Date(data.endTime), end],
    ];

    if (hasChanges(pairs)) {
      if (start <= new Date()) {
        setError('Festival cannot start in the past');
        return;
      }

      mutation.mutate(updateData);
    }

    nextStep();
  }, [data, start, end, mutation, nextStep]);

  return (
    <div>
      {renderSteps(validate)}
      <Snackbar error={error} onClose={() => setError(false)} />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="wizard__start-date-time">
          <div className="wizard__start-date">
            <div className="wizard__date-label">Start date</div>
            <DatePicker
              className="wizard-date-time-picker"
              disablePast
              disabled={data.hasSoldPasses}
              color="#9B63F8"
              title="Set start date"
              format="MM/DD/YYYY"
              onChange={({ _d: date }) => setTime(date, 'date', 'start')}
              TextFieldComponent={(props) => <DateTimeInput {...props} type="date" />}
              value={start}
            />
          </div>
          <div className="wizard__start-time">
            <div className="wizard__date-label">Start time</div>
            <DateTimeInput
              type="time"
              disabled={data.hasSoldPasses}
              onChange={(date) => setTime(date, 'time', 'start')}
              value={start}
              format="h:mm A Z"
            />
          </div>
        </div>
        <div className="wizard__start-date-time">
          <div className="wizard__start-date">
            <div className="wizard__date-label">End date</div>
            <DatePicker
              className="wizard-date-time-picker"
              disablePast
              title="Set end date"
              open={endDatePickerOpen}
              onOpen={() => setEndDatePickerOpen(true)}
              onClose={() => setEndDatePickerOpen(false)}
              format="MM/DD/YYYY"
              disabled={data.hasSoldPasses}
              onChange={({ _d: date }) => setTime(date, 'date', 'end')}
              value={end}
              TextFieldComponent={(props) => <DateTimeInput {...props} type="date" />}
            />
          </div>
          <div className="wizard__start-time">
            <div className="wizard__date-label">End time</div>
            <DateTimeInput
              type="time"
              format="h:mm A Z"
              disabled={data.hasSoldPasses}
              onChange={(date) => setTime(date, 'time', 'end')}
              value={end}
            />
          </div>
        </div>
      </MuiPickersUtilsProvider>
      <Footer
        status={data && data.published}
        lastSaved={data && data.updatedAt}
        onNextStep={validate}
        saving={isLoading}
      />
    </div>
  );
};

export default Dates;
