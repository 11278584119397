import { FESTIVAL_ACTION_TYPES, FETCH_EXPERIENCES } from 'appconstants';

const initialState = {
  festivalInfo: {}, // festivalInfo is for display
  festivalInfoError: null,
  loadingFestivalInfo: false, // when loading festivalInfo
  savingFestival: false, // when saving festival
  userFestivals: [],
  loadingFestivals: false,
  festivals: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_EXPERIENCES.FETCH_EXPERIENCES_SUCCESS:
      return {
        ...state,
        festivals: action.response.festivals.filter(
          ({ endTime }) => new Date(endTime) >= new Date()
        ),
      };

    case FETCH_EXPERIENCES.FETCH_EXPERIENCES_FAILURE:
      return {
        ...state,
        festivals: action.reset ? [] : state.festivals,
      };

    case FESTIVAL_ACTION_TYPES.SET_MAIN_CHANNEL:
      return {
        ...state,
        savingFestival: true,
      };
    case FESTIVAL_ACTION_TYPES.SET_MAIN_CHANNEL_SUCCESS:
      return {
        ...state,
        festival: action.response,
        savingFestival: false,
        festivalError: null,
      };
    case FESTIVAL_ACTION_TYPES.SET_MAIN_CHANNEL_ERROR:
      return {
        ...state,
        savingFestival: false,
        festivalError: action.error,
      };
    case FESTIVAL_ACTION_TYPES.GET_FESTIVALS_REQUEST:
      return {
        ...state,
        loadingFestivals: true,
      };
    case FESTIVAL_ACTION_TYPES.GET_FESTIVALS_SUCCESS:
      return {
        ...state,
        loadingFestivals: false,
        userFestivals: action.response,
      };
    case FESTIVAL_ACTION_TYPES.GET_FESTIVALS_ERROR:
      return {
        ...state,
        loadingFestivals: false,
      };
    default:
      return state;
  }
}
