import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import useFestivalInformation, { useLastStep } from 'hooks/useFestivalInformation';
// import useWizardValidation from 'hooks/useWizardValidation';

/**
 * content: is an object where the key is the step you want to display (creation order) and value is the component itself.
 * title: is the title you want to display at the top of the page
 */

const Wizard = ({ content, params, setLastStep: shouldSetLastStep = false, title }) => {
  const [selectedStep, setSelectedStep] = useState(Object.keys(content)[0]);
  const [stepIndex, setStepIndex] = useState(0);
  const [setLastStep, setSetLastStep] = useState(shouldSetLastStep);
  const Component = useMemo(() => content[selectedStep].component, [content, selectedStep]);
  const [lastCompletedStep, setLastCompletedStep] = useState(0);

  const isStepDisabled = useCallback(
    (index) => {
      return lastCompletedStep !== undefined && index > lastCompletedStep;
    },
    [lastCompletedStep]
  );

  const setStep = useCallback(
    (index, checkLastStepCompleted = false, triggerSave = false) => {
      if (checkLastStepCompleted && isStepDisabled(index)) {
        return;
      }

      if (index >= 0 && index < Object.keys(content).length) {
        setSelectedStep(Object.keys(content)[index]);
        setStepIndex(index);
        setLastCompletedStep(Math.max(index, lastCompletedStep));
      }
    },
    [content, lastCompletedStep, isStepDisabled]
  );

  const renderSteps = useCallback(
    (validate = () => {}, lastStep = 0) => {
      return (
        <Steps>
          {Object.keys(content).map((step, index) => (
            <Step
              selected={selectedStep === step}
              onClick={() => {
                validate();
                setStep(index, true, true);
              }}
              disabled={isStepDisabled(index)}
            >
              {step}
            </Step>
          ))}
        </Steps>
      );
    },
    [setStep, isStepDisabled, content, selectedStep]
  );

  return (
    <Container>
      <Title>{title || content[selectedStep].title}</Title>
      <WizardContainer>
        <Content>
          <Component
            renderSteps={renderSteps}
            goToStep={(i) => setStep(i)}
            injectedParams={params}
            nextStep={() => setStep(stepIndex + 1)}
            params={params}
            previousStep={() => setStep(stepIndex - 1)}
            setLastCompletedStep={() =>
              setLastCompletedStep(Math.max(stepIndex, lastCompletedStep))
            }
            setLastStep={setLastStep}
            setSetLastStep={setSetLastStep}
          />
        </Content>
      </WizardContainer>
    </Container>
  );
};

Wizard.propTypes = {
  content: PropTypes.objectOf(PropTypes.element),
  title: PropTypes.string,
};

const Container = styled.div`
  background-color: #f5f5f5;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: 'CircularStd Book';
  gap: 20px;
  min-height: calc(100vh - 82px);
  padding-top: 55px;
  align-items: center;
`;

const WizardContainer = styled.div`
  display: flex;
  gap: 50px;
  grid-auto-flow: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 860px;
`;

const Content = styled.div`
  width: 600px;
  padding: 0 90px;
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 90px;
  top: 0;
  left: 0;
  flex: 0 0 90px;
`;

const Step = styled.div`
  color: ${({ selected }) => (selected ? '#A55BFF' : '#999999')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  height: 35px;
  letter-spacing: 0;
  line-height: 35px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding-right: 12px;
  text-align: right;
  transition: color 0.25s;
  user-select: none;

  &::before {
    border-right: 1px solid ${({ selected }) => (selected ? '#A55BFF' : '#ebebeb')};
    content: '';
    height: 35px;
    position: absolute;
    right: 0;
    transition: border-right 0.25s;
  }

  &:hover {
    ${({ disabled }) => !disabled && 'color: #A55BFF;'}

    &::before {
      ${({ disabled }) => !disabled && 'border-right-color: #A55BFF;'}
    }
  }
`;

const Title = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
  padding: 0 90px;
  width: 592px;
`;

export default Wizard;
