import React from 'react';
import { Dialog } from 'material-ui';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

const DiscountModal = ({ disabled, discount, error, isOpen, onChange, onClose, onSave }) => {
  return (
    <Dialog
      bodyClassName="ticketing-dialog-body"
      contentClassName="ticketing-dialog-content"
      open={isOpen}
      onRequestClose={onClose}
      title={
        <div>
          {discount.createdOn ? 'Edit discount' : 'Create discount'}
          <CloseIcon color="#999999" onClick={onClose} />
        </div>
      }
      titleClassName="ticketing-dialog-title"
    >
      <span className="label">Code</span>
      <div className="gray-form-field">
        <input
          className="input"
          type="text"
          placeholder="Enter discount code"
          value={discount.code}
          onChange={({ target: { value: code } }) => onChange({ code })}
        />
      </div>
      <div>
        <div className="amount">
          <span className="label">Amount</span>
          <div className="gray-form-field">
            <input
              className="input"
              type="text"
              placeholder="$"
              value={discount.amountAbsolute}
              onChange={({ target: { value: amountAbsolute } }) => onChange({ amountAbsolute })}
            />
          </div>
        </div>
        <div className="amount">
          <div className="gray-form-field">
            <input
              className="input"
              type="text"
              placeholder="%"
              value={discount.amountPercentage}
              onChange={({ target: { value: amountPercentage } }) => onChange({ amountPercentage })}
            />
          </div>
        </div>
      </div>
      <div className="uses">
        <span className="label">Uses</span>
        <div className="gray-form-field">
          <input
            className="input"
            type="text"
            placeholder="Leave blank for unlimited"
            value={discount.total}
            onChange={({ target: { value: total } }) => onChange({ total })}
          />
        </div>
      </div>
      <div className="button-wrapper" onClick={disabled ? () => {} : onSave}>
        <button className="popup-link button-label">SAVE</button>
      </div>
      {error && <div className="discount-modal-error">{error}</div>}
    </Dialog>
  );
};

export default DiscountModal;
