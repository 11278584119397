import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, stopSubmit } from 'redux-form';
import { VALIDATION } from 'appconstants';
import { Card } from 'material-ui/Card';
import Check from 'material-ui/svg-icons/action/check-circle';
import Snackbar from 'material-ui/Snackbar';
import { get, isEqual } from 'lodash';
import { setNewPassword } from 'actions/auth';
import FormFieldShowingError from 'components/common/FormFieldShowingError';
import { CircularProgress } from 'material-ui';
import { required, createValidator, stringMinMax } from 'utils/validation';

const colors = {
  green: '#69D27C',
  red: 'rgba(255, 0, 13, 0.88)',
  yellow: '#FEBD21',
};

const styles = {
  snackbar: {
    backgroundColor: colors.red,
  },
  snackbarInfo: {
    backgroundColor: colors.green,
  },
  card: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
  },
  label: {
    color: '#999999',
    fontFamily: 'CircularStd Book',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '20px',
    marginTop: '25px',
    marginBottom: '10px',
  },
};

const validate = createValidator({
  newPassword: [
    stringMinMax({
      fieldName: 'newPassword',
      min: VALIDATION.PASSWORD_MIN,
      max: VALIDATION.PASSWORD_MAX,
    }),
  ],
  currentPassword: [required('currentPassword')],
  confirmNewPassword: [
    stringMinMax({
      fieldName: 'confirmNewPassword',
      min: VALIDATION.PASSWORD_MIN,
      max: VALIDATION.PASSWORD_MAX,
    }),
  ],
});

class PasswordSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrors: false,
      infoMessage: false,
      message: null,
    };
    this.resetShowErrors = this.resetShowErrors.bind(this);
  }

  async submit(data) {
    if (!isEqual(data.newPassword, data.confirmNewPassword)) {
      this.setState({
        showErrors: true,
        message: 'the New Password has to be the same as Confirm new Password',
      });
      return;
    }

    const newValues = { currentPassword: data.currentPassword, newPassword: data.newPassword };
    try {
      await this.props.setNewPassword(newValues);
      this.setState({ infoMessage: true, message: 'Your password was successfully changed.' });
    } catch (err) {
      const validationKeys = get(err, 'validation.keys', []);
      let keys = {};
      validationKeys.forEach((key) => (keys[key] = err.message));
      throw new SubmissionError({
        ...keys,
        _error: err.message || 'Submit Failed',
      });
    }
  }

  resetShowErrors() {
    this.setState({
      showErrors: false,
      infoMessage: false,
      message: null,
    });
  }

  render() {
    const { handleSubmit, error, errorMessage, submitFailed, loading } = this.props;
    const { infoMessage, message: infoMsg } = this.state;
    const message = error || errorMessage || infoMsg;
    const open = submitFailed && message;

    return loading ? (
      <div className="loader-container-settings">
        <CircularProgress size={80} thickness={5} color={'#9764f0'} />
      </div>
    ) : (
      <div className="container-settings">
        <form className="settings-form" onSubmit={handleSubmit((values) => this.submit(values))}>
          <Card style={styles.card}>
            <div className="containerInput">
              <div className="labelContainer">
                <div style={styles.label}>Current password</div>
              </div>
              <Field
                name="currentPassword"
                className="simple-input"
                component={FormFieldShowingError}
                placeholder=""
                type="password"
              />
            </div>
            <div className="containerInput">
              <div className="labelContainer">
                <div style={styles.label}>New Password</div>
              </div>
              <Field
                name="newPassword"
                className="simple-input"
                component={FormFieldShowingError}
                placeholder=""
                type="password"
              />
            </div>
            <div className="containerInput">
              <div className="labelContainer">
                <div style={styles.label}>Confirm new password</div>
              </div>
              <Field
                name="confirmNewPassword"
                className="simple-input"
                component={FormFieldShowingError}
                placeholder=""
                type="password"
              />
            </div>

            <button
              className="continueProfile-button button-settings"
              onClick={this.resetShowErrors}
            >
              <Check color="#FFFFFF" className="check" />
              <div className="container-save"> Save </div>
            </button>
          </Card>
        </form>
        <Snackbar
          open={!!open || infoMessage}
          message={message}
          autoHideDuration={4000}
          bodyStyle={infoMessage ? styles.snackbarInfo : styles.snackbar}
        />
      </div>
    );
  }
}

PasswordSettings.propTypes = {
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  submitFailed: PropTypes.bool,
  setNewPassword: PropTypes.func,
  loading: PropTypes.bool,
};

PasswordSettings = reduxForm({
  form: 'PasswordProfileForm',
  validate,
  onChange: (values, dispatch, props) => {
    if (props.submitFailed && !props.submitting) {
      dispatch(stopSubmit('PasswordProfileForm'));
    }
  },
})(PasswordSettings);

const mapStateToProps = (state) => {
  const form = state.form.PasswordProfileForm;
  const { loading } = state.auth;
  const errors = form && (form.syncErrors || (form.submitErrors && 'Submit failed!'));
  return {
    errors,
    errorMessage: errors && errors[Object.keys(errors)[0]],
    loading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNewPassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PasswordSettings);
