import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Card, CardText } from 'material-ui/Card';
import Check from 'material-ui/svg-icons/action/check-circle';
import Snackbar from 'material-ui/Snackbar';
import { saveNotifications } from 'actions/auth';

import NotificationToggle from 'components/NotificationToggle';
import { CircularProgress } from 'material-ui';
import Divider from 'material-ui/Divider';

const colors = {
  green: '#69D27C',
  red: 'rgba(255, 0, 13, 0.88)',
  yellow: '#FEBD21',
};

const styles = {
  snackbar: {
    backgroundColor: colors.red,
  },
  snackbarInfo: {
    backgroundColor: colors.green,
  },
  card: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
  },
  label: {
    color: '#999999',
    fontFamily: 'CircularStd Book',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '20px',
    marginTop: '25px',
    marginBottom: '10px',
  },
  h3: {
    fontSize: '20px',
    marginTop: '10px',
  },
  p: {
    color: '#626262',
    fontFamily: 'CircularStd Book',
    fontSize: '13px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '16px',
  },
};

class NotificationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoMessage: false,
      message: null,
    };
    this.resetShowErrors = this.resetShowErrors.bind(this);
  }

  async submit() {
    const { user } = this.props;
    const { notifications } = user;
    const newValues = { notifications };
    this.props.saveNotifications(newValues).then(() => {
      this.setState({ infoMessage: true, message: 'Your notifications were saved successfully.' });
    });
  }

  resetShowErrors() {
    this.setState({
      infoMessage: false,
      message: null,
    });
  }

  render() {
    const { handleSubmit, error, errorMessage, submitFailed, user, loading } = this.props;
    const { infoMessage, message: infoMsg } = this.state;
    const message = error || errorMessage || infoMsg;
    const open = submitFailed && message;
    return (
      <div className="container-settings">
        <form className="settings-form" onSubmit={handleSubmit((values) => this.submit(values))}>
          <Card style={styles.card}>
            {loading && (
              <div className="loader-container-settings">
                <CircularProgress size={80} thickness={5} color={'#9764f0'} />
              </div>
            )}
            {!loading && user && user.notifications && (
              <CardText style={{ padding: '0px 0px 20px 0px' }}>
                <NotificationToggle
                  user={user}
                  type="weekly-digest"
                  label="Weekly digest of upcoming shows in your home city"
                />
                <Divider style={styles.divider} />
                <NotificationToggle
                  user={user}
                  type="spot-claim-me"
                  label="Notify friends when I claim a spot"
                />
                <Divider style={styles.divider} />
                <NotificationToggle
                  user={user}
                  type="spot-claim-friend"
                  label="Notify me when friends claim spots"
                />
                <Divider style={styles.divider} />
                <NotificationToggle
                  user={user}
                  type="remaining-tickets"
                  label="Notify me when last 2 tickets are remaining"
                />
                <Divider style={styles.divider} />
                <NotificationToggle user={user} type="product-updates" label="Product updates" />
                <Divider style={styles.divider} />
              </CardText>
            )}
            <button
              className="continueProfile-button button-settings"
              onClick={this.resetShowErrors}
            >
              <Check color="#FFFFFF" className="check" />
              <div className="container-save"> Save </div>
            </button>
          </Card>
        </form>
        <Snackbar
          open={!!open || infoMessage}
          message={message}
          autoHideDuration={4000}
          bodyStyle={infoMessage ? styles.snackbarInfo : styles.snackbar}
        />
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  submitFailed: PropTypes.bool,
  user: PropTypes.object,
  saveNotifications: PropTypes.func,
  loading: PropTypes.bool,
};

NotificationSettings = reduxForm({
  form: 'NotificationProfileForm',
})(NotificationSettings);

const mapStateToProps = (state) => {
  const form = state.form.NotificationProfileForm;
  const { user, loading } = state.auth;
  const errors = form && (form.syncErrors || (form.submitErrors && 'Submit failed!'));
  return {
    errors,
    errorMessage: errors && errors[Object.keys(errors)[0]],
    user,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ saveNotifications }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
