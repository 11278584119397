import React from 'react';
import { object } from 'prop-types';
import './styles.css';

const VideoPlayer = ({ videoData }) => (
  <div className="video-player-container">
    <div
      dangerouslySetInnerHTML={{ __html: videoData.html }} // eslint-disable-line react/no-danger
      className="video-player"
    />
  </div>
);

VideoPlayer.propTypes = {
  videoData: object.isRequired,
};

export default VideoPlayer;
