export const hasChanges = (pairs = []) => pairs.some(hasChanged);

const hasChanged = ([first, second]) => {
  if (first === second) {
    return true;
  }

  if (Array.isArray(first) && JSON.stringify(first) === JSON.stringify(second)) {
    return true;
  }

  if (first.getMonth) {
    return first.toJSON() !== second.toJSON();
  }

  return false;
};
