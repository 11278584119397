import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { selectRSVPedExp, selectRSVPedFest } from 'selectors/userExperiences';
import { capitalize } from 'lodash';
import { getResizedImageURL } from 'utils/helpers';
import CheckoutScheduleItem from 'containers/FestivalCheckout/CheckoutScheduleItem';
import FestivalCard from 'components/FestivalCard';
import ExperienceScheduleMobileCard from 'containers/ExperienceScheduleMobileCard';
import FestivalCardMobile from 'components/FestivalCardMobile';
import MediaQuery from 'react-responsive';
import { IMAGE_SIZES, MOBILE_MAX_WIDTH } from 'appconstants';
import ImageNotFound from 'images/no-image-box.png';
import { useMediaQuery } from '@material-ui/core';
import { TIME_FORMATS } from 'appconstants/time2';
import { selectLoadingUserExperiences } from 'selectors/experiences';
import { CircularProgress } from 'material-ui';
import { withRouter } from 'react-router';
import { dateFormat } from 'utils/dates';

const CheckoutTickets = ({
  rsvpedExperiences,
  rsvpedFestivals,
  router,
  loadingUserExperiences,
}) => {
  const [tab, setTab] = useState('tickets');

  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH);

  const tickets = useMemo(
    () => ({
      tickets: rsvpedExperiences.filter((experience) => new Date() < new Date(experience.endTime)),
      relives: rsvpedExperiences.filter((experience) => experience.reliveUrl),
      past: rsvpedExperiences.filter((experience) => new Date() > new Date(experience.endTime)),
    }),
    [rsvpedExperiences]
  );

  const festivalsToShow = useMemo(() => {
    if (tab === 'tickets') {
      return rsvpedFestivals.filter(({ endTime }) => new Date(endTime) >= new Date());
    }

    if (tab === 'past') {
      return rsvpedFestivals.filter(({ endTime }) => new Date(endTime) < new Date());
    }

    return [];
  }, [tab, rsvpedFestivals]);

  const experiencesByDates = useCallback((festival) => {
    const orderedExperiences = festival.experiences.sort(
      (a, b) => new Date(a.startTime) - new Date(b.startTime)
    );

    let result = orderedExperiences.reduce((prev, curr) => {
      const newResult = { ...prev };

      const experienceDate = new Date(curr.startTime).toDateString();

      newResult[experienceDate] = [...(newResult[experienceDate] || []), curr];

      return newResult;
    }, {});

    result = Object.entries(result).map(([key, value]) => ({
      title: dateFormat(TIME_FORMATS.FESTIVAL_EXPERIENCE_DATE, key),
      items: value,
    }));

    return result;
  }, []);

  const imageUrl = useCallback(
    (festival) =>
      festival?.heroPicture
        ? getResizedImageURL(festival.heroPicture, IMAGE_SIZES.THUMBNAIL)
        : ImageNotFound,
    []
  );

  const festivalUrl = useCallback(
    (festival) => (festival ? `/in/${festival.channel.urlSlug}/f/${festival.alias}` : ''),
    []
  );

  const ticketsToShow = useMemo(() => tickets[tab].filter(({ festival }) => !festival), [
    tickets,
    tab,
  ]);

  const hasActiveTickets = useMemo(() => {
    const experienceTickets = rsvpedExperiences?.length ?? 0;
    const festivalTickets = festivalsToShow?.length ?? 0;
    return experienceTickets + festivalTickets > 0;
  }, [rsvpedExperiences, festivalsToShow]);

  const noTickets = <NoTickets>You have no tickets.</NoTickets>;

  return !loadingUserExperiences ? (
    <Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <Container>
          <Tabs>
            <Tab selected={tab === 'tickets'} onClick={() => setTab('tickets')}>
              Tickets
            </Tab>
            <Tab selected={tab === 'relives'} onClick={() => setTab('relives')}>
              Re-lives
            </Tab>
            <Tab selected={tab === 'past'} onClick={() => setTab('past')}>
              Past
            </Tab>
          </Tabs>
          <ScheduledExperiences>
            {hasActiveTickets ? (
              <>
                {festivalsToShow.map((festival, index) => (
                  <FestivalCard
                    key={index}
                    festival={festival}
                    imageUrl={() => imageUrl(festival)}
                    festivalUrl={() => festivalUrl(festival)}
                    experiencesByDates={() => experiencesByDates(festival)}
                  />
                ))}
                {ticketsToShow.map((experience, index) => (
                  <CheckoutScheduleItem
                    ticketPage
                    key={index}
                    // key={experience.id}
                    experience={experience}
                    lastStartTime={index !== 0 ? tickets[tab][index - 1].startTime : ''}
                    isLastItem={tickets[tab].length - 1 === index}
                    getLabel={() => `Go to ${capitalize(experience.location.platform)}`}
                    showTitle={false}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(experience.location.url);
                    }}
                    $color={'#9b63f8'}
                  />
                ))}
              </>
            ) : (
              noTickets
            )}
          </ScheduledExperiences>
        </Container>
      </MediaQuery>
      <MediaQuery query="(max-device-width: 1224px)">
        <ScheduledExperiences isMobile={isMobile}>
          {hasActiveTickets ? (
            <>
              {festivalsToShow.map((festival) => (
                <FestivalCardMobile
                  festival={festival}
                  imageUrl={() => imageUrl(festival)}
                  festivalUrl={() => festivalUrl(festival)}
                  experiencesByDates={() => experiencesByDates(festival)}
                />
              ))}
              {ticketsToShow
                .filter(({ festival }) => !festival)
                .map((experience, index) => (
                  <ExperienceScheduleMobileCard
                    experience={experience}
                    isLastItem={
                      rsvpedExperiences.filter(({ festival }) => !festival).length - 1 === index
                    }
                  />
                ))}
            </>
          ) : (
            noTickets
          )}
        </ScheduledExperiences>
      </MediaQuery>
    </Fragment>
  ) : (
    <ScheduledExperiences loading>
      <center>
        <CircularProgress />
      </center>
      ;
    </ScheduledExperiences>
  );
};

const NoTickets = styled.div`
  color: #000000;
  font-family: CircularStd Book;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f3f4;
  align-items: center;
  width: 100%;
  padding: 60px 0 20px;
  justify-content: flex-start;
  flex: 1 1 100vh;
  box-sizing: border-box;
`;

const Tabs = styled.div`
  display: flex;
  gap: 12px;
  width: 900px;
`;

const Tab = styled.div`
  color: ${({ selected }) => (selected ? '#000000' : '#999999')};
  font-family: 'Circular Std';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
  cursor: pointer;
  ${({ selected }) => (selected ? 'border-bottom: 1px solid #000000;' : '')};
`;

const ScheduledExperiences = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #f3f3f4;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  padding-top: ${({ isMobile }) => (isMobile ? 89 : 20)}px;
  justify-content: flex-start;
  ${({ loading }) => loading && 'padding-top: 89px'}
`;

export default connect((state) => ({
  rsvpedExperiences: selectRSVPedExp(state),
  rsvpedFestivals: selectRSVPedFest(state),
  loadingUserExperiences: selectLoadingUserExperiences(state),
}))(withRouter(CheckoutTickets));
