import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Card, Snackbar } from 'material-ui';
import Check from 'material-ui/svg-icons/action/check-circle';
import { fetchOAuthConfirmation } from 'actions/stripe';
import Loading from 'components/Loading';
import { updateUser } from 'actions/auth';

import './styles.css';

const styles = {
  card: {
    style: {
      borderBottom: '1px solid #f8f8f8',
      padding: '34px 32px',
      boxShadow: '0px 0px 0px',
      borderRadius: '5px',
      overflowWrap: 'anywhere',
      maxWidth: '900px',
      margin: 'auto',
      width: '100%',
    },
  },
};

class FinishSetupStripe extends React.Component {
  constructor(props) {
    super(props);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.getConfirmation = this.getConfirmation.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { code, state } = this.props.location.query;
    this.getConfirmation({ code, state });
  }

  getConfirmation = async ({ code, state }) => {
    try {
      await this.props.fetchOAuthConfirmation({ code, state });
      this.setState({ done: true });
    } catch (e) {
      this.setState({ done: true, error: true, errorMessage: e.message });
    }
  };

  closeSnackbar() {
    this.setState({ errorMessage: false });
  }

  async updateUserData() {
    this.props.updateUser();
    browserHistory.push('/dashboard');
  }

  render() {
    const { done, error } = this.state;
    const success = `Congrats! You’ve successfully setup and connected your Stripe account with PRESENCE. All payments for tickets, donations and subscriptions will be made to this account. Let’s wrap this up with one last thing.`;
    const loading = `Getting everything ready...`;
    const failed = `There was an error authorizing your account. Try again later`;
    return (
      <div className="container stripe-finish-container">
        <h3 className="text title stripe-finish-title">Setup your payment</h3>
        <Card style={styles.card.style}>
          <div className="text-block">
            <h3 className="text card-text stripe-finish-message">
              {!done && loading}
              {done && (error ? failed : success)}
            </h3>
          </div>
          {done || error ? (
            <button
              className="continueProfile-button"
              style={{ backgroundColor: '#69D27C' }}
              onClick={this.updateUserData}
            >
              <Check color="#FFFFFF" style={styles.check} /> Continue
            </button>
          ) : (
            <Loading />
          )}
          <Snackbar
            open={!!this.state.errorMessage}
            message={this.state.errorMessage}
            autoHideDuration={4000}
            onRequestClose={this.closeSnackbar}
            bodyStyle={{ backgroundColor: 'rgba(255, 0, 13, 0.88)' }}
          />
        </Card>
      </div>
    );
  }
}

FinishSetupStripe.propTypes = {
  fetchOAuthConfirmation: PropTypes.func,
  updateUser: PropTypes.func,
  location: PropTypes.object,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchOAuthConfirmation, updateUser }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FinishSetupStripe);
