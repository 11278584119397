import React from 'react';
import { string, shape, bool, node, object, oneOf } from 'prop-types';
import cn from 'classnames';

import { SELECT_STYLES } from 'appconstants';
import './styles.css';

const SelectFieldShowingError = ({
  input,
  meta: { touched, error } = {},
  children,
  className = '',
  disabled,
  selectStyle = SELECT_STYLES.TRANSPARENT,
  value,
  ...other
}) => (
  <select
    {...input}
    {...other}
    disabled={disabled}
    className={cn('select-all', `select-${selectStyle}`, className, {
      'input-error': touched && error,
      'input-disabled': disabled,
    })}
    value={disabled ? '' : value}
  >
    {disabled && (
      <option value="" selected>
        ---
      </option>
    )}
    {children}
  </select>
);

SelectFieldShowingError.propTypes = {
  input: object,
  className: string,
  meta: shape({ error: string, touched: bool }),
  children: node,
  selectStyle: oneOf(Object.keys(SELECT_STYLES).map((k) => SELECT_STYLES[k])),
};

export default SelectFieldShowingError;
