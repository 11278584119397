/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { reduxForm, Field, SubmissionError, change, stopSubmit } from 'redux-form';
import { RaisedButton, Divider, Snackbar } from 'material-ui';
import cn from 'classnames';

import * as actions from 'actions/auth';
import { selectUserLoading } from 'selectors/user';

import FormFieldShowingError from 'components/common/FormFieldShowingError';
import FieldCheckbox from 'components/FieldCheckbox';

import { USER_TYPES, SIGNUP_TABS } from 'appconstants';
import routes from 'appconstants/routes';
import { hasPendingOnboarding } from 'utils/helpers';
import { createValidator, required, email } from 'utils/validation';

import 'stylesheets/style.css';
import './styles.css';

const validate = createValidator({
  name: [required('name')],
  email: [required('email'), email],
  password: [required('password')],
});

const styles = {
  submitButton: {
    style: {
      height: '54px',
    },
    label: {
      paddingTop: '10px',
      color: '#FFFFFF',
      fontFamily: 'CircularStd Book',
      fontSize: '13.5px',
      fontWeight: 'bold',
      letterSpacing: '1.6px',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  divider: {
    marginTop: '107px',
  },
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
};

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInText: 'Welcome to Presence',
      signupText: 'Sign-up to join',
      selectedTab: props.defaultTab,
      showErrors: true,
    };
  }

  componentDidMount() {
    this.loadDataFromInviteToken();
  }

  UNSAFE_componentWillReceiveProps({ defaultTab: newDefaultTab }) {
    const { defaultTab: currentDefaultTab } = this.props;
    if (newDefaultTab !== currentDefaultTab) {
      this.setState({ selectedTab: newDefaultTab });
    }
  }

  onSubmitError = (rejection) => {
    let message = 'Submit failed!';
    switch (rejection.code) {
      case 409:
        message = 'The email is already in use!';
        break;
      default:
        message = 'Submit failed!';
    }
    this.setState({ showErrors: true });
    throw new SubmissionError({ password: rejection.error, _error: message });
  };

  onSubmitLoginSuccess = (user) => {
    const { onSubmitSuccess, location } = this.props;
    const path = hasPendingOnboarding(user);

    if (path) return browserHistory.push(routes.onBoarding);
    if (onSubmitSuccess) return onSubmitSuccess();

    document.body.style.removeProperty('position');
    if (location.state && location.state.nextPathname) {
      return browserHistory.push(location.state.nextPathname);
    }
    return browserHistory.push('/');
  };

  onSubmitLoginError = (rejection) => {
    this.setState({ showErrors: true });
    throw new SubmissionError({
      password: rejection.error,
      _error: 'Login failed!',
    });
  };

  setData = () => {
    const { token, email: signUpEmail, name } = this.state;
    if (token) this.props.change('email', signUpEmail);
    if (token) this.props.change('name', name);
  };

  loadDataFromInviteToken = async () => {
    const { token } = this.props;

    if (token) {
      try {
        const {
          name,
          email: userEmail,
          type,
          channelName,
        } = await this.props.loadDataFromInviteToken(token);
        this.setState({
          email: userEmail,
          name,
          token,
          type,
          signupText:
            type === USER_TYPES.CREATOR
              ? `Sign up to create with ${channelName}`
              : 'Sign up as producer',
        });
      } catch (error) {
        this.setState({ error: 'Invalid invite link!' });
      }
    }
  };

  signIn = async (data) => {
    const { submitLogin } = this.props;
    try {
      const { payload: user } = await submitLogin({
        email: data.email,
        password: data.password,
      });
      this.onSubmitLoginSuccess(user);
    } catch (err) {
      this.onSubmitLoginError(err);
    }
  };

  signUp = (data) => {
    const { saveUser } = this.props;
    const { token } = this.state;

    data['newsletter-opt-in'] = !!data['newsletter-opt-in'];

    return saveUser({ ...data, token }).then(() => this.signIn(data), this.onSubmitError);
  };

  handleChangeTab = (newTab) => {
    const { onChangeTab } = this.props;
    this.setState({ selectedTab: newTab });
    if (onChangeTab) onChangeTab(newTab);
  };

  closeSnackBar = () => {
    this.setState({ error: '', showErrors: false });
  };

  renderSnackbar = () => {
    const { error: submitError, submitFailed } = this.props;
    const { showErrors, error } = this.state;
    const message = (submitFailed && submitError) || error;
    const open = showErrors && message;
    return (
      <Snackbar
        open={!!open}
        message={message}
        onRequestClose={this.closeSnackBar}
        autoHideDuration={4000}
        bodyStyle={styles.snackbar}
      />
    );
  };

  render() {
    const { handleSubmit, pristine, submitting, error, loading } = this.props;
    const { token, signupText, signInText, selectedTab } = this.state;
    const isSignUp = selectedTab === SIGNUP_TABS.signup;
    this.setData();

    return (
      <div className="form-container clearfix">
        <div className="signup-form-wrapper">
          <div className="signup-form" style={isSignUp ? { height: '705px' } : { height: '520px' }}>
            <div className="form-head-container">
              <div className="form-header">
                <h5>{isSignUp ? signupText : signInText}</h5>
                <div className="containerTabs">
                  <button
                    onClick={() => this.handleChangeTab(SIGNUP_TABS.signin)}
                    className={cn({ tab: isSignUp, tabSelected: !isSignUp })}
                  >
                    Sign-in
                  </button>
                  <button
                    onClick={() => this.handleChangeTab(SIGNUP_TABS.signup)}
                    className={cn({ tab: !isSignUp, tabSelected: isSignUp })}
                  >
                    Create account
                  </button>
                </div>
              </div>
            </div>
            <Divider style={styles.divider} />
            <form onSubmit={handleSubmit(isSignUp ? this.signUp : this.signIn)}>
              {isSignUp && (
                <div className="containerInput container-sign-up">
                  <div className="labelContainer">
                    <div className="label-sign-up">Full Name</div>
                  </div>
                  <Field
                    name="name"
                    component={FormFieldShowingError}
                    type="text"
                    disabled={!!token}
                    className="simple-input"
                  />
                </div>
              )}
              <div className="containerInput container-sign-up">
                <div className="labelContainer">
                  <div className="label-sign-up">Email</div>
                </div>
                <Field
                  name="email"
                  component={FormFieldShowingError}
                  type="email"
                  className="simple-input"
                  disabled={!!token}
                  autoComplete="new-password"
                />
              </div>
              <div className="containerInput container-sign-up">
                <div className="labelContainer">
                  <div className="label-sign-up">Password</div>
                </div>
                <Field
                  name="password"
                  component={FormFieldShowingError}
                  type="password"
                  placeholder=""
                  className="simple-input"
                  autoComplete="new-password"
                />
              </div>
              {isSignUp && (
                <div className="containerInput container-sign-up">
                  <div className="signup-checkbox-wrapper">
                    <Field
                      parentClassName="signup-checkbox"
                      labelClassName="signup-checkbox-label"
                      name="newsletter-opt-in"
                      component={FieldCheckbox}
                      label="Send me weekly digest of upcoming experiences"
                    />
                  </div>
                </div>
              )}
              <RaisedButton
                className="signup-button"
                label={isSignUp ? 'continue' : 'sign in'}
                onClick={handleSubmit(isSignUp ? this.signUp : this.signIn)}
                style={styles.submitButton.style}
                labelStyle={styles.submitButton.label}
                disabled={loading || pristine || submitting}
              />
            </form>
            <div>
              {isSignUp && (
                <p className="p-sign-up">
                  By signing up for Presence, you agree to our
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://terms.withpresence.com"
                    className="signup-link"
                  >
                    Terms of Service
                  </a>
                  and
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://privacypolicy.withpresence.com"
                    className="signup-link"
                  >
                    Privacy Policy.
                  </a>
                  You also agree to receive offers and concert updates from us.
                </p>
              )}
              {!isSignUp && (
                <div className="forgot-password-link">
                  <p> Forgot Password? </p>
                  <Link to="/forgot-password" className="signup-link">
                    Get it here
                  </Link>
                </div>
              )}
            </div>
          </div>
          {this.renderSnackbar(error || this.state.error)}
        </div>
      </div>
    );
  }
}

SignupForm.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  saveUser: PropTypes.func.isRequired,
  submitLogin: PropTypes.func.isRequired,
  location: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  token: PropTypes.string,
  loadDataFromInviteToken: PropTypes.func,
  defaultTab: PropTypes.oneOf([SIGNUP_TABS.signup, SIGNUP_TABS.signin]),
  change: PropTypes.func,
  onChangeTab: PropTypes.func,
  submitFailed: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ change }, dispatch);

export default connect(
  (state) => ({
    initialValues: { 'newsletter-opt-in': true },
    loading: selectUserLoading(state),
  }),
  {
    ...actions,
    mapDispatchToProps,
  }
)(
  reduxForm({
    form: 'SignupForm',
    validate,
    onChange: (values, dispatch, props) => {
      if (props.submitFailed && !props.submitting) {
        dispatch(stopSubmit('SignupForm'));
      }
    },
  })(SignupForm)
);
