export const SAVE_ARTIST_REQUEST = 'SAVE_ARTIST_REQUEST';
export const SAVE_ARTIST_SUCCESS = 'SAVE_ARTIST_SUCCESS';
export const SAVE_ARTIST_FAILURE = 'SAVE_ARTIST_FAILURE';
export const UPDATE_ARTIST_REQUEST = 'UPDATE_ARTIST_REQUEST';
export const UPDATE_ARTIST_SUCCESS = 'UPDATE_ARTIST_SUCCESS';
export const UPDATE_ARTIST_FAILURE = 'UPDATE_ARTIST_FAILURE';
export const LOAD_ARTIST_REQUEST = 'LOAD_ARTIST_REQUEST';
export const LOAD_ARTIST_SUCCESS = 'LOAD_ARTIST_SUCCESS';
export const LOAD_ARTIST_FAILURE = 'LOAD_ARTIST_FAILURE';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_ARTIST_SUCCESS:
      return {
        ...state,
        loading: false,
        image: action,
      };
    case SAVE_ARTIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ARTIST_SUCCESS:
      return {
        ...state,
        loading: false,
        artist: action,
      };
    case UPDATE_ARTIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LOAD_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ARTIST_SUCCESS:
      return {
        ...state,
        loading: false,
        image: action,
      };
    case LOAD_ARTIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
