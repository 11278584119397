import { FESTIVAL_ACTION_TYPES } from 'appconstants';
import { CALL_API } from 'redux_store/middleware/api';

function setFestivalMainChannel(channel, festivalId = null) {
  const method = 'post';
  const endpoint = `/festivals${festivalId ? `/${festivalId}` : ''}`;
  const body = { channel };
  return {
    [CALL_API]: {
      types: [
        FESTIVAL_ACTION_TYPES.SET_MAIN_CHANNEL,
        FESTIVAL_ACTION_TYPES.SET_MAIN_CHANNEL_SUCCESS,
        FESTIVAL_ACTION_TYPES.SET_MAIN_CHANNEL_ERROR,
      ],
      body,
      method,
      endpoint,
    },
  };
}

export const updateFestivalInformation = (festivalId, step, payload) => {
  const method = 'put';
  const endpoint = `/festivals/${festivalId}/${step}`;
  const body = payload;

  const action = {
    [CALL_API]: {
      types: [
        FESTIVAL_ACTION_TYPES.UPDATE_FESTIVAL_INFORMATION,
        FESTIVAL_ACTION_TYPES.UPDATE_FESTIVAL_INFORMATION_SUCCESS,
        FESTIVAL_ACTION_TYPES.UPDATE_FESTIVAL_INFORMATION_ERROR,
      ],
      body,
      method,
      endpoint,
    },
  };

  return (dispatch) => dispatch(action);
};

export const saveFestivalChannel = (channelId, festivalId = null) => {
  return (dispatch) => dispatch(setFestivalMainChannel(channelId, festivalId));
};

export const getExperienceAvailableFestivals = (experienceId) => {
  const method = 'get';
  const endpoint = `/festivals/experience-available-festivals/${experienceId}`;

  const action = {
    [CALL_API]: {
      types: [
        FESTIVAL_ACTION_TYPES.GET_FESTIVALS_REQUEST,
        FESTIVAL_ACTION_TYPES.GET_FESTIVALS_SUCCESS,
        FESTIVAL_ACTION_TYPES.GET_FESTIVALS_ERROR,
      ],
      method,
      endpoint,
    },
  };

  return (dispatch) => dispatch(action);
};
