import React from 'react';
import { object, number, func, node } from 'prop-types';

import Button from 'components/Button';
import SelectFieldShowingError from 'components/common/SelectFieldShowingError';

import { BUTTON_STYLES, SELECT_STYLES } from 'appconstants';
import { calculateDiscount } from 'utils/helpers';

import './styles.css';

const SelectTicketMobile = ({
  experience,
  totalTickets,
  subTotal,
  fee,
  onSubmit,
  onChange,
  header,
  appliedDiscount,
  discountCode,
}) => {
  const { tickets } = experience;

  const experienceQuantity = experience.totalTickets - experience.sold;

  const renderDropDownTickets = (item, index) => {
    const handleChange = (e) => {
      onChange({
        id: item.id,
        quantity: Number(e.target.value),
        cardPrice: item.price,
        discount: item.discount,
      });
    };

    const maxQuantity = Math.min(item.quantity > 4 ? 4 : item.quantity, experienceQuantity);

    const options = [...Array(maxQuantity).keys()];

    return (
      <SelectFieldShowingError
        onChange={handleChange}
        defaultValue={index === 0 ? options[1] : options[0]}
        selectStyle={SELECT_STYLES.GREY}
        className="ticket-page-select"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SelectFieldShowingError>
    );
  };

  return (
    <div className="ticket-page-container-mob">
      <div className="ticket-page-info-wrapper-mob">
        {header}
        <div className="ticket-list-mob">
          {tickets &&
            tickets.map((item, index) => (
              <div key={item.id} className="ticket-item-mob">
                <div className="ticket-item-name-wrapper-mob">
                  <h4 className="ticket-item-name-mob">{item.description}</h4>
                </div>
                <div className="ticket-price-wrapper-mob">
                  {item.discount && item.discount !== 0 ? (
                    <div className="ticket-price-mob">
                      <small className="original-price">${item.price}</small>
                      <strong className="offer-price">
                        ${calculateDiscount(item.price, item.discount)}
                      </strong>
                    </div>
                  ) : (
                    <div className="ticket-price-mob">
                      <strong className="offer-price">${item.price}</strong>
                    </div>
                  )}
                </div>
                <div className="num-of-ticket-mob">{renderDropDownTickets(item, index)}</div>
              </div>
            ))}
        </div>
      </div>
      {discountCode}
      {appliedDiscount}
      <div className="ticket-footer-mob">
        <div className="btn-wrap-mob clearfix gradient-btn-mob">
          <div className="purchase-ticket-button-wrapper">
            <Button
              className="purchase-ticket-button"
              buttonStyle={BUTTON_STYLES.GREEN}
              onClick={onSubmit}
            >
              {`PURCHASE ${totalTickets}x: $${subTotal} + $${fee}`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectTicketMobile.propTypes = {
  experience: object.isRequired,
  subTotal: number.isRequired,
  fee: number.isRequired,
  totalTickets: number.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  header: node.isRequired,
  discountCode: node.isRequired,
  appliedDiscount: node.isRequired,
};

export default SelectTicketMobile;
