// const DATETIME_RANGE_TEMPLATE_REPLACE_STRING = 'END_OF_RANGE';
// const MONTH_AND_DATE_WITH_ABBREV = 'MMM Do';

export const TIME_FORMATS = {
  DATETIME: {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: 'true',
    timeZoneName: 'short',
  },
  EVENT_TIME: {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  },
  TICKET_PAGE_DATE_AND_TIME: {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZoneName: 'short',
  },
  MONTH_AND_DATETIME: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  },
  NAMED_DAY_AND_MONTH: {
    weekday: 'short',
    month: 'long',
    day: '2-digit',
  },
  FESTIVAL_EXPERIENCE_DATE: {
    month: 'short',
    day: 'numeric',
  },
  FESTIVAL_EXPERIENCE_DATE_FULL_MONTH: { month: 'long', day: 'numeric' },

  EVENT_MONTH: {
    month: 'short',
  },
  EVENT_DAY: {
    day: 'numeric',
  },
  EVENT_DAY_WITH_ABBREV: {
    day: 'numeric',
  },
  MONTH_AND_DATE_WITH_ABBREV: {
    month: 'short',
    day: 'numeric',
  },
  FULL_NAMED_DAY_AND_MONTH: {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  },
};

//   // DATETIME_RANGE_TEMPLATE_REPLACE_STRING to be later replaced. Within brackets for escaping
//   DATETIME_RANGE_TEMPLATE_REPLACE_STRING, // String to be replaced and complete a date range string.
//   DATETIME_RANGE_TEMPLATE: `ddd, ${MONTH_AND_DATE_WITH_ABBREV} - [${DATETIME_RANGE_TEMPLATE_REPLACE_STRING}] | h:mma`, // e.g. Tue, Dec 8th - 10th | 7:03pm
//   ERA: 'A', // e.g AM
//   EVENT_TIME_WITHOUT_ERA: 'h:mm', // e.g. 7:30
//   FULL_NAMED_DAY_AND_MONTH: 'dddd, MMMM Do', // e.g. Monday, February 8th
// };

export const DATE_RANGE_FORMATS = {
  DATE: 'DATE',
  MONTH: 'MONTH',
  FULL_DATE: 'FULL_DATE',
};
