import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import { FontIcon } from 'material-ui';
import { FILE_UPLOADER_TYPES } from 'appconstants';
import uploadIcon from 'images/uploadFile.png';

import { iconText, messages, disclaimer } from './constants';
import './style.css';

// TODO: Add all of these into classes
const styles = {
  [FILE_UPLOADER_TYPES.SQUARE]: {
    display: 'flex',
  },
  [FILE_UPLOADER_TYPES.LOGO]: {
    height: '95px',
    width: '95px',
    borderRadius: '50px',
    backgroundColor: '#000000',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
  },
  [FILE_UPLOADER_TYPES.INLINE]: {
    cursor: 'pointer',
    textAlign: 'center',
    borderColor: '#D5D5D5',
    borderStyle: 'dashed',
    borderRadius: 5,
    padding: 12,
    color: '#D5D5D5',
  },
  [FILE_UPLOADER_TYPES.MULTIPLE]: {
    cursor: 'pointer',
    textAlign: 'center',
    borderColor: '#D5D5D5',
    borderStyle: 'dashed',
    borderRadius: 5,
    padding: 15,
    color: '#D5D5D5',
  },
};

const FileDropZone = ({
  meta: { touched, error },
  addFiles,
  minWidth,
  minHeight,
  type = FILE_UPLOADER_TYPES.SQUARE,
}) => {
  const isSquare = type === FILE_UPLOADER_TYPES.SQUARE;
  const isInline = type === FILE_UPLOADER_TYPES.INLINE;
  const isMultiple = type === FILE_UPLOADER_TYPES.MULTIPLE;
  const isLogo = type === FILE_UPLOADER_TYPES.LOGO;
  const showText = isInline || isMultiple;

  return (
    <div className={cn({ 'file-uploader-container': isSquare })}>
      <Dropzone
        style={styles[type]}
        className={`file-dropzone-${type} file-dropezone`}
        multiple={isMultiple}
        activeStyle={styles.dropzoneActive}
        accept="image/*"
        onDrop={(acceptedFiles) => addFiles(acceptedFiles, !isMultiple)}
      >
        {isSquare && <div className="empty-square-dropzone" />}
        {isSquare && (
          <div
            className={cn({
              'input-error': isSquare && touched && error,
              'button-upload-empty': isSquare,
            })}
          >
            <img src={uploadIcon} alt="" width="39px" style={{ margin: '30px' }} />
          </div>
        )}
        {showText && (
          <div>
            <FontIcon className="material-icons" color="#999999" style={{ fontSize: 45 }}>
              {iconText[type]}
            </FontIcon>
            <h3 style={{ margin: 0 }}>{messages[type]}</h3>
            {isInline && (
              <p style={{ marginTop: '0px' }}>{disclaimer[type]({ minWidth, minHeight })}</p>
            )}
          </div>
        )}
        {isLogo && <img src={uploadIcon} className="upload-image-logo" alt="" width="39px" />}
      </Dropzone>
    </div>
  );
};

FileDropZone.propTypes = {
  meta: PropTypes.object,
  addFiles: PropTypes.func,
  type: PropTypes.oneOf([
    FILE_UPLOADER_TYPES.SQUARE,
    FILE_UPLOADER_TYPES.LOGO,
    FILE_UPLOADER_TYPES.INLINE,
    FILE_UPLOADER_TYPES.MULTIPLE,
  ]),
};

export default FileDropZone;
