import React, { useState } from 'react';
import useFestivalInformation from 'hooks/useFestivalInformation';
import FestivalHeader from './FestivalHeader';
import Guestlist from './Guestlist';
import FestivalExperiences from './Experiences';
import Discounts from './Discounts';
import FestivalWizard from 'containers/FestivalWizard';
import Loader from './Loader';
import MediaQuery from 'react-responsive';

const FestivalDashboard = ({ params: { alias, channel }, location }) => {
  const [section, setSection] = useState(location?.query?.['initial-tab'] || 'guestlist');
  const { isLoading, data } = useFestivalInformation(`/${channel}/${alias}`);

  if (isLoading) {
    return <Loader />;
  }

  const {
    _id,
    endTime,
    name,
    netSales,
    pageDesign: { heroPicture },
    sold,
    startTime,
    tickets,
  } = data;

  return (
    <>
      <MediaQuery query="(max-width: 1224px">
        <div className="dashboard-container--mobile">
          <h1>Please access this page on your desktop</h1>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 1224px)">
        <div className="dashboard-container">
          <div className="dashboard">
            <FestivalHeader
              alias={alias}
              channel={channel}
              endTime={endTime}
              heroPicture={heroPicture}
              name={name}
              netSales={netSales || 0}
              section={section}
              setSection={setSection}
              sold={sold}
              startTime={startTime}
              tickets={tickets}
            />
            <div className="dashboard-content">
              {section === 'guestlist' && <Guestlist alias={alias} channel={channel} />}
              {section === 'experiences' && <FestivalExperiences alias={alias} channel={channel} />}
              {section === 'edit' && (
                <FestivalWizard
                  params={{
                    name: data.name,
                    id: data._id,
                  }}
                />
              )}
              {section === 'discounts' && (
                <Discounts festivalId={_id} alias={alias} channel={channel} />
              )}
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default FestivalDashboard;
