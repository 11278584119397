const categories = [
  {
    id: 'dance',
    description: 'Dance',
  },
  {
    id: 'mindful',
    description: 'Mindful',
  },
  {
    id: 'wellness',
    description: 'Wellness',
  },
  {
    id: 'adventurous',
    description: 'Adventurous',
  },
  {
    id: 'social',
    description: 'Social',
  },
  {
    id: 'learning',
    description: 'Learning',
  },
  {
    id: 'playful',
    description: 'Playful',
  },
  {
    id: 'self-love',
    description: 'Self-love',
  },
  {
    id: 'experimental',
    description: 'Experimental',
  },
];

export default categories;
