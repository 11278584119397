const SAVE_LOCATION_REQUEST = 'SAVE_LOCATION_REQUEST';
const SAVE_LOCATION_SUCCESS = 'SAVE_LOCATION_SUCCESS';
const SAVE_LOCATION_FAILURE = 'SAVE_LOCATION_FAILURE';
const LOAD_LOCATION_REQUEST = 'LOAD_LOCATION_REQUEST';
const LOAD_LOCATION_SUCCESS = 'LOAD_LOCATION_SUCCESS';
const LOAD_LOCATION_FAILURE = 'LOAD_LOCATION_FAILURE';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        location: action,
      };
    case SAVE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LOAD_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        location: action,
      };
    case LOAD_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
