import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Snackbar } from 'material-ui';
import StripeDashboard from './StripeDashboard';
import SetupStripe from './SetupStripe';
import { fetchStripeLink } from 'actions/stripe';
import Loading from 'components/Loading';
import './styles.css';

class SetupStripeWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: '' };
  }

  UNSAFE_componentWillMount() {
    this.loadInformation();
  }

  loadInformation = async () => {
    try {
      const { url, connected, actionNeeded } = await this.props.fetchStripeLink();
      this.setState({ url, actionNeeded, connected });
    } catch (e) {
      this.setState({
        errorMessage: `There was an error loading stripe information. Try again later.`,
      });
    }
  };

  render() {
    const { settings } = this.props;
    const { url, connected, actionNeeded } = this.state;
    const label = actionNeeded ? 'Go to Stripe Dashboard' : 'Connect with Stripe';
    return (
      <div>
        {!(settings || !connected) && <h3 className="text title">Setup your payment</h3>}
        {url &&
          (!connected ? (
            <SetupStripe url={url} label={label} settings={settings} />
          ) : (
            <StripeDashboard
              url={url}
              label={label}
              actionNeeded={actionNeeded}
              settings={settings}
            />
          ))}
        {!url && <Loading />}

        <Snackbar
          open={!!this.state.errorMessage}
          message={this.state.errorMessage}
          bodyStyle={{ backgroundColor: 'rgba(255, 0, 13, 0.88)' }}
        />
      </div>
    );
  }
}

SetupStripeWrapper.propTypes = {
  fetchStripeLink: PropTypes.func,
  settings: PropTypes.bool,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchStripeLink }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SetupStripeWrapper);
