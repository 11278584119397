export const US_COUNTRY = { id: 'US', description: 'United States' };

const countries = [
  US_COUNTRY,
  { id: 'AF', description: 'Afghanistan' },
  { id: 'AX', description: 'Åland Islands' },
  { id: 'AL', description: 'Albania' },
  { id: 'DZ', description: 'Algeria' },
  { id: 'AS', description: 'American Samoa' },
  { id: 'AD', description: 'Andorra' },
  { id: 'AO', description: 'Angola' },
  { id: 'AI', description: 'Anguilla' },
  { id: 'AQ', description: 'Antarctica' },
  { id: 'AG', description: 'Antigua and Barbuda' },
  { id: 'AR', description: 'Argentina' },
  { id: 'AM', description: 'Armenia' },
  { id: 'AW', description: 'Aruba' },
  { id: 'AU', description: 'Australia' },
  { id: 'AT', description: 'Austria' },
  { id: 'AZ', description: 'Azerbaijan' },
  { id: 'BS', description: 'Bahamas' },
  { id: 'BH', description: 'Bahrain' },
  { id: 'BD', description: 'Bangladesh' },
  { id: 'BB', description: 'Barbados' },
  { id: 'BY', description: 'Belarus' },
  { id: 'BE', description: 'Belgium' },
  { id: 'BZ', description: 'Belize' },
  { id: 'BJ', description: 'Benin' },
  { id: 'BM', description: 'Bermuda' },
  { id: 'BT', description: 'Bhutan' },
  { id: 'BO', description: 'Bolivia' },
  { id: 'BA', description: 'Bosnia and Herzegovina' },
  { id: 'BW', description: 'Botswana' },
  { id: 'BV', description: 'Bouvet Island' },
  { id: 'BR', description: 'Brazil' },
  { id: 'IO', description: 'British Indian Ocean Territory' },
  { id: 'BN', description: 'Brunei Darussalam' },
  { id: 'BG', description: 'Bulgaria' },
  { id: 'BF', description: 'Burkina Faso' },
  { id: 'BI', description: 'Burundi' },
  { id: 'KH', description: 'Cambodia' },
  { id: 'CM', description: 'Cameroon' },
  { id: 'CA', description: 'Canada' },
  { id: 'CV', description: 'Cape Verde' },
  { id: 'KY', description: 'Cayman Islands' },
  { id: 'CF', description: 'Central African Republic' },
  { id: 'TD', description: 'Chad' },
  { id: 'CL', description: 'Chile' },
  { id: 'CN', description: 'China' },
  { id: 'CX', description: 'Christmas Island' },
  { id: 'CC', description: 'Cocos (Keeling) Islands' },
  { id: 'CO', description: 'Colombia' },
  { id: 'KM', description: 'Comoros' },
  { id: 'CG', description: 'Congo' },
  { id: 'CD', description: 'Congo, The Democratic Republic of the' },
  { id: 'CK', description: 'Cook Islands' },
  { id: 'CR', description: 'Costa Rica' },
  { id: 'CI', description: "Cote D'Ivoire" },
  { id: 'HR', description: 'Croatia' },
  { id: 'CU', description: 'Cuba' },
  { id: 'CY', description: 'Cyprus' },
  { id: 'CZ', description: 'Czech Republic' },
  { id: 'DK', description: 'Denmark' },
  { id: 'DJ', description: 'Djibouti' },
  { id: 'DM', description: 'Dominica' },
  { id: 'DO', description: 'Dominican Republic' },
  { id: 'EC', description: 'Ecuador' },
  { id: 'EG', description: 'Egypt' },
  { id: 'SV', description: 'El Salvador' },
  { id: 'GQ', description: 'Equatorial Guinea' },
  { id: 'ER', description: 'Eritrea' },
  { id: 'EE', description: 'Estonia' },
  { id: 'ET', description: 'Ethiopia' },
  { id: 'FK', description: 'Falkland Islands (Malvinas)' },
  { id: 'FO', description: 'Faroe Islands' },
  { id: 'FJ', description: 'Fiji' },
  { id: 'FI', description: 'Finland' },
  { id: 'FR', description: 'France' },
  { id: 'GF', description: 'French Guiana' },
  { id: 'PF', description: 'French Polynesia' },
  { id: 'TF', description: 'French Southern Territories' },
  { id: 'GA', description: 'Gabon' },
  { id: 'GM', description: 'Gambia' },
  { id: 'GE', description: 'Georgia' },
  { id: 'DE', description: 'Germany' },
  { id: 'GH', description: 'Ghana' },
  { id: 'GI', description: 'Gibraltar' },
  { id: 'GR', description: 'Greece' },
  { id: 'GL', description: 'Greenland' },
  { id: 'GD', description: 'Grenada' },
  { id: 'GP', description: 'Guadeloupe' },
  { id: 'GU', description: 'Guam' },
  { id: 'GT', description: 'Guatemala' },
  { id: 'GG', description: 'Guernsey' },
  { id: 'GN', description: 'Guinea' },
  { id: 'GW', description: 'Guinea-Bissau' },
  { id: 'GY', description: 'Guyana' },
  { id: 'HT', description: 'Haiti' },
  { id: 'HM', description: 'Heard Island and Mcdonald Islands' },
  { id: 'VA', description: 'Holy See (Vatican City State)' },
  { id: 'HN', description: 'Honduras' },
  { id: 'HK', description: 'Hong Kong' },
  { id: 'HU', description: 'Hungary' },
  { id: 'IS', description: 'Iceland' },
  { id: 'IN', description: 'India' },
  { id: 'ID', description: 'Indonesia' },
  { id: 'IR', description: 'Iran, Islamic Republic Of' },
  { id: 'IQ', description: 'Iraq' },
  { id: 'IE', description: 'Ireland' },
  { id: 'IM', description: 'Isle of Man' },
  { id: 'IL', description: 'Israel' },
  { id: 'IT', description: 'Italy' },
  { id: 'JM', description: 'Jamaica' },
  { id: 'JP', description: 'Japan' },
  { id: 'JE', description: 'Jersey' },
  { id: 'JO', description: 'Jordan' },
  { id: 'KZ', description: 'Kazakhstan' },
  { id: 'KE', description: 'Kenya' },
  { id: 'KI', description: 'Kiribati' },
  { id: 'KP', description: "Korea, Democratic People'S Republic of" },
  { id: 'KR', description: 'Korea, Republic of' },
  { id: 'KW', description: 'Kuwait' },
  { id: 'KG', description: 'Kyrgyzstan' },
  { id: 'LA', description: "Lao People'S Democratic Republic" },
  { id: 'LV', description: 'Latvia' },
  { id: 'LB', description: 'Lebanon' },
  { id: 'LS', description: 'Lesotho' },
  { id: 'LR', description: 'Liberia' },
  { id: 'LY', description: 'Libyan Arab Jamahiriya' },
  { id: 'LI', description: 'Liechtenstein' },
  { id: 'LT', description: 'Lithuania' },
  { id: 'LU', description: 'Luxembourg' },
  { id: 'MO', description: 'Macao' },
  { id: 'MK', description: 'Macedonia, The Former Yugoslav Republic of' },
  { id: 'MG', description: 'Madagascar' },
  { id: 'MW', description: 'Malawi' },
  { id: 'MY', description: 'Malaysia' },
  { id: 'MV', description: 'Maldives' },
  { id: 'ML', description: 'Mali' },
  { id: 'MT', description: 'Malta' },
  { id: 'MH', description: 'Marshall Islands' },
  { id: 'MQ', description: 'Martinique' },
  { id: 'MR', description: 'Mauritania' },
  { id: 'MU', description: 'Mauritius' },
  { id: 'YT', description: 'Mayotte' },
  { id: 'MX', description: 'Mexico' },
  { id: 'FM', description: 'Micronesia, Federated States of' },
  { id: 'MD', description: 'Moldova, Republic of' },
  { id: 'MC', description: 'Monaco' },
  { id: 'MN', description: 'Mongolia' },
  { id: 'MS', description: 'Montserrat' },
  { id: 'MA', description: 'Morocco' },
  { id: 'MZ', description: 'Mozambique' },
  { id: 'MM', description: 'Myanmar' },
  { id: 'NA', description: 'Namibia' },
  { id: 'NR', description: 'Nauru' },
  { id: 'NP', description: 'Nepal' },
  { id: 'NL', description: 'Netherlands' },
  { id: 'AN', description: 'Netherlands Antilles' },
  { id: 'NC', description: 'New Caledonia' },
  { id: 'NZ', description: 'New Zealand' },
  { id: 'NI', description: 'Nicaragua' },
  { id: 'NE', description: 'Niger' },
  { id: 'NG', description: 'Nigeria' },
  { id: 'NU', description: 'Niue' },
  { id: 'NF', description: 'Norfolk Island' },
  { id: 'MP', description: 'Northern Mariana Islands' },
  { id: 'NO', description: 'Norway' },
  { id: 'OM', description: 'Oman' },
  { id: 'PK', description: 'Pakistan' },
  { id: 'PW', description: 'Palau' },
  { id: 'PS', description: 'Palestinian Territory, Occupied' },
  { id: 'PA', description: 'Panama' },
  { id: 'PG', description: 'Papua New Guinea' },
  { id: 'PY', description: 'Paraguay' },
  { id: 'PE', description: 'Peru' },
  { id: 'PH', description: 'Philippines' },
  { id: 'PN', description: 'Pitcairn' },
  { id: 'PL', description: 'Poland' },
  { id: 'PT', description: 'Portugal' },
  { id: 'PR', description: 'Puerto Rico' },
  { id: 'QA', description: 'Qatar' },
  { id: 'RE', description: 'Reunion' },
  { id: 'RO', description: 'Romania' },
  { id: 'RU', description: 'Russian Federation' },
  { id: 'RW', description: 'RWANDA' },
  { id: 'SH', description: 'Saint Helena' },
  { id: 'KN', description: 'Saint Kitts and Nevis' },
  { id: 'LC', description: 'Saint Lucia' },
  { id: 'PM', description: 'Saint Pierre and Miquelon' },
  { id: 'VC', description: 'Saint Vincent and the Grenadines' },
  { id: 'WS', description: 'Samoa' },
  { id: 'SM', description: 'San Marino' },
  { id: 'ST', description: 'Sao Tome and Principe' },
  { id: 'SA', description: 'Saudi Arabia' },
  { id: 'SN', description: 'Senegal' },
  { id: 'CS', description: 'Serbia and Montenegro' },
  { id: 'SC', description: 'Seychelles' },
  { id: 'SL', description: 'Sierra Leone' },
  { id: 'SG', description: 'Singapore' },
  { id: 'SK', description: 'Slovakia' },
  { id: 'SI', description: 'Slovenia' },
  { id: 'SB', description: 'Solomon Islands' },
  { id: 'SO', description: 'Somalia' },
  { id: 'ZA', description: 'South Africa' },
  { id: 'GS', description: 'South Georgia and the South Sandwich Islands' },
  { id: 'ES', description: 'Spain' },
  { id: 'LK', description: 'Sri Lanka' },
  { id: 'SD', description: 'Sudan' },
  { id: 'SR', description: 'Suriname' },
  { id: 'SJ', description: 'Svalbard and Jan Mayen' },
  { id: 'SZ', description: 'Swaziland' },
  { id: 'SE', description: 'Sweden' },
  { id: 'CH', description: 'Switzerland' },
  { id: 'SY', description: 'Syrian Arab Republic' },
  { id: 'TW', description: 'Taiwan, Province of China' },
  { id: 'TJ', description: 'Tajikistan' },
  { id: 'TZ', description: 'Tanzania, United Republic of' },
  { id: 'TH', description: 'Thailand' },
  { id: 'TL', description: 'Timor-Leste' },
  { id: 'TG', description: 'Togo' },
  { id: 'TK', description: 'Tokelau' },
  { id: 'TO', description: 'Tonga' },
  { id: 'TT', description: 'Trinidad and Tobago' },
  { id: 'TN', description: 'Tunisia' },
  { id: 'TR', description: 'Turkey' },
  { id: 'TM', description: 'Turkmenistan' },
  { id: 'TC', description: 'Turks and Caicos Islands' },
  { id: 'TV', description: 'Tuvalu' },
  { id: 'UG', description: 'Uganda' },
  { id: 'UA', description: 'Ukraine' },
  { id: 'AE', description: 'United Arab Emirates' },
  { id: 'GB', description: 'United Kingdom' },
  { id: 'UM', description: 'United States Minor Outlying Islands' },
  { id: 'UY', description: 'Uruguay' },
  { id: 'UZ', description: 'Uzbekistan' },
  { id: 'VU', description: 'Vanuatu' },
  { id: 'VE', description: 'Venezuela' },
  { id: 'VN', description: 'Viet Nam' },
  { id: 'VG', description: 'Virgin Islands, British' },
  { id: 'VI', description: 'Virgin Islands, U.S.' },
  { id: 'WF', description: 'Wallis and Futuna' },
  { id: 'EH', description: 'Western Sahara' },
  { id: 'YE', description: 'Yemen' },
  { id: 'ZM', description: 'Zambia' },
  { id: 'ZW', description: 'Zimbabwe' },
];

export default countries;
