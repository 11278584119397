import React, { useEffect, useRef } from 'react';
import SliderItem from './SliderItem';
import Slider from 'react-slick';

const PresenceSlider = ({
  settings,
  featured,
  channel,
  experiencesUsers,
  onLearnMore,
  onRSVP,
  backgroundColor,
  slideChangeTo = -1,
}) => {
  const sliderRef = useRef();

  useEffect(() => {
    if (slideChangeTo !== -1 && sliderRef.current) {
      sliderRef.current.innerSlider.slickGoTo(slideChangeTo);
    }
  }, [slideChangeTo, sliderRef]);

  return (
    <Slider
      ref={sliderRef}
      {...settings}
      beforeChange={() => changeNavigationButtonsVisibility(false)}
      afterChange={() => changeNavigationButtonsVisibility(true)}
    >
      {featured.map((experience, index, array) => (
        <div key={index}>
          <SliderItem
            changeSlide={(slide) => {
              updateBackgroundColor(backgroundColor);
              if (sliderRef.current) {
                sliderRef.current.innerSlider.slickGoTo(slide);
              }
            }}
            experiencesUsers={experiencesUsers}
            channel={channel}
            experience={experience}
            index={index}
            array={array}
            backgroundColor={backgroundColor}
            updateBackgroundColor={updateBackgroundColor}
          />
        </div>
      ))}
    </Slider>
  );
};

function changeNavigationButtonsVisibility(show) {
  const navigationButtons = document.getElementsByClassName('slide-controls');

  for (let index = 0; index < navigationButtons.length; index += 1) {
    if (show) {
      navigationButtons[index].setAttribute('style', 'visibility: visible;');
    } else {
      navigationButtons[index].setAttribute('style', 'visibility: hidden;');
    }
  }
}

function updateBackgroundColor(backgroundColor) {
  const divDgr = document.getElementById('degradeDiv');

  if (divDgr) {
    divDgr.setAttribute('style', `height: 100%; ${backgroundColor}`);
  }
}

export default PresenceSlider;
