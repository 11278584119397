import { useDisableCheckoutButton } from 'hooks/useCheckoutTicketLogic';
import React from 'react';
import styled from 'styled-components';

const CheckoutFooter = ({
  fee,
  onClick,
  selectDays,
  selectedTicket,
  selectQty,
  subtotal,
  ticketsToPurchase,
}) => {
  const disabledButton = useDisableCheckoutButton({
    selectDays,
    selectedTicket,
    selectQty,
    ticketsToPurchase,
  });

  return (
    <FooterContainer>
      <PurchaseButton disabled={disabledButton} onClick={onClick}>
        {`PURCHASE: `}
        {subtotal.toLowerCase().includes('free') || subtotal === '0.00'
          ? 'Free'
          : `$${subtotal} + $${fee}`}
      </PurchaseButton>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  height: 102px;
  background-color: #FFFFFF;
  box-shadow: 0 -7px 26px 0 rgba(0,0,0,0.09)
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`;

const PurchaseButton = styled.button`
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #69d27c;
  cursor: pointer;
  color: #ffffff;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  width: 100%;
  height: 62px;

  &:disabled {
    opacity: 0.5;
  }
`;

export default CheckoutFooter;
