import React from 'react';
import { Card } from 'material-ui';
import SetupStripeWrapper from './SetupStripeWrapper';

const styles = {
  card: {
    style: {
      borderBottom: '1px solid #f8f8f8',
      padding: '34px 32px',
      boxShadow: '0px 0px 0px',
      borderRadius: '5px',
      overflowWrap: 'anywhere',
      width: '900px',
    },
  },
};

const SetupStripePage = () => {
  return (
    <div className="page-container page-container-flex clearfix">
      <div className="stripe-container">
        <Card style={styles.card.style}>
          <SetupStripeWrapper />
        </Card>
      </div>
    </div>
  );
};

export default SetupStripePage;
