import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import useFestivalInformation from 'hooks/useFestivalInformation';
import DesktopCheckoutContent from './Desktop/CheckoutContent';
import DesktopCheckoutDescription from './Desktop/CheckoutDescription';
import DesktopCheckoutSelectable from './Desktop/CheckoutSelectable';
import DesktopCheckoutFooter from './Desktop/CheckoutFooter';
import MediaQuery from 'react-responsive';
import MobileCheckoutContent from './Mobile/CheckoutContent';
import MobileCheckoutDescription from './Mobile/CheckoutDescription';
import MobileCheckoutSelectable from './Mobile/CheckoutSelectable';
import MobileCheckoutFooter from './Mobile/CheckoutFooter';
import { browserHistory } from 'react-router';
import routes from 'appconstants/routes';
import bigNumber from 'bignumber.js';
import { rsvpFestivalTicket } from 'api/festivals';
import { isAllAccessTicket, ticketSortFunction } from 'utils/helpers';
import { Helmet } from 'react-helmet';

const FestivalCheckout = ({
  params,
  location,
  user,
  nextStep,
  setPayment,
  setSelectDays,
  selectDays,
  referral,
}) => {
  const { data } = useFestivalInformation(`${params.channel}/${params.id}`);
  const [discount, setDiscount] = useState();
  const [selectTicketType, setSelectTicketType] = useState(
    location.state?.redirectState ? location.state.redirectState.selectTicketType : ''
  );

  const [selectQty, setSelectQty] = useState(
    location.state?.redirectState ? location.state.redirectState.selectQty : 1
  );

  const tickets = useMemo(() => {
    if (data?.tickets) {
      const ticketOptions = [...data.tickets];

      return ticketOptions.sort(ticketSortFunction);
    }

    return [];
  }, [data]);

  const [ticketsToPurchase, setTicketsToPurchase] = useState({});

  const setTicketAmount = useCallback(
    (id, amount) => {
      const newTickets = { ...ticketsToPurchase };

      if (amount === 0) {
        delete newTickets[id];
      } else {
        newTickets[id] = amount;
      }

      setTicketsToPurchase(newTickets);
    },
    [ticketsToPurchase, setTicketsToPurchase]
  );

  const selectedTicket = useMemo(() => tickets.find((ticket) => ticket._id === selectTicketType), [
    selectTicketType,
    tickets,
  ]);

  const subtotal = useMemo(() => {
    if (!data) {
      return '';
    }

    let calculatedSubtotal = 0;

    if (ticketsToPurchase) {
      calculatedSubtotal += Object.entries(ticketsToPurchase).reduce((prev, [id, amount]) => {
        const ticket = data.tickets.find(({ _id }) => id === _id);

        if (ticket) {
          return prev + ticket.price * amount;
        }

        return prev;
      }, 0);
    }

    if (selectedTicket?.price > 0) {
      calculatedSubtotal += selectedTicket.price * selectQty;
    }

    if (discount) {
      if (discount.isPercentage) {
        return (calculatedSubtotal * ((100 - discount.amount) / 100)).toFixed(2);
      }

      const sub = Math.max(0, calculatedSubtotal - discount.amount);

      if (sub === 0) {
        return 'Free';
      }

      return sub.toLocaleString({}, { minimumSignificantDigits: 2, maximumSignificantDigits: 2 });
    }

    return calculatedSubtotal.toLocaleString(
      {},
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    );
  }, [selectedTicket, selectQty, discount, ticketsToPurchase, data]);

  const total = useMemo(
    () =>
      subtotal !== '' && subtotal !== 'Free'
        ? bigNumber(subtotal).plus(0.3).div(0.971).toFixed(2)
        : 0,
    [subtotal]
  );

  const fee = useMemo(
    () =>
      subtotal !== '' && subtotal !== 'Free' && total !== ''
        ? Number(bigNumber(total).minus(subtotal).toFixed(2))
        : '',
    [subtotal, total]
  );

  const toggleDate = useCallback(
    (date) => {
      const dateIndex = selectDays.findIndex((day) => day === date);
      const newSelectDays = [...selectDays];
      if (dateIndex !== -1) {
        newSelectDays.splice(dateIndex, 1);
      } else if (selectDays.length < selectedTicket.days) {
        newSelectDays.push(date);
      } else {
        return;
      }

      setSelectDays(newSelectDays);
    },
    [selectedTicket, setSelectDays, selectDays]
  );

  const canSelectMoreDays = useMemo(() => {
    if (isAllAccessTicket(selectedTicket)) {
      return true;
    }

    return selectDays.length < (selectedTicket?.days || 0);
  }, [selectedTicket, selectDays]);

  const goToSignUp = useCallback(() => {
    browserHistory.push({
      pathname: routes.checkoutSignUp,
      state: {
        redirectPath: window.location.pathname,
        redirectState: { selectTicketType, selectQty, selectDays },
      },
    });
  }, [selectTicketType, selectQty, selectDays]);

  const rsvp = useCallback(
    async (experience) => {
      const body = {
        tickets: [],
        experience,
      };

      if (selectQty > 0) {
        body.tickets = [
          {
            id: selectedTicket._id,
            quantity: selectQty,
            days: selectDays,
          },
        ];
      }

      if (Object.keys(ticketsToPurchase).length > 0) {
        const individualTickets = Object.entries(ticketsToPurchase).map(([id, quantity]) => ({
          id,
          quantity,
        }));

        body.tickets = [...body.tickets, ...individualTickets];
      }

      if (discount) {
        body.discount = discount._id;
      }

      try {
        return await rsvpFestivalTicket({
          channel: params.channel,
          alias: params.id,
          body,
        });
      } catch (error) {
        // console.log(error);
      }
    },
    [params, selectedTicket, selectQty, selectDays, discount, ticketsToPurchase]
  );

  const onClick = useCallback(async () => {
    if (user.email === 'guest') {
      goToSignUp();
    } else {
      try {
        const { order } = await rsvp();
        const { total, clientSecret } = order;

        if (clientSecret) {
          setPayment({ total, clientSecret });
        }

        nextStep(order);
      } catch (error) {
        // console.log(error);
      }
    }
  }, [user, goToSignUp, rsvp, setPayment, nextStep]);

  useEffect(() => {
    if (location.state?.redirectState?.selectDays) {
      setSelectDays(location.state.redirectState.selectDays);
      setTimeout(() => {
        onClick();
      }, 100);
    }
  }, [location, setSelectDays, onClick]);

  return data ? (
    <Container>
      <Helmet>
        <title>{`${data.channel?.name} | ${data.name}`}</title>
        <meta property="og:title" content={`PRESENCE | ${data.name}`} />
        <meta property="og:description" name="description" content={data.description} />
        <meta property="og:image" name="og:image" content={data.pageDesign.heroPicture} />
        <meta
          property="og:image:secure_url"
          name="og:image:secure_url"
          content={data.pageDesign.heroPicture}
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_APP_URL}/in/${data.channel.urlSlug}/${data.alias}`}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={data.pageDesign.heroPicture} />
      </Helmet>
      <DesktopContainer query="(min-device-width: 1224px)">
        <CheckoutTitleAndDescriptionContainer>
          <DesktopCheckoutContent data={data} />
          <DesktopCheckoutDescription data={data} />
        </CheckoutTitleAndDescriptionContainer>
        <DesktopCheckoutSelectable
          canSelectMoreDays={canSelectMoreDays}
          data={data}
          onClick={onClick}
          selectDays={selectDays}
          selectedTicket={selectedTicket}
          selectQty={selectQty}
          selectTicketType={selectTicketType}
          setSelectDays={setSelectDays}
          setSelectQty={setSelectQty}
          setSelectTicketType={setSelectTicketType}
          setTicketAmount={setTicketAmount}
          tickets={tickets}
          ticketsToPurchase={ticketsToPurchase}
          toggleDate={toggleDate}
        />
        <DesktopCheckoutFooter
          activeDiscount={discount}
          fee={fee}
          goToSignUp={goToSignUp}
          nextStep={nextStep}
          onClick={onClick}
          params={params}
          rsvp={() => rsvp(referral)}
          selectDays={selectDays}
          selectedTicket={selectedTicket}
          setDiscount={setDiscount}
          setPayment={setPayment}
          subtotal={subtotal}
          ticketsToPurchase={ticketsToPurchase}
          selectQty={selectQty}
          user={user}
        />
      </DesktopContainer>
      <MobileContainer query="(max-device-width: 1224px)">
        <MobileCheckoutContent data={data} />
        <MobilePadding>
          <MobileCheckoutDescription data={data} />
          <MobileCheckoutSelectable
            canSelectMoreDays={canSelectMoreDays}
            data={data}
            discount={discount}
            params={params}
            selectDays={selectDays}
            selectedTicket={selectedTicket}
            selectQty={selectQty}
            selectTicketType={selectTicketType}
            setDiscount={setDiscount}
            setSelectDays={setSelectDays}
            setTicketAmount={setTicketAmount}
            setSelectQty={setSelectQty}
            setSelectTicketType={setSelectTicketType}
            tickets={tickets}
            ticketsToPurchase={ticketsToPurchase}
            toggleDate={toggleDate}
          />
        </MobilePadding>
        <MobileCheckoutFooter
          fee={fee}
          goToSignUp={goToSignUp}
          nextStep={nextStep}
          onClick={onClick}
          rsvp={() => rsvp(referral)}
          selectDays={selectDays}
          selectedTicket={selectedTicket}
          selectQty={selectQty}
          setPayment={setPayment}
          subtotal={subtotal}
          ticketsToPurchase={ticketsToPurchase}
          user={user}
        />
      </MobileContainer>
    </Container>
  ) : null;
};

const Container = styled.div``;

const CheckoutTitleAndDescriptionContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const DesktopContainer = styled(MediaQuery)`
  padding: 50px 0 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

const MobileContainer = styled(MediaQuery)`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const MobilePadding = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 17px 122px;
  flex: 1;
`;

export default FestivalCheckout;
