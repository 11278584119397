import { createSelector } from 'reselect';
import get from 'lodash/get';
import { USER_TYPES } from 'appconstants';

export const selectUser = (state) => get(state, 'auth.user', {});
export const selectUserLoading = (state) => state.auth.loading;
export const selectAllUserChannels = createSelector(selectUser, (user) => {
  return user.channels || [];
});
export const selectChannelsForUserToEdit = createSelector(
  selectAllUserChannels,
  selectUser,
  (channels, user) =>
    channels.filter(
      (channel) =>
        channel.isOwner ||
        (channel.allowedToInvite && !channel.setupPending) ||
        user.maxRole === USER_TYPES.ADMIN
    )
);
export const selectIsChannelOwner = createSelector(selectUser, (user) => user.channelOwner);
export const selectUserMaxRole = createSelector(selectUser, (user) => user.maxRole);
export const selectChannelUserById = (state, { channelId }) => {
  const channels = selectAllUserChannels(state);
  return channels.find((c) => c.id === channelId) || null;
};
export const selectUserActiveChannels = createSelector(selectUser, (user) =>
  user.channels.filter((channel) => !channel.setupPending)
);
