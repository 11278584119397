import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import moment from 'moment-timezone';
import { Dialog } from 'material-ui';
import get from 'lodash/get';
import { geocodeByAddress } from 'react-places-autocomplete';
import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';
import Linkify from 'react-linkify';

import Loading from 'components/Loading';
import ExperienceTagsDetails from 'components/ExperienceTagsDetails';
import ExperienceArtists from 'components/ExperienceArtists';
import TGHoverButton from 'components/HoverButton';
import ThumbExperiences from 'components/ThumbExperiences';
import TopBar from 'components/TopBar';
import ImageNotFound from 'images/no-image-box.png';
import * as actionsExperiences from 'actions/experiences';
import * as actionsChannels from 'actions/channels';
import * as actionsAuth from 'actions/auth';
import * as actionsLocations from 'actions/locations';
import * as actionsArtists from 'actions/artists';
import imageBg from 'images/bg-slide1.jpg';
import PlayImage from 'images/play-icon.png';
import * as actionsTags from 'actions/tags';
import { saveUserRelive } from 'actions/users';
import {
  getRSVPLabelAndStatus,
  getResizedImageURL,
  replaceRelivePage,
  getTicketsUrl,
} from 'utils/helpers';
import ExperienceProfilePageMob from 'containers/ExperienceProfilePageMob';
import { USER_TYPES, IMAGE_SIZES, PLAYERS, EXPERIENCES_TYPES } from 'appconstants';
import './styles.css';
import { selectRSVPedToExperience, selectInterestedInExperience } from 'selectors/userExperiences';
import {
  selectCurrentExperience,
  selectFeaturedExperiences,
  selectLoadingExperiences,
} from 'selectors/experiences';
import { selectChannels, selectChannelsLoading, selectCurrentChannel } from 'selectors/channels';
import { selectUser } from 'selectors/user';
import ExperienceDateAndLocation from 'components/ExperienceDateAndLocation';
import routes from 'appconstants/routes';
import { dateFormat } from 'utils/dates';

const styles = {
  bodyContainer: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  rsvpButton: {
    style: {
      marginLeft: 6,
      height: '60px',
      borderRadius: '13px',
    },
    button: {
      backgroundImage: '#9764f0',
      backgroundColor: '#f07da8',
      cursor: 'pointer',
      minWidth: '190px',
    },
    label: {
      fontSize: '20px',
      color: '#fff',
      fontFamily: 'CircularStd Book',
      fontWeight: 'bold',
      lineHeight: '60px',
      textTransform: 'initial',
    },
  },
  reliveButton: {
    style: {
      marginLeft: 6,
      height: '60px',
      borderRadius: '13px',
    },
    button: {
      backgroundImage: '#9764f0',
      backgroundColor: '#f07da8',
      borderRadius: '8px',
      cursor: 'pointer',
      minWidth: '190px',
    },
    label: {
      fontSize: '20px',
      color: '#fff',
      fontFamily: 'CircularStd Book',
      fontWeight: 'bold',
      lineHeight: '60px',
      textTransform: 'initial',
    },
  },
  InterestedButton: {
    style: {
      height: '60px',
      borderRadius: '13px',
    },
    button: {
      backgroundColor: '#9764f0',
      cursor: 'pointer',
      width: '190px',
    },
    label: {
      textTransform: 'none',
      fontSize: '20px',
      color: '#FFFFFF',
      fontFamily: 'FuturaStd Medium',
      lineHeight: '60px',
    },
    label2: {
      textTransform: 'none',
      fontSize: '20px',
      color: '#000',
      fontFamily: 'FuturaStd Medium',
      lineHeight: '60px',
    },
  },
  icon: {
    width: '30px',
    height: '30px',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  containerRight: {
    marginTop: 0,
    width: 300,
    float: 'right',
  },
  dialog: {
    width: '609px',
  },
  dialogBody: {
    padding: '5px',
    borderRadius: '4px',
    overflowY: 'scroll',
  },
  dialogOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.541176)',
  },
};

// const StarIcon = (props) => (
//   <SvgIcon {...props} style={styles.icon}>
//     <path d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
//   </SvgIcon>
// );

// const StarIconFull = (props) => (
//   <SvgIcon {...props} style={styles.icon} color={yellow500} hoverColor={yellow500}>
//     <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
//   </SvgIcon>
// );
class ExperienceProfilePage extends Component {
  constructor() {
    super();
    this.renderItem = this.renderItem.bind(this);
    this.state = {
      experienceLocation: {},
      modalGLVisible: false,
      password: '',
      loading: true,
      reliveLoading: false,
    };
    this.showModalGL = this.showModalGL.bind(this);
    this.closeModalGL = this.closeModalGL.bind(this);
    this.renderArtists = this.renderArtists.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { alias, channel } = this.props.params;
    const { alias: newAlias, channel: newChannel } = nextProps.params;
    if (alias !== newAlias || channel !== newChannel) {
      this.setState({
        experienceLocation: {},
        modalGLVisible: false,
      });
      this.loadDataID({ channel: newChannel, alias: newAlias });
    }
  }

  onRSVP({ alias, channel, experience }) {
    if (experience.festival && experience.festivalUnlocked) {
      this.props
        .rsvpExperience({
          alias,
          channel,
          tickets: [],
        })
        .then(() => {
          this.props.router.push(routes.tickets);
        });
    } else {
      const url = getTicketsUrl(alias, channel, experience);
      const state = {};

      if (experience.festival) {
        state.referral = {
          channel: experience.channel.urlSlug,
          alias: experience.alias,
        };
      }
      browserHistory.push({
        pathname: url,
        state,
      });
    }
  }

  onReliveClick = async (experience) => {
    const {
      channel: { urlSlug: channel },
      alias,
      reliveUrl,
      relivePlayer,
    } = experience;
    this.setState({ reliveLoading: true });
    if (relivePlayer === PLAYERS.YOUTUBE || relivePlayer === PLAYERS.VIMEO) {
      browserHistory.push(replaceRelivePage({ channel, alias }));
    } else {
      await this.props.saveUserRelive({ channel, alias });
      window.open(reliveUrl);
    }
    this.setState({ reliveLoading: false });
  };

  loadData() {
    const { alias, channel } = this.props.params;
    this.loadDataID({ channel, alias });
  }

  loadDataID({ channel, alias, password }) {
    const { loadExperienceProfile, loadExperiencesByUser, openExperience, user } = this.props;

    if (!this.props.channels.length) {
      this.props.fetchChannels().then(() => {
        this.props.setCurrentChannel(channel);
      });
    } else {
      this.props.setCurrentChannel(channel);
    }

    loadExperienceProfile({ channel, alias, password }).then((experience) => {
      let { artists, location } = experience;

      this.setState({ experience, expArtists: artists });
      try {
        geocodeByAddress(
          `${location.address}, ${location.city} ${location.state}`,
          (err, latLng) => {
            this.setState({ experienceLocation: latLng });
          }
        );
      } catch (error) {
        this.setState({ experienceLocation: null });
      }

      if (user.id !== USER_TYPES.GUEST) {
        loadExperiencesByUser();
        openExperience(experience.id);
      }
    });
  }

  showModalGL() {
    this.setState({ modalGLVisible: true });
  }

  closeModalGL() {
    this.setState({ modalGLVisible: false });
  }

  renderReliveButton = (experience) => {
    if (this.state.reliveLoading) return <Loading />;

    return (
      <TGHoverButton
        onClick={() => this.onReliveClick(experience)}
        buttonStyle={styles.reliveButton.button}
        labelStyle={styles.reliveButton.label}
        style={styles.reliveButton.style}
        disabled={this.state.reliveLoading}
      >
        <div className="experience-profile-relive-button">
          <div>
            <img src={PlayImage} alt="" />
            <span>Re-live</span>
          </div>
        </div>
      </TGHoverButton>
    );
  };

  renderItem(experience) {
    const { currentChannel, rsvp, interested } = this.props;
    const backgroundColor = currentChannel && `background: ${currentChannel.color || '#000'};`;

    const rsvpVisible = new Date(experience.endTime) > new Date();
    const isRelive = !!experience.reliveUrl;
    const { rsvpDisable, rsvpLabel, longText, festivalUnlocked } = getRSVPLabelAndStatus(
      experience,
      experience.tickets,
      rsvp
    );

    let likeBtn = interested;

    const imageUrl =
      experience.images && experience.images.length
        ? getResizedImageURL(experience.images[0].url, IMAGE_SIZES.LARGE)
        : `${imageBg}`;
    const divApp = document.getElementById('blurredDiv');
    if (divApp) {
      divApp.setAttribute(
        'style',
        'background: 0 0 no-repeat; background-image: url(' +
          `${imageUrl}` +
          ');' + // eslint-disable-line
          ' background-size: 100% 200%;  transition: background-image 0.5s linear;' +
          ' -o-transition:background-image 0.5s linear; -moz-transition:background-image 0.5s linear;' +
          ' -webkit-transition: background-image 0.5s linear;height: 590px; '
      );
    }
    const divDgr = document.getElementById('degradeDiv');
    if (divApp) {
      divDgr.setAttribute('style', `height: 614px; ${backgroundColor}`);
    }

    return (
      <div className="banner-content container" style={{ width: '100%' }}>
        <div className="profile-content">
          <ExperienceDateAndLocation
            festival={experience.festival}
            startTime={experience.startTime}
            endTime={experience.endTime}
            location={experience.location}
          />
          <h1 className="slide-title" style={{ lineHeight: '60px' }}>
            {experience.name}
          </h1>
          <div className="slide-buttons">
            {rsvpVisible && (
              <TGHoverButton
                id="btnRSVP"
                label={rsvpLabel}
                onClick={() =>
                  !rsvpDisable &&
                  this.onRSVP({
                    alias: experience.alias,
                    channel: experience.channel.urlSlug,
                    experience,
                    festivalUnlocked,
                  })
                }
                buttonStyle={styles.rsvpButton.button}
                labelStyle={styles.rsvpButton.label}
                style={styles.rsvpButton.style}
                backgroundColor="#f07da8"
                backgroundColorHover="#9764f0"
              />
            )}
            {isRelive && this.renderReliveButton(experience)}
          </div>
        </div>
        <div className="slide-right" style={{ display: 'none' }}>
          <img src="images/img-slide1.jpg" alt="" />
        </div>
        {this.renderTopBar(
          experience,
          rsvp,
          rsvpLabel,
          rsvpDisable,
          likeBtn,
          rsvpVisible,
          longText,
          festivalUnlocked
        )}
      </div>
    );
  }

  renderTopBar(
    experience,
    rsvp,
    rsvpLabel,
    rsvpDisable,
    likeBtn,
    rsvpVisible,
    rsvpLongText,
    festivalUnlocked
  ) {
    const { likeExperience, unlikeExperience } = this.props;
    return (
      <TopBar
        experience={experience}
        rsvp={rsvp}
        rsvpLabel={rsvpLabel}
        rsvpLongText={rsvpLongText}
        rsvpDisable={rsvpDisable}
        likeBtn={likeBtn}
        rsvpVisible={rsvpVisible}
        onLikeExperience={() => likeExperience(experience.id)}
        onUnlikeExperience={() => unlikeExperience(experience.id)}
        onRSVP={() =>
          this.onRSVP({
            alias: experience.alias,
            channel: experience.channel.urlSlug,
            experience,
            festivalUnlocked,
          })
        }
      />
    );
  }

  renderImage(experience) {
    const srcSet =
      experience.images &&
      experience.images.length &&
      [
        `${getResizedImageURL(experience.images[0].url, IMAGE_SIZES.LARGE)}`,
        `${getResizedImageURL(experience.images[0].url, IMAGE_SIZES.LARGE)} 2x`,
        `${getResizedImageURL(experience.images[0].url, IMAGE_SIZES.LARGE)} 3x`,
      ].join(', ');

    const image =
      experience.images && experience.images.length ? (
        <img
          width="604px"
          height="724px"
          src={getResizedImageURL(experience.images[0].url, IMAGE_SIZES.LARGE)}
          srcSet={srcSet}
          alt={experience.description}
        />
      ) : (
        <img alt="" src={ImageNotFound} />
      );

    return <div className="clearfix exp-image">{image}</div>;
  }

  renderGuideLines(item) {
    return (
      <div className="guideline-location">
        <Dialog
          open={this.state.modalGLVisible}
          onRequestClose={this.closeModalGL}
          contentStyle={styles.dialog}
          bodyStyle={styles.dialogBody}
          overlayStyle={styles.dialogOverlay}
        >
          <div
            className="form-wrapper upgrade-wrapper"
            align="center"
            style={{ marginTop: '-30px' }}
          >
            <div className="popup-content" align="center" style={{ width: '600px' }}>
              <h3 className="popup-title">GUIDELINES</h3>
              <ul>
                {item.guidelines.map((guideLine) => {
                  // eslint-disable-line
                  return (
                    <li>
                      <div
                        className="package-block"
                        style={{ fontSize: '15px', color: '#000' }}
                        dangerouslySetInnerHTML={{ __html: guideLine.guideline }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Dialog>
        <span className="guideline-location-title">Guidelines</span>
        <Link onClick={this.showModalGL} className="guideline-location-link">
          Read our guidelines
        </Link>{' '}
        for a great experience
      </div>
    );
  }

  renderTime() {
    const {
      experience: { startTime, endTime },
    } = this.state;
    const startTimeString = dateFormat(
      { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true },
      startTime
    );
    let endTimeString;
    if (moment(startTime).startOf('date').isSame(moment(endTime).startOf('date'))) {
      endTimeString = dateFormat({ hour: 'numeric', minute: 'numeric', hour12: true }, endTime);
    } else {
      endTimeString = `${dateFormat({ month: 'short', day: 'numeric' }, endTime)} at ${dateFormat(
        { hour: 'numeric', minute: 'numeric', hour12: true },
        endTime
      )}`;
    }
    const text = `${startTimeString} to ${endTimeString}`;
    return (
      <div className="guideline-location">
        <span className="guideline-location-title">DATE/TIME</span>
        {text}
      </div>
    );
  }

  renderLocation() {
    const {
      state: {
        experience: {
          location: { address, city, state },
        },
      },
      props: { rsvp },
    } = this;

    return (
      <div className="guideline-location">
        <span className="guideline-location-title">Location</span>
        {rsvp ? `${address}, ${city}, ${state}` : 'Address revealed with ticket purchase'}
      </div>
    );
  }

  renderDescription() {
    const {
      experience: { description },
    } = this.state;
    if (!description) {
      return;
    }

    return (
      <div className="what-to-expect event-info" style={{ marginTop: '10px' }}>
        <h5>What to expect</h5>
        <div className="what-expect-content">
          <p className="profile-description" style={{ whiteSpace: 'pre-wrap' }}>
            <Linkify>{description}</Linkify>
          </p>
        </div>
      </div>
    );
  }

  renderFlow() {
    const {
      experience: { flow },
    } = this.state;
    return !flow || !flow.length ? (
      []
    ) : (
      <div className="includes event-info">
        <h5>Includes</h5>
        <ul className="includes-list clearfix">
          {flow.map((value) => {
            return <li>{value.activity}</li>;
          })}
        </ul>
      </div>
    );
  }

  renderSpinner() {
    const { loadingExperiences, loadingChannels } = this.props;
    return loadingExperiences || loadingChannels ? <Loading /> : [];
  }

  renderContentLeft() {
    const { loadingExperiences } = this.props;
    const { experience } = this.state;
    if (loadingExperiences || !experience) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return (
      <div className="experience-panel">
        <div className="experience-image-card">
          {this.renderImage(experience)}
          <div className="guideline-location-wrapper">
            {/* https://linear.app/presence/issue/PRS-254/remove-link-to-guidelines-on-experience-profile-page
            {this.renderGuideLines(experience)} */}
            {this.renderTime()}
            {experience.type === EXPERIENCES_TYPES.PHYSICAL && this.renderLocation()}
          </div>
        </div>
      </div>
    );
  }

  renderContentRight() {
    const { loadingExperiences } = this.props;
    const { experience } = this.state;
    if (loadingExperiences || !experience) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return (
      <div className="experience-panel relative">
        <div className="banner-section">{this.renderItem(experience)}</div>
        <div className="content-right">
          <ExperienceTagsDetails experience={experience} />
          {this.renderDescription()}
          {this.renderFlow()}
        </div>
      </div>
    );
  }

  renderContainer() {
    return (
      <div
        className="experience-profile-container container"
        style={{ margin: '0 auto', 'padding-bottom': '70px' }}
      >
        {this.renderSpinner()}
        {this.renderContentLeft()}
        {this.renderContentRight()}
      </div>
    );
  }

  renderArtists() {
    const { experience, expArtists } = this.state;
    const { featured } = this.props;
    if (!get(experience, 'artists.length')) {
      return;
    }
    const imageUrl = get(experience, 'images[0].url', '');
    return (
      <div>
        <ExperienceArtists artists={expArtists} defaultImg={imageUrl} />
        <ThumbExperiences featured={featured} />
      </div>
    );
  }

  renderDialogPrivateExperience() {
    const {
      loadingExperiences,
      params: { channel, alias },
    } = this.props;
    const { experience, password } = this.state;

    return experience && experience.id === undefined && experience.private ? (
      <Dialog
        bodyClassName="password-protected-body"
        bodyStyle={{
          borderRadius: '0 0 3px 3px',
          height: '100%',
          padding: '0',
          width: '100%',
        }}
        contentClassName="password-protected-content"
        contentStyle={{
          backgroundColor: '#FFFFFF',
          borderRadius: '3px',
          boxShadow: '0 2px 14px 0 rgba(0,0,0,0.23)',
          height: '273px',
          width: '412px',
        }}
        open
        title="PASSWORD PROTECTED"
        titleStyle={{
          backgroundColor: '#F5F5F5',
          borderRadius: '3px 3px 0 0',
          color: '#3C2784',
          fontFamily: 'CircularStd Book',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '24px',
          margin: '0px',
          padding: '25px 18px',
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.loadDataID({ channel, alias, password });
          }}
        >
          <div
            onClick={() => {
              this.passwordInput.focus();
            }}
            style={{
              boxSizing: 'border-box',
              borderBottom: '1px solid #EBEBEB',
              height: '93px',
              padding: '18px 15px',
            }}
          >
            <input
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              placeholder="ENTER PASSWORD"
              ref={(el) => {
                this.passwordInput = el;
              }}
              style={{
                border: 'none',
                color: '#838383',
                fontFamily: 'CircularStd Book',
                fontSize: '11px',
                fontWeight: '300',
                lineHeight: '14px',
                padding: '0',
              }}
              type="password"
              value={password}
            />
          </div>
          <button
            style={{
              backgroundColor: '#9B63F8',
              border: 'none',
              borderRadius: '3px',
              color: '#FFFFFF',
              cursor: 'pointer',
              display: 'block',
              fontFamily: 'CircularStd Book',
              fontSize: '14px',
              fontWeight: '300',
              lineHeight: '18px',
              margin: '34px auto',
              opacity: loadingExperiences ? '0.5' : '1',
              padding: '10px',
              textAlign: 'center',
              width: '119px',
            }}
            type="submit"
          >
            Enter
          </button>
        </form>
      </Dialog>
    ) : (
      []
    );
  }

  render() {
    const { experience } = this.state;
    const { currentChannel } = this.props;

    return experience && experience.id === undefined && experience.private ? (
      this.renderDialogPrivateExperience()
    ) : (
      <section style={{ zIndex: 2 }}>
        {experience && (
          <Helmet>
            <title>{`${experience.channel?.name} | ${experience.name}`}</title>
            <meta property="og:title" content={`PRESENCE | ${experience.name}`} />
            <meta property="og:description" name="description" content={experience.description} />
            <meta property="og:image" name="og:image" content={experience.images[0].url} />
            <meta
              property="og:image:secure_url"
              name="og:image:secure_url"
              content={experience.images[0].url}
            />
            <meta
              property="og:url"
              content={`${process.env.REACT_APP_APP_URL}/in/${experience.channel.urlSlug}/${experience.alias}`}
            />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:image" content={experience.images[0].url} />
            <link rel="icon" href={currentChannel?.images[0]} />
          </Helmet>
        )}
        <MediaQuery query="(min-device-width: 1224px)">
          <section style={styles.bodyContainer}>
            <section className="clearfix experience-single">{this.renderContainer()}</section>
            {this.renderArtists()}
          </section>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <ExperienceProfilePageMob experience={experience} />
        </MediaQuery>
      </section>
    );
  }
}

ExperienceProfilePage.propTypes = {
  loadExperienceProfile: PropTypes.func,
  loadExperiencesByUser: PropTypes.func,
  user: PropTypes.object,
  likeExperience: PropTypes.func,
  unlikeExperience: PropTypes.func,
  openExperience: PropTypes.func,
  loadingExperiences: PropTypes.bool,
  loadingChannels: PropTypes.bool,
  params: PropTypes.object,
  rsvp: PropTypes.bool,
  interested: PropTypes.bool,
  currentChannel: PropTypes.object,
  saveUserRelive: PropTypes.func,
};

export default connect(
  (state) => ({
    loadingExperiences: selectLoadingExperiences(state),
    channels: selectChannels(state),
    loadingChannels: selectChannelsLoading(state),
    currentChannel: selectCurrentChannel(state),
    experience: selectCurrentExperience(state),
    featured: selectFeaturedExperiences(state),
    rsvp: selectRSVPedToExperience(state),
    interested: selectInterestedInExperience(state),
    user: selectUser(state),
  }),
  {
    ...actionsExperiences,
    ...actionsAuth,
    ...actionsLocations,
    ...actionsTags,
    ...actionsArtists,
    ...actionsChannels,
    saveUserRelive,
  }
)(ExperienceProfilePage);
