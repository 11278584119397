import React from 'react';
import { array } from 'prop-types';
import { CircularProgress } from 'material-ui';

import Avatar from 'components/Avatar';
import Text from 'V2/js/elements/Text';

import { getResizedImageURL } from 'utils/helpers';
import { IMAGE_SIZES } from 'appconstants';

import styles from './styles.css';

const GuestListRelive = ({ items }) => {
  const getTotalViews = () =>
    items.reduce((acc, item) => ({ quantity: acc.quantity + item.quantity })).quantity;

  if (!items) {
    return (
      <div class={styles.spinner}>
        <CircularProgress size={90} />
      </div>
    );
  }

  if (!items.length) {
    return <div class={styles.tac}>No one re-lived this experience yet.</div>;
  }

  const totalViews = getTotalViews();

  return (
    <div>
      <div class={styles.content}>
        <table class={styles.table}>
          <thead>
            <tr>
              <th>
                <Text h4>NAME</Text>
              </th>
              <th>
                <Text h4>EMAIL</Text>
              </th>
              <th>
                <Text h4>VIEWS ({totalViews})</Text>
              </th>
              <th>
                <Text h4>COST</Text>
              </th>
            </tr>
          </thead>
          <tbody class={styles.listItem}>
            {items.map((item, index) => {
              const { name, quantity, email, photo } = item;
              const image = photo && getResizedImageURL(photo, IMAGE_SIZES.SMALL);

              return (
                <tr class={styles.row} key={`${email}-${index}`}>
                  <td>
                    <div class={styles.avatar}>
                      <Avatar email={email} image={image} size={28} isSquare />
                    </div>
                    <Text body1>{name}</Text>
                  </td>
                  <td>
                    <Text body1>{email}</Text>
                  </td>
                  <td>
                    <Text body1>{quantity}</Text>
                  </td>
                  <td>
                    <Text body1>$0.0</Text>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

GuestListRelive.propTypes = {
  items: array.isRequired,
};

export default GuestListRelive;
