import React, { useMemo } from 'react';
import styled from 'styled-components';
import { dateFormat } from 'utils/dates';
const Status = ({ lastSaved, status }) => {
  const lastSaveFormat = useMemo(
    () => dateFormat({ hour: 'numeric', minutes: 'numeric', hour12: true }, lastSaved),
    [lastSaved]
  );
  const statusText = useMemo(() => (!!status ? 'Published' : 'Draft'), [status]);

  return (
    <StatusWrapper>
      <StatusText>{statusText}</StatusText>
      <LastSave>Last save: {lastSaveFormat}</LastSave>
    </StatusWrapper>
  );
};

const StatusWrapper = styled.div`
  color: #838383;
  font-family: 'CircularStd Book';
  margin: 0;
`;

const StatusText = styled.p`
  width: 82px;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 13px;
  color: #4d4d4d;
  margin: 0;
  letter-spacing: 0;
  line-height: 12px;
`;

const LastSave = styled.p`
  margin: 0;
  font-size: 11px;
`;

export default Status;
