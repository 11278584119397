import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const CheckoutDescription = ({ data }) => (
  <DescriptionContainer>
    <FestivalName>{data.name}</FestivalName>
    <Channel>{`by ${data.channel.name}`}</Channel>
    <TimeRange>{`${moment(data.startTime).format('MMM Do H:mm A')} - ${moment(data.endTime).format(
      'MMM Do H:mm A'
    )}`}</TimeRange>
  </DescriptionContainer>
);

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const FestivalName = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
`;

const Channel = styled.div`
  margin-top: 5px;
  color: #999999;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
`;

const TimeRange = styled.div`
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 10px;
`;

export default CheckoutDescription;
