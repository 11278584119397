import {
  CREATE_NEW_EXPERIENCE,
  FETCH_EXPERIENCES,
  GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES,
  LIKE_ACTIONS,
} from 'appconstants';

const initialState = {
  currentChannel: '',
  items: [],
  loading: false,
  loadingExperiences: false,
  loadingUserExperiences: false,
  subcategories: [],
  updateFeatured: true,

  loadingFeaturedExperiences: true,
  loadingReliveExperiences: true,
  loadingUpcomingExperiences: true,

  featuredExperiences: [],
  reliveExperiences: [],
  upcomingExperiences: [],

  reliveNextPage: '',
  upcomingNextPage: '',

  reliveExperiencesCount: 0,
  upcomingExperiencesCount: 0,
};

const getFeatured = ({ experiences, festivals }) => {
  // Remove Featival Experiences
  const featuredExperiences = experiences.filter((el) => !el.festival);
  return [...featuredExperiences, ...festivals]
    .sort((a, b) => new Date(a.endTime) - new Date(b.endTime))
    .filter((a) => new Date(a.endTime) > new Date())
    .slice(0, 4);
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_EXPERIENCES.FETCH_EXPERIENCES_REQUEST:
      return {
        ...state,
        loading: true,
        loadingExperiences: true,
        loadingUpcomingExperiences: true,
        currentChannel: action.channel,
        loadingFeaturedExperiences: action.reset,
        updateFeatured: action.channel !== state.currentChannel,
      };
    case FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_REQUEST:
      return {
        ...state,
        loadingReliveExperiences: true,
      };
    case FETCH_EXPERIENCES.FETCH_FEATURED_EXPERIENCES_REQUEST:
      return {
        ...state,
        loadingFeaturedExperiences: true,
      };
    case FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_SUCCESS:
      return {
        ...state,
        loadingReliveExperiences: false,
        reliveExperiences: action.reset
          ? action.response.experiences
          : [...state.reliveExperiences, ...action.response.experiences],
        reliveNextPage: action.response.nextPage,
        reliveExperiencesCount: action.response.totalCount,
      };
    case FETCH_EXPERIENCES.FETCH_FEATURED_EXPERIENCES_SUCCESS:
      return {
        ...state,
        loadingFeaturedExperiences: false,
        featuredExperiences: [...action.response.experiences],
      };
    case FETCH_EXPERIENCES.FETCH_FEATURED_EXPERIENCES_FAILURE:
      return {
        ...state,
        loadingFeaturedExperiences: false,
        featuredExperiences: [],
      };
    case FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_FAILURE:
      return {
        ...state,
        loadingReliveExperiences: false,
        reliveNextPage: '',
        reliveExperiences: action.reset ? [] : state.reliveExperiences,
        reliveExperiencesCount: 0,
      };

    case FETCH_EXPERIENCES.FETCH_EXPERIENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingExperiences: false,
        loadingUpcomingExperiences: false,
        upcomingNextPage: action.response.nextPage,
        loadingFeaturedExperiences: false,
        featuredExperiences: action.reset
          ? getFeatured(action.response)
          : state.featuredExperiences,
        upcomingExperiences: action.reset
          ? action.response.experiences
          : [...state.upcomingExperiences, ...action.response.experiences],
        upcomingExperiencesCount: action.response.totalCount,
      };

    case FETCH_EXPERIENCES.FETCH_EXPERIENCES_FAILURE:
      return {
        ...state,
        loading: false,
        loadingExperiences: false,
        loadingUpcomingExperiences: false,
        upcomingExperiences: action.reset ? [] : state.upcomingExperiences,
        items: {},
      };

    case FETCH_EXPERIENCES.FETCH_CMS_EXPERIENCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXPERIENCES.FETCH_CMS_EXPERIENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.response,
      };
    case FETCH_EXPERIENCES.FETCH_CMS_EXPERIENCES_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
      };
    case FETCH_EXPERIENCES.FETCH_USER_EXPERIENCES_REQUEST:
      return {
        ...state,
        loadingUserExperiences: true,
      };
    case FETCH_EXPERIENCES.FETCH_USER_EXPERIENCES_SUCCESS:
      return {
        ...state,
        loadingUserExperiences: false,
        users: action.response,
      };
    case FETCH_EXPERIENCES.FETCH_USER_EXPERIENCES_FAILURE:
      return {
        ...state,
        loadingUserExperiences: false,
        items: null,
      };
    case FETCH_EXPERIENCES.LOAD_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXPERIENCES.LOAD_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        experience: action.response,
      };
    case FETCH_EXPERIENCES.LOAD_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
      };
    case CREATE_NEW_EXPERIENCE.CREATE_NEW_EXPERIENCE_REQUEST:
      return {
        ...state,
        creatingExperience: true,
      };
    case CREATE_NEW_EXPERIENCE.CREATE_NEW_EXPERIENCE_SUCCESS:
      return {
        ...state,
        creatingExperience: false,
        isUpdated: true,
        experience: {
          ...action.response,
          name: '',
          description: '',
          startDate: new Date(action.response.startTime),
          endDate: new Date(action.response.endTime),
        },
      };
    case CREATE_NEW_EXPERIENCE.CREATE_NEW_EXPERIENCE_FAILURE:
      return {
        ...state,
        isUpdated: false,
        creatingExperience: false,
      };

    case FETCH_EXPERIENCES.LOAD_CMS_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXPERIENCES.LOAD_CMS_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
      };
    case FETCH_EXPERIENCES.LOAD_CMS_EXPERIENCE_FINISH:
      return {
        ...state,
        loading: false,
        experience: action.experience,
      };
    case FETCH_EXPERIENCES.SAVE_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case FETCH_EXPERIENCES.SAVE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        experience: action.response,
      };
    case FETCH_EXPERIENCES.RSVP_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case FETCH_EXPERIENCES.RSVP_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case FETCH_EXPERIENCES.RSVP_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case LIKE_ACTIONS.LIKE_SUCCESS:
      return {
        ...state,
        users: action.response,
      };
    case LIKE_ACTIONS.UNLIKE_SUCCESS:
      return {
        ...state,
        users: action.response,
      };
    case FETCH_EXPERIENCES.SAVE_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_EXPERIENCES.SAVE_CHANNEL_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case FETCH_EXPERIENCES.SAVE_CHANNEL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case FETCH_EXPERIENCES.SAVE_CHANNEL_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case FETCH_EXPERIENCES.GUESTLIST_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };

    case FETCH_EXPERIENCES.GUESTLIST_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        experience: { ...state.experience, ...{ guestlist: action.response } },
      };
    case FETCH_EXPERIENCES.GUESTLIST_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      };
    case GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES.GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        subcategories: [],
      };
    case GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES.GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        subcategories: action.response,
      };
    default:
      return state;
  }
}
