import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import { FontIcon } from 'material-ui';
import styled from 'styled-components';

import { setCurrentChannel } from 'actions/channels';

// import routes from 'appconstants/routes';
import { APP_THEMES } from 'appconstants/colors';

import LogoWhite from 'images/icon-logo-white.png';
import LogoPurple from 'images/icon-logo-purple.png';

import UserIcon from './UserIcon';
import ChannelLogo from './ChannelLogo';
import DashboardAdminMenu from './DashboardAdminMenu';
// import { USER_TYPES } from 'appconstants';
import SubmitMenu from './SubmitMenu';
import {
  useAccessToDashboard,
  useDashboardRedirects,
  useShowAdminOptions,
} from 'hooks/navigationHeader';

const NavigationHeader = ({
  admin = false,
  currentChannel,
  location,
  router,
  setCurrentChannel,
  theme = APP_THEMES.WHITE,
  user,
}) => {
  const logo = useMemo(() => (theme === APP_THEMES.TRANSPARENT ? LogoWhite : LogoPurple), [theme]);
  const showAdminOptions = useShowAdminOptions({ location, admin, user });
  const hasAccessToDashboard = useAccessToDashboard({ user, currentChannel });
  const { goToDashboard, logoRedirect } = useDashboardRedirects({
    user,
    currentChannel,
    router,
    showAdminOptions,
    setCurrentChannel,
  });
  return (
    <HeaderWrapper admin={admin}>
      <StyledLink
        to={logoRedirect}
        // target={showAdminOptions ? '_self' : '_blank'}>
        target={'_self'}
      >
        <Logo src={logo} />
      </StyledLink>
      <ChannelLogo admin={showAdminOptions} theme={theme} />
      {showAdminOptions && <DashboardAdminMenu />}
      <Space />
      {!showAdminOptions && hasAccessToDashboard && (
        <DashboardButton onClick={goToDashboard} theme={theme}>
          Dashboard
          <StyledFontIcon
            className="material-icons"
            color={theme === APP_THEMES.TRANSPARENT ? '#D5D5D5' : '#2c2c2c'}
          >
            keyboard_arrow_right
          </StyledFontIcon>
        </DashboardButton>
      )}
      {showAdminOptions && <SubmitMenu />}
      <UserIcon user={user} theme={theme} />
    </HeaderWrapper>
  );
};

const DashboardButton = styled.button`
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  background: transparent;
  border-radius: 8px;
  transition: all 0.25s;

  ${({ theme }) =>
    theme === APP_THEMES.TRANSPARENT
      ? `
      border: 2px solid rgba(255,255,255,0.12);
      color: rgba(255,255,255,0.6);

      &:hover {
        border-color: rgba(255,255,255,0.6);
        color: #D5D5D5;

        & span {
          color: rgba(255,255,255,0.6) !important;
          opacity: 1 !important;
        }
      }
    `
      : `
      border: 2px solid #D5D5D5;
      color: #D5D5D5;
    `}

  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: CircularStd Book;
  font-size: 16px;
  height: 40px;
  justify-content: flex-end;
  margin-right: 6px;
  padding: 0 8px;
  width: 136px;
`;

const HeaderWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 33px;
  width: 100vw;
  height: 90px;
  z-index: 2;

  ${({ admin }) =>
    admin &&
    `
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.04);
  `}
`;

const Logo = styled.img`
  cursor: pointer;
  height: 32px;
  width: 30px;
  opacity: 0.4;
  transition: opacity 0.3s;
  user-select: none;

  &:hover {
    opacity: 1;
  }
`;

const Space = styled.div`
  width: 0;
  margin-left: auto;
`;

const StyledFontIcon = styled(FontIcon)`
  height: 36px;
  align-items: center;
  display: flex !important;
  align-items: center;
  opacity: 0.25;
  width: 25px;
  height: 25px;
  margin-left: 3px;
`;

const StyledLink = styled(Link)`
  padding-right: 20px;
  border-right: 1px solid #ebebeb2e;
  margin-right: 17px;
`;

const mapStateToProps = (state) => ({
  state,
  currentChannel: state.channels.currentChannel,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentChannel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationHeader));
