import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findLast, compact, concat, filter } from 'lodash';
import Snackbar from 'material-ui/Snackbar';

import { FILE_UPLOADER_TYPES } from 'appconstants';

import FileDropZone from 'components/FileDropZone';
import FilePreview from 'components/FilePreview';
import './style.css';

import { ERROR_MESSAGES } from './constants';

const styles = {
  snackbar: {
    body: {
      backgroundColor: 'rgba(255, 0, 13, 0.88)',
    },
    root: {
      top: 0,
      bottom: 'auto',
    },
  },
};

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackbar: false,
      errorMessage: '',
    };
  }

  openSnackbar = () => {
    this.setState({
      openSnackbar: true,
    });
  };

  closeSnackbar = () => {
    this.setState({
      openSnackbar: false,
    });
  };

  /* eslint no-param-reassign: ["error", { "props": false }]*/
  addFiles = (acceptedFiles, isDefault) => {
    const {
      input: { value, onChange },
    } = this.props;
    const reader = new window.FileReader();
    const file = acceptedFiles[0];
    file.hash = `img${Math.floor(Math.random() * 1000000)}`;
    reader.onload = (e) => {
      file.data = e.target.result;

      if (isDefault) {
        file.default = true;
      }

      this.imageVerifySize(file, (result) => {
        if (result === 2) {
          onChange(concat(value, [file]));
        }
      });
    };

    reader.readAsDataURL(file);
  };

  imageVerifySize = (file, callback) => {
    const { minWidth, minHeight, uploaderType } = this.props;
    if (file.size > 10000000) {
      this.setState({
        openSnackbar: true,
        errorMessage: ERROR_MESSAGES.LARGE,
      });
      this.openSnackbar();
      callback(1);
      return;
    }
    const img = new Image(); // eslint-disable-line

    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if (width < 800 && height < 800 && uploaderType !== FILE_UPLOADER_TYPES.LOGO) {
        this.setState({
          openSnackbar: true,
          errorMessage: ERROR_MESSAGES.SMALL,
        });
        this.openSnackbar();
        callback(1);
      } else if (minWidth && width < minWidth) {
        this.setState({
          openSnackbar: true,
          errorMessage: ERROR_MESSAGES.width(minWidth),
        });
        this.openSnackbar();
        callback(1);
      } else if (minHeight && height < minHeight) {
        this.setState({
          openSnackbar: true,
          errorMessage: ERROR_MESSAGES.height(minHeight),
        });
        this.openSnackbar();
        callback(1);
      } else {
        this.setState({
          openSnackbar: false,
          errorMessage: '',
        });
        callback(2);
      }
    };
  };

  render() {
    const { openSnackbar, errorMessage } = this.state;
    const { meta, input, uploaderType, large, minWidth, minHeight, photo } = this.props;
    let files;
    const isMultiple = uploaderType === FILE_UPLOADER_TYPES.MULTIPLE;
    const findLastFile = findLast(input.value, 'default');
    const onlyFile = findLastFile || (input.value.length ? input.value : undefined);
    if (isMultiple) {
      files = compact(
        concat(
          [{ dropzone: true }],
          filter(input.value, (f) => !f.delete && !f.default)
        )
      );
    }

    return (
      <div className="file-uploader">
        {onlyFile || photo ? (
          <FilePreview
            files={[onlyFile]}
            meta={meta}
            input={input}
            large={large}
            photo={photo || onlyFile.url}
            type={isMultiple ? FILE_UPLOADER_TYPES.INLINE : uploaderType}
          />
        ) : (
          <FileDropZone
            meta={meta}
            minHeight={minHeight}
            minWidth={minWidth}
            addFiles={this.addFiles}
            type={isMultiple ? FILE_UPLOADER_TYPES.INLINE : uploaderType}
          />
        )}
        {isMultiple && (
          <div>
            {files && (
              <FilePreview
                type={FILE_UPLOADER_TYPES.MULTIPLE}
                meta={meta}
                input={input}
                files={files}
                cols={3}
                multipleLastColumn={
                  <FileDropZone
                    type={FILE_UPLOADER_TYPES.MULTIPLE}
                    meta={meta}
                    addFiles={this.addFiles}
                  />
                }
              />
            )}
          </div>
        )}
        <Snackbar
          open={openSnackbar}
          message={errorMessage}
          autoHideDuration={4000}
          onRequestClose={this.closeSnackbar}
          bodyStyle={styles.snackbar.body}
          style={{
            ...styles.snackbar.root,
            left: (window.innerWidth - 288) / 2,
            transform: openSnackbar ? 'translate3d(0, 0, 0)' : 'translate3d(0, -50px, 0)',
          }}
        />
      </div>
    );
  }
}

FileUploader.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  minWidth: PropTypes.number,
  uploaderType: PropTypes.oneOf([
    FILE_UPLOADER_TYPES.SQUARE,
    FILE_UPLOADER_TYPES.LOGO,
    FILE_UPLOADER_TYPES.INLINE,
    FILE_UPLOADER_TYPES.MULTIPLE,
  ]),
};

export default FileUploader;
