import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { routerReducer as routing } from 'react-router-redux';

import accounts from './accounts';
import artists from './artists';
import auth from './auth';
import channels from './channels';
import discounts from './discounts';
import experiences from './experiences';
import festivals from './festivals';
import flows from './flows';
import images from './images';
import imagesArtists from './imagesArtists';
import locations from './locations';
import notifications from './notifications';
import payouts from './payouts';
import tags from './tags';

export default combineReducers({
  accounts,
  artists,
  auth,
  channels,
  discounts,
  experiences,
  festivals,
  flows,
  form,
  images,
  imagesArtists,
  locations,
  notifications,
  routing,
  payouts,
  tags,
});
