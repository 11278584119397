import React, { useCallback } from 'react';
import Avatar from 'components/Avatar';

import { useFestivalCMS } from 'hooks/useFestivalInformation';
import { formatCurrency } from 'utils/helpers';
import Loader from './Loader';
import api from 'api';
import FileSaver from 'file-saver';

const Guestlist = ({ alias, channel }) => {
  const { isLoading, data } = useFestivalCMS(alias, channel, 'guestlist');
  const getTicketsCount = useCallback(
    (tickets) => tickets.reduce((prev, { quantity }) => quantity + prev, 0),
    []
  );

  const getCSV = useCallback(async () => {
    const data = await api.festivals.getFestivalCMSData(alias, channel, 'guestlist', { csv: true });
    const csvName = `${channel}-${alias}-guestlist.csv`;
    const blobData = new Blob([data]);
    FileSaver.saveAs(blobData, csvName);
  }, [alias, channel]);

  if (isLoading) {
    return <Loader />;
  }

  const { guestList } = data;

  return (
    <>
      <div className="discounts-title-container">
        <span className="discounts-title">Guest list</span>
        <button className="discounts-new-btn" onClick={getCSV}>
          Download
        </button>
      </div>
      <div className="content">
        <table>
          <thead>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>QTY</th>
            <th>TICKETS</th>
            <th>TOTAL PAID</th>
          </thead>
          <tbody>
            {guestList.map(({ _id, tickets, total, user: { name, email } }) => (
              <tr key={_id}>
                <td>
                  <div className="avatar">
                    <Avatar email={email} size={28} isSquare />
                  </div>
                  {name}
                </td>
                <td>{email}</td>
                <td>{getTicketsCount(tickets)}</td>
                <td>
                  {tickets
                    .map(({ name, description, quantity }) => `${quantity}x ${name || description}`)
                    .join('\n')}
                </td>
                <td className="total">{formatCurrency({ value: total })}</td>
              </tr>
            ))}
            {!guestList?.length && (
              <tr>
                <td colSpan="5">
                  <center>
                    <span className="discounts--empty-state">
                      No tickets has been sold for this event
                    </span>
                  </center>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Guestlist;
