import { useMemo, useCallback } from 'react';
import { USER_TYPES } from 'appconstants';
import routes from 'appconstants/routes';

// This hook answers the question:
// Should this user see the administration options?
export const useShowAdminOptions = ({ location, admin, user }) => {
  const showAdminOptions = useMemo(() => {
    // If not on an admin screen, if user is not logged in (logged out users show as having email 'guest')
    // or if their max role is 'guest', then they should not see admin options.
    if (!admin || user.email === 'guest' || user.maxRole === USER_TYPES.GUEST) {
      return false;
    }

    // On the `tickets` and `checkout` pages we show the `admin` header but they should not be able to
    // access the channel settings or create new experiences/festivals from those screens.
    if (['/checkout', '/tickets'].some((url) => location.pathname.includes(url))) {
      return false;
    }

    return true;
  }, [location, admin, user]);

  return showAdminOptions;
};

// This hook answers the question:
// Can the current user access the dashboard page?
export const useAccessToDashboard = ({ user, currentChannel }) => {
  const hasAccessToDashboard = useMemo(() => {
    // There's no user or the user is logged out
    // logged out users show up as having email `guest`
    if (!user || user.email === 'guest') {
      return false;
    }

    // If logged in user is not a guest (can be producer, admin or creator)
    // they should be able to access the dashboard
    if (user.maxRole !== USER_TYPES.GUEST) {
      return true;
    }

    if (user.isAdmin) {
      return true;
    }

    // If the user show not have the current channel in their user channels.
    const channelInfo = user.channels?.find(({ urlSlug }) => urlSlug === currentChannel.urlSlug);

    if (!channelInfo) {
      return false;
    }

    return false;
  }, [user, currentChannel]);

  return hasAccessToDashboard;
};

// This hook returns the function to navigate to the dashboard and
// the route the presence logo should redirect to
export const useDashboardRedirects = ({
  user,
  currentChannel,
  router,
  showAdminOptions,
  setCurrentChannel,
}) => {
  const goToDashboard = useCallback(async () => {
    // Check this user's access to the current channel
    const userChannelPermissions = user.channels.find(
      ({ urlSlug, setupPending }) => !setupPending && urlSlug === currentChannel?.urlSlug
    );
    let channelSlug = currentChannel?.urlSlug;

    // If the current channel is outside of the current user's reach
    if (!userChannelPermissions) {
      // Get a channel that the user has producer, creator or admin access to.
      const channelUserHasAccessTo = user.channels.find(
        ({ canEditExperiences, role, setupPending }) =>
          !setupPending &&
          (canEditExperiences ||
            [USER_TYPES.CREATOR, USER_TYPES.PRODUCER, USER_TYPES.ADMIN].includes(role))
      );
      channelSlug = channelUserHasAccessTo.urlSlug;
    }

    // Set the current channel to that channel to prevent presence logo flicker on dashboard page
    // (it showed the presence logo for a split second due to not having the current channel set)
    // and go to dashboard!
    await setCurrentChannel(channelSlug);
    router.push(routes.dashboard(channelSlug));
  }, [user, currentChannel, router, setCurrentChannel]);

  const logoRedirect = useMemo(() => {
    // showAdminOptions = true means the user is in the dashboard
    if (useAccessToDashboard) {
      // If there's a current channel, go to that channel's profile page, else go
      // to WithPresence's landing page
      return currentChannel?.urlSlug ? `/${currentChannel.urlSlug}/dashboard` : '/';
    }

    // User is using the app, not the admin panel, redirect to Presence's presentation page
    // withpresence.com (not .co)
    return process.env.REACT_APP_PRESENCE_MAIN_PAGE;
  }, [currentChannel]);

  return {
    goToDashboard,
    logoRedirect,
  };
};
