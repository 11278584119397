import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Close from 'images/close.png';
import IconButton from '@material-ui/core/IconButton';

const GuidelineItem = ({ guideline, setGuideline, removeGuideline }) => {
  const [value, setValue] = useState(guideline);

  useEffect(() => {
    setValue(guideline);
  }, [guideline]);

  return (
    <GuidelineItemContainer>
      <input
        type="text"
        value={value}
        style={{ width: '93%' }}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => setGuideline(value)}
      />
      <IconButton onClick={() => removeGuideline()}>
        <StyledClose src={Close} />
      </IconButton>
    </GuidelineItemContainer>
  );
};

const GuidelineItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;

  & .MuiIconButton-root {
    margin: 0 0 14px 4px;
  }
`;

const StyledClose = styled.img`
  height: 14px;
  width: 14px;
`;

export default GuidelineItem;
