import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontIcon } from 'material-ui';
import styled from 'styled-components';

import { ListItemText, Menu, MenuItem } from '@material-ui/core';

import { saveFestivalChannel } from 'actions/festivals';
import { createNewExperience, loadingExperienceFinish } from 'actions/experiences';
import { EXPERIENCES_TYPES } from 'appconstants';
import { DROP_MENU_SHADOW_STYLE } from 'containers/App/constants';

const SubmitMenu = ({ createExperience, createFestival, currentChannel, router }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const disabled = useMemo(() => {
    const pathname = router.location.pathname;
    const experienceOrFestivalCreation = [/^\/experiences\//, /^\/festivals\//, /^\/camps\//];

    if (experienceOrFestivalCreation.some((regex) => regex.test(pathname))) {
      return true;
    }

    return false;
  }, [router.location.pathname]);

  const infinitePlaya = useMemo(() => {
    const pathname = router.location.pathname;
    return /infiniteplaya/.test(pathname);
  }, [router.location.pathname]);

  useEffect(() => {
    setAnchorEl(null);
  }, [setAnchorEl, router.location.pathname]);

  const onCreateFestival = useCallback(async () => {
    if (currentChannel?.id) {
      try {
        const { id } = await createFestival(currentChannel.id);
        router.push(`/festivals/${id}/?create=true`);
      } catch (error) {}
    }
  }, [currentChannel, createFestival, router]);
  const onCreateCamp = useCallback(async () => {
    if (currentChannel?.id) {
      try {
        const { id } = await createFestival(currentChannel.id);
        router.push(`/camps/${id}/?create=true`);
      } catch (error) {}
    }
  }, [currentChannel, createFestival, router]);

  const onCreateExperience = useCallback(
    async (type = EXPERIENCES_TYPES.VIRTUAL) => {
      if (currentChannel?.id) {
        try {
          const experience = await createExperience({ type, channel: currentChannel.id });
          router.push(`/experiences/${experience.id}/?create=true`);
        } catch (error) {}
      }
    },
    [currentChannel, createExperience, router]
  );
  return currentChannel ? (
    <>
      <SubmitWrapper
        disabled={disabled}
        onClick={(event) => !disabled && setAnchorEl(anchorEl ? null : event.currentTarget)}
      >
        <SubmitLabel
          disabled={disabled}
          onClick={(event) => {
            event.stopPropagation();
            if (!disabled) {
              onCreateExperience(EXPERIENCES_TYPES.VIRTUAL);
            }
          }}
        >
          Submit
        </SubmitLabel>
        <StyledFontIcon
          className="material-icons"
          color={anchorEl ? '#ffffff' : 'rgba(0,0,0,0.4)'}
          hoverColor={disabled ? '#9c9c9c' : '#ffffff'}
          style={{ fontSize: 20, width: 30, textAlign: 'center' }}
        >
          keyboard_arrow_down
        </StyledFontIcon>
      </SubmitWrapper>
      <StyledMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        PaperProps={DROP_MENU_SHADOW_STYLE}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setAnchorEl(null)}
      >
        <StyledMenuItem onClick={() => onCreateExperience()}>
          <ListItemText primary="Virtual" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => onCreateExperience(EXPERIENCES_TYPES.PHYSICAL)}>
          <ListItemText primary="IRL" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onCreateFestival}>
          <ListItemText primary="Festival" />
        </StyledMenuItem>
        {infinitePlaya && (
          <StyledMenuItem onClick={onCreateCamp}>
            <ListItemText primary="Camp" />
          </StyledMenuItem>
        )}
      </StyledMenu>
    </>
  ) : null;
};

const StyledFontIcon = styled(FontIcon)``;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    padding: 0 16px;
    // width: 154px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    background-color: transparent !important;
    border-bottom: 2px solid transparent;
    color: #9c9c9c;
    font-size: 14px;
    margin-right: 30px;
    padding-left: 0;

    & span {
      font-family: CircularStd Book;
    }

    &:hover {
      background-color: transparent;
      border-bottom-color: #9b63f8;
      color: #9b63f8;
    }
  }

  &.Mui-disabled {
    && {
      cursor: not-allowed;
      pointer-events: all;
      color: #9c9c9c;
      border-bottom-color: transparent;
    }
  }

  & p.MuiListItemText-secondary {
    font-size: 10px;
  }
`;

const SubmitLabel = styled.div`
  // padding-right: 9px;
  height: 100%;
  width: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const SubmitWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: CircularStd Book;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  justify-content: flex-end;
  margin-right: 6px;
  user-select: none;
  width: 120px;

  ${({ disabled }) =>
    disabled
      ? `
    background-color: #ffffff;
    cursor: not-allowed;
    color: #9c9c9c;
    border: 2px solid #d5d5d5;
    box-sizing: border-box;

    hover span {
      color: #9c9c9c !important
    }
  `
      : `
    background-color: #9B63F8;
    hover span {
      color: #fff !important
    }
  `}
`;

const mapStateToProps = (state) => ({
  currentChannel: state.channels.currentChannel,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFestival: saveFestivalChannel,
      createExperience: createNewExperience,
      loadingExperienceFinish,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubmitMenu));
