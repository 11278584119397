import React from 'react';
import styled from 'styled-components';
import { dateFormat } from 'utils/dates';

const CheckoutDescription = ({ data, descriptionColor }) => (
  <DescriptionContainer>
    <FestivalContainer>
      <Channel>{`Festival | ${data.channel.name}`}</Channel>
      <StartTime>
        {dateFormat(
          { hour: 'numeric', minute: 'numeric', timeZoneName: 'short', hour12: true },
          data.startTime
        )}
      </StartTime>
    </FestivalContainer>
    <NameAndDescriptionContainer>
      <FestivalName>{data.name}</FestivalName>
      <FestivalDescription descriptionColor={descriptionColor}>
        {data.description}
      </FestivalDescription>
    </NameAndDescriptionContainer>
  </DescriptionContainer>
);

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NameAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 500px;
`;

const FestivalName = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 45.5px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 44px;
`;

const FestivalDescription = styled.div`
  color: ${({ descriptionColor }) => (descriptionColor ? descriptionColor : '#4d4d4d')};
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const FestivalContainer = styled.div`
  height: 60px;
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  flex-direction: column;
`;

const Channel = styled.div``;

const StartTime = styled.div``;

export default CheckoutDescription;
