import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import ImageNotFound from 'images/no-image-box.png';
import TGSlider from 'components/Slider';
import { selectLoadingChannelExperiences } from 'selectors/experiences';
import { getResizedImageURL } from 'utils/helpers';
import { getFormattedDateRange } from 'utils/dates';
import { IMAGE_SIZES } from 'appconstants';
import { APP_THEMES } from 'appconstants/colors';
import { DATE_RANGE_FORMATS } from 'appconstants/time';

const styles = {
  footerLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

class ThumbExperiences extends Component {
  constructor(props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
  }

  goToExperienceProfile({ urlSlug, alias, isFestival }) {
    browserHistory.push(`/in/${urlSlug}${isFestival ? '/f' : ''}/${alias}`);
  }

  handleClick(event, index, item) {
    const {
      channel: { urlSlug },
      alias,
      isFestival,
    } = item;
    const { onClickThumb } = this.props;

    event.preventDefault();

    if (typeof onClickThumb === 'function') {
      onClickThumb(index);
    } else {
      this.goToExperienceProfile({ urlSlug, alias, isFestival });
    }
  }

  renderItem(item, index) {
    const { theme } = this.props;
    const { startTime, endTime, images, name, description } = item;
    const date = getFormattedDateRange({
      startTime,
      endTime,
      format: DATE_RANGE_FORMATS.FULL_DATE,
    });
    const imageURL =
      images && images.length
        ? getResizedImageURL(images[0], IMAGE_SIZES.THUMBNAIL)
        : ImageNotFound;

    return (
      <a
        href="/"
        data-index={index}
        onClick={(event) => {
          this.handleClick(event, index, item);
        }}
        key={index}
        className="media-item"
        style={{ backgroundColor: theme === APP_THEMES.DARK ? APP_THEMES.DARK : APP_THEMES.WHITE }}
      >
        <div className="media-thumbnail">
          <img src={imageURL} alt={description} width="144px" height="76px" />
        </div>
        <div className="media-content">
          <h4 style={styles.footerLabel}>{name}</h4>
          <span>{date}</span>
        </div>
      </a>
    );
  }

  render() {
    const { hasShadow, experiences, featured, theme } = this.props;
    const settings = {
      className: `center ${hasShadow ? 'bottom-shadow' : ''}`,
      arrows: false,
      centerMode: false,
      dots: false,
      infinite: true,
      centerPadding: '0px',
      slidesToShow: 4,
      speed: 500,
      draggable: false,
    };

    return (
      ((experiences && experiences.length >= 0) || (featured && featured.length >= 0)) && (
        <div
          id="bx-pager"
          className="media-facility clearfix"
          style={{
            backgroundColor: theme === APP_THEMES.DARK ? APP_THEMES.DARK : APP_THEMES.WHITE,
            height: 157,
          }}
        >
          <TGSlider
            settings={settings}
            items={experiences || featured}
            renderItem={this.renderItem}
          />
        </div>
      )
    );
  }
}

ThumbExperiences.propTypes = {
  loading: PropTypes.bool,
  featured: PropTypes.array,
  experiences: PropTypes.array,
  onClickThumb: PropTypes.func,
  hasShadow: PropTypes.bool,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loading: selectLoadingChannelExperiences(state),
});

export default connect(mapStateToProps)(ThumbExperiences);
