import { CALL_API } from 'redux_store/middleware/api';
import { FETCH_TAGS } from 'appconstants';

const SAVE_TAG_REQUEST = 'SAVE_TAG_REQUEST';
const SAVE_TAG_SUCCESS = 'SAVE_TAG_SUCCESS';
const SAVE_TAG_FAILURE = 'SAVE_TAG_FAILURE';

const LOAD_TAG_REQUEST = 'LOAD_TAG_REQUEST';
const LOAD_TAG_SUCCESS = 'LOAD_TAG_SUCCESS';
const LOAD_TAG_FAILURE = 'LOAD_TAG_FAILURE';

function getAllTags() {
  const method = 'get';
  const endpoint = `/tags/`;
  return {
    [CALL_API]: {
      types: [
        FETCH_TAGS.FETCH_ALL_TAGS_REQUEST,
        FETCH_TAGS.FETCH_ALL_TAGS_SUCCESS,
        FETCH_TAGS.FETCH_ALL_TAGS_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function getTagById(id) {
  const method = 'get';
  const endpoint = `/tags/${id}`;
  return {
    [CALL_API]: {
      types: [LOAD_TAG_REQUEST, LOAD_TAG_SUCCESS, LOAD_TAG_FAILURE],
      method,
      endpoint,
    },
  };
}

function postTag(body) {
  const method = 'post';
  const endpoint = '/tags';
  return {
    [CALL_API]: {
      types: [SAVE_TAG_REQUEST, SAVE_TAG_SUCCESS, SAVE_TAG_FAILURE],
      method,
      endpoint,
      body,
    },
  };
}

export function fetchAllTags() {
  return (dispatch) => {
    return dispatch(getAllTags());
  };
}

export function loadTags(tags) {
  let tagsId;

  if (tags == null || tags.length === 0) {
    tagsId = tags;
  }

  return (dispatch) => {
    tagsId = typeof tags[0] === 'string' ? tags : tags.map((tag) => tag.id);
    const getActions = tagsId.map((id) => {
      return dispatch(getTagById(id));
    });
    return Promise.all(getActions);
  };
}

export function saveTag(data) {
  return (dispatch) => {
    const body = {
      ...data,
    };
    return dispatch(postTag(body)).then((res) => {
      return res;
    });
  };
}

export function saveTags(tags) {
  return (dispatch) => {
    const saveActions = tags.map((item) => {
      return dispatch(saveTag(item));
    });
    return Promise.all(saveActions);
  };
}
