import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
// import { find } from 'lodash';

import TGHoverButton from 'components/HoverButton';
import ExperienceDateAndLocation from 'components/ExperienceDateAndLocation';

import { IMAGE_SIZES } from 'appconstants';

import { getRSVPLabelAndStatus, getResizedImageURL, getTicketsUrl } from 'utils/helpers';
import { dateIsAfterNow } from 'utils/dates';

import ImageNotFound from 'images/no-image-box.png';

import './styles.css';

import * as experiencesActions from 'actions/experiences';
import routes from 'appconstants/routes';

const styles = {
  rsvpButton: {
    style: {
      marginLeft: 6,
      height: '52px',
      borderRadius: '8px',
    },
    button: {
      backgroundImage: '#9764f0',
      backgroundColor: '#f07da8',
      cursor: 'pointer',
      minWidth: '186px',
    },
    label: {
      textTransform: 'initial',
      fontSize: '20px',
      color: '#fff',
      fontFamily: 'CircularStd Bold',
      lineHeight: '52px',
    },
  },
  learnMoreButton: {
    style: {
      height: '52px',
      borderRadius: '8px',
    },
    button: {
      backgroundColor: '#FFFFFF',
      cursor: 'pointer',
      width: '150px',
    },
    label: {
      textTransform: 'none',
      fontSize: '20px',
      color: '#fff',
      fontFamily: 'CircularStd Book',
      lineHeight: '52px',
    },
  },
  expImg: {
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
    objectPosition: 'center',
  },
};

const SliderItem = ({
  experience,
  index,
  array,
  experiencesUsers,
  changeSlide,
  backgroundColor,
  updateBackgroundColor,
  router,
  rsvpExperience,
}) => {
  const onRSVP = useCallback(
    ({ urlSlug, alias, experience }) => {
      if (experience.festival && experience.festivalUnlocked) {
        const {
          alias,
          channel: { urlSlug: channel },
        } = experience;

        rsvpExperience({
          channel,
          alias,
        }).then(() => {
          router.push(routes.tickets);
        });
      } else {
        const url = getTicketsUrl(alias, urlSlug, experience);
        const state = {};

        if (experience.festival) {
          state.referral = {
            channel: experience.channel.urlSlug,
            alias: experience.alias,
          };
        }

        router.push({
          pathname: url,
          state,
        });
      }
    },
    [router, rsvpExperience]
  );

  const onLearnMore = useCallback(
    ({ urlSlug, alias }) => {
      router.push(`/in/${urlSlug}/${experience.isFestival ? 'f/' : ''}${alias}`);
    },
    [router, experience]
  );

  const imageURL = useMemo(() => {
    if (experience.images && experience.images[0]) {
      return getResizedImageURL(experience.images[0], IMAGE_SIZES.LARGE);
    }

    return ImageNotFound;
  }, [experience]);

  const rsvp = useMemo(() => {
    if (experiencesUsers?.experiences) {
      return (
        experiencesUsers.experiences.findIndex(
          ({ id, rsvp }) => id === (experience._id || experience.id) && rsvp
        ) !== -1
      );
    }

    return false;
  }, [experiencesUsers, experience]);

  const { rsvpDisable, rsvpLabel } = useMemo(() => {
    return getRSVPLabelAndStatus(experience, experience.tickets, rsvp, experience.isFestival);
  }, [experience, rsvp]);

  const rsvpVisible = useMemo(() => {
    return dateIsAfterNow(experience.endTime);
  }, [experience]);

  const {
    channel: { urlSlug },
    alias,
  } = experience;

  updateBackgroundColor(backgroundColor);
  return (
    <div key={experience.id}>
      <div className="slide">
        <NavigationButton index={index} array={array} direction={-1} changeSlide={changeSlide} />
        <div className="slide-inner">
          <div className="slide-img">
            <img
              width="680px"
              height="460px"
              src={imageURL}
              alt={experience.description}
              style={styles.expImg}
            />
          </div>
          <div className="profile-content">
            <ExperienceDateAndLocation
              startTime={experience.startTime}
              endTime={experience.endTime}
              location={experience.location}
              // festival={experience.festival}
            />
            <h1 className="slide-title">{experience.name}</h1>
            <div className="slide-buttons">
              <TGHoverButton
                id="btnLearnMore"
                label="Learn more"
                onClick={() => onLearnMore({ urlSlug, alias })}
                buttonStyle={styles.learnMoreButton.button}
                labelStyle={styles.learnMoreButton.label}
                style={styles.learnMoreButton.style}
                className="btn-learnmore"
                backgroundColor="rgba(255,255,255,.24)"
                backgroundColorHover="#f07da8"
              />

              {rsvpVisible && (
                <TGHoverButton
                  id="btnRSVP"
                  label={rsvpLabel}
                  onClick={
                    rsvpDisable
                      ? () => onLearnMore({ urlSlug, alias })
                      : () => onRSVP({ urlSlug, alias, experience })
                  }
                  buttonStyle={styles.rsvpButton.button}
                  labelStyle={styles.rsvpButton.label}
                  style={styles.rsvpButton.style}
                  className="btn-learnmore"
                  backgroundColor="#f07da8"
                  backgroundColorHover="#9764f0"
                />
              )}
            </div>
          </div>
          <div className="slide-right" style={{ display: 'none' }}>
            <img alt="" src="images/img-slide1.jpg" />
          </div>
        </div>
        <NavigationButton index={index} array={array} direction={1} changeSlide={changeSlide} />
      </div>
    </div>
  );
};

function NavigationButton({ index, array, direction, changeSlide }) {
  if ((index > 0 && direction === -1) || (index < array.length - 1 && direction === 1)) {
    let targetIndex = index - 1;
    let image = ImageNotFound;
    let directionClass = 'left';

    if (direction === 1) {
      targetIndex = index + 1;
      directionClass = 'right';
    }

    // Get the previous or next experience image.
    if (array[targetIndex].images && array[targetIndex].images.length) {
      image = array[targetIndex].images[0];
    }

    return (
      <button
        className={`slide-controls ${directionClass}`}
        onClick={() => changeSlide(targetIndex)}
      >
        <img src={image} alt="" />
      </button>
    );
  }

  return false;
}

export default connect(() => ({}), {
  ...experiencesActions,
})(withRouter(SliderItem));
