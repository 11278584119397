import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FILE_UPLOADER_TYPES } from 'appconstants';
import FileUploader from 'components/FileUploader';
// import soundcloudIcon from 'images/soundcloud.png';
// import youtubeIcon from 'images/youtube.png';
import Loading from 'components/Loading';

const PresentationPage = ({ loading }) =>
  loading ? (
    <Loading message="Loading Media..." />
  ) : (
    <div className="photos-wrapper experience-wrapper">
      <div className="upload-file-container">
        <div className="data-col upload-file">
          <Field
            name="images"
            minWidth={1600}
            minHeight={1064}
            component={FileUploader}
            uploaderType={FILE_UPLOADER_TYPES.INLINE}
            large
            type="select-multiple"
          />
        </div>
      </div>
    </div>
  );

PresentationPage.propTypes = {
  loading: PropTypes.bool,
};

export default PresentationPage;
