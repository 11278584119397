import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia, CardTitle, CardText } from 'material-ui';
import { browserHistory } from 'react-router';
import ImageNotFound from 'images/no-image-box.png';

const ExperienceMobileCard = ({ channel, title, date, imageUrl, alias, isFestival, ticketURL }) => {
  const styles = {
    experience: {
      height: '227px',
      backgroundImage: `url(${imageUrl || ImageNotFound})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    },
    titleStyle: {
      color: '#000000',
      fontFamily: 'CircularStd Book',
      fontSize: '18px',
      fontWeight: '300',
      letterSpacing: '0',
      lineHeight: '22px',
    },
    dateStyle: {
      color: '#9B63F8',
      fontFamily: 'CircularStd Book',
      fontSize: '13px',
      fontWeight: '300',
      letterSpacing: '0',
      lineHeight: '22px',
      padding: '0px',
      paddingLeft: '13px',
      paddingButton: '16px',
    },
  };

  const onClickHandler = () => {
    const experienceURL = `/in/${channel?.urlSlug || channel}/${isFestival ? 'f/' : ''}${alias}`;

    if (ticketURL) {
      window.open(ticketURL);
    } else {
      browserHistory.push(experienceURL);
    }
  };
  return (
    <div className="container-experience-mobile">
      <Card className="my-ticket mobile-card">
        <CardMedia onClick={onClickHandler} style={styles.experience} />
        <CardTitle className="card-title-mobile" title={title} titleStyle={styles.titleStyle} />
        <CardText style={styles.dateStyle}>
          {Intl.DateTimeFormat('en', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
            weekday: 'long',
          }).format(new Date(date))}
        </CardText>
      </Card>
    </div>
  );
};

ExperienceMobileCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  imageUrl: PropTypes.string,
  channel: PropTypes.object,
  alias: PropTypes.string,
  ticketURL: PropTypes.string,
};

export default ExperienceMobileCard;
