import React from 'react';
import { string, number, bool } from 'prop-types';
import cn from 'classnames';
import Gravatar from 'react-gravatar';
import { black } from 'appconstants/colors';

import './styles.css';

const styles = ({ isSquare, size, clickable }) => ({
  backgroundColor: black,
  borderRadius: !isSquare && '50%',
  cursor: clickable && 'pointer',
  height: `${size}px`,
  width: `${size}px`,
});

const Avatar = ({
  image,
  size = 36,
  className = '',
  isSquare = false,
  clickable = false,
  ...props
}) => {
  if (!image) {
    return <Gravatar style={styles({ isSquare, clickable })} size={size} {...props} />;
  }

  return (
    <div
      style={{ ...styles({ isSquare, size, clickable }), backgroundImage: `url(${image})` }}
      className={cn('avatar-container', className)}
      {...props}
    />
  );
};

Avatar.propTypes = {
  image: string,
  size: number,
  className: string,
  isSquare: bool,
  clickable: bool,
};

export default Avatar;
