import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { arrayOf, shape, string, func } from 'prop-types';
import Footer from 'components/WizardComponents/Footer';
import Snackbar from 'components/WizardComponents/Snackbar';
import categories from 'utils/categories';
import validateDescriptionStep from '../Validations/description';
import { updateFestivalInformation } from 'actions/festivals';
import { bindActionCreators } from 'redux';
import { hasChanges } from '../Validations/utils';
import useFestivalInformation, {
  useSetLastStep,
  useUpdateFestival,
} from 'hooks/useFestivalInformation';
import styled from 'styled-components';
// import useWizardValidation from 'hooks/useWizardValidation';

const Description = ({
  channels = [],
  goToStep,
  nextStep,
  params,
  setLastStep,
  setSetLastStep,
  setLastCompletedStep,
  renderSteps,
}) => {
  const { isLoading, data } = useFestivalInformation(params.id);
  const mutation = useUpdateFestival(params.id, 'description');
  useSetLastStep({
    setLastStep,
    setSetLastStep,
    goToStep,
    data,
    isLoading,
    setLastCompletedStep,
  });

  const [category, setCategory] = useState({});
  const [description, setDescription] = useState('');
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [participatingChannels, setParticipatingChannels] = useState([]);

  /*
    // ---------------------------- //
    // TOGGLE PARTICIPATING CHANNEL //
    // ---------------------------- //
    const toggleChannel = useCallback(({ target: { value }}) => {
      setParticipatingChannels(value);
    });

    const renderParticipatingChannels = useCallback((values) => {
      if (values.length === 0) {
        return (
          <span className="select__placeholder">Select participating channels</span>
        );
      }

      if (!channels.length) {
        return '';
      }

      return values.map((key) => (channels.find(({ id }) => id === key) || {}).name).join(', ')
    }, [channels]);
  */

  const renderCategory = useCallback(
    () =>
      category && category.id ? (
        category.description
      ) : (
        <span className="select__placeholder">Select category</span>
      ),
    [category]
  );

  const validate = useCallback(() => {
    const missingFields = validateDescriptionStep(name, description, category);

    if (missingFields.length) {
      const errorMessage = `Missing required fields: ${missingFields.join(', ')}`;
      setError(errorMessage);
      return;
    }

    setError(false);

    const data = {
      category: category.id,
      description,
      name,
      participatingChannels,
    };

    const pairs = [
      [category, data?.category],
      [description, data?.description],
      [name, data?.name],
    ];

    if (hasChanges(pairs)) {
      mutation.mutate(data);
    }

    nextStep();
  }, [category, description, name, participatingChannels, mutation, nextStep]);

  useEffect(() => {
    if (data && data._id) {
      setDescription(data.description);
      setName(data.name);
      setParticipatingChannels(data.participatingChannels);
      if (data.category) {
        setCategory(categories.find(({ id }) => id === data.category));
      }
    }
  }, [data, setDescription, setName, setParticipatingChannels]);

  return (
    <>
      {renderSteps(validate)}
      <Snackbar error={error} onClose={() => setError(false)} />
      <input
        maxLength="64"
        minLength="3"
        onChange={({ target: { value } }) => setName(value)}
        placeholder="What is the name of your camp?"
        type="text"
        name="fest-name"
        value={name}
      />
      <StyledTextarea
        maxLength="280"
        minLength="10"
        onChange={({ target: { value } }) => setDescription(value)}
        placeholder="Add detailed description"
        value={description}
      />
      <StyledSelect
        classes={{
          root: 'select',
          icon: 'select__icon',
          input: 'select__input',
          select: 'select__input2',
        }}
        disableUnderline
        MenuProps={{
          classes: {
            paper: 'select__paper',
          },
        }}
        displayEmpty
        fullWidth
        hintText="Select category"
        onChange={({ target: { value } }) => setCategory(categories.find(({ id }) => id === value))}
        renderValue={renderCategory}
        value={category.id}
      >
        {categories.map(({ id, description }) => (
          <MenuItem key={id} value={id} primaryText={description}>
            <ListItemText primary={description} />
          </MenuItem>
        ))}
      </StyledSelect>
      {/* <SelectField
        classes={{
          root: 'select',
          icon: 'select__icon'
        }}
        disableUnderline
        displayEmpty
        fullWidth
        label="invite"
        labelStyle={labelStyle}
        multiple
        onChange={toggleChannel}
        renderValue={renderParticipatingChannels}
        value={participatingChannels || []}
      >
        {
          channels.map(({ name, id }) => (
            <MenuItem
              key={id}
              value={id}
            >
              <ListItemText primary={name} />
            </MenuItem>
          ))
        }
      </SelectField> */}
      <Footer
        lastSaved={data ? data.updatedAt : null}
        onNextStep={validate}
        saving={isLoading}
        status={data && data.published}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { channels } = state.channels;

  return {
    channels,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateFestivalInformation,
    },
    dispatch
  );

Description.propTypes = {
  channels: arrayOf(shape()),
  nextStep: func.isRequired,
  status: string,
  updateFestivalInformation: func.isRequired,
};

const StyledSelect = styled(SelectField)`
  & .MuiSelect-select:focus {
    background-color: #ffffff;
  }
`;

const StyledTextarea = styled.textarea`
  background-color: #ffffff;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Description);
