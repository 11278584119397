import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { range } from 'lodash';
import moment from 'moment';
import useCheckoutTicketLogic from 'hooks/useCheckoutTicketLogic';
import api from 'api';
import { isAllAccessTicket } from 'utils/helpers';

const CheckoutSelectable = ({
  data,
  tickets,
  selectTicketType,
  setSelectTicketType,
  selectQty,
  setSelectQty,
  selectedTicket,
  params,
  setDiscount: setActiveDiscount,
  selectDays,
  setSelectDays,
  toggleDate,
  canSelectMoreDays,
  ticketsToPurchase,
  setTicketAmount,
}) => {
  const {
    festivalTickets,
    onSelectTicket,
    passes,
    selectableDays,
    ticketDescription,
  } = useCheckoutTicketLogic({
    data,
    tickets,
    setSelectTicketType,
    selectedTicket,
    setSelectDays,
  });

  const [discount, setDiscount] = useState('');
  const [loading, setLoading] = useState(false);
  const { channel, id } = params;
  const requestDiscount = useCallback(async () => {
    setLoading(true);
    try {
      const discountData = await api.festivals.requestFestivalDiscount(id, channel, discount);
      setActiveDiscount(discountData);
    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [discount, setLoading, channel, id, setActiveDiscount]);

  return (
    <Container>
      <SelectableContainer>
        <UpperContainer>
          <TicketSelect>
            <StyledFormControlLarge variant="outlined">
              <Select
                id="select-ticket-type"
                value={selectTicketType}
                margin="dense"
                displayEmpty
                classes={{
                  root: 'festival__checkout-select',
                }}
                onChange={onSelectTicket}
              >
                {[{ _id: '', description: 'Select a pass type' }, ...passes].map((ticket) => (
                  <StyledMenuItem value={ticket._id} disabled={ticket._id === ''} key={ticket._id}>
                    {ticket.name || ticket.description}
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControlLarge>
            <PriceContainer>
              {selectedTicket ? (
                selectedTicket && selectedTicket.price === 0 ? (
                  <Free>Free</Free>
                ) : (
                  <Price>{`$${selectedTicket.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}</Price>
                )
              ) : (
                ''
              )}
            </PriceContainer>
            <StyledFormControl>
              <Select
                id="select-qty"
                value={selectQty}
                defaultValue={1}
                onChange={(e) => setSelectQty(e.target.value)}
                disabled={selectTicketType === ''}
              >
                {range(0, selectedTicket?.total > 10 ? 10 : selectedTicket?.total + 1).map(
                  (qty) => (
                    <MenuItem value={qty} key={qty}>
                      {qty}
                    </MenuItem>
                  )
                )}
              </Select>
            </StyledFormControl>
          </TicketSelect>
          <TicketDescription>{ticketDescription}</TicketDescription>
        </UpperContainer>
        <LowerContainer>
          {selectedTicket?.isTicket ? (
            <PaidTicketDescription>
              Paid tickets do not unlock any experiences
            </PaidTicketDescription>
          ) : (
            <StyledFormControlLarge variant="outlined">
              {selectableDays.map((day) => (
                <DateMenuItem
                  key={day.value}
                  value={day.value}
                  disabled={
                    isAllAccessTicket(selectedTicket) ||
                    moment().startOf('day').isAfter(moment(day.value)) ||
                    (!canSelectMoreDays && !selectDays.find((sday) => sday === day.value))
                  }
                  onClick={() => toggleDate(day.value)}
                >
                  <StyledCheckbox checked={!!selectDays.find((sday) => sday === day.value)} />
                  <ListItemText primary={day.label} />
                </DateMenuItem>
              ))}
            </StyledFormControlLarge>
          )}
        </LowerContainer>
      </SelectableContainer>
      {festivalTickets.length > 0 && (
        <SelectableContainer compact>
          {festivalTickets.map(
            ({ description, longDescription, _id, price, quantity }) =>
              quantity > 0 && (
                <TicketSelect isTicket>
                  <TicketDescription>{description}</TicketDescription>
                  <PriceContainer>
                    {price === 0 ? (
                      <Free>Free</Free>
                    ) : (
                      <Price>{`$${price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}</Price>
                    )}
                  </PriceContainer>
                  <StyledFormControl>
                    <Select
                      id="select-qty"
                      value={ticketsToPurchase[_id] || 0}
                      onChange={(e) => setTicketAmount(_id, e.target.value)}
                      disabled={selectTicketType === ''}
                    >
                      {range(0, selectedTicket?.total > 10 ? 11 : selectedTicket?.quantity + 1).map(
                        (qty) => (
                          <MenuItem value={qty} key={qty}>
                            {qty}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </StyledFormControl>
                </TicketSelect>
              )
          )}
        </SelectableContainer>
      )}
      <Discounts>
        <DiscountInput
          value={discount}
          disabled={loading}
          onChange={(e) => setDiscount(e.target.value)}
          placeholder="Enter discount code"
        />
        <ApplyDiscount disabled={loading} onClick={requestDiscount}>
          Apply
        </ApplyDiscount>
      </Discounts>
    </Container>
  );
};

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#9b63f8',
    },
    paddingLeft: 0,
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaidTicketDescription = styled.div`
  color: #cacaca;
  border-bottom: 0;
  font-weight: 100;
  font-style: italic;
  text-align: center;
`;

const Discounts = styled.div`
  height: 56px;
  width: 265px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 15px;
  margin: 15px auto 0;
  box-sizing: border-box;
`;

// const TicketsTitle = styled.div`
//   color: #000;
//   font-size: 18px;
//   margin-top: 20px;
//   font-family: CircularStd Book;
// `;

const DiscountInput = styled.input`
  background-color: #ffffff;
  color: #999999;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  outline: none;
  text-align: right;
`;

const ApplyDiscount = styled.button`
  height: 31px;
  width: 82px;
  cursor: pointer;
  outline: none;
  color: #9b63f8;
  font-family: CircularStd Book;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
`;

const SelectableContainer = styled.div`
  ${({ compact }) => !compact && 'min-height: 199px;'}
  padding: ${({ compact }) => (compact ? '15px' : '0')} 17px;
  border-radius: 8px;
  margin-top: 15px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #cbcbcb;
`;

const TicketSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const TicketDescription = styled.span`
  color: #676767;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const LowerContainer = styled.div`
  padding-top: 20px;
`;

const StyledFormControl = styled(FormControl)`
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #9b63f8;
  }

  & .MuiSelect-select {
    color: #9b63f8;
  }
  & .MuiSelect-icon {
    color: #9b63f8;
  }

  & .MuiInput-underline:after {
    border-bottom: 2px solid #9b63f8;
  }
`;

const StyledFormControlLarge = styled(StyledFormControl)`
  width: 187px;
`;

const PriceContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 10px;
  width: 80px;
  margin-left: auto;
`;

const Price = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 18.05px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22.56px;
  align-self: center;
`;

const Free = styled.div`
  width: max-content;
  color: silver;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  padding: 5px 10px;
  border: 2px solid #d5d5d5;
  border-radius: 8px;
  line-height: 28px;
`;

const StyledMenuItem = styled(MenuItem)`
  margin: 0 18px;
  border-bottom: 2px solid transparent;
  &:hover {
    border-color: #9b63f8;
    background: transparent;
  }
`;

const DateMenuItem = styled(MenuItem)`
  && {
    padding: 0;
    width: unset;
  }
`;

export default CheckoutSelectable;
