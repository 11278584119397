import React from 'react';
import Wizard from 'components/Wizard/Wizard';

import steps from './Steps';

import './index.css';

const CampWizard = ({ params }) => {
  return (
    <div className="festival-wrapper">
      <Wizard params={params} content={steps} setLastStep />
    </div>
  );
};

export default CampWizard;
