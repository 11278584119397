import React from 'react';
import PropTypes from 'prop-types';

const FacebookAvatar = (props) => {
  const { size, facebookId } = props;
  const url = `https://graph.facebook.com/${facebookId}/picture?width=${size}&height=${size}`;

  const hostStyle = {
    display: 'inline-block',
    borderRadius: '50%',
    ...props.style,
  };
  return (
    <div style={hostStyle}>
      {url && <img src={url} style={{ borderRadius: '50%' }} alt={'Facebook avatar'} />}
    </div>
  );
};

FacebookAvatar.propTypes = {
  size: PropTypes.number,
  facebookId: PropTypes.string,
  style: PropTypes.object,
};

export default FacebookAvatar;
