import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { CircularProgress } from 'material-ui';
import { browserHistory } from 'react-router';
import ListImages from 'components/ListImages';
import { connect } from 'react-redux';
import { setCurrentChannel } from 'actions/channels';
import { selectChannels, selectChannelsLoading } from 'selectors/channels';
import './styles.css';

class ChannelList extends Component {
  onSelectChannel = ({ urlSlug }) => {
    this.props.selectChannel(urlSlug);
    browserHistory.push(`/in/${urlSlug}`);
  };

  render() {
    const { channels, channelsDefault, loading } = this.props;

    let totalChannels = channels || channelsDefault;

    totalChannels.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div className="channel-list">
        <h3 className="page-title">Browse by channels</h3>
        {loading && (
          <div className="loader-center">
            <CircularProgress />
          </div>
        )}
        {!loading && (
          <div id="bx-pager">
            <ListImages list={totalChannels} onClick={this.onSelectChannel} />
          </div>
        )}
      </div>
    );
  }
}
ChannelList.propTypes = {
  channels: PropTypes.array,
  channelsDefault: PropTypes.array,
  loading: PropTypes.bool,
  selectChannel: PropTypes.func,
};

const mapStateToProps = (state) => ({
  channelsDefault: selectChannels(state),
  loading: selectChannelsLoading(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ selectChannel: setCurrentChannel }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChannelList);
