import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import { Image } from 'material-ui-image';
import { Link } from 'react-router';
import styled from 'styled-components';
import cn from 'classnames';

import logo from 'images/presence_logo@2x.png';

const styles = {
  root: {
    logo: {
      image: { width: 90, height: 22, float: 'left' },
    },
  },
  buttonLogin: {
    root: {
      float: 'right',
    },
    button: {
      height: 40,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: 'white',
      borderRadius: '20px',
      marginTop: '4px',
    },
    buttonLabel: {
      position: 'relative',
      textTransform: 'none',
      fontFamily: 'CircularStd Book',
      fontSize: '17px',
      paddingLeft: '25px',
      paddingRight: '25px',
      color: 'white',
    },
    buttonBorder: {
      borderStyle: 'none',
    },
  },
};

const SignInButton = styled(FlatButton)`
  &:hover {
    background: rgba(255, 255, 255, 0.3) !important;
  }
`;

export default class TGSignupHeader extends Component {
  renderButtonLogin() {
    const { goToSignIn } = this.props;
    return (
      <div style={styles.buttonLogin.root}>
        <SignInButton
          label="Sign-in"
          onClick={() => goToSignIn(false)}
          backgroundColor="transparent"
          labelStyle={styles.buttonLogin.buttonLabel}
          style={styles.buttonLogin.button}
        />
      </div>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <header className={cn('signup-header clearfix', className)}>
        <div className="clearfix container">
          <div className="logo-wrapper">
            <Link to="/">
              <Image src={logo} color="transparent" style={styles.root.logo.image} alt="Presence" />
            </Link>
          </div>
          {this.renderButtonLogin()}
        </div>
      </header>
    );
  }
}

TGSignupHeader.propTypes = {
  goToSignIn: PropTypes.func,
  className: PropTypes.string,
};
