import { FILE_UPLOADER_TYPES } from 'appconstants';

export const messages = {
  [FILE_UPLOADER_TYPES.INLINE]: 'Drag and drop image to upload',
  [FILE_UPLOADER_TYPES.MULTIPLE]: 'Add',
};

export const iconText = {
  [FILE_UPLOADER_TYPES.INLINE]: 'file_upload',
  [FILE_UPLOADER_TYPES.MULTIPLE]: 'add',
};

export const disclaimer = {
  [FILE_UPLOADER_TYPES.INLINE]: ({ minWidth = 1600, minHeight = 1064 }) =>
    `Image must be at least ${minWidth}px ${minHeight ? `by ${minHeight}px` : ''}`,
};
