import { isDate, isEmpty, map } from 'lodash';
import isValidUrl from 'is-valid-http-url';
import { EXPERIENCES_TYPES, VALIDATION } from 'appconstants';
import moment from 'moment';

const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i.test(value)) {
    return 'Invalid email address';
  }
  return false;
}

export function required(fieldName) {
  return (value) => {
    if (isEmpty(value)) {
      return `Required field ${fieldName} is empty`;
    }
    return false;
  };
}

export function match(fieldToMatch) {
  return (value, allValues) => {
    if (value !== allValues[fieldToMatch]) {
      return `The field doesn't match with ${fieldToMatch}`;
    }
    return false;
  };
}

export function phoneNumber(value) {
  if (
    !isEmpty(value) &&
    !/^(?:\([2-9]\d{2}\) ?|[2-9]\d{2}(?:-?| ?))[2-9]\d{2}[- ]?\d{4}$/i.test(value)
  ) {
    return 'Invalid phone number';
  }
  return false;
}

export function validUrl(str) {
  const valid = isValidUrl(str) || isValidUrl(`http://${str}`);
  return !valid && 'Invalid URL';
}

export function validOrEmptyURL(str) {
  if (isEmpty(str)) {
    return false;
  }
  return validUrl(str);
}

export function stringMinMax({ fieldName, min, max }) {
  return (value) => {
    if (!isEmpty(value) && value.length >= min && value.length <= max) {
      return false;
    }
    return `Field ${fieldName} must have between ${min} and ${max} characters`;
  };
}

export function stringMin({ fieldName, min }) {
  return (value) => {
    if (!isEmpty(value) && value.length >= min) {
      return false;
    }
    return `Field ${fieldName} must have at least ${min} characters`;
  };
}

export function stringMax({ fieldName, max }) {
  return (value) => {
    if (!isEmpty(value) && value.length <= max) {
      return false;
    }
    return `Field ${fieldName} must have a maximum if ${max} characters`;
  };
}

export function validateDescription(values) {
  const { name, description, category } = values;
  const errors = [];
  const nameValid = name?.length > VALIDATION.NAME;
  const descriptionValid = description?.length > VALIDATION.DESCRIPTION;
  if (!nameValid) errors.push(`Please check Name need to have more ${VALIDATION.NAME} characters`);
  if (!descriptionValid)
    errors.push(`Please check Description need to have more ${VALIDATION.DESCRIPTION} characters`);
  if (!category || category === 'Select category') errors.push('Please select category');

  return errors.length ? { status: false, errors } : { status: true };
}

export function validateDates(values) {
  const { startDate, endDate, startTime, endTime } = values;
  const errors = [];

  if (!(isDate(startDate) || isDate(startTime))) {
    errors.push('Please set the start date of the experience');
  }

  if (!(isDate(endDate) || isDate(endTime))) {
    errors.push('Please set the end date of the experience');
  }

  if (!errors.length) {
    const formattedStartDate = moment(startTime)
      .set({
        year: startDate.getFullYear(),
        month: startDate.getMonth(),
        date: startDate.getDate(),
      })
      .format();

    const formattedEndDate = moment(endTime)
      .set({
        year: endDate.getFullYear(),
        month: endDate.getMonth(),
        date: endDate.getDate(),
      })
      .format();

    if (moment(formattedStartDate).isAfter(formattedEndDate)) {
      errors.push('You’ve incorrectly entered your start and end date.');
    }
  }
  return errors.length ? { status: false, errors } : { status: true };
}

export function validateTickets(values) {
  const { tickets } = values;
  const errors = [];

  if (isEmpty(tickets)) errors.push('Please the experience must have 1 Ticket or more');

  map(tickets, (x) => {
    if (
      !x.description ||
      x.description === ' ' ||
      x.description === '' ||
      (x.description && x.description.length <= VALIDATION.DESCRIPTION)
    ) {
      errors.push(
        `Please check description in the ticket, must have ${VALIDATION.DESCRIPTION} characters or more`
      );
    }
    if (!x.total || x.total <= 0 || x.total === ' ' || parseInt(x.total, 10) <= 0) {
      errors.push('Please check quantity in the ticket, must to be > 0');
    }
    if (x.price === undefined || x.price === '' || x.price < 0) {
      errors.push('Please check price in the ticket, must to be 0 or more but not empity');
    }
  });

  return errors.length ? { status: false, errors } : { status: true };
}

export function validateLocation(values) {
  const { type, location } = values;
  const errors = [];
  if (!location || isEmpty(location))
    return { status: false, errors: ['Please add a Plataform and URL'] };

  if (type === EXPERIENCES_TYPES.VIRTUAL) {
    const { platform, url } = values.location;
    if (!platform || platform === 'Platform' || platform.length <= VALIDATION.PLATFORM)
      errors.push('Please choose a Plataform');
    if (!url || url.length <= VALIDATION.URL) errors.push('Please add a URL');
    if (validUrl(url)) errors.push(validUrl(url));
  }

  if (type === EXPERIENCES_TYPES.PHYSICAL) {
    const { space, name, address, city, state, zip } = values.location;
    if (!name || name.length <= VALIDATION.NAME) errors.push('Please add a name of location ');
    if (!space || space === 'Type of location' || space.length <= VALIDATION.DESCRIPTION)
      errors.push('Please choose a type of location');
    if (!address || address.length <= VALIDATION.ADDRESS) errors.push('Please add a address');
    if (!city || city.length <= VALIDATION.CITY) errors.push('Please add a city');
    if (!state || state.length <= VALIDATION.STATE) errors.push('Please add a state');
    if (!zip || zip.length <= VALIDATION.ZIP) errors.push('Please add a Zip code');
  }

  return errors.length ? { status: false, errors } : { status: true };
}

export function validateArtists(values) {
  const { artists } = values;
  const errors = [];

  if (isEmpty(artists)) errors.push('Please must have 1 Artist or more');

  map(artists, (x) => {
    if (!x.name || x.name.length <= VALIDATION.NAME) errors.push('Please add a name of Artist');
    if (!x.bio || x.bio.length <= VALIDATION.DESCRIPTION)
      errors.push(`Please add a Bio min ${VALIDATION.DESCRIPTION} characters`);
    if (!x.quote || x.quote.length <= VALIDATION.QUOTE)
      errors.push(`Please add a quote min ${VALIDATION.QUOTE} characters`);
    if (isEmpty(x.images)) errors.push('Please add a Image');
  });

  return errors.length ? { status: false, errors } : { status: true };
}

export function validateFoodAndDrinks(values) {
  const { drinks, food } = values;
  const errors = [];
  if (isEmpty(food)) errors.push('Please add none or the Food');
  if (isEmpty(drinks)) errors.push('Please add none or the Drinks');

  return errors.length ? { status: false, errors } : { status: true };
}

export function validatePhotos(values) {
  const { images } = values;
  const errors = [];
  const deleted = [];
  const preview = [];
  if (isEmpty(images)) errors.push('Please must have 1 Photo or more');

  map(images, (x) => {
    if (x.preview) preview.push('preview');
    if (x.delete) deleted.push('delete');
  });
  if (preview.length <= 0 && images.length <= deleted.length) {
    errors.push('Please must have 1 Photo or more');
  }

  return errors.length ? { status: false, errors } : { status: true };
}
