import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'material-ui/Card';
import { FontIcon } from 'material-ui';

import icon1 from 'images/noun_Location_2872049.png';
import icon2 from 'images/shape.png';

const styles = {
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '497px',
    marginTop: '10px',
    cursor: 'pointer',
  },
  containerDisabled: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '497px',
    marginTop: '10px',
    cursor: 'pointer',
    opacity: 0.5,
  },
  subtitleStyle: {
    marginTop: '-10px',
    marginLeft: '5px',
    fontSize: '13px',
    fontFamily: 'CircularStd Book',
    fontWeight: '200',
    lineheight: '31px',
  },
  subtitleDisabled: {
    marginTop: '-10px',
    marginLeft: '3px',
    color: 'red',
    fontSize: '11px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '17px',
    fontWeight: '200',
    lineHeight: '31px',
    fontFamily: 'CircularStd Book',
    border: 'none !important',
  },
  icon2: {
    float: 'left',
    margin: '10px',
    marginLeft: '10px',
  },
  icon1: {
    float: 'left',
    margin: '10px',
    marginLeft: '5px',
  },
  arrowIcon: {
    fontSize: 20,
    float: 'right',
    paddingTop: '15px',
  },
};

const CardSelector = ({ title, subtitle, handlerOnClick = null, disabled = false }) => {
  if (disabled) {
    return (
      <div style={styles.containerDisabled}>
        <Card className="cardSelector">
          <CardHeader
            title={title}
            subtitle={subtitle}
            subtitleStyle={styles.subtitleDisabled}
            titleStyle={styles.titleStyle}
            style={{ height: '82px' }}
          >
            <img alt="" src={icon1} width="26px" style={styles.icon1} />
            <FontIcon className="material-icons" color="#999999" style={styles.arrowIcon}>
              keyboard_arrow_right
            </FontIcon>
          </CardHeader>
        </Card>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <Card onClick={handlerOnClick} className="cardSelector">
        <CardHeader
          title={title}
          subtitle={subtitle}
          subtitleStyle={styles.subtitleStyle}
          titleStyle={styles.titleStyle}
          style={{ height: '82px' }}
        >
          <img alt="" src={icon2} width="16px" style={styles.icon2} />
          <FontIcon className="material-icons" color="#999999" style={styles.arrowIcon}>
            keyboard_arrow_right
          </FontIcon>
        </CardHeader>
      </Card>
    </div>
  );
};

CardSelector.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  handlerOnClick: PropTypes.func,
  disabled: PropTypes,
};

export default CardSelector;
