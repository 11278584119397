import React, { useCallback, useEffect, useState } from 'react';
import FestivalCheckout from './FestivalCheckout';
import Payment from './Payment';
import CheckoutGuidelines from './CheckoutGuidelines';
import CheckoutSchedule from './CheckoutSchedule';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentChannel, fetchChannels } from 'actions/channels';
import { selectCurrentChannel, selectChannels } from 'selectors/channels.js';
import { selectUser } from 'selectors/user';
import useFestivalInformation from 'hooks/useFestivalInformation';
import { CircularProgress } from 'material-ui';

const Checkout = (props) => {
  const { channels, fetchChannels, setCurrentChannel, params, location } = props;
  const [step, setStep] = useState('checkout');
  const { isLoading, data } = useFestivalInformation(`${params.channel}/${params.id}`);
  const [payment, setPayment] = useState({});
  const [referral, setReferral] = useState(location?.state?.referral);
  const paymentNextStep = useCallback(
    () => (payment.total && payment.total > 0 ? setStep('guidelines') : setStep('todo')),
    [payment, setStep]
  );

  const [selectDays, setSelectDays] = useState([]);

  useEffect(() => window.scrollTo(0, 0), []);

  const resetCheckout = useCallback(
    (referral) => {
      setReferral(referral);
      setStep('checkout');
    },
    [setStep, setReferral]
  );

  useEffect(() => {
    async function fetchData() {
      if (!channels.length) {
        await fetchChannels();
      }
      setCurrentChannel(params.channel);
    }
    fetchData();
  }, [setCurrentChannel, params, channels, fetchChannels]);

  if (isLoading) {
    return <CircularProgress />;
  }

  switch (step) {
    case 'checkout':
      return (
        <FestivalCheckout
          {...props}
          nextStep={(paymentIntent) =>
            paymentIntent.total && paymentIntent.total > 0
              ? setStep('payment')
              : setStep('guidelines')
          }
          setPayment={setPayment}
          selectDays={selectDays}
          setSelectDays={setSelectDays}
          referral={referral || undefined}
        />
      );
    case 'payment':
      return <Payment {...props} nextStep={() => paymentNextStep()} payment={payment} />;
    case 'guidelines':
      return (
        <CheckoutGuidelines
          {...props}
          nextStep={() => {
            if (data?.experiences?.experiences?.length) {
              setStep('schedule');
            } else {
              browserHistory.push('/tickets');
            }
          }}
        />
      );
    case 'schedule':
      return (
        <CheckoutSchedule
          {...props}
          resetCheckout={resetCheckout}
          nextStep={() => browserHistory.push('/tickets')}
          selectDays={selectDays}
          referral={referral}
        />
      );
    default:
      return (
        <FestivalCheckout
          {...props}
          nextStep={() => setStep('payment')}
          setPayment={setPayment}
          selectDays={selectDays}
          setSelectDays={setSelectDays}
          referral={referral || undefined}
        />
      );
  }
};

const mapStateToProps = (state) => ({
  channels: selectChannels(state),
  currentChannel: selectCurrentChannel(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentChannel, fetchChannels }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
