import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from 'images/icon-logo-blue.svg';

const DefaultHelmet = () => (
  <Helmet>
    <title>PRESENCE | For producers of experiences</title>
    <link rel="icon" href={favicon} />
  </Helmet>
);

export default DefaultHelmet;
