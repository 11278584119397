import React, { useState, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { USER_TYPES } from 'appconstants';
import { getRevShare, postRevShare } from 'actions/channels';
import { selectUser } from 'selectors/user';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import PayoutsList from 'components/PayoutsList';
import ConfirmDialog from './ConfirmDialog';

const styles = {
  card: {
    boxSizing: 'border-box',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '900px',
    padding: '30px',
    textAlign: 'left',
    width: '100%',
  },
};

const SplitSettings = ({
  channelPayouts,
  channelRevShare,
  channelRevShareError,
  currentChannel,
  savingRevShare,
  getRevShare,
  loading,
  postRevShare,
  user,
}) => {
  const [channelTakes, setChannelTakes] = useState('100%');
  const [creatorGets, setCreatorGets] = useState('0%');
  const [channel, setChannel] = useState(null);
  const [channelTakesError, setChannelTakesError] = useState(false);
  const [creatorGetsError, setCreatorGetsError] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);

  useEffect(() => {
    if (currentChannel?.urlSlug) {
      setChannel(currentChannel.urlSlug);
    }
  }, [currentChannel, setChannel]);

  useEffect(() => {
    if (channel) {
      getRevShare(channel);
    }
  }, [channel, getRevShare]);

  useEffect(() => {
    if (!loading) {
      const channelTakes = channelRevShare * 100;
      const creatorGets = 100 - channelTakes;

      setChannelTakes(`${channelTakes}%`);
      setCreatorGets(`${creatorGets}%`);
    }
  }, [setChannelTakes, setCreatorGets, loading, channelRevShare]);

  const getPercentageValue = (value) => {
    const percentageRegex = /^(\d{1,3})%?$/;
    const validValue = percentageRegex.exec(value);
    return validValue && validValue[1];
  };

  const setValue = useCallback(
    (setValue, setOtherValue, setError) => ({ target: { value } }) => {
      const validValue = getPercentageValue(value);

      if (validValue) {
        setValue(`${validValue}%`);
        setOtherValue(`${100 - validValue}%`);
      } else {
        setError(true);
      }
    },
    []
  );

  const saveRevShare = useCallback(() => {
    const channelValue = getPercentageValue(channelTakes);

    if (channelValue) {
      postRevShare(channel, channelValue / 100).then(() => {
        setShowPromptModal(false);
      });
    }
  }, [channelTakes, setShowPromptModal, channel, postRevShare]);

  return (
    <div className="container-settings">
      <ConfirmDialog
        isOpen={showPromptModal}
        onClose={() => setShowPromptModal(false)}
        onAccept={saveRevShare}
        loading={savingRevShare}
      />
      {user.maxRole !== USER_TYPES.CREATOR && (
        <StyledCard style={styles.card}>
          <Title>Revenue share</Title>
          <Form>
            <FormValue>
              <Label>Channel takes</Label>
              <Input
                onBlur={setValue(setChannelTakes, setCreatorGets, setChannelTakesError)}
                onChange={({ target: { value } }) => {
                  setChannelTakes(value);
                  setChannelTakesError(false);
                }}
                value={channelTakes}
                error={channelTakesError}
              />
            </FormValue>
            <FormValue>
              <Label>Creator gets</Label>
              <Input
                onBlur={setValue(setCreatorGets, setChannelTakes, setCreatorGetsError)}
                onChange={({ target: { value } }) => {
                  setCreatorGets(value);
                  setCreatorGetsError(false);
                }}
                value={creatorGets}
                error={creatorGetsError}
              />
            </FormValue>
            <StyledButton
              disabled={creatorGetsError || channelTakesError}
              onClick={() => setShowPromptModal(true)}
            >
              Save
            </StyledButton>
          </Form>
        </StyledCard>
      )}
      <StyledCard style={styles.card}>
        <Title>Payouts</Title>
        <PayoutsList payouts={channelPayouts} loading={loading} />
      </StyledCard>
    </div>
  );
};

const FormValue = styled.div`
  display: flex;
  flex-direction: column;
  width: 41%;
`;

const Input = styled.input`
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 2px solid transparent;
  box-sizing: border-box;
  color: #4d4d4d;
  font-family: CircularStd Book;
  font-size: 18px;
  height: 48px;
  line-height: 23px;
  width: 100%;
  padding: 0 13px;
  ${({ error }) => error && 'border-color: red;'}
  transition: border-color .25s;

  &:hover,
  &:focus {
    border-color: #9b63f8;
    outline: 0;
  }
`;

const Form = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const StyledCard = styled(Card)`
  & + & {
    margin-top: 10px;
  }
`;

const Label = styled.span`
  margin-bottom: 10px;
  font-family: CircularStd Book;
  font-size: 16px;
  color: #999999;
`;

// const Select = styled.select`
//   background-color: #F3F3F4;
//   border-color: #F3F3F4;
//   border-radius: 8px;
//   border: 2px solid transparent;
//   box-sizing: border-box;
//   color: #4D4D4D;
//   font-family: "CircularStd Book";
//   font-size: 18px;
//   font-weight: 300;
//   height: 47px;
//   letter-spacing: 0;
//   line-height: 27px;
//   width: 100%;
//   transition: border-color .25s;

//   &:hover, &:focus {
//     border-color: #9b63f8;
//     outline: 0;
//   }
// `;

const StyledButton = styled(Button)`
  && {
    background-color: #44af69;
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    font-family: CircularStd Book;
    font-size: 16px;
    font-weight: normal;
    height: 48px;
    text-transform: none;
    width: 124px;

    &:hover {
      background-color: #2d8f3f;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.5);
      cursor: not-allowed;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

const Title = styled.h1`
  font-family: CircularStd Book;
  font-weight: normal;
  font-size: 20px;
  color: #000000;
`;

const mapStateToProps = (state) => ({
  user: selectUser(state),
  channelRevShare: state.channels.channelRevShare,
  currentChannel: state.channels.currentChannel,
  channelPayouts: state.channels.channelPayouts,
  loading: state.channels.loadingRevShare,
  channelRevShareError: state.channels.channelRevShareError,
  savingRevShare: state.channels.savingRevShare,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRevShare,
      postRevShare,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SplitSettings);
