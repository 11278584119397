import React from 'react';
import { array } from 'prop-types';
import { CircularProgress } from 'material-ui';
import { find } from 'lodash';

import Avatar from 'components/Avatar';

import { formatCurrency, formatDiscount, getResizedImageURL } from 'utils/helpers';
import { IMAGE_SIZES, TICKET_ABSTRACT_LENGTH } from 'appconstants';

import './styles.css';

const formatTickets = (tickets) => {
  const description = tickets[0].description;
  const abstract = description.substring(0, TICKET_ABSTRACT_LENGTH);
  const others = tickets.length - 1;
  const longAbstract = description.length > TICKET_ABSTRACT_LENGTH;

  return {
    abstract: `${abstract}${longAbstract ? '...' : ''}`, // A very long ticket descr...
    others: !!others && ` + ${others} other${others > 1 ? 's' : ''}`, // + 6 others
  };
};

const GuestListRsvp = ({ items }) => {
  if (!items) {
    return (
      <div className="spinner">
        <CircularProgress size={90} />
      </div>
    );
  }

  if (!items.length) {
    return (
      <div className="tac">
        <span>No one's purchased tickets as yet. Get started with promotion </span>
        <a href="https://docs.google.com/document/d/1nLUUoKNrcceOL_gDkAAf13KbEc1vDa0Ky5WkfoT-X00/edit?usp=sharing">
          here
        </a>
      </div>
    );
  }

  const disocuntsUsed = find(items, (i) => i.discount);
  return (
    <div>
      <div className="content">
        <table>
          <thead>
            <tr>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>QTY</th>
              <th>TICKETS</th>
              {disocuntsUsed && <th> DISCOUNT</th>}
              <th>TOTAL PAID</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              const { id, name, email, tickets, total, photo, quantity, discount } = item;
              const image = photo && getResizedImageURL(photo, IMAGE_SIZES.SMALL);
              const { abstract, others } = formatTickets(tickets);
              return (
                <tr key={`${id}`}>
                  <td>
                    <div className="avatar">
                      <Avatar email={email} image={image} size={28} isSquare />
                    </div>
                    {name}
                  </td>
                  <td>{email}</td>
                  <td>{quantity}</td>
                  <td>
                    <span>{abstract}</span>
                    {others && (
                      <div className="others">
                        {others}
                        <div className="tooltip">
                          {tickets.map((t) => (
                            <span>{`${t.quantity}x ${t.description}`} </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </td>
                  {disocuntsUsed && (
                    <td>
                      {discount && (
                        <div className="discount-code">
                          {discount.code}
                          <span className="discount-value"> / {formatDiscount(discount)}</span>
                        </div>
                      )}
                    </td>
                  )}
                  <td className="total">{formatCurrency({ value: total })}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

GuestListRsvp.propTypes = {
  items: array.isRequired,
};

export default GuestListRsvp;
