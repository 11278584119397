import { COMPONENT_PAGES } from 'appconstants';
import {
  AddArtistsPage,
  AddGuidelinesPage,
  ConfirmPage,
  DateAndTimePage,
  DescriptionPage,
  LocationPage,
  PresentationPage,
  TicketingPage,
  VirtualLocalizationPage,
  ConfirmPageVirtual,
} from 'containers/ExperienceWizard';

export const pagesVirtual = [
  {
    id: COMPONENT_PAGES.DESCRIPTION_PAGE,
    label: 'Description',
  },
  {
    id: COMPONENT_PAGES.DATE_AND_TIME_PAGE,
    label: 'Dates',
  },
  {
    id: COMPONENT_PAGES.TICKETS_PAGE,
    label: 'Tickets',
  },
  {
    id: COMPONENT_PAGES.VIRTUAL_LOCALIZATION_PAGE,
    label: 'Virtual-Venue',
  },
  {
    id: COMPONENT_PAGES.ADD_ARTISTS_PAGE,
    label: 'Featuring',
  },
  {
    id: COMPONENT_PAGES.ADD_GUIDELINE_PAGE,
    label: 'Guidelines',
  },
  {
    id: COMPONENT_PAGES.PRESENTATION_PAGE,
    label: 'Cover Photo',
  },
  {
    id: COMPONENT_PAGES.CONFIRM_VIRTUAL_PAGE,
    label: 'Publish',
  },
];

export const pagesIRL = [
  {
    id: COMPONENT_PAGES.DESCRIPTION_PAGE,
    label: 'Description',
  },
  {
    id: COMPONENT_PAGES.DATE_AND_TIME_PAGE,
    label: 'Dates',
  },
  {
    id: COMPONENT_PAGES.TICKETS_PAGE,
    label: 'Tickets',
  },
  {
    id: COMPONENT_PAGES.LOCALIZATION_PAGE,
    label: 'Venue',
  },
  {
    id: COMPONENT_PAGES.ADD_ARTISTS_PAGE,
    label: 'Featuring',
  },
  {
    id: COMPONENT_PAGES.ADD_GUIDELINE_PAGE,
    label: 'Guidelines',
  },
  {
    id: COMPONENT_PAGES.PRESENTATION_PAGE,
    label: 'Cover Photo',
  },
  {
    id: COMPONENT_PAGES.CONFIRM_VIRTUAL_PAGE,
    label: 'Publish',
  },
];

export const pagesComponents = [
  {
    title: 'Tell the story of your experience.',
    component: DescriptionPage,
    id: COMPONENT_PAGES.DESCRIPTION_PAGE,
  },
  {
    title: 'When it starts. When it ends.',
    component: DateAndTimePage,
    id: COMPONENT_PAGES.DATE_AND_TIME_PAGE,
  },
  {
    title: 'Your ticket information.',
    component: TicketingPage,
    id: COMPONENT_PAGES.TICKETS_PAGE,
  },
  {
    title: 'Where is  your experience hosted?',
    component: VirtualLocalizationPage,
    id: COMPONENT_PAGES.VIRTUAL_LOCALIZATION_PAGE,
  },
  {
    title: "Add the people you'd like to feature for this experience.",
    component: AddArtistsPage,
    id: COMPONENT_PAGES.ADD_ARTISTS_PAGE,
  },
  {
    title: 'Let your guests know how to be present.',
    component: AddGuidelinesPage,
    id: COMPONENT_PAGES.ADD_GUIDELINE_PAGE,
  },
  {
    title: 'Presentation matters! Make sure it looks beautiful.',
    component: PresentationPage,
    id: COMPONENT_PAGES.PRESENTATION_PAGE,
  },
  {
    title: "Make sure it's all good before you publish.",
    component: ConfirmPageVirtual,
    id: COMPONENT_PAGES.CONFIRM_VIRTUAL_PAGE,
  },
  {
    title: "Make sure it's all good before you publish.",
    component: ConfirmPage,
    id: COMPONENT_PAGES.CONFIRM_PAGE,
  },
  {
    title: 'Provide location.',
    component: LocationPage,
    id: COMPONENT_PAGES.LOCALIZATION_PAGE,
  },
];
