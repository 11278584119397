import React from 'react';
import { string, func, bool } from 'prop-types';
import styled from 'styled-components';

import SaveButton from '../SaveButton';
import Status from '../Status';

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 57px 0 30px;
`;

const Footer = ({
  status,
  lastSaved = '',
  onNextStep,
  saving = false,
  disabled = false,
  buttonLabel = 'Continue  »',
}) => (
  <FooterWrapper>
    <Status status={status} lastSaved={lastSaved} />
    <SaveButton onClick={onNextStep} disabled={saving || disabled}>
      {buttonLabel}
    </SaveButton>
  </FooterWrapper>
);

Footer.propTypes = {
  buttonLabel: string,
  lastSaved: string,
  onNextStep: func.isRequired,
  saving: bool,
  status: string.isRequired,
};

export default Footer;
