import { SubmissionError } from 'redux-form';
import ReactGA from 'react-ga';
import LogRocket from 'logrocket';
import { CALL_API, SIGN_OUT_FROM_API } from 'redux_store/middleware/api';
import { setAccessToken, removeAccessToken } from 'utils/helpers';
import {
  createCustomer,
  getCustomer,
  subscribeCustomerToPlan,
  changeSubscriptionPlan,
  processSinglePaymentCard,
} from './accounts';
import { rsvpExperience } from './experiences';
import {
  USER_NOTIFICATIONS,
  USER_NEW_PASSWORD,
  USER_REQUEST,
  LIKE_ACTIONS,
  FETCH_PROFILE,
  SUBMIT_RESET_PASSWORD,
  SUBMIT_FORGOT_PASSWORD,
  SUBMIT_LOGIN,
} from 'appconstants';

const LOADING_USER_START = 'LOADING_USER_START';
const LOADING_USER_FINISH = 'LOADING_USER_FINISH';
const GET_USER_REQUEST = 'GET_USER_REQUEST';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_FAILURE = 'GET_USER_FAILURE';
const SUBMIT_INVITE_LINK_REQUEST = 'SUBMIT_INVITE_LINK_REQUEST';
const SUBMIT_INVITE_LINK_SUCCESS = 'SUBMIT_INVITE_LINK_SUCCESS';
const SUBMIT_INVITE_LINK_FAILURE = 'SUBMIT_INVITE_LINK_FAILURE';
const GET_INVITE_LINK_DATA_REQUEST = 'GET_INVITE_LINK_DATA_REQUEST';
const GET_INVITE_LINK_DATA_SUCCESS = 'GET_INVITE_LINK_DATA_SUCCESS';
const GET_INVITE_LINK_DATA_FAILURE = 'GET_INVITE_LINK_DATA_FAILURE';
const OPENED_EXPERIENCE_REQUEST = 'OPENED_EXPERIENCE_REQUEST';
const OPENED_EXPERIENCE_SUCCESS = 'OPENED_EXPERIENCE_SUCCESS';
const OPENED_EXPERIENCE_FAILURE = 'OPENED_EXPERIENCE_FAILURE';

function updateProfile(data) {
  // DELETE INITIAL VALUES THAT ARE NOT ACCEPTABLE FOR CREATE PROFILE SINCE ROUTE DOESN'T ACCEPT ANYTHING ELSE:
  // THIS IS HACKY AND SHOULD BE CHANGED LATER
  const trimmedData = {
    about: data.about,
    country: data.country,
    facebook: data.facebook,
    instagram: data.instagram,
    name: data.name,
    phone: data.phone,
    photo: data.photo,
    profession: data.profession,
    state: data.state,
  };
  const method = 'put';
  const endpoint = '/users';
  return {
    [CALL_API]: {
      types: [
        FETCH_PROFILE.SAVE_PROFILE_REQUEST,
        FETCH_PROFILE.SAVE_PROFILE_SUCCESS,
        FETCH_PROFILE.SAVE_PROFILE_FAILURE,
      ],
      method,
      endpoint,
      body: { ...trimmedData },
    },
  };
}

function loadingUserStart() {
  return {
    type: LOADING_USER_START,
  };
}

/* eslint no-underscore-dangle: ["error", { "allow": ["user", "_id"] }]*/
function initAnalytics(user) {
  if (user && user._id) {
    // eslint-disable-line
    ReactGA.initialize('UA-102288290-1', {
      gaOptions: {
        user: user._id, // eslint-disable-line
      },
    });
  }
}

function loadingUserFinish(response) {
  const { id, name, email, token } = response;
  if (token) {
    setAccessToken(token);
  }
  initAnalytics(response);

  LogRocket.identify(id, {
    name,
    email,
    env: process.env.NODE_ENV,
  });

  return {
    type: LOADING_USER_FINISH,
    payload: response,
  };
}

function postLikeExperience(id) {
  const endpoint = `/users/experiences/${id}/like`;
  const method = 'put';
  return {
    [CALL_API]: {
      types: [LIKE_ACTIONS.LIKE_REQUEST, LIKE_ACTIONS.LIKE_SUCCESS, LIKE_ACTIONS.LIKE_FAILURE],
      method,
      endpoint,
    },
  };
}

function postUnlikeExperience(id) {
  const endpoint = `/users/experiences/${id}/unlike`;
  const method = 'put';
  return {
    [CALL_API]: {
      types: [
        LIKE_ACTIONS.UNLIKE_REQUEST,
        LIKE_ACTIONS.UNLIKE_SUCCESS,
        LIKE_ACTIONS.UNLIKE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function postOpenExperience(id) {
  const endpoint = `/users/experiences/${id}/opened`;
  const method = 'put';
  return {
    [CALL_API]: {
      types: [OPENED_EXPERIENCE_REQUEST, OPENED_EXPERIENCE_SUCCESS, OPENED_EXPERIENCE_FAILURE],
      method,
      endpoint,
    },
  };
}

function getUserById(id) {
  const method = 'get';
  const endpoint = `/users/${id}`;

  return {
    [CALL_API]: {
      types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
      method,
      endpoint,
    },
  };
}

function updateUserData() {
  const method = 'get';
  const endpoint = `/users/`;

  return {
    [CALL_API]: {
      types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
      method,
      endpoint,
    },
  };
}

function postUser(data) {
  const { id } = data;
  const method = id ? 'put' : 'post';
  const endpoint = id ? `/users/${id}` : '/users';

  return {
    [CALL_API]: {
      types: [
        USER_REQUEST.SUBMIT_CREATEUSER_REQUEST,
        USER_REQUEST.SUBMIT_CREATEUSER_SUCCESS,
        USER_REQUEST.SUBMIT_CREATEUSER_FAILURE,
      ],
      method,
      endpoint,
      body: { ...data },
    },
  };
}

function putNotification(data) {
  const endpoint = '/users/notifications';
  const method = 'put';
  return {
    [CALL_API]: {
      types: [
        USER_NOTIFICATIONS.SUBMIT_NOTIFICATIONS_REQUEST,
        USER_NOTIFICATIONS.SUBMIT_NOTIFICATIONS_SUCCESS,
        USER_NOTIFICATIONS.SUBMIT_NOTIFICATIONS_FAILURE,
      ],
      method,
      endpoint,
      body: { ...data },
    },
  };
}

function putNewPassword(data) {
  const endpoint = '/users/change-password';
  const method = 'put';
  return {
    [CALL_API]: {
      types: [
        USER_NEW_PASSWORD.SUBMIT_NEW_PASSWORD_REQUEST,
        USER_NEW_PASSWORD.SUBMIT_NEW_PASSWORD_SUCCESS,
        USER_NEW_PASSWORD.SUBMIT_NEW_PASSWORD_FAILURE,
      ],
      method,
      endpoint,
      body: { ...data },
    },
  };
}

function postInviteLink(data) {
  const method = 'post';
  const endpoint = '/users/invite';

  return {
    [CALL_API]: {
      types: [SUBMIT_INVITE_LINK_REQUEST, SUBMIT_INVITE_LINK_SUCCESS, SUBMIT_INVITE_LINK_FAILURE],
      method,
      endpoint,
      body: { ...data },
    },
  };
}

function getInviteLinkData(token) {
  const method = 'get';
  const endpoint = '/users/invite';

  return {
    [CALL_API]: {
      types: [
        GET_INVITE_LINK_DATA_REQUEST,
        GET_INVITE_LINK_DATA_SUCCESS,
        GET_INVITE_LINK_DATA_FAILURE,
      ],
      method,
      endpoint,
      params: { token },
    },
  };
}

function postUserSubscription(data) {
  const { id } = data;
  const method = id ? 'put' : 'post';
  const endpoint = id ? `/users/${id}/subscribe` : '/users';

  return {
    [CALL_API]: {
      types: [
        USER_REQUEST.SUBMIT_CREATEUSER_REQUEST,
        USER_REQUEST.SUBMIT_CREATEUSER_SUCCESS,
        USER_REQUEST.SUBMIT_CREATEUSER_FAILURE,
      ],
      method,
      endpoint,
      body: { ...data },
    },
  };
}

function login(data) {
  return {
    [CALL_API]: {
      types: [
        SUBMIT_LOGIN.SUBMIT_LOGIN_REQUEST,
        SUBMIT_LOGIN.SUBMIT_LOGIN_SUCCESS,
        SUBMIT_LOGIN.SUBMIT_LOGIN_FAILURE,
      ],
      method: 'post',
      endpoint: '/users/login',
      body: { ...data },
    },
  };
}

function forgotPassword(data) {
  return {
    [CALL_API]: {
      types: [
        SUBMIT_FORGOT_PASSWORD.SUBMIT_FORGOT_PASSWORD_REQUEST,
        SUBMIT_FORGOT_PASSWORD.SUBMIT_FORGOT_PASSWORD_SUCCESS,
        SUBMIT_FORGOT_PASSWORD.SUBMIT_FORGOT_PASSWORD_FAILURE,
      ],
      method: 'post',
      endpoint: '/users/forgot-password',
      body: { ...data },
    },
  };
}

function resetPassword(data, token) {
  return {
    [CALL_API]: {
      types: [
        SUBMIT_RESET_PASSWORD.SUBMIT_RESET_PASSWORD_REQUEST,
        SUBMIT_RESET_PASSWORD.SUBMIT_RESET_PASSWORD_SUCCESS,
        SUBMIT_RESET_PASSWORD.SUBMIT_RESET_PASSWORD_FAILURE,
      ],
      method: 'post',
      endpoint: `/users/reset-password`,
      body: { ...data, token },
    },
  };
}

function logoutUser() {
  removeAccessToken();
  return {
    type: USER_REQUEST.LOGOUT,
  };
}

export function saveProfileAction(payload) {
  return async (dispatch) => {
    return dispatch(updateProfile(payload));
  };
}

export function saveNotifications(data) {
  return (dispatch) => {
    return dispatch(putNotification(data));
  };
}

export function setNewPassword(data) {
  return (dispatch) => {
    return dispatch(putNewPassword(data));
  };
}

export const signOut = () => ({
  [SIGN_OUT_FROM_API]: {},
});

export function likeExperience(id) {
  return (dispatch) => {
    return dispatch(postLikeExperience(id));
  };
}

export function unlikeExperience(id) {
  return (dispatch) => {
    return dispatch(postUnlikeExperience(id));
  };
}

export function openExperience(id) {
  return (dispatch) => {
    return dispatch(postOpenExperience(id));
  };
}

/**
 *
 * @param {*} user
 */
export function loadUser(user) {
  return (dispatch) => {
    dispatch(loadingUserStart());
    const res = {
      ...user,
    };
    return dispatch(loadingUserFinish(res));
  };
}

export function loadDataFromInviteToken(token) {
  return async (dispatch) => {
    try {
      const data = await dispatch(getInviteLinkData(token));
      return data;
    } catch (e) {
      throw e;
    }
  };
}

export function saveUser(data) {
  return async (dispatch) => {
    const createdUser = await dispatch(postUser(data));
    if (createdUser.stripeCustomerId) {
      const customer = await dispatch(getCustomer(createdUser.stripeCustomerId));
      const res = {
        ...createdUser,
        customer,
      };
      return dispatch(loadUser(res));
    }
    return dispatch(loadUser(createdUser.user));
  };
}

/**
 *
 * @param {*} data
 */
export function saveUserSubscription(data) {
  return (dispatch) => {
    return dispatch(postUserSubscription(data)).then((createdUser) => {
      if (createdUser.stripeCustomerId) {
        return dispatch(getCustomer(createdUser.stripeCustomerId)).then((customer) => {
          const res = {
            ...createdUser,
            customer,
          };
          return dispatch(loadUser(res));
        });
      }

      return dispatch(loadUser(createdUser.user));
    });
  };
}

/**
 *
 * @param {*} data
 */
export function saveAccountSettings(data) {
  return (dispatch) => {
    return dispatch(postUser(data)).then((createdUser) => {
      dispatch(getUserById(createdUser.id)).then((user) => {
        return dispatch(loadUser(user));
      });
    });
  };
}

export function updateUser() {
  return async (dispatch) => {
    const user = await dispatch(updateUserData());
    dispatch(loadUser(user));
    return user;
  };
}

export function sendCreatorInvite(data) {
  return async (dispatch) => {
    return dispatch(postInviteLink(data));
  };
}

/**
 *
 *
 * @export
 * @param {any} userId
 * @param {any} subscriptionId
 * @param {any} plan
 */
export function changeUserSubscription(userId, subscriptionId, plan) {
  return (dispatch) => {
    return dispatch(changeSubscriptionPlan(subscriptionId, plan)).then((user) => {
      if (user.stripeCustomerId) {
        return dispatch(getCustomer(user.stripeCustomerId)).then((customer) => {
          const res = {
            ...user,
            customer,
          };
          return dispatch(loadUser(res));
        });
      }
      return dispatch(loadUser(user.user));
    });
  };
}

/**
 *
 *
 * @export
 * @param {any} userId
 * @param {any} fullname
 * @param {any} email
 * @param {any} cardData
 * @param {any} plan
 * @returns
 */
export function subscribeUserToPlan(userId, fullname, email, cardData, plan) {
  let card = cardData;
  const expiration = card.date.split('/');
  const exp_month = parseInt(expiration[0], 10); // eslint-disable-line
  const exp_year = parseInt(expiration[1], 10); // eslint-disable-line
  const cvc = card.cvc;
  const number = card.number.replace('_', '');
  card = {
    object: 'card',
    number,
    exp_month,
    exp_year,
    cvc,
  };

  return (dispatch) => {
    return dispatch(
      createCustomer({
        description: fullname,
        email,
        source: card,
      })
    ).then((newCustomer) => {
      if (newCustomer.error) {
        throw new SubmissionError({
          fullName: newCustomer.error.message,
          _error: newCustomer.error.message,
        });
      }

      return dispatch(subscribeCustomerToPlan(newCustomer.id, plan)).then(() => {
        return dispatch(
          saveUserSubscription({
            id: userId,
            subscription: plan,
            stripeCustomerId: newCustomer.id,
          })
        );
      });
    });
  };
}

/**
 *
 *
 * @export
 * @param {any} value
 * @param {any} cardData
 * @param {any} email
 */
export function processUserSinglePaymentCard(value, cardData, email, experience, cart, discount) {
  let card = cardData;
  if (card) {
    const expiration = card.date.split('/');
    const exp_month = parseInt(expiration[0], 10); // eslint-disable-line
    const exp_year = parseInt(expiration[1], 10); // eslint-disable-line
    const cvc = card.cvc;
    const number = card.number.replace('_', '');
    card = {
      name: card.fullName,
      object: 'card',
      number,
      exp_month,
      exp_year,
      cvc,
    };
  }
  return (dispatch) => {
    return dispatch(processSinglePaymentCard(value, card, email)).then((result) => {
      if (result.type === 'StripeCardError') {
        throw new SubmissionError({ fullName: 'Payment Failed', _error: result.message });
      }
      return dispatch(rsvpExperience(experience.id, cart, discount));
    });
  };
}

/**
 *
 * @param {*} data
 */
export function submitLogin(data) {
  return (dispatch) => {
    return dispatch(login(data)).then((result) => {
      if (process.env.REACT_APP_LOGROCKET) {
        LogRocket.startNewSession();
      }
      return dispatch(loadUser(result));
    });
  };
}

/**
 *
 *
 * @export
 * @param {any} token
 * @param {any} id
 * @returns
 */
export function submitLoginWithTokenId(token, id) {
  setAccessToken(token);
  return (dispatch) => {
    return dispatch(getUserById(id)).then((result) => {
      if (process.env.REACT_APP_LOGROCKET) {
        LogRocket.startNewSession();
      }
      return dispatch(getCustomer(result.stripeCustomerId)).then((customer) => {
        const res = {
          ...result,
          customer,
        };
        return dispatch(loadUser(res));
      });
    });
  };
}

/**
 *
 * @param {*} data
 */
export function submitForgotPassword(data) {
  return (dispatch) => {
    return dispatch(forgotPassword(data));
  };
}

/**
 *
 * @param {*} data
 */
export function submitResetPassword(data, token) {
  return (dispatch) => {
    return dispatch(resetPassword(data, token));
  };
}

export function logout() {
  return (dispatch) => {
    return dispatch(logoutUser());
  };
}
