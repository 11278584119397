import PropTypes from 'prop-types';
import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { FILE_UPLOADER_TYPES } from 'appconstants';
import FileUploader from 'components/FileUploader';
import Loading from 'components/Loading';
import addArtistImage from 'images/add-contacts.svg';

import FormFieldShowingError from 'components/common/FormFieldShowingError';
import TextAreaShowingError from 'components/common/TextAreaShowingError';
import RemoveIcon from 'images/close.png';
import styled from 'styled-components';

const renderLinks = (
  { fields } // eslint-disable-line
) => (
  <StyledSection>
    {fields.map((item, index) => {
      return (
        <div key={index}>
          {fields.length > 1 && index > 0 && (
            <Field
              name={`${item}.url`}
              type="text"
              placeholder="Add a Twitter, Instagram or Facebook link"
              component="input"
            />
          )}
        </div>
      );
    })}
  </StyledSection>
);

const renderArtists = ({ fields }) => (
  <div>
    {fields.map((item, index) => {
      return (
        <div className="artist-fields" key={index}>
          <Field name={`${item}.id`} component="input" type="hidden" />
          <div className="containerInput">
            <Field
              name={`${item}.name`}
              className="simple-input feactirung-input-style"
              placeholder="Name"
              maxLength={32}
              component={FormFieldShowingError}
              type="text"
            />
            <Field
              name={`${item}.bio`}
              className="aboutTextarea feactirung-input-style"
              placeholder="Bio"
              component={TextAreaShowingError}
            />
            <Field
              name={`${item}.quote`}
              className="simple-input feactirung-input-style"
              placeholder="Quote"
              maxLength={64}
              component={FormFieldShowingError}
              type="text"
            />
            <Field
              name={`${item}.social[0].url`}
              className="simple-input feactirung-input-style"
              placeholder="Add a Twitter, Instagram or Facebook link"
              component={FormFieldShowingError}
              type="text"
            />
          </div>
          <FieldArray name={`${item}.social`} component={renderLinks} />
          <div className="upload-file">
            <Field
              name={`${item}.images`}
              uploaderType={FILE_UPLOADER_TYPES.INLINE}
              component={FileUploader}
              props={{ minWidth: 800, minHeight: 800 }}
              type="select-multiple"
            />
          </div>
          <button
            onClick={() => {
              fields.remove(index);
            }}
            // href="javascript:void(0);"
            className="remove-artist"
          >
            <img alt="Remove" width="19px" src={RemoveIcon} />
          </button>
        </div>
      );
    })}
    <button
      onClick={() => {
        fields.push({});
      }}
      // href="javascript:void(0);"
      className="add-field-wrapper"
    >
      <img alt="add artist" src={addArtistImage} />
      ADD ARTIST / FACILITATOR / HOST / CREATOR
    </button>
  </div>
);

const StyledSection = styled.section`
  display: flex;
  flex-grow: 1;
`;

const AddArtistsPage = (props) => {
  const { loading } = props;
  return loading ? (
    <Loading message="Loading Featuring ..." />
  ) : (
    <div className="artist-wrapper experience-wrapper">
      <FieldArray name="artists" component={renderArtists} />
    </div>
  );
};

renderArtists.propTypes = {
  fields: PropTypes.object,
};

AddArtistsPage.propTypes = {
  loading: PropTypes.func,
};

export default AddArtistsPage;
