import React from 'react';
import PropTypes from 'prop-types';

import StripeConnectButton from './StripeConnectButton';

const StripeDashboard = ({ url, actionNeeded }) => {
  return (
    <div>
      <div className="text-block">
        <h3 className="text card-text">
          {actionNeeded
            ? 'Your account is connected but not yet active to receive payments, there might be missing information required by stripe. Go to your dashboard to correct this'
            : 'Your stripe account is connected and ready to receive payments. Go to your stripe dashboard to see more information about your account'}
        </h3>
      </div>
      <div className="actions">
        <StripeConnectButton href={url} label="Go to Stripe Dashboard" newTab />
        <a
          href={process.env.REACT_APP_AIRTABLE_REFUND_REQUEST_URL}
          className="text skip"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request customer refund
        </a>
      </div>
    </div>
  );
};

StripeDashboard.propTypes = {
  settings: PropTypes.bool,
  url: PropTypes.string,
  actionNeeded: PropTypes.bool,
};

export default StripeDashboard;
