import React from 'react';
import ExperienceMobileCard from 'components/ExperienceMobileCard';
import ExperienceScheduleMobileCard from 'containers/ExperienceScheduleMobileCard';
import { isEmpty } from 'lodash';
import { MOBILE_MAX_WIDTH } from 'appconstants';
import styled from 'styled-components';

import { getFullDate } from 'utils/dates';

const ListItemMobile = ({ experience, isLastItem, lastStartTime, view }) => {
  const experienceDate = new Date(experience.startTime);

  if (view === 'tiled') {
    return (
      <ExperienceMobileCard
        key={experience._id}
        channel={experience.channel}
        title={experience.name}
        date={experience.startTime}
        imageUrl={!isEmpty(experience.images) && experience.images[0]}
        alias={experience.alias}
      />
    );
  }

  return (
    <div>
      {lastStartTime === '' ||
      experienceDate.toDateString() !== new Date(lastStartTime).toDateString() ? (
        <TitleContainer>
          <ScheduledContainer>
            {lastStartTime === '' ? <ScheduleTitle>Schedule</ScheduleTitle> : <ScheduleTitle />}
            <Dot />
          </ScheduledContainer>
          <DateContainer>{getFullDate(experienceDate)}</DateContainer>
        </TitleContainer>
      ) : null}
      <ExperienceScheduleMobileCard experience={experience} isLastItem={isLastItem} />
    </div>
  );
};

const TitleContainer = styled.div`
  height: 104px;
  padding: 0 16px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 142px auto;
  align-items: center;
`;

const ScheduledContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  position: relative;
  align-items: center;
`;

const ScheduleTitle = styled.div`
  height: 20px;
  width: 70px;
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
`;

const Dot = styled.div`
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 8px solid #f3f3f4;
  background-color: #999999;
  z-index: 14;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    border-left: 1px solid #ebebeb;
    top: 16px;
    z-index: 10;
    height: 64px;
    right: 26px;

    @media screen and ${MOBILE_MAX_WIDTH} {
      left: 50%;
      top: calc(100% + 8px);
    }
  }
`;

const DateContainer = styled.div`
  height: 20px;
  width: 100%;
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
`;

export default ListItemMobile;
