const validateDescriptionStep = (name, description, category) => {
  const missingFields = [];

  if (!(name && name.trim())) {
    missingFields.push('Name');
  }

  if (!(description && description.trim())) {
    missingFields.push('Description');
  }

  if (!category || (typeof category === 'object' && !category.id)) {
    missingFields.push('Category');
  }

  return missingFields;
};

export default validateDescriptionStep;
