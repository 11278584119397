import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { browserHistory } from 'react-router';
import { capitalize } from 'lodash';
import { getLogo } from 'utils/logos';
import { getResizedImageURL } from 'utils/helpers';
import { IMAGE_SIZES } from 'appconstants';
import ImageNotFound from 'images/no-image-box.png';
import { dateFormat } from 'utils/dates';

const ExperienceScheduleMobileCard = ({ experience, isLastItem }) => {
  const experienceDate = new Date(experience.startTime);
  const LogoIcon = getLogo(experience.location.platform);
  const imageURL = experience.images[0]
    ? getResizedImageURL(experience.images[0].url || experience.images[0], IMAGE_SIZES.THUMBNAIL)
    : ImageNotFound;

  const experienceURL =
    experience.isFestival || experience.festival
      ? `/in/${experience.channel.urlSlug}/f/${experience.alias}`
      : `/in/${experience.channel.urlSlug}/${experience.alias}`;

  return (
    <Container onClick={() => browserHistory.push(experienceURL)}>
      <PictureContainer>
        <Picture src={imageURL} alt="thumb" />
        <Circle isLastItem={isLastItem}>{LogoIcon}</Circle>
      </PictureContainer>
      <ContentContainer>
        <PlatformContainer>
          {`${capitalize(experience.location.platform)}`}
          <Slash> / </Slash>
          {dateFormat(
            { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' },
            experienceDate
          )}
        </PlatformContainer>
        <TitleContainer>{experience.name}</TitleContainer>
        {experience.artists?.length > 0 && (
          <SubtitleContainer>{`With ${experience.artists[0].name}`} </SubtitleContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 104px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
  display: grid;
  width: calc(100vw - 50px);
  grid-template-columns: 125px auto;
  padding: 0 16px;
  cursor: pointer;

  & + & {
    margin-top: 12px;
  }
`;

const PictureContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  position: relative;
  align-self: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  overflow: hidden;
`;

const PlatformContainer = styled.div`
  height: 22px;
  color: #626262;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleContainer = styled.div`
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubtitleContainer = styled.div`
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Picture = styled.img`
  height: 58px;
  width: 87px;
  object-fit: cover;
  border-radius: 4px;
`;

const Circle = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 25px;
  position: relative;
  width: 25px;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  ${({ isLastItem }) =>
    !isLastItem
      ? `
   &::before {
    content: '';
    position: absolute;
    border-left: 1px solid #EBEBEB;
    top: 24px;
    z-index: 10;
    height: 112px;
    right: 50%;
   }
   `
      : ''}
`;

const Slash = styled.span`
  color: #d5d5d5;
`;

ExperienceScheduleMobileCard.propTypes = {
  experience: PropTypes.object,
  isLastItem: PropTypes.bool,
};

export default ExperienceScheduleMobileCard;
