const SAVE_IMAGE_REQUEST = 'SAVE_IMAGE_REQUEST';
const SAVE_IMAGE_SUCCESS = 'SAVE_IMAGE_SUCCESS';
const SAVE_IMAGE_FAILURE = 'SAVE_IMAGE_FAILURE';
const LOAD_IMAGE_REQUEST = 'LOAD_IMAGE_REQUEST';
const LOAD_IMAGE_SUCCESS = 'LOAD_IMAGE_SUCCESS';
const LOAD_IMAGE_FAILURE = 'LOAD_IMAGE_FAILURE';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        image: action,
      };
    case SAVE_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LOAD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        image: action,
      };
    case LOAD_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
