import { CALL_API } from 'redux_store/middleware/api';
import { USERS_ACTIONS_TYPES } from 'appconstants';

function postUserRelive({ alias, channel }) {
  const endpoint = `/users/relive/${channel}/${alias}`;
  const method = 'post';
  return {
    [CALL_API]: {
      types: [
        USERS_ACTIONS_TYPES.USERS_RELIVE_REQUEST,
        USERS_ACTIONS_TYPES.USERS_RELIVE_SUCCESS,
        USERS_ACTIONS_TYPES.USERS_RELIVE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

export function getUserProfile({ id }) {
  const endpoint = `/users/${id}`;
  const method = 'get';

  const action = {
    [CALL_API]: {
      endpoint,
      method,
      types: [
        USERS_ACTIONS_TYPES.GET_PROFILE_REQUEST,
        USERS_ACTIONS_TYPES.GET_PROFILE_SUCCESS,
        USERS_ACTIONS_TYPES.GET_PROFILE_FAILURE,
      ],
    },
  };

  return (dispatch) => dispatch(action);
}

// eslint-disable-next-line import/prefer-default-export
export function saveUserRelive({ alias, channel }) {
  return (dispatch) => {
    return dispatch(postUserRelive({ alias, channel }));
  };
}
