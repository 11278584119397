const SAVE_IMAGE_ARTIST_REQUEST = 'SAVE_IMAGE_ARTIST_REQUEST';
const SAVE_IMAGE_ARTIST_SUCCESS = 'SAVE_IMAGE_ARTIST_SUCCESS';
const SAVE_IMAGE_ARTIST_FAILURE = 'SAVE_IMAGE_ARTIST_FAILURE';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_IMAGE_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_IMAGE_ARTIST_SUCCESS:
      return {
        ...state,
        loading: false,
        image: action,
      };
    case SAVE_IMAGE_ARTIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
