import useFestivalInformation from 'hooks/useFestivalInformation';
import React from 'react';
import styled from 'styled-components';
import CheckoutContent from './Desktop/CheckoutContent';
import CheckoutContentMob from './Mobile/CheckoutContent';
import CheckoutDescription from './Desktop/CheckoutDescription';
import CheckoutDescriptionMob from './Mobile/CheckoutDescription';
import PurpleCheck from 'images/purple-check.png';
import MediaQuery from 'react-responsive';
import CheckoutGuidelinesMob from './Mobile/CheckoutGuidelines';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_MAX_WIDTH } from 'appconstants';

const CheckoutGuidelines = ({ params, nextStep }) => {
  const { data } = useFestivalInformation(`${params.channel}/${params.id}`);
  const mobile = useMediaQuery(MOBILE_MAX_WIDTH);

  return data ? (
    <Container isMobile={mobile}>
      <ContentContainer isMobile={mobile}>
        <StyledMediaQuery query={MOBILE_MAX_WIDTH} isMobile={mobile}>
          <CheckoutContentMob data={data} />
          <MobileDescriptionWrapper>
            <CheckoutDescriptionMob descriptionColor={'#999999'} data={data} />
          </MobileDescriptionWrapper>
        </StyledMediaQuery>
        <StyledMediaQuery query="(min-width: 1224px)">
          <CheckoutContent data={data} />
          <CheckoutDescription descriptionColor={'#999999'} data={data} />
        </StyledMediaQuery>
      </ContentContainer>
      <GuidelineContainer>
        <MediaQuery query={MOBILE_MAX_WIDTH}>
          <CheckoutGuidelinesMob guidelines={data?.guidelines || []} />
        </MediaQuery>
        <MediaQuery query="(min-width: 1224px)">
          <GuidelineTitle>Guidelines</GuidelineTitle>
          <GuidelinesList>
            {data?.guidelines.map((guideline, index) => (
              <GuidelineItem key={index}>
                <CheckContainer>
                  <Check src={PurpleCheck} alt="check" />
                </CheckContainer>
                <GuidelineText>{guideline}</GuidelineText>
              </GuidelineItem>
            ))}
          </GuidelinesList>
        </MediaQuery>
      </GuidelineContainer>
      <ButtonContainer mobile={mobile}>
        <AgreeButton onClick={() => nextStep()}>I agree</AgreeButton>
      </ButtonContainer>
    </Container>
  ) : null;
};

const Container = styled.div`
  padding: 50px 0 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ isMobile }) => (isMobile ? 0 : 60)}px;
  padding-top: ${({ isMobile }) => (isMobile ? 0 : 50)}px;
`;

const GuidelineContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  max-width: 860px;
  padding: 0 30px;
  width: 100%;
`;

const GuidelinesList = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
`;

const StyledMediaQuery = styled(MediaQuery)`
  ${({ isMobile }) => (isMobile ? `width: 100%;` : '')}
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? 0 : 20)}px;
`;

const GuidelineItem = styled.div`
  align-items: center;
  border-bottom: 1px solid #cbcbcb;
  display: flex;
  gap: 20px;
  height: 60px;
  justify-content: center;
  max-width: 800px;
  padding-bottom: 15px;
  width: 100%;
`;

const GuidelineText = styled.div`
  color: #4d4d4d;
  flex: 1 1;
  font-family: 'Circular Std';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
  max-width: 750px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const Check = styled.img`
  height: 17px;
  width: 18px;
`;

const GuidelineTitle = styled.div`
  border-bottom: 1px solid #cbcbcb;
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  letter-spacing: 0.6px;
  line-height: 15px;
  max-width: 810px;
  width: 100%;
`;

const ContentContainer = styled.div`
  ${({ isMobile }) => (isMobile ? `width: 100%;` : '')}
  display: flex;
  gap: 20px;
`;

const CheckContainer = styled.div`
  height: 27px;
  width: 26px;
  border-radius: 4px;
  background-color: #9b63f8;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 800px;

  ${({ mobile }) =>
    mobile &&
    `
    position: fixed;
    width: 100%;
    max-width: unset;
    bottom: 0;
    padding: 10px 30px;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0 -7px 26px 0 rgba(0,0,0,0.09);
  `}
`;

const AgreeButton = styled.button`
  height: 56px;
  width: 178px;
  border-radius: 8px;
  background-color: #9b63f8;
  outline: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.24px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: 1224px) {
    border-radius: 0;
    height: 3.9rem;
    width: 100%;
    text-transform: none;
  }
`;

const MobileDescriptionWrapper = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding-left: 1rem;
  width: 100%;
`;

export default CheckoutGuidelines;
