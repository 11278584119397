import { CALL_API } from 'redux_store/middleware/api';
import { STRIPE_ACTIONS } from 'appconstants';

function getStripeLink() {
  const method = 'get';
  const endpoint = `/stripe/get-stripe-link`;
  return {
    [CALL_API]: {
      types: [
        STRIPE_ACTIONS.LINK_REQUEST,
        STRIPE_ACTIONS.LINK_SUCCESS,
        STRIPE_ACTIONS.LINK_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function authorizeOAuth({ code, state }) {
  const method = 'get';
  const endpoint = `/stripe/authorize-oauth?code=${code}&state=${state}`;
  return {
    [CALL_API]: {
      types: [
        STRIPE_ACTIONS.OAUTH_URL_REQUEST,
        STRIPE_ACTIONS.OAUTH_URL_SUCCESS,
        STRIPE_ACTIONS.OAUTH_URL_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

export function fetchStripeLink() {
  return async (dispatch) => {
    return dispatch(getStripeLink());
  };
}

export function fetchOAuthConfirmation({ code, state }) {
  return async (dispatch) => {
    return dispatch(authorizeOAuth({ code, state }));
  };
}
