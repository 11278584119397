import { createSelector } from 'reselect';
import { selectCurrentExperience } from './experiences';
import get from 'lodash/get';

export const loadingUserExperiences = (state) => state.experiences.loadingUserExperiences;

export const selectRSVPedExp = (state) =>
  get(state, 'experiences.users.experiences', []).filter((exp) => exp.rsvp);

export const selectRSVPedFest = (state) =>
  get(state, 'experiences.users.festivals', []).filter((fest) => fest.rsvp);

export const selectInterested = (state) =>
  get(state, 'item.experiences.users', []).filter((exp) => exp.interested);

export const selectRSVPedToExperience = createSelector(
  selectRSVPedExp,
  selectCurrentExperience,
  (rsvped, currentExperience) =>
    !!rsvped.find((exp) => currentExperience && currentExperience.id === exp.id)
);

export const selectInterestedInExperience = createSelector(
  selectInterested,
  selectCurrentExperience,
  (interested, currentExperience) =>
    !!interested.find((exp) => currentExperience && currentExperience.id === exp.id)
);
