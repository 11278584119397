import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from 'material-ui';
import { browserHistory } from 'react-router';

import TGExperiencesFilter from 'components/ExperiencesFilter';
import TGExperiencesList from 'components/ExperiencesList';
import * as actionsExperiences from 'actions/experiences';
import * as actionsAuth from 'actions/auth';

class ExperiencesPage extends React.Component {
  constructor(props) {
    super(props);
    this.goToCreateExperience = this.goToCreateExperience.bind(this);
    this.state = {
      creatingExp: false,
      filter: 'upcoming',
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  onSelectFilter = (filter) => {
    this.setState({ filter });
  };

  componentDidUpdate({ currentChannel: prevChannel }) {
    const { currentChannel, loadCmsExperiences } = this.props;

    if (currentChannel !== prevChannel) {
      loadCmsExperiences(currentChannel?.urlSlug);
    }
  }

  loadData() {
    const { loadCmsExperiences, currentChannel } = this.props;
    const urlSlug = currentChannel?.urlSlug;

    if (urlSlug) {
      loadCmsExperiences(urlSlug);
    }
  }

  goToCreateExperience() {
    browserHistory.push('/create-experience');
  }

  renderSpinner() {
    return (
      <div style={{ textAlign: 'center', width: '100%', paddingTop: '50px' }}>
        <CircularProgress size={90} />
      </div>
    );
  }

  render() {
    const { experiences, loading } = this.props;
    return (
      <div className="page-container page-container-flex clearfix">
        <div className="container">
          <form className="manage-experiences">
            <h3>My Experiences</h3>

            {!this.state.creatingExp && (
              <TGExperiencesFilter
                onSelectFilter={this.onSelectFilter}
                onSubmit={this.goToCreateExperience}
                selectedFilter={this.state.filter}
              />
            )}

            {!this.state.creatingExp && experiences && (
              <TGExperiencesList
                loading={loading}
                onCreateExperience={this.goToCreateExperience}
                // onSelectExperience={exp => window.open(`/experiences/${exp.id}?create=false`, '_self')}
                selectedFilter={this.state.filter}
              />
            )}

            {this.state.creatingExp && this.renderSpinner()}
          </form>
        </div>
      </div>
    );
  }
}

ExperiencesPage.propTypes = {
  loadCmsExperiences: PropTypes.func,
  loading: PropTypes.bool,
  experiences: PropTypes.object,
};

export default connect(
  (state) => ({
    experiences: state.experiences,
    currentChannel: state.channels.currentChannel,
    loading: state.experiences.loading,
  }),
  {
    ...actionsExperiences,
    ...actionsAuth,
  }
)(ExperiencesPage);
