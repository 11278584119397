/* eslint-disable import/prefer-default-export */
import { CALL_API } from 'redux_store/middleware/api';

const GET_EMBED_REQUEST = 'GET_EMBED_REQUEST';
const GET_EMBED_SUCCESS = 'GET_EMBED_SUCCESS';
const GET_EMBED_FAILURE = 'GET_EMBED_FAILURE';

function getReliveOEmbed({ channel, alias }) {
  const method = 'get';
  const endpoint = `/experiences/${channel}/${alias}/relive-embed`;

  return {
    [CALL_API]: {
      types: [GET_EMBED_REQUEST, GET_EMBED_SUCCESS, GET_EMBED_FAILURE],
      method,
      endpoint,
    },
  };
}

export function getReliveVideo({ alias, channel }) {
  return (dispatch) => {
    return dispatch(getReliveOEmbed({ alias, channel }));
  };
}
