import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const Loader = () => (
  <ProgressContainer>
    <CircularProgress />
  </ProgressContainer>
);

const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default Loader;
