import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaQuery from 'react-responsive';

import AppWrapper from 'components/AppWrapper';
// import AdminHeader from 'components/AdminHeader';
import AdminFooter from 'components/AdminFooter';
import ThumbExperiences from 'components/ThumbExperiences';
import { changeUserSubscription, subscribeUserToPlan } from 'actions/auth';
// import { NO_CHANNEL_MENU_PATHS, STATIC_HEADER_PATHS } from 'appconstants';
import { APP_THEMES } from 'appconstants/colors';
import { selectUser } from 'selectors/user';
import { selectFeaturedExperiences } from 'selectors/experiences';
import NavigationHeader from 'components/NavigationHeader';

class AppAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      updateDialogOpened: false,
    };
    this.renderUpgradeDialog = this.renderUpgradeDialog.bind(this);
  }

  renderUpgradeDialog() {
    this.setState({
      updateDialogOpened: true,
    });
  }

  render() {
    const {
      children,
      location,
      user,
      route: { theme },
      featured,
    } = this.props;
    let showPageFooter = true;
    let showThumbExperiences = true;
    // const hideChannelMenu = NO_CHANNEL_MENU_PATHS.includes(route && route.path);
    // const staticHeader = STATIC_HEADER_PATHS.includes(route && route.path);

    if (
      location.pathname === '/experiences' ||
      location.pathname.indexOf('/relive') >= 0 ||
      location.pathname.indexOf('/ticket/') >= 0 ||
      location.pathname.indexOf('/dashboard') >= 0 ||
      location.pathname.indexOf('/checkout') >= 0 ||
      location.pathname.indexOf('/tickets') >= 0
    ) {
      showThumbExperiences = false;
    } else if (
      location.pathname.indexOf('/experiences/') >= 0 ||
      location.pathname.indexOf('/festivals/') >= 0
    ) {
      showThumbExperiences = false;
      showPageFooter = false;
    }

    return (
      <AppWrapper>
        <MediaQuery query="(min-device-width: 1224px)" className="mediaQueryDiv">
          <NavigationHeader admin user={user} location={location} theme={theme} />
          {React.cloneElement(children, { onUpgradeClick: this.renderUpgradeDialog })}
          {showThumbExperiences && <ThumbExperiences featured={featured} hasShadow theme={theme} />}
          {showPageFooter && <AdminFooter theme={theme || APP_THEMES.WHITE} />}
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          {React.cloneElement(children, { onUpgradeClick: this.renderUpgradeDialog })}
        </MediaQuery>
      </AppWrapper>
    );
  }
}

AppAdmin.propTypes = {
  children: PropTypes.node,
  location: PropTypes.any,
  subscribeUserToPlan: PropTypes.func,
  changeUserSubscription: PropTypes.func,
  route: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  featured: selectFeaturedExperiences(state),
  user: selectUser(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeUserSubscription, subscribeUserToPlan }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppAdmin);
