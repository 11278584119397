import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import CardChannel from 'components/CardChannel';
import Snackbar from 'material-ui/Snackbar';

import * as festivalActions from 'actions/festivals';
import * as actionsAuth from 'actions/auth';
import { map } from 'lodash';
import { selectUser, selectUserActiveChannels } from 'selectors/user';

const styles = {
  constainerSnackbar: {
    position: 'fixed',
    top: '0px',
    height: '25px',
  },
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
  snackbarInfo: {
    backgroundColor: 'rgb(14, 141, 186)',
  },
};

class CreateFestival extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };

    this.handleSelectChannel = this.handleSelectChannel.bind(this);
    this.renderSnackbar = this.renderSnackbar.bind(this);
  }

  handleSelectChannel(channel) {
    const { saveFestivalChannel } = this.props;
    // const { id } = this.props.params;
    try {
      this.setState({ error: '' });
      saveFestivalChannel(channel.id).then(({ id }) => {
        browserHistory.push(`/festivals/${id}/?create=true`);
      });
    } catch (err) {
      this.setState({ error: `Submission error: ${err.message}` });
    }
  }

  handleRequestClose = () => {
    this.setState({
      error: false,
    });
  };

  renderSnackbar() {
    const { error } = this.state;
    return (
      <Snackbar
        open={!!error}
        message={error}
        autoHideDuration={4000}
        bodyStyle={styles.snackbar}
        onRequestClose={this.handleRequestClose}
        style={styles.constainerSnackbar}
      />
    );
  }

  render() {
    const { userChannels: channels } = this.props;
    return (
      <div className="page-container page-container-flex clearfix">
        <div className="container">
          <form className="manage-experiences">
            {this.renderSnackbar()}
            <div className="select-title-channel">My channels</div>
            {channels &&
              map(channels, (channel) => {
                return (
                  <Link key={channel.name} onClick={() => this.handleSelectChannel(channel)}>
                    <CardChannel
                      hover
                      name={channel.name}
                      image={channel.images[0]}
                      background={channel.color}
                    />
                  </Link>
                );
              })}
          </form>
        </div>
      </div>
    );
  }
}

CreateFestival.propTypes = {
  params: PropTypes.object,
  saveChannelExperience: PropTypes.func,
  user: PropTypes.object,
};

export default connect(
  (state) => ({
    user: selectUser(state),
    userChannels: selectUserActiveChannels(state),
  }),
  {
    ...festivalActions,
    ...actionsAuth,
  }
)(CreateFestival);
