import { useCallback, useMemo, useEffect } from 'react';
import moment from 'moment';

import { getFullDate } from 'utils/dates';
import { DEFAULT_TICKET_DESCRIPTIONS } from 'appconstants';

const useCheckoutTicketLogic = ({
  data,
  tickets,
  setSelectTicketType,
  selectedTicket,
  setSelectDays,
}) => {
  // Calculate the start of the 24 hour blocks
  // starting with the festival start time
  const selectableDays = useMemo(() => {
    const days = [];
    if (data) {
      const start = new Date(data.startTime);
      const end = new Date(data.endTime);

      let dayStart = moment(start);

      while (dayStart.isBefore(moment(end))) {
        days.push({
          label: getFullDate(dayStart),
          value: dayStart.toISOString(),
        });

        dayStart = moment(dayStart).add(1, 'days');
      }
    }
    return days;
  }, [data]);

  const setDefaultTicketDays = useCallback(
    ({ days, description }) => {
      const today = moment().startOf('day');
      let newSelectableDays = [...selectableDays];

      // If selected pass is not `all access` then select the amount of days of the ticket
      // based on the selectable days that are at least today
      if (!description.toLowerCase().includes('all')) {
        newSelectableDays = [...newSelectableDays]
          .filter(({ value }) => moment(value).isSameOrAfter(today))
          .slice(0, days);
      }

      setSelectDays(newSelectableDays.map((sd) => sd.value));
    },
    [setSelectDays, selectableDays]
  );

  useEffect(() => {
    if (!selectedTicket && tickets) {
      const today = moment();
      const startDate = moment(data.startTime);

      const daysUntilFestivalStart = startDate.diff(today, 'days');
      const maxSelectableDays =
        moment(data.endTime).diff(startDate, 'days') + daysUntilFestivalStart;

      let selectablePass = [...tickets].filter(
        ({ quantity, isTicket }) => !isTicket && quantity > 0
      );

      if (daysUntilFestivalStart < 0) {
        // Festival has already started
        selectablePass = selectablePass.filter(({ days }) => days <= maxSelectableDays);
      }

      const newSelectedPass = selectablePass.find(
        ({ days }) => !selectablePass.find(({ days: ticketDays }) => ticketDays > days)
      );
      if (newSelectedPass) {
        setSelectTicketType(newSelectedPass._id);
        setDefaultTicketDays(newSelectedPass);
      }
    }
  }, [
    data,
    selectableDays,
    selectedTicket,
    setDefaultTicketDays,
    setSelectDays,
    setSelectTicketType,
    tickets,
  ]);

  // When the selected ticket changes, we should change the default selected dates
  // to the most amount of days possible, starting today
  const onSelectTicket = useCallback(
    (e) => {
      setSelectTicketType(e.target.value);
      const newTicket = tickets.find((ticket) => ticket._id === e.target.value);
      setDefaultTicketDays(newTicket);
    },
    [tickets, setDefaultTicketDays, setSelectTicketType]
  );

  // Get the currently selected ticket description.
  const ticketDescription = useMemo(() => {
    if (!selectedTicket) {
      return '';
    }

    if (selectedTicket.longDescription) {
      return selectedTicket.longDescription;
    }

    // If the selected ticket does not have a description, use the default
    // one based on ticket being `allAccess` or not
    if (selectedTicket.description.toLowerCase().includes('all')) {
      return DEFAULT_TICKET_DESCRIPTIONS.ALL_ACCESS;
    }

    return DEFAULT_TICKET_DESCRIPTIONS.RESTRICTED_ACCESS;
  }, [selectedTicket]);

  // Available passes are `tickets` with the `isTicket` property set to false and that have
  // at least one remaining ticket
  const passes = useMemo(
    () => tickets.filter(({ quantity, isTicket }) => !isTicket && quantity > 0),
    [tickets]
  );

  // This is similar to passes, but with `isTicket` property set to true.
  const festivalTickets = useMemo(
    () => tickets.filter(({ quantity, isTicket }) => isTicket && quantity > 0),
    [tickets]
  );

  return {
    festivalTickets,
    onSelectTicket,
    passes,
    selectableDays,
    setDefaultTicketDays,
    ticketDescription,
  };
};

export const useDisableCheckoutButton = ({
  selectDays,
  selectedTicket,
  selectQty,
  ticketsToPurchase,
}) => {
  const disabledButton = useMemo(() => {
    // User has selected tickets to purchase
    if (Object.keys(ticketsToPurchase || {}).length > 0) {
      return false;
    }

    // User has selected a pass to purchase
    if (selectedTicket && selectQty > 0) {
      // Pass is ticket or all access
      if (selectedTicket.allAccess || selectedTicket.isTicket) {
        return false;
      }

      // User has selected the right amount of days
      if (selectedTicket.days === selectDays.length) {
        return false;
      }
    }

    return true;
  }, [selectDays, selectedTicket, selectQty, ticketsToPurchase]);

  return disabledButton;
};

export default useCheckoutTicketLogic;
