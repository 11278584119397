import React, { useMemo, useCallback, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import useFestivalInformation, { useUpdateFestival } from 'hooks/useFestivalInformation';
import Footer from 'components/WizardComponents/Footer';
import validateDescriptionStep from '../Validations/description';
import validateTickets from '../Validations/tickets';
import Snackbar from 'components/WizardComponents/Snackbar';
import PublishRow from 'components/WizardComponents/PublishRow';
import { withRouter } from 'react-router';
// import routes from 'appconstants/routes';

const Publish = ({ injectedParams, params, goToStep, router, renderSteps }) => {
  const [festivalId] = useState(injectedParams.id || params.id);
  const { isLoading, data } = useFestivalInformation(festivalId);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const mutation = useUpdateFestival(festivalId, 'publish', false, {
    onError: setError,
    onSuccess: () => setDisabled(true),
  });
  const { description, date, tickets, media, guidelines } = useMemo(() => {
    let ticketPrice = 'No tickets';

    if (data && data.tickets && data.tickets.length) {
      if (data.tickets.every((ticket) => data.tickets[0].price === ticket.price)) {
        ticketPrice = `$${data.tickets[0].price}`;
      } else {
        ticketPrice = `$${Math.min.apply(
          Math,
          data.tickets.map(function (o) {
            return o.price;
          })
        )} - $${Math.max.apply(
          Math,
          data.tickets.map(function (o) {
            return o.price;
          })
        )}`;
      }
    }

    return {
      description: {
        title: 'Description',
        text: data?.description || '',
        hasError: data
          ? validateDescriptionStep(data.name, data.description, data.category).length > 0
          : false,
      },
      date: {
        title: 'Date',
        text:
          data && data.startTime && data.endTime
            ? `${moment(data.startTime).format('Do MMM, h:mma')} - ${moment(data.endTime).format(
                'Do MMM, h:mma'
              )}`
            : '',
        hasError: data ? data.startTime >= data.endTime || data.startTime <= new Date() : false,
      },
      tickets: {
        title: 'Tickets',
        text: ticketPrice,
        hasError: data ? !validateTickets(data.tickets) : false,
      },
      media: {
        title: 'Media',
        text: data?.pageDesign?.heroPicture ? 'Cover successfully uploaded.' : '',
        hasError: data?.pageDesign?.heroPicture === '' || false,
      },
      guidelines: {
        title: 'Guidelines',
        text: data && data.guidelines ? `${data.guidelines.length} guidelines` : '',
        hasError: data?.guidelines?.length === 0 || false,
      },
    };
  }, [data]);

  const isValid = useMemo(
    () =>
      !description.hasError &&
      !date.hasError &&
      !tickets.hasError &&
      !media.hasError &&
      !guidelines.hasError,
    [description, date, tickets, media, guidelines]
  );

  const submit = useCallback(() => {
    if (isValid) {
      mutation.mutate(
        { published: true },
        {
          onSuccess: ({ alias, channel: { urlSlug } }) => {
            let redirectUrl = `/${urlSlug}/dashboard`;

            if (data.published) {
              redirectUrl = `/in/${urlSlug}/f/${alias}/dashboard`;
            }

            router.push(redirectUrl);
          },
        }
      );
    } else {
      setError(
        `You are missing to fill fields at: ${[description, date, tickets, media, guidelines]
          .filter((item) => item.hasError)
          .map((item) => item.title)
          .join(', ')}`
      );
    }
  }, [mutation, data, router, date, description, guidelines, isValid, media, tickets]);

  const buttonLabel = useMemo(() => {
    if (disabled) {
      return 'Saved';
    }

    return data?.status === 'published' ? 'Save' : 'Publish';
  }, [data, disabled]);

  return (
    <Container>
      {renderSteps()}
      <Snackbar error={error} onClose={() => setError('')} />
      <Table>
        <PublishRow
          title={description.title}
          description={description.text}
          hasError={description.hasError}
          index={0}
          goToStep={goToStep}
        />
        <PublishRow
          title={date.title}
          description={date.text}
          hasError={date.hasError}
          index={1}
          goToStep={goToStep}
        />
        <PublishRow
          title={tickets.title}
          description={tickets.text}
          hasError={tickets.hasError}
          index={2}
          goToStep={goToStep}
        />
        <PublishRow
          title={media.title}
          description={media.text}
          hasError={media.hasError}
          index={3}
          goToStep={goToStep}
        />
        <PublishRow
          title={guidelines.title}
          description={guidelines.text}
          hasError={guidelines.hasError}
          index={4}
          goToStep={goToStep}
        />
      </Table>
      <Footer
        buttonLabel={buttonLabel}
        lastSaved={data ? data.updatedAt : null}
        disabled={disabled}
        onNextStep={submit}
        saving={isLoading}
        status={data && data.published}
      />
    </Container>
  );
};

const Container = styled.div``;

const Table = styled.div`
  height: 507px;
  width: 653px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
`;

export default withRouter(Publish);
