import React, { useState } from 'react';

import Avatar from 'components/Avatar';

import { formatCurrency } from 'utils/helpers';

import discountIcon from 'images/discount-icon.png';

import './DiscountItem.css';
import { TIME_FORMATS } from 'appconstants/time2';
import { dateFormat } from 'utils/dates';

const DiscountItem = ({
  discount: {
    amountAbsolute,
    used,
    amountPercentage,
    code,
    orders,
    createdOn,
    isPercentage,
    total,
  },
  discount,
  onEdit,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className="content discount-box discount-box--expandable"
      onClick={() => setExpanded(!expanded)}
    >
      <div className="discount-box__details">
        <img className="icon" alt="discount icon" src={discountIcon} />
        <div className="code-container">
          <div className="value">{code}</div>
          <div className="label">
            Created {dateFormat(TIME_FORMATS.NAMED_DAY_AND_MONTH, createdOn)}
          </div>
        </div>
        <div className="discount-box__stats">
          <div className="amount-container">
            <div className="value">
              {isPercentage ? `${amountPercentage}%` : `$${amountAbsolute}`}
            </div>
            <div className="label">discount</div>
          </div>
          <div className="uses-container">
            <div className="value">
              {used}/{total ? total : '∞'}
            </div>
            <div className="label">uses</div>
          </div>
          <button
            className="edit-discount-btn"
            onClick={(e) => {
              e.preventDefault();
              onEdit(discount);
            }}
          >
            Edit
          </button>
        </div>
      </div>
      {expanded && (
        <table onClick={(e) => e.stopPropagation()}>
          <thead>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>QTY</th>
            <th>TICKETS</th>
            <th>COST</th>
          </thead>
          <tbody>
            {orders.map(
              ({
                _id,
                user: { name, photo, email },
                ticketRequest,
                totalQuantity,
                stripeTotal,
              }) => (
                <tr key={_id}>
                  <td>
                    <div className="avatar">
                      <Avatar email={email} image={photo} size={28} isSquare />
                    </div>
                    {name}
                  </td>
                  <td>{email}</td>
                  <td>{totalQuantity}</td>
                  <td>{ticketRequest[0]?.description || '-'}</td>
                  <td>{formatCurrency({ value: stripeTotal / 100 })}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DiscountItem;
