import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './styles.css';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

class ExperienceArtists extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  changeNavigationButtonsVisibility(show) {
    const navigationButtons = document.getElementsByClassName('slide-controls');

    for (let index = 0; index < navigationButtons.length; index += 1) {
      if (show) {
        navigationButtons[index].setAttribute('style', 'visibility: visible;');
      } else {
        navigationButtons[index].setAttribute('style', 'visibility: hidden;');
      }
    }
  }

  changeSlide(slide) {
    this.refs.slider.slickGoTo(slide); // eslint-disable-line
  }

  parseURL(url) {
    // @TODO: For some reason it needs a console.log to work.
    console.log(''); // eslint-disable-line
    url.match(
      /(?:https?:\/\/)?(?:www\.)?(instagram|facebook|twitter)\.com\/(?:#!\/)?@?([^\/\?\s]*)/ // eslint-disable-line
    ); // eslint-disable-line
    const user = RegExp.$2;
    return user ? `@${user}` : 'Website';
  }

  renderNavigationButton(index, array, direction, defaultImg) {
    if ((index > 0 && direction === -1) || (index < array.length - 1 && direction === 1)) {
      let targetIndex = index - 1;
      let image = defaultImg;
      let directionClass = 'left';

      if (direction === 1) {
        targetIndex = index + 1;
        directionClass = 'right';
      }

      // Get the previous or next experience image.
      if (array[targetIndex].images && array[targetIndex].images.length) {
        image = array[targetIndex].images[0].url;
      }

      return (
        <button
          className={`slide-controls ${directionClass}`}
          onClick={() => this.changeSlide(targetIndex)}
        >
          <img src={image} alt="" />
        </button>
      );
    }

    return false;
  }

  renderArtist(artist) {
    const { defaultImg } = this.props;
    const imgUrl =
      (artist.images && artist.images.length > 0 && artist.images[0].url) || defaultImg;
    let socialUrl = artist.social && artist.social.length > 0 && artist.social[0].url;
    socialUrl = socialUrl && socialUrl.trim().replace(/^www/, '//www');

    return (
      <div className="artist-wrapper" style={{ listStyle: 'none' }}>
        <div className="artist-column-left">
          <div className="artist-img">
            <img src={imgUrl} alt={artist.name} />
          </div>
        </div>
        <div className="artist-column-right">
          <div className="artist-content">
            {artist.quote && <h3>“{artist.quote}” </h3>}
            <div>
              <div className="artist-name">
                <h4>{artist.name}</h4>
                {socialUrl && (
                  <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                    {this.parseURL(socialUrl)}
                  </a>
                )}
              </div>
              <p className="profile-description" style={{ whiteSpace: 'pre-wrap' }}>
                {artist.bio}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { artists } = this.props;
    const featuredArtist = artists && artists.some((item) => item.name);

    if (!featuredArtist) {
      return (
        <div>
          <br />
          <br />
        </div>
      );
    }

    return (
      <PageWrapper className="artist artist-wrapper">
        <div className="artist-title">
          <div className="artist-column-left">
            <p> Featuring </p>
          </div>
          <div className="artist-column-right" />
        </div>
        {artists &&
          artists.map((artist) => {
            return this.renderArtist(artist);
          })}
      </PageWrapper>
    );
  }
}

ExperienceArtists.propTypes = {
  artists: PropTypes.array,
  defaultImg: PropTypes.string,
};

export default ExperienceArtists;
