import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { loadExperiences, loadReliveExperiences } from 'actions/experiences';
import { setCurrentChannel, fetchChannels } from 'actions/channels';
import { selectCurrentChannel, selectChannels } from 'selectors/channels';

import MainPageMob from 'containers/MainPageMob';
import AdminFooter from 'components/AdminFooter';
import ChannelList from 'components/ChannelList';
import ListExperiences from 'components/ListExperiences';
import ThumbExperiences from 'components/ThumbExperiences';
import Snackbar from 'material-ui/Snackbar';
import { getMaxExperiences } from 'utils/helpers';
import { EXPERIENCES_TYPES } from 'appconstants';
import {
  selectExperiences,
  selectLoadingExperiences,
  selectFeaturedExperiences,
  selectUpcomingExperiences,
  selectReliveExperiences,
  selectExperiencesUsers,
  selectNextPage,
  selectCanLoadMore,
} from 'selectors/experiences';

import { NUM_ACTIVE_CHANNELS, NUM_ROWS_RELIVE } from './constants';
import './styles.css';
import PresenceSlider from 'components/PresenceSlider/PresenceSlider';
import DefaultHelmet from 'components/DefaultHelmet';
const styles = {
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
  snackbarInfo: {
    backgroundColor: '#69D27C',
  },
};
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

class MainPage extends Component {
  constructor(props) {
    super(props);
    const location = props.router.location;
    this.state = {
      width: window.innerWidth,
      maxExperiences: getMaxExperiences(window.innerWidth),
      view: 'schedule',
      slideChangeTo: -1,
      infoMessage: (location.state && location.state.infoMessage) || false,
      message: (location.state && location.state.message) || null,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData(this.props.params.channel, true);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize, false);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.params.channel !== newProps.params.channel) {
      this.loadData(newProps.params.channel);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize, false);
  }

  onResize = () => {
    this.setState({
      width: window.innerWidth,
      maxExperiences: getMaxExperiences(window.innerWidth),
    });
  };

  getUpcomingExperiences = () => {
    const { upcomingExperiences } = this.props;

    return upcomingExperiences;
  };

  getReliveExperiences = () => {
    const { reliveExperiences } = this.props;
    const { maxExperiences } = this.state;
    return reliveExperiences
      .filter(({ type }) => type === EXPERIENCES_TYPES.VIRTUAL)
      .slice(0, maxExperiences * NUM_ROWS_RELIVE);
  };

  getFirstNChannels = ({ experienceList, initial, total }) => {
    const channels = {};
    let channelQnty = 0;

    initial.forEach((channel) => {
      channels[channel.id] = channel;
    });

    for (let i = 0; i < experienceList.length; i += 1) {
      const { channel } = experienceList[i];
      if (!channels[channel.id]) {
        channels[channel.id] = channel;
        channelQnty += 1;
      }

      if (channelQnty === total) break;
    }
    return Object.values(channels);
  };

  getActiveChannels = () => {
    const { upcomingExperiences, reliveExperiences } = this.props;

    let channelList = this.getFirstNChannels({
      experienceList: upcomingExperiences,
      initial: [],
      total: NUM_ACTIVE_CHANNELS,
    });

    if (channelList.length < NUM_ACTIVE_CHANNELS) {
      const experiencesNotShown = reliveExperiences.filter(
        ({ channel: { urlSlug } }) =>
          !channelList.find(({ urlSlug: channelSlug }) => channelSlug === urlSlug)
      );

      channelList = this.getFirstNChannels({
        experienceList: experiencesNotShown,
        initial: channelList,
        total: NUM_ACTIVE_CHANNELS - channelList.length,
      });
    }

    return channelList;
  };

  updateBackgroundColor() {
    const { currentChannel } = this.props;
    const backgroundColor = currentChannel && `background: ${currentChannel.color || '#000'};`;
    const divDgr = document.getElementById('degradeDiv');

    if (divDgr) {
      divDgr.setAttribute('style', `height: 100%; ${backgroundColor}`);
    }
  }

  changeSlide(slide) {
    this.updateBackgroundColor();
    this.refs.slider.slickGoTo(slide); // eslint-disable-line
  }

  async loadData(channel, reset = true) {
    const {
      loadExperiences,
      loadReliveExperiences,
      fetchChannels,
      setCurrentChannel,
      channels,
      nextPage,
    } = this.props; // eslint-disable-line

    if (!channels.length) {
      await fetchChannels();
    }

    setCurrentChannel(channel);

    loadExperiences(channel, nextPage, reset);

    if (reset) {
      loadReliveExperiences(channel, nextPage, reset);
    }
  }

  renderSpinner() {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <MainCircularProgress size={250} thickness={2} />
      </div>
    );
  }

  render() {
    const {
      experiences,
      loading,
      currentChannel,
      featured,
      // reliveExperiences,
      experiencesUsers,
      params,
      loadingRelive,
      // loadingUpcoming,
      loadingFeatured,
      canLoadMore,
    } = this.props;

    const { width, infoMessage, message } = this.state;
    const settings = {
      arrows: false,
      centerMode: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      draggable: false,
    };

    const upcomingExperiencesRow = this.getUpcomingExperiences();
    const filteredReliveExperiences = this.getReliveExperiences();
    const activeChannels = this.getActiveChannels();

    this.updateBackgroundColor();

    return (
      <div className="main-page-container">
        <DefaultHelmet />
        <MediaQuery query="(min-device-width: 1224px)">
          <PageWrapper style={{ height: '100%' }}>
            <div className="section-slideshow page-container-flex">
              {(loadingFeatured || !experiences) && this.renderSpinner()}
              {!loadingFeatured && !!featured.length && (
                <PresenceSlider
                  backgroundColor={
                    currentChannel ? `background: ${currentChannel.color || '#000'};` : ''
                  }
                  settings={settings}
                  featured={featured}
                  channel={currentChannel}
                  experiencesUsers={experiencesUsers}
                  slideChangeTo={this.state.slideChangeTo}
                />
              )}
              {!loadingFeatured && !featured.length && (
                <div
                  style={{
                    textAlign: 'center',
                    margin: '40px 20px 20px',
                    color: '#fff',
                  }}
                >
                  <h2>No experiences available</h2>
                  <p>We are sorry, but there are no experiences available.</p>
                </div>
              )}
            </div>
            {featured?.length > 1 && (
              <ThumbExperiences
                onClickThumb={(slide) => this.setState({ ...this.state, slideChangeTo: slide })}
                hasShadow
                featured={featured}
              />
            )}
            {!loadingRelive && (
              <>
                <ListExperiences
                  experiences={upcomingExperiencesRow}
                  className="main-page-list-experiences"
                  alignment="left"
                  width={width}
                  title="Upcoming"
                />
                {canLoadMore && (
                  <div className="load-more-container">
                    <LoadMoreButton
                      className="load-more-button"
                      disabled={loading}
                      onClick={() => this.loadData(params.channel, false)}
                    >
                      {loading ? (
                        <CircularProgress size={25} thickness={2} />
                      ) : (
                        'Load more experiences'
                      )}
                    </LoadMoreButton>
                  </div>
                )}
                <ListExperiences
                  experiences={filteredReliveExperiences}
                  className="main-page-list-experiences"
                  alignment="left"
                  width={width}
                  title="Re-live"
                />
                {!currentChannel && <ChannelList channels={activeChannels} />}
              </>
            )}
            <Snackbar
              open={!!infoMessage}
              message={message}
              autoHideDuration={4000}
              onRequestClose={() => this.setState({ infoMessage: false, message: '' })}
              bodyStyle={infoMessage ? styles.snackbarInfo : styles.snackbar}
            />
            <AdminFooter />
          </PageWrapper>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <MainPageMob
            view={this.state.view}
            setView={(view) => this.setState({ ...this.state, view })}
          />
        </MediaQuery>
      </div>
    );
  }
}

const MainCircularProgress = styled(CircularProgress)`
  & .MuiCircularProgress-circle {
    color: #fff;
  }
`;

const LoadMoreButton = styled.button`
  cursor: pointer;
  height: 54px;
  width: 230px;
  border-radius: 8px;
  border: 2px solid #9b63f8;
  background-color: #ffffff;
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  outline: none;
  transition: background-color 0.25s;

  & .MuiCircularProgress-circle {
    color: #9b63f8;
  }

  &:hover {
    background-color: #9b63f8;
    border: 2px solid #9b63f8;
    color: #ffffff;
  }

  &:hover .MuiCircularProgress-circle {
    color: #fff;
  }
`;

MainPage.propTypes = {
  loadExperiences: PropTypes.func,
  loading: PropTypes.bool,
  params: {
    channel: PropTypes.string,
  },
  channels: PropTypes.array,
  experiences: PropTypes.array,
  currentChannel: PropTypes.object,
  featured: PropTypes.array,
  reliveExperiences: PropTypes.array,
  upcomingExperiences: PropTypes.array,
  setCurrentChannel: PropTypes.func,
  nextPage: PropTypes.string,
  canLoadMore: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  experiences: selectExperiences(state),
  loading: selectLoadingExperiences(state),
  loadingUpcoming: state.experiences.loadingUpcomingExperiences,
  loadingFeatured: state.experiences.loadingFeaturedExperiences,
  loadingRelive: state.experiences.loadingReliveExperiences,
  experiencesUsers: selectExperiencesUsers(state),
  currentChannel: selectCurrentChannel(state),
  featured: selectFeaturedExperiences(state),
  upcomingExperiences: selectUpcomingExperiences(state),
  reliveExperiences: selectReliveExperiences(state),
  channels: selectChannels(state),
  nextPage: selectNextPage(state),
  canLoadMore: selectCanLoadMore(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadExperiences,
      fetchChannels,
      setCurrentChannel,
      loadReliveExperiences,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPage));
