import React, { useCallback, useEffect, useState } from 'react';
import Footer from 'components/WizardComponents/Footer';
import styled from 'styled-components';
import useFestivalInformation, { useUpdateFestival } from 'hooks/useFestivalInformation';
import Snackbar from 'components/WizardComponents/Snackbar';
import { arrayOf, shape, string, func } from 'prop-types';
import { hasChanges } from '../Validations/utils';
import GuidelineItem from 'components/WizardComponents/GuidelineItem';

const Guidelines = ({ nextStep, params, injectedParams, renderSteps }) => {
  const [festivalId] = useState(injectedParams.id || params.id);
  const { isLoading, data } = useFestivalInformation(festivalId);
  const mutation = useUpdateFestival(festivalId, 'guidelines');
  const [guidelines, setGuidelines] = useState([]);
  const [error, setError] = useState('');

  const validate = useCallback(() => {
    if (guidelines.length === 0) {
      const errorMessage = `Missing required fields: Guidelines`;
      setError(errorMessage);
      return;
    } else {
      setError('');
    }

    const data = {
      guidelines,
    };

    const pairs = [[guidelines, data.guidelines]];

    if (hasChanges(pairs)) {
      mutation.mutate(data);
    }

    nextStep();
  }, [nextStep, mutation, setError, guidelines]);

  useEffect(() => {
    if (data && data.guidelines) {
      setGuidelines(data.guidelines);
    }
  }, [data, setGuidelines]);

  return (
    <Container>
      {renderSteps(validate)}
      <Snackbar error={error} onClose={() => setError('')} />
      {guidelines.map((guideline, index) => (
        <GuidelineItem
          key={index}
          guideline={guideline}
          setGuideline={(value) => {
            if (value !== guidelines[index]) {
              guidelines[index] = value;
              setGuidelines([...guidelines]);
            }
          }}
          removeGuideline={() =>
            setGuidelines((prevState) => prevState.filter((_, i) => i !== index))
          }
        />
      ))}
      <AddNewGuidelineButton onClick={() => setGuidelines([...guidelines, ''])}>
        + Add guide
      </AddNewGuidelineButton>
      <Footer
        lastSaved={data ? data.updatedAt : null}
        onNextStep={validate}
        saving={isLoading}
        status={data && data.published ? 'Saved' : 'Draft'}
      />
    </Container>
  );
};

const Container = styled.div``;

const AddNewGuidelineButton = styled.button`
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
`;

Guidelines.propTypes = {
  channels: arrayOf(shape()),
  nextStep: func.isRequired,
  status: string,
  updateFestivalInformation: func.isRequired,
};

export default Guidelines;
