import React from 'react';
import styled from 'styled-components';
import { getResizedImageURL } from 'utils/helpers';
import { IMAGE_SIZES } from 'appconstants';
import WhiteClose from 'images/white-close.png';
import { withRouter } from 'react-router';

const CheckoutContent = ({ data, router }) => (
  <ContentContainer>
    <GoBack onClick={router.goBack} alt="go-back" src={WhiteClose} />
    <FestivalPicture
      alt="festival-picture"
      src={getResizedImageURL(data.pageDesign.heroPicture, IMAGE_SIZES.MEDIUM)}
    />
  </ContentContainer>
);

const GoBack = styled.img`
  height: 16px;
  width: 16px;
  position: absolute;
  top: 35%;
  left: 18px;
`;

const FestivalPicture = styled.img`
  height: 5rem;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

const ContentContainer = styled.div`
  height: 5rem;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.03);
  position: relative;
`;

export default withRouter(CheckoutContent);
