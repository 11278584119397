import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from 'material-ui';

const divStyle = {
  color: 'white',
};

const Loading = ({ message }) => (
  <div>
    <CircularProgress size={30} thickness={2} color={'#9B63F8'} style={{ margin: '10px' }} />
    <div style={divStyle}>{message || 'loading, please wait...'}</div>
  </div>
);

Loading.propTypes = {
  message: PropTypes.string,
};

export default Loading;
