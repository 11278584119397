import React from 'react';
import PropTypes from 'prop-types';
import checkIcon from 'images/checked-1.png';
import Button from 'components/Button';
import { BUTTON_STYLES } from 'appconstants';

import CheckoutHeader from '../CheckoutHeader';
import './styles.css';

const ExperienceGuidelines = (props) => {
  const { experience, goToTickets } = props;

  return (
    <section className="body-container">
      <div className="container page-guideline">
        <CheckoutHeader experience={experience} />
        <div className="guidelines-wrapper clearfix">
          <h3 className="guidelines-legend">{`Thank you for joining us. Here’s how we’d like you to show up with us`}</h3>
          <ul className="guidelines-list">
            {experience &&
              experience.guidelines &&
              experience.guidelines.map(
                (guideline) => (
                  <li className="guidelines-item-wrapper">
                    <div>
                      <div className="guidelines-check">
                        <img alt="" src={checkIcon} />
                      </div>
                    </div>
                    <div
                      htmlFor="guideline1"
                      className="guidelines-item"
                      dangerouslySetInnerHTML={{
                        // eslint-disable-line react/no-danger
                        __html: guideline.guideline,
                      }}
                    />
                  </li>
                ) // eslint-disable-line
              )}
          </ul>
          <Button
            type="button"
            className="guidelines-button"
            onClick={goToTickets}
            buttonStyle={BUTTON_STYLES.PURPLE_SQUARE}
          >
            I AGREE
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ExperienceGuidelines;

ExperienceGuidelines.propTypes = {
  experience: PropTypes.object,
  goToTickets: PropTypes.func,
};
