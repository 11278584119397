import React from 'react';
import { Route, IndexRoute } from 'react-router';
import App from 'containers/App';
import AppAdmin from 'containers/AppAdmin';
import TicketsPage from 'containers/TicketsPage';
import CheckoutPage from 'containers/CheckoutPage';
import ExperienceProfilePage from 'containers/ExperienceProfilePage';
import ExperiencesPage from 'containers/ExperiencesPage';
import ExperienceManagerPage from 'containers/ExperienceManagerPage';
import MainPage from 'containers/MainPage';
import MainPageChannel from 'containers/MainPageChannel';
import SignupPage from 'containers/SignupPage';
import PrivacyPage from 'containers/PrivacyPage';
import RelivePage from 'containers/RelivePage';
import TermsPage from 'containers/TermsPage';
import NotFoundPage from 'containers/NotFoundPage';
import SettingsPage from 'containers/SettingsPage';
import ExperienceGuestList from 'containers/ExperienceGuestList';
import ExperienceDashboard from 'containers/ExperienceDashboard';
import FestivalDashboard from 'containers/FestivalDashboard';
import CreateExperience from 'containers/CreateExperience';
import OnBoardingPage from 'containers/OnBoardingPage';
import SelectChannel from 'containers/SelectChannel';
import SetupStripePage from 'containers/SetupStripe/SetupStripePage';
import FinishSetupStripe from 'containers/SetupStripe/FinishStripeSetup';
import ForgotPasswordPage from 'containers/ForgotPasswordPage';
import ResetPasswordPage from 'containers/ResetPasswordPage';
import FestivalLandingPage from 'containers/FestivalLandingPage/FestivalLandingPage';
import { requireAuthentication } from 'utils/helpers';
import { SIGNUP_TABS } from 'appconstants';
import { APP_THEMES } from 'appconstants/colors';
import routes from 'appconstants/routes';
import CreateFestival from 'containers/CreateFestival';
import FestivalWizard from 'containers/FestivalWizard';
import CampWizard from 'containers/CampWizard';
import FestivalCheckout from 'containers/FestivalCheckout';
import CheckoutLogin from 'containers/CheckoutLogin';

export default (
  <Route>
    <Route path="/tickets" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={TicketsPage} />
    </Route>
    <Route path="/tickets/:channel/:alias" component={AppAdmin}>
      <IndexRoute component={CheckoutPage} />
    </Route>
    <Route path="/experiences" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={ExperiencesPage} />
      <Route path=":id" component={ExperienceManagerPage} />
    </Route>

    <Route path="/create-experience" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={CreateExperience} />
    </Route>

    <Route path={routes.createFestival} component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={CreateFestival} />
    </Route>

    <Route path="/select-channel/:id" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={SelectChannel} />
    </Route>

    <Route path={routes.onBoarding} component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={OnBoardingPage} />
    </Route>

    <Route path={`/checkout/${SIGNUP_TABS.signup}`} component={AppAdmin}>
      <IndexRoute component={CheckoutLogin} />
    </Route>
    <Route path={`/checkout/${SIGNUP_TABS.signin}`} component={AppAdmin}>
      <IndexRoute component={CheckoutLogin} />
    </Route>
    <Route path={`/${SIGNUP_TABS.signup}`} component={SignupPage} />
    <Route path={`/${SIGNUP_TABS.signin}`} component={SignupPage} />
    <Route path={`/${SIGNUP_TABS.signup}/:token`} component={SignupPage} />
    <Route path={`/${SIGNUP_TABS.signin}/:token`} component={SignupPage} />

    <Route path="/stripe" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={SetupStripePage} />
    </Route>
    <Route path="/stripe/confirm" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={FinishSetupStripe} />
    </Route>
    <Route path="/privacy" component={PrivacyPage} />
    <Route path="/terms" component={TermsPage} />

    <Route path={routes.relive} theme={APP_THEMES.DARK} component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={RelivePage} />
    </Route>

    <Route path="/in/:channel/:alias/guestlist" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={ExperienceGuestList} />
    </Route>

    <Route path="/in/:channel/:alias/dashboard" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={ExperienceDashboard} />
    </Route>

    <Route path="/in/:channel/f/:alias/dashboard" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={FestivalDashboard} />
    </Route>

    <Route path="/in/:channel/:alias" component={App}>
      <IndexRoute component={ExperienceProfilePage} />
    </Route>

    <Route path="/in/:channel" component={App}>
      <IndexRoute component={MainPageChannel} />
    </Route>

    <Route path={routes.festivalsManager} component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={FestivalWizard} />
    </Route>
    <Route path={routes.campsManager} component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={CampWizard} />
    </Route>

    <Route path="/in/:channel/f/:id/checkout" component={AppAdmin}>
      <IndexRoute component={FestivalCheckout} />
    </Route>

    <Route path="/in/:channel/f/:alias" component={App}>
      <IndexRoute component={FestivalLandingPage} />
    </Route>
    <Route path="/forgot-password" component={ForgotPasswordPage} />
    <Route path="/reset-password/:token" component={ResetPasswordPage} />
    <Route path="/:channel/dashboard" component={requireAuthentication(AppAdmin)}>
      <IndexRoute component={ExperiencesPage} />
    </Route>
    {['account', ':channel'].map((route) => (
      <Route key={route} path={`${route}/:tab`} component={requireAuthentication(AppAdmin)}>
        <IndexRoute component={SettingsPage} />
      </Route>
    ))}
    <Route path="/" component={requireAuthentication(App)}>
      <IndexRoute component={MainPage} />
      <Route path="*" component={NotFoundPage} />
    </Route>
  </Route>
);
