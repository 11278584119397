import React from 'react';
import { string } from 'prop-types';

const BackArrowIcon = ({ color = '#FFF', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g stroke={color} strokeWidth="2" transform="translate(-16 -24)">
        <g transform="matrix(0 1 1 0 17 25)">
          <path d="M0 8L8.96969732 0 17.4545468 8" />
          <path d="M9 0L9 22" />
        </g>
      </g>
    </g>
  </svg>
);

BackArrowIcon.propTypes = {
  color: string,
};

export default BackArrowIcon;
