import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import api from 'api';
import { useEffect } from 'react';

import steps from 'containers/FestivalWizard/Steps';

export const useFestivalCMS = (alias, channel, section) => {
  return useQuery(
    `festivalCMS-${section}`,
    async () => await api.festivals.getFestivalCMSData(alias, channel, section)
  );
};

const useFestivalInformation = (festivalId, options = {}) => {
  return useQuery(
    `festival-${festivalId}`,
    async () => await api.festivals.getFestivalInformation(festivalId),
    { refetchOnWindowFocus: false, ...options }
  );
};

export const useFestivalExperiences = (festivalId) => {
  return useInfiniteQuery(
    'festival-infinite',
    async ({ pageParam }) =>
      api.festivals.getFestivalInformation(festivalId, pageParam).then((data) => {
        return data.experiences;
      }),
    {
      getNextPageParam: (lastPage, page) => lastPage.nextPage,
    }
  );
};

export const useSetLastStep = ({
  setLastStep,
  setSetLastStep,
  goToStep,
  data,
  isLoading,
  setLastCompletedStep,
}) =>
  useEffect(() => {
    if (!setLastStep || isLoading || !data) {
      return;
    }

    if (data.status === 'published') {
      setLastCompletedStep(Object.keys(steps).length);
    } else if (data?.lastStep) {
      goToStep(data.lastStep);
      setLastCompletedStep(data?.lastStep);
    }

    setSetLastStep(false);
  }, [setLastStep, setSetLastStep, goToStep, data, isLoading, setLastCompletedStep]);

export const useLastStep = (id) => {
  return useQuery(
    `festival-${id}-lastStep`,
    async () => await api.festivals.getFestivalInformation(id),
    { refetchOnWindowFocus: false, force: true, cacheTime: 0 }
  );
};

export const useUpdateFestival = (festivalId, step, isFormData = false, options) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      return api.festivals.updateFestivalInformation(festivalId, step, data, isFormData);
    },
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries(`festival-${festivalId}`);
        queryClient.setQueryData(`festival-${festivalId}`, {
          ...queryClient.getQueryData(`festival-${festivalId}`),
          ...data,
        });
        if (options && options.onSuccess) {
          options.onSuccess(queryClient.getQueryData(`festival-${festivalId}`));
        }
      },
      onError: (error, _val) => {
        if (options && options.onError) {
          options.onError(
            error.response?.data?.error || error.response?.data?.message || 'Cannot update festival'
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(`festival-${festivalId}`);
      },
    }
  );
};

export default useFestivalInformation;
