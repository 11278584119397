import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from 'material-ui';
import { browserHistory } from 'react-router';

import CardSelector from 'components/CardSelector';

import * as actionsExperiences from 'actions/experiences';
import * as actionsAuth from 'actions/auth';
// import * as festivalsActions from 'actions/festivals';
import { EXPERIENCES_TYPES } from 'appconstants';

const styles = {
  spiner: {
    textAlign: 'center',
    width: '100%',
    paddingTop: '50px',
  },
};

class CreateExperience extends React.Component {
  constructor(props) {
    super(props);
    this.goToCreateExperience = this.goToCreateExperience.bind(this);
    this.state = {
      creatingExp: false,
      filter: 'upcoming',
    };

    props.fetch();
  }

  onSelectFilter = (filter) => {
    this.setState({ filter });
  };

  async goToCreateExperience(type = EXPERIENCES_TYPES.VIRTUAL) {
    const { createNewExperience, currentChannel } = this.props;
    if (currentChannel?.id) {
      try {
        this.setState({ creatingExp: true });
        const experience = await createNewExperience({ type, channel: currentChannel.id });
        browserHistory.replace(`/experiences/${experience.id}/?create=true`);
      } catch (error) {
      } finally {
        this.setState({ creatingExp: false });
      }
    }
  }

  renderSpinner() {
    return (
      <div style={styles.spiner}>
        <CircularProgress size={90} />
      </div>
    );
  }

  render() {
    return (
      <div className="page-container page-container-flex clearfix">
        <div className="container">
          <form className="manage-experiences">
            <div className="select-title-channel">Select Type</div>

            <CardSelector
              title="Virtual experiences"
              subtitle="For experiences hosted online"
              handlerOnClick={() => this.goToCreateExperience(EXPERIENCES_TYPES.VIRTUAL)}
            />

            <CardSelector
              title="Physical experiences"
              subtitle="For experiences hosted on a physical location"
              handlerOnClick={() => this.goToCreateExperience(EXPERIENCES_TYPES.PHYSICAL)}
            />

            {this.state.creatingExp && this.renderSpinner()}
          </form>
        </div>
      </div>
    );
  }
}

CreateExperience.propTypes = {
  // createNewEmptyExperience: PropTypes.func,
  fetch: PropTypes.func,
};

export default connect(
  (state) => ({
    experiences: state.experiences,
    loading: state.experiences.loading,
    currentChannel: state.channels.currentChannel,
  }),
  {
    ...actionsExperiences,
    ...actionsAuth,
  }
)(CreateExperience);
