import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Gravatar from 'react-gravatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from 'material-ui/Divider/Divider';
import { withRouter } from 'react-router';

import { logout } from 'actions/auth';

import routes from 'appconstants/routes';
import { APP_THEMES } from 'appconstants/colors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { USER_TYPES } from 'appconstants';
import { DROP_MENU_SHADOW_STYLE } from 'containers/App/constants';

const UserIcon = ({ user, router, theme, logout }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(null);
  }, [setAnchorEl, router.location]);

  if (user.email === 'guest') {
    return (
      <AvatarWrapper
        login
        onClick={() =>
          router.push({
            pathname: routes.signIn,
            state: {
              redirectPath: router.location.pathname,
            },
          })
        }
      >
        Login
      </AvatarWrapper>
    );
  }

  return (
    <>
      <AvatarWrapper onClick={(event) => setAnchorEl(event.currentTarget)} theme={theme}>
        <HamburgerIcon theme={theme} />
        {user?.photo ? (
          <Avatar src={user.photo} />
        ) : (
          <StyledGravatar email={user.email} size={29} />
        )}
      </AvatarWrapper>
      <StyledMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        PaperProps={DROP_MENU_SHADOW_STYLE}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        <StyledMenuItem onClick={() => router.push(routes.tickets)}>
          <StyledListItemText primary="My tickets" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => router.push(routes.accountPage)}>
          <StyledListItemText primary="Account" />
        </StyledMenuItem>
        {user.maxRole !== USER_TYPES.GUEST && (
          <StyledMenuItem onClick={() => router.push(routes.paymentPage)}>
            <StyledListItemText primary="My payouts" />
          </StyledMenuItem>
        )}
        <Divider style={{ marginTop: 10 }} />
        <StyledMenuItem onClick={logout}>
          <StyledListItemText primary="Logout" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const AvatarWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 5px 0 15px;
  box-sizing: border-box;
  width: 76px;
  cursor: pointer;
  transition: all 0.25s;

  ${({ theme }) =>
    theme === APP_THEMES.TRANSPARENT
      ? `
    border: 2px solid rgba(255,255,255,0.12);

    &:hover {
      border-color: rgba(255,255,255,0.6);

      & span,
      & span::before,
      & span::after {
        background-color: rgba(255,255,255,1);
      }
    }
  `
      : `
    border: 2px solid #D5D5D5;
  `}

  ${({ login }) =>
    login &&
    `
    opacity: 0.6;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-family: CircularStd Book;
    font-size: 16px;
    height: 40px;
    color: #fff;
    justify-content: center;
    margin-right: 6px;
    padding: 0 8px;
    width: 136px;

    &:hover {
      opacity: 1;
    }
  `}
`;

const Avatar = styled.img`
  border-radius: 4px;
  height: 26px;
  width: 26px;
`;

const HamburgerIcon = styled.span`
  &,
  &::before,
  &::after {
    border-radius: 1px;
    border-width: 1px;
    border-style: solid;
    transition: all 0.25s;

    ${({ theme }) =>
      theme === APP_THEMES.TRANSPARENT
        ? `
      border-color: rgba(255,255,255,0.12);
      background-color: rgba(255,255,255,0.12);
    `
        : `
      background-color: #626262;
      border-color: #626262;
    `}

    display: inline-block;
    height: 0px;
    opacity: 0.55;
    position: relative;
    width: 15px;
    flex: 0 0 auto;
  }

  &::before,
  &::after {
    content: ' ';
    opacity: 1;
  }

  &::before {
    left: -0.5px;
    position: absolute;
    top: -7px;
  }

  &::after {
    position: absolute;
    bottom: -7px;
    left: -0.5px;
  }
`;

const StyledGravatar = styled(Gravatar)`
  width: 29px;
  height: 29p;
  border-radius: 4px;
`;

const StyledListItemText = styled(ListItemText)`
  && span {
    font-family: CircularStd Book !important;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    width: 154px;
    padding: 0 16px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    color: #9c9c9c;
    background-color: transparent !important;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    padding-left: 0;
    margin-right: 30px;

    &:hover {
      color: #9b63f8;
      background-color: transparent;
      border-bottom-color: #9b63f8;
    }
  }
`;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserIcon));
