import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';

import Loading from 'components/Loading';

import Tickets from './Tickets';

const TicketingPage = ({ loading, onTicketChange, tickets, channel, experience }) => {
  if (loading) return <Loading message="Loading Tickets..." />;

  return (
    <div className="tickets-wrapper experience-wrapper">
      {experience && experience.festival ? (
        <div />
      ) : (
        <FieldArray
          name="tickets"
          component={Tickets}
          onTicketChange={onTicketChange}
          tickets={
            experience && experience.festival ? experience.festivalInformation.tickets : tickets
          }
          channel={channel}
          disabled={experience && experience.festival}
        />
      )}
    </div>
  );
};

TicketingPage.propTypes = {
  onTicketChange: PropTypes.func,
  tickets: PropTypes.array,
  loading: PropTypes.bool,
  channel: PropTypes.object,
};

export default TicketingPage;
