const SAVE_FLOW_REQUEST = 'SAVE_FLOW_REQUEST';
const SAVE_FLOW_SUCCESS = 'SAVE_FLOW_SUCCESS';
const SAVE_FLOW_FAILURE = 'SAVE_FLOW_FAILURE';
const UPDATE_FLOW_REQUEST = 'UPDATE_FLOW_REQUEST';
const UPDATE_FLOW_SUCCESS = 'UPDATE_FLOW_SUCCESS';
const UPDATE_FLOW_FAILURE = 'UPDATE_FLOW_FAILURE';
const LOAD_FLOW_REQUEST = 'LOAD_FLOW_REQUEST';
const LOAD_FLOW_SUCCESS = 'LOAD_FLOW_SUCCESS';
const LOAD_FLOW_FAILURE = 'LOAD_FLOW_FAILURE';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_FLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        image: action,
      };
    case SAVE_FLOW_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_FLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        artist: action,
      };
    case UPDATE_FLOW_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LOAD_FLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        image: action,
      };
    case LOAD_FLOW_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
