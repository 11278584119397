const locations = [
  {
    id: 'AL',
    description: 'AL',
  },
  {
    id: 'AK',
    description: 'AK',
  },
  {
    id: 'AZ',
    description: 'AZ',
  },
  {
    id: 'AR',
    description: 'AR',
  },
  {
    id: 'CA',
    description: 'CA',
  },
  {
    id: 'CO',
    description: 'CO',
  },
  {
    id: 'CT',
    description: 'CT',
  },
  {
    id: 'DE',
    description: 'DE',
  },
  {
    id: 'DC',
    description: 'DC',
  },
  {
    id: 'FL',
    description: 'FL',
  },
  {
    id: 'GA',
    description: 'GA',
  },
  {
    id: 'HI',
    description: 'HI',
  },
  {
    id: 'ID',
    description: 'ID',
  },
  {
    id: 'IL',
    description: 'IL',
  },
  {
    id: 'IN',
    description: 'IN',
  },
  {
    id: 'IA',
    description: 'IA',
  },
  {
    id: 'KS',
    description: 'KS',
  },
  {
    id: 'KY',
    description: 'KY',
  },
  {
    id: 'LA',
    description: 'LA',
  },
  {
    id: 'ME',
    description: 'ME',
  },
  {
    id: 'MD',
    description: 'MD',
  },
  {
    id: 'MA',
    description: 'MA',
  },
  {
    id: 'MI',
    description: 'MI',
  },
  {
    id: 'MN',
    description: 'MN',
  },
  {
    id: 'MO',
    description: 'MO',
  },
  {
    id: 'MT',
    description: 'MT',
  },
  {
    id: 'NE',
    description: 'NE',
  },
  {
    id: 'NV',
    description: 'NV',
  },
  {
    id: 'NH',
    description: 'NH',
  },
  {
    id: 'NJ',
    description: 'NJ',
  },
  {
    id: 'NM',
    description: 'NM',
  },
  {
    id: 'NY',
    description: 'NY',
  },
  {
    id: 'NC',
    description: 'NC',
  },
  {
    id: 'ND',
    description: 'ND',
  },
  {
    id: 'OH',
    description: 'OH',
  },
  {
    id: 'OK',
    description: 'OK',
  },
  {
    id: 'OR',
    description: 'OR',
  },
  {
    id: 'PA',
    description: 'PA',
  },
  {
    id: 'RI',
    description: 'RI',
  },
  {
    id: 'SC',
    description: 'SC',
  },
  {
    id: 'SD',
    description: 'SD',
  },
  {
    id: 'TN',
    description: 'TN',
  },
  {
    id: 'TX',
    description: 'TX',
  },
  {
    id: 'UT',
    description: 'UT',
  },
  {
    id: 'VT',
    description: 'VT',
  },
  {
    id: 'VA',
    description: 'VA',
  },
  {
    id: 'WA',
    description: 'WA',
  },
  {
    id: 'WV',
    description: 'WV',
  },
  {
    id: 'WI',
    description: 'WI',
  },
  {
    id: 'WY',
    description: 'WY',
  },
];

export default locations;
