import React, { useMemo, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { ListItemText, Menu, MenuItem, Divider } from '@material-ui/core';
import { FontIcon } from 'material-ui';

import routes from 'appconstants/routes';
import { fetchChannels, setCurrentChannel } from 'actions/channels';

import {
  dashboardAccessFinder,
  getChannelPermissions,
  getChannelsWithDashboardAccess,
} from 'utils/helpers';

import { USER_TYPES } from 'appconstants';
import { updateUser } from 'actions/auth';
import { DROP_MENU_SHADOW_STYLE } from 'containers/App/constants';

const DashboardAdminMenu = ({
  channels,
  currentChannel,
  fetchChannels,
  router,
  setCurrentChannel,
  updateUser,
  user,
}) => {
  const channelsLength = channels.length;
  useEffect(() => {
    const channelSlug = router.params.channel;
    const isOnboarding = router.location.pathname === routes.onBoarding;
    const isUserSettings = /^\/account/.test(router.location.pathname);

    if (isOnboarding || !channelSlug) {
      return;
    }

    if (!currentChannel) {
      const setChannel = (urlSlug) => {
        setCurrentChannel(urlSlug);
      };

      const loadChannel = async () => {
        if (!channelsLength) {
          await fetchChannels();
          await updateUser();
        }

        if (channelSlug) {
          const channelToPick = user?.channels?.find(({ urlSlug }) => urlSlug === channelSlug);

          if (channelToPick) {
            setChannel(channelToPick.urlSlug);
          }
        } else {
          const channel = user?.channels.find(dashboardAccessFinder);

          if (!isUserSettings) {
            router.replace(routes.dashboard(channel?.urlSlug));
          } else {
            setChannel(channel?.urlSlug);
          }
        }
      };

      loadChannel();
    } else {
      if (currentChannel.urlSlug !== channelSlug) {
        if (!isUserSettings) {
          router.replace(routes.dashboard(currentChannel.urlSlug));
        }

        return;
      }

      const channelData = getChannelPermissions({ id: currentChannel.id, user });

      if (channelData.pendingSetup) {
        router.replace('/welcome');
      }
    }
  }, [currentChannel, setCurrentChannel, user, router, fetchChannels, updateUser, channelsLength]);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(null);
  }, [currentChannel, setAnchorEl]);

  const userChannels = useMemo(() => getChannelsWithDashboardAccess(user.channels), [user]).filter(
    ({ urlSlug, name }) => name && urlSlug
  );

  const isCurrentChannelAdmin = useMemo(() => {
    if (user?.channels && currentChannel?.id) {
      const currentChannelData = user.channels.find(({ id }) => id === currentChannel.id);

      if (currentChannelData) {
        return currentChannelData?.isOwner || currentChannelData?.role === USER_TYPES.ADMIN;
      }
    }

    return false;
  }, [user, currentChannel]);
  const menuItems = [
    <StyledMenuItem onClick={() => router.push(routes.dashboard(currentChannel?.urlSlug))}>
      <ListItemText primary="Dashboard" />
    </StyledMenuItem>,
    isCurrentChannelAdmin && (
      <StyledMenuItem onClick={() => router.push(routes.channelPage(currentChannel?.urlSlug))}>
        <ListItemText primary="Settings" />
      </StyledMenuItem>
    ),
    userChannels.length > 1 && <Divider style={{ marginTop: 10 }} />,

    userChannels.length > 1 && (
      <ChannelsMenuItem>
        {userChannels.map(({ images: [icon], color, name, urlSlug }, index) => (
          <Icon
            key={index}
            color={color}
            onClick={() => {
              setCurrentChannel(urlSlug);
              browserHistory.push(`/in/${urlSlug}`);
            }}
            src={icon}
            title={name}
          />
        ))}
      </ChannelsMenuItem>
    ),
  ];
  return (
    <>
      <StyledFontIcon
        active={!!anchorEl ? 'active' : ''}
        className="material-icons"
        onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
      >
        more_horiz
      </StyledFontIcon>
      <StyledMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        PaperProps={DROP_MENU_SHADOW_STYLE}
        anchorOrigin={{ horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setAnchorEl(null)}
      >
        {menuItems}
      </StyledMenu>
    </>
  );
};

const ChannelsMenuItem = styled.div`
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 10px;

  & img:nth-child(2n) {
    margin: 0 auto;
  }

  & img:last-child {
    margin: 0 auto 15px 5px;
    justify-self: flex-start;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    padding: 0 16px;
    width: 154px;
  }
`;

const Icon = styled.img`
  border-radius: 30px;
  cursor: pointer;
  height: 30px;
  margin: 0 5px 15px;
  width: 30px;

  background-color: ${({ color }) => color};
`;

const StyledFontIcon = styled(FontIcon)`
  && {
    height: 36px;
    align-items: center;
    display: flex !important;
    align-items: center;
    opacity: 0.25;
    width: 25px;
    height: 25px;
    margin-left: 3px;
    cursor: pointer;
    transition: all 0.25s !important;

    ${({ active }) =>
      active &&
      `
      opacity: 1 !important;
      color: #9B63F8 !important;
    `}

    &:hover {
      opacity: 1 !important;
      color: #9b63f8 !important;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    background-color: transparent !important;
    border-bottom: 2px solid transparent;
    color: #9c9c9c;
    font-size: 14px;
    margin-right: 30px;
    padding-left: 0;

    & span {
      font-family: CircularStd Book;
    }

    &:hover {
      background-color: transparent;
      border-bottom-color: #9b63f8;
      color: #9b63f8;
    }
  }
`;

const mapStateToProps = (state) => ({
  currentChannel: state.channels.currentChannel,
  channels: state.channels,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentChannel,
      fetchChannels,
      updateUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardAdminMenu));
