import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { without } from 'lodash';

import { GridTile, IconButton } from 'material-ui';
import IconDelete from 'material-ui/svg-icons/action/delete';

import uploadIcon from 'images/uploadFile.png';
import TGGridList from 'components/GridList';
import TGErrorMessage from 'components/ErrorMessage';

import { FILE_UPLOADER_TYPES } from 'appconstants';
import './style.css';

const styles = {
  [FILE_UPLOADER_TYPES.SQUARE]: {
    boxSizing: 'border-box',
    height: '93px',
    width: '207px',
    borderRadius: '6px',
    marginLeft: '5px',
    marginRight: '15px',
    backgroundColor: 'rgb(243, 243, 244)',
    float: 'left',
  },
  gridTile: {
    height: 97,
    background: '#ccc',
    cursor: 'pointer',
    borderRadius: '8px',
  },
  [FILE_UPLOADER_TYPES.LOGO]: {
    clipPath: 'circle(50px at center)',
    width: '116px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [FILE_UPLOADER_TYPES.INLINE]: {},
  [FILE_UPLOADER_TYPES.MULTIPLE]: {
    marginTop: '34px',
  },
};

const FilePreview = ({
  files,
  type = FILE_UPLOADER_TYPES.SQUARE,
  meta,
  input,
  large,
  cols,
  multipleLastColumn,
  photo,
}) => {
  const { value, onChange } = input;
  const { touched, error } = meta;

  const showFile = (fileToShow) => {
    window.open(fileToShow.url || fileToShow.preview, '_blank');
  };

  const removeFile = (e, fileToRemove) => {
    e.preventDefault();
    e.stopPropagation();

    const newFiles = without(value, fileToRemove);
    if (fileToRemove.id) newFiles.push({ id: fileToRemove.id, delete: true });

    onChange(newFiles);
  };

  const renderColumn = (fileToPreview) => {
    if (fileToPreview?.dropzone) return multipleLastColumn;
    // if (fileToPreview) {
    //   const { id, name, url, preview } = fileToPreview;
    // }

    // const key = id || name;

    return (
      <GridTile
        style={styles.gridTile}
        className={cn({ 'file-image-wrapper': large })}
        key={fileToPreview?.id}
        title={fileToPreview?.id}
        onClick={() => showFile(fileToPreview || photo)}
        actionIcon={
          <IconButton onClick={(e) => removeFile(e, fileToPreview || photo)}>
            <IconDelete color="white" />
          </IconButton>
        }
      >
        <img src={photo || fileToPreview?.url || fileToPreview?.preview || fileToPreview} alt="" />
      </GridTile>
    );
  };

  const isSquare = type === FILE_UPLOADER_TYPES.SQUARE;
  const isMultiple = type === FILE_UPLOADER_TYPES.MULTIPLE;

  return (
    <div className={cn({ 'file-uploader-wrapper': large, 'file-uploader-container': isSquare })}>
      <div style={styles[type]}>
        <TGGridList items={files} cols={cols || 1} renderItem={renderColumn} />
        {isMultiple && error && <TGErrorMessage error={error} />}
      </div>
      {isSquare && (
        <div className={cn('button-upload', { 'input-error': touched && error })}>
          <img src={uploadIcon} alt="" width="39px" style={{ margin: '30px' }} />
        </div>
      )}
    </div>
  );
};

FilePreview.propTypes = {
  files: PropTypes.array,
  meta: PropTypes.object,
  input: PropTypes.object,
  type: PropTypes.oneOf([
    FILE_UPLOADER_TYPES.SQUARE,
    FILE_UPLOADER_TYPES.LOGO,
    FILE_UPLOADER_TYPES.INLINE,
    FILE_UPLOADER_TYPES.MULTIPLE,
  ]),
  cols: PropTypes.number,
  multipleLastColumn: PropTypes.node,
};

export default FilePreview;
